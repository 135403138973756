export const map_numbers = (
	num: number,
	in_min: number,
	in_max: number,
	out_min: number,
	out_max: number
): number => {
	return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
}

export const remapNumbers = map_numbers

export const lerp = (v0: number, v1: number, t: number): number => {
	return v0 * (1 - t) + v1 * t
}

export const degrees_to_radians = (degrees: number): number => {
	var pi = Math.PI
	return degrees * (pi / 180)
}

export const radians_to_degrees = (radians: number): number => {
	var pi = Math.PI
	return radians * (180 / pi)
}

export const round2 = (v: number): number => {
	v = Math.round(v * 100) / 100
	return v
}
