import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import Input from "src/components/input/input"
import Select from "src/components/input/select"
import useSWR from "swr"
import DashboardHeader from "../../../../components/dashboardHeader/dashboardHeader"
import { HasAuth } from "../../../../components/privateRoute/privateRoute"
import useHandleChange from "../../../../hooks/useHandleChange"
import { fetchApiAuthorized } from "../../../../network/fetch"

const CreateUser = ({ auth }: HasAuth) => {
	const { t } = useTranslation()
	const { id } = useParams() as Record<string, string>
	const navigate = useNavigate()

	const [title, setTitle] = useHandleChange("")
	const [name, setName] = useHandleChange("")
	const [email, setEmail] = useHandleChange("")
	const [role, setRole] = useHandleChange(undefined)
	const { data: currentUser } = useSWR(
		id ? [`/users/user/${id}`, auth.token, auth.expiresIn] : null,
		fetchApiAuthorized
	)

	useEffect(() => {
		if (currentUser) {
			setTitle(currentUser.title)
			setName(currentUser.name)
			setEmail(currentUser.email)
			setRole(currentUser.role)
		}
	}, [currentUser, setTitle, setName, setEmail, setRole])

	const save = async () => {
		const endpoint = id
			? `/users/moderators/${id}`
			: "/users/moderators/register"

		const success = await fetchApiAuthorized(
			endpoint,
			auth.token,
			auth.expiresIn,
			{ title, email, name, role },
			id ? "PUT" : "POST"
		)
		if (success) {
			navigate("/moderator/user")
		}
	}

	const disabled =
		name.length === 0 || role === undefined || !email.includes("@")

	return (
		<>
			<DashboardHeader
				buttonDisabled={disabled}
				buttonOnClick={save}
				buttonTitle={t("save") as string}
				cancelButtonOnClick={() => navigate("/moderator/user")}
				cancelButtonTitle={t("cancel") as string}
				title={id ? (t("edit_user") as string) : (t("create_user") as string)}
			/>

			<div className="flex flex-col px-5 py-4 my-1 space-y-8">
				<div className="flex flex-row items-baseline space-x-2.5">
					<p className="w-12">{t("title")}</p>
					<Input
						className="flex-1"
						placeholder="…"
						value={title}
						onChange={setTitle}
					/>
				</div>

				<div className="flex flex-row items-baseline space-x-2.5">
					<p className="w-12">{t("name")}</p>
					<Input placeholder="…" type="name" value={name} onChange={setName} />
				</div>

				<div className="flex flex-row items-baseline space-x-2.5">
					<p className="w-12">{t("email")}</p>
					<Input
						placeholder="…"
						type="email"
						value={email}
						onChange={setEmail}
					/>
				</div>

				{/* // TODO: dont use option, make it like other */}
				<div className="flex flex-row items-baseline">
					<p className="w-12">{t("role")}</p>
					<Select value={role} onChange={setRole}>
						<option value="default" disabled selected>
							{t("select_role")}
						</option>
						<option value="moderator">{t("moderator")}</option>
						<option value="admin">{t("admin")}</option>
						<option value="support">Support</option>
					</Select>
				</div>
			</div>
		</>
	)
}

export default CreateUser
