import { useRef } from "react"
import { useTranslation } from "react-i18next"
import DndContainer from "src/components/dndContainer/dndContainer"
import Icon, { IconImage, MoveIcon } from "src/components/iconImage/iconImage"
import Input from "src/components/input/input"
import useDnd from "../../hooks/useDnd"
import useHandleChange from "../../hooks/useHandleChange"
import CheckBox from "../checkBox/checkBox"

interface AnswerProps {
	id: string | number
	index: number
	moveCard: (dragIndex: number, hoverIndex: number) => void
	isSolution: boolean
	onDelete: () => void
	setSolution: (isSolution: boolean) => void
	onChangeAnswer: (value: string) => void
	initialValue: string
	showIcons?: boolean
}

const Answer = ({
	id,
	index,
	moveCard,
	isSolution = false,
	setSolution,
	initialValue,
	onChangeAnswer,
	onDelete,
	showIcons = true,
}: AnswerProps) => {
	const { t } = useTranslation()
	const ref = useRef<HTMLDivElement>(null!)
	const opacity = useDnd(ref, id, index, moveCard)
	const [answer, setAnswer] = useHandleChange(initialValue, onChangeAnswer)

	return (
		<DndContainer opacity={opacity} ref={ref}>
			<Input className="shadow-none mr-5" value={answer} onChange={setAnswer} />
			<CheckBox
				checked={isSolution}
				style={{ marginRight: "10px" }}
				title={(t("correct_answer") as string) ?? ""}
				onCheck={(isSolution) => setSolution(isSolution)}
			/>
			{showIcons && (
				<div className="flex flex-row">
					<Icon iconImage={IconImage.Trash} onClick={onDelete} />
					<MoveIcon />
				</div>
			)}
		</DndContainer>
	)
}

export default Answer
