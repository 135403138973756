import {
	cloneElement,
	forwardRef,
	ReactElement,
	useEffect,
	useState,
} from "react"
import { useTranslation } from "react-i18next"
import { useKeyPressEvent } from "react-use"
import { CustomError, useErrorStore } from "../../state/stores/errorStore"
import Button from "../button/button"

// TODO: press enter not closing anymore
interface AlertProps {
	error: CustomError | undefined
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(({ error }, ref) => {
	const { t } = useTranslation()
	const [title, setTitle] = useState("")
	const [description, setDescription] = useState("")
	const [transform, setTransform] = useState("scale(0.9) rotateX(0deg)")
	const [custom, setCustom] = useState<ReactElement>()
	const [destructive, setDestructive] = useState<boolean>(false)

	const close = () => {
		setTransform("scale(0.9) rotateX(0deg)")

		useErrorStore.getState().clear()
	}

	useKeyPressEvent("Enter", close)
	useKeyPressEvent("Escape", close)

	useEffect(() => {
		if (
			error &&
			error.message &&
			error.message.length > 0 &&
			error.message !== description &&
			error.destructive !== undefined
		) {
			setTitle(
				error.title === "Error" || error.title === "TypeError"
					? t("general_error")
					: error.title
			)
			setDescription(
				error.message === "Unknown Error" || error.message === "Failed to fetch"
					? t("general_error_description")
					: error.message
			)

			if (transform === "scale(0.9) rotateX(0deg)") {
				window.requestAnimationFrame(function () {
					setTransform("scale(1.0) rotateX(0deg)")
				})
			}

			if (error.component) {
				setCustom(error.component)
			}

			setDestructive(error.destructive)
		}
	}, [error, description, transform, t])

	return (
		<div
			className="fixed left-0 right-0 top-0 bottom-0 z-50 flex items-center justify-center bg-black bg-opacity-20"
			ref={ref}
		>
			<div
				className="rounded-xl flex flex-col bg-white transition-all duration-300 overflow-hidden"
				style={{ transform, transformOrigin: "top center" }}
			>
				<div className="flex flex-col md:flex-row items-start md:items-center pt-6 px-5 pb-2 text-left md:text-center">
					<div
						className={`rounded-full p-1 mr-3 mb-3 ${
							destructive ? "bg-s-alert-destructive" : "bg-s-alert"
						}`}
						// destructive={destructive}
					>
						{destructive ? (
							<svg
								className="w-7 block stroke-s-red"
								fill="none"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
								/>
							</svg>
						) : (
							<svg
								className="w-7 block stroke-s-yellow"
								fill="none"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
								/>
							</svg>
						)}
					</div>

					<div className="flex flex-col">
						<h1
							style={{
								marginBottom: "10px",
								fontSize: "18px",
								fontWeight: 500,
							}}
						>
							{title}
						</h1>
						<p className="mb-2.5 text-slate-400 max-w-[500px] leading-relaxed">
							{description}
						</p>
					</div>
				</div>

				<div className="bg-slate-50 px-5 py-3 flex justify-end flex-col-reverse md:flex-row">
					{custom ? (
						cloneElement(custom, { close })
					) : (
						<Button onClick={close}>{t("close")}</Button>
					)}
				</div>
			</div>
		</div>
	)
})

export default Alert
