import create from "zustand"

interface SensorStore extends Record<string, unknown> {
	data: number[] | undefined
	connected: boolean

	onSensor: (data: number[]) => void
	connect: (connected: boolean) => void
}

export const useSensorStore = create<SensorStore>((set, get) => {
	return {
		data: undefined,
		connected: false,

		onSensor: (data) => {
			if (data !== undefined) {
				set({
					data: data as number[],
				})
			}
		},

		connect: (connected) => {
			if (connected !== undefined) {
				set({ connected: connected })
			}
		},

		reset: () => {
			set({ data: undefined, connected: false })
		},
	}
})
