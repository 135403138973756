import { useTranslation } from "react-i18next"
import Icon, { IconImage } from "src/components/iconImage/iconImage"

interface AddItemProps {
	onClick: () => void
}

const AddItem = ({ onClick }: AddItemProps) => {
	const { t } = useTranslation()

	return (
		<div
			className="flex items-center justify-center font-semibold text-s-blue flex-1 cursor-pointer shadow-md space-x-2.5 bg-white border border-slate-400 rounded-xl transition-all mb-5 py-3 px-6"
			onClick={onClick}
		>
			<Icon iconImage={IconImage.Add} />
			{t("add_question")}
		</div>
	)
}

export default AddItem
