import QRCode from "qrcode.react"
import { useEffect, useState } from "react"
import CheckBox from "src/components/checkBox/checkBox"
import Select from "src/components/input/select"
import { HasAuth } from "src/components/privateRoute/privateRoute"
import { FRONT_BASE_URL } from "src/constants/constants"
import { fetchApiAuthorized } from "src/network/fetch"
import useSWR from "swr"

const OptionsSidebar = ({
	auth,
	code,
	onChangePatient,
}: HasAuth & {
	code: string
	onChangePatient: (
		volumeUrl: string,
		mirroredLeftRight: boolean,
		mirroredFrontBack: boolean
	) => void
}) => {
	const { data: patients } = useSWR(
		["/patients", auth.token, auth.expiresIn],
		fetchApiAuthorized
	)

	const [menuVisible, setMenuVisible] = useState(true)
	const [patient, setPatient] = useState<undefined | string>(undefined)

	const [mirroredHorizontal, setMirroredHorizontal] = useState(false)
	const [mirroredBackFront, setMirroredBackFront] = useState(false)

	useEffect(() => {
		const _patient =
			patients && patient
				? patients.find((p: { id: string }) => p.id === patient)
				: undefined
		if (_patient) {
			onChangePatient(_patient.volumeUrl, mirroredHorizontal, mirroredBackFront)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [patient, mirroredHorizontal, mirroredBackFront])

	return (
		<div
			className="fixed right-5 top-5 rounded-xl flex flex-col items-stretch justify-start bg-white z-50 overflow-hidden transition-all"
			style={{
				width: menuVisible ? "280px" : "122px",
				height: menuVisible ? "506px" : "50px",
			}}
		>
			<div
				className="flex flex-row self-stretch items-center justify-end p-3 cursor-pointer"
				onClick={() => setMenuVisible((v) => !v)}
			>
				<h4 className="mr-0.5 font-normal select-none">Optionen</h4>
				<svg
					className="stroke-s-black w-4 h-4 transition-transform"
					fill="none"
					strokeWidth={3}
					style={{
						transform: `rotate(${menuVisible ? "180deg" : "0deg"})`,
					}}
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M19 9l-7 7-7-7"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>

			<div className="flex flex-col items-stretch justify-start p-3 space-y-3 bg-slate-50">
				<Select
					className="w-full max-h-11"
					value={patient ?? "default"}
					onChange={(newPatient) => setPatient(newPatient)}
				>
					<option value="default" disabled>
						Patient auswählen
					</option>
					{patients &&
						patients.map((v: Record<string, unknown>) => {
							return (
								<option key={v.id as string} value={v.id as string}>
									{v.name as string}
								</option>
							)
						})}
				</Select>

				<div className="flex flex-col">
					<p className="font-medium mb-2">Smartphone verbinden:</p>
					<QRCode size={256} value={`${FRONT_BASE_URL}/connect/${code}`} />
					<p>{code}</p>
				</div>

				<div className="flex flex-col">
					<p className="font-medium mb-2">Spiegeln:</p>

					<div
						className={`flex flex-row items-center justify-between ${
							patient === undefined
								? "pointer-events-none opacity-50"
								: "pointer-events-auto opacity-100"
						}`}
					>
						<CheckBox
							checked={mirroredHorizontal}
							title="Links/Rechts"
							onCheck={setMirroredHorizontal}
						/>
						<CheckBox
							checked={mirroredBackFront}
							title="Vorne/Hinten"
							onCheck={setMirroredBackFront}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default OptionsSidebar
