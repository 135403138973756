import moment from "moment"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Button, { ButtonTheme } from "src/components/button/button"
import { ListContainer, ListRow } from "src/components/list/list"
import useSWR from "swr"
import DashboardBody from "../../../components/dashboardBody/dashboardBody"
import DashboardHeader from "../../../components/dashboardHeader/dashboardHeader"
import { HasAuth } from "../../../components/privateRoute/privateRoute"
import { fetchApiAuthorized } from "../../../network/fetch"
import { useErrorStore } from "../../../state/stores/errorStore"

const WebinarsRunning = ({ auth }: HasAuth) => {
	const { data: webinars, mutate } = useSWR(
		["/webinars/running", auth.token, auth.expiresIn],
		fetchApiAuthorized
	)

	const navigate = useNavigate()
	const { t } = useTranslation()

	const deletionConfirmation = useErrorStore(
		(state) => state.deleteConfirmation
	)

	return (
		<DashboardBody>
			<DashboardHeader title={t("webinars_running") as string} />

			<ListContainer>
				{webinars === undefined
					? "Loading…"
					: webinars.map((v: Record<string, unknown>, i: number) => (
							<ListRow
								className="mb-5"
								key={v.id as string}
								showDivider={false}
							>
								<div className="flex flex-row space-x-5">
									<>
										<p>{moment(v.date as string).format("DoMoYYYY, HH:mm")}</p>
										{v.title && (
											<p className="text-s-blue">{v.title as string}</p>
										)}
									</>
								</div>
								<div className="flex flex-row space-x-5">
									{moment(v.date as string).isSame(moment(), "d") && (
										<Button
											onClick={() =>
												navigate(`/moderator/webinar/live/${v.id}`)
											}
										>
											{t("start_webinar")}
										</Button>
									)}

									<Button
										theme={ButtonTheme.White}
										onClick={() =>
											window.open(
												`/moderator/webinar/schedule/${v.id}`,
												"_blank"
											)
										}
									>
										Programm
									</Button>

									<Button
										theme={ButtonTheme.WhiteOnRed}
										onClick={() => {
											deletionConfirmation(async () => {
												await fetchApiAuthorized(
													`/webinars/quit/${v.id}`,
													auth.token,
													auth.expiresIn,
													undefined,
													"POST"
												)

												mutate()
											}, `In dem Webinar sind User ${v.onlineUsers} eingeloggt`)
										}}
									>
										{t("quit")}
									</Button>
								</div>
							</ListRow>
					  ))}
			</ListContainer>
		</DashboardBody>
	)
}

export default WebinarsRunning
