// @ts-ignore
import { stackHelperFactory, VolumeLoader } from "ami.js"
import {
	MutableRefObject,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react"
import * as THREE from "three"
import { PerspectiveCamera, Scene, Vector3, WebGLRenderer } from "three"
import { usePatientStore } from "../../../state/stores/patientStore"
import { useSensorStore } from "../../../state/stores/sensorStore"
import { degrees_to_radians, map_numbers } from "../../../utils/math"
import { JsonVector, jsonVectorToTuple } from "../../../utils/vector"
import MirrorOptions from "../../mirrorOptions/mirrorOptions"
import UserPatientInformation from "../../patientInformation/patientInformation"
import PatientLocation from "../../patientLocation/patientLocation"
import ProgressBar from "../../progressBar/progressBar"
import SegmentedControl from "../../segmentedControl/segmentedControl"
import Loading from "./loading"

interface PatientProps {
	patient: Record<string, unknown>
	code: string
	showTweaks?: boolean
}

const Patient = ({ patient, code, showTweaks = true }: PatientProps) => {
	const threeDContainer = useRef<HTMLDivElement>(null)

	const mirrorLeftRightRef = useRef(patient.mirrored)
	const mirrorFrontBackRef = useRef(patient.mirroredEnds)

	const renderer = useRef<WebGLRenderer>(
		new WebGLRenderer({
			antialias: true,
		})
	)
	const scene = useRef(new Scene())
	const camera: MutableRefObject<PerspectiveCamera | null> =
		useRef<PerspectiveCamera>(null)
	const stackHelper: MutableRefObject<any | null> = useRef(null)

	const _zoom = useRef(0)
	const [zoom, setZoom] = useState(0)

	const [isLoading, setIsLoading] = useState(true)

	const data = useRef<number[]>([0, 0, 0])

	const position = useRef<HTMLParagraphElement>(null)
	const beta = useRef<HTMLParagraphElement>(null)
	const gamma = useRef<HTMLParagraphElement>(null)

	useEffect(() =>
		useSensorStore.subscribe((state) => {
			if (
				stackHelper.current &&
				state.data &&
				position.current &&
				beta.current &&
				gamma.current
			) {
				const worldBBox = stackHelper.current.stack.worldBoundingBox()

				const distance = (worldBBox[5] - worldBBox[4]) * 0.05
				const newData = [
					map_numbers(
						state.data[0],
						mirrorFrontBackRef.current ? 1 : 0,
						mirrorFrontBackRef.current ? 0 : 1,
						worldBBox[4] + distance,
						worldBBox[5] - distance
					),
					state.data[1],
					state.data[2],
				]

				data.current = newData
				position.current.textContent = `Position: ${Math.round(newData[0])}`
				beta.current.textContent = `β: ${newData[1]}`
				gamma.current.textContent = `γ: ${newData[2]}`
			}
		})
	)

	const updateGeometries = useCallback(() => {
		if (stackHelper.current && camera.current && data.current) {
			let planeDir = new Vector3(0, 0, mirrorLeftRightRef.current ? 1 : -1)

			planeDir.applyAxisAngle(
				new Vector3(0, 1, 0),
				degrees_to_radians(data.current[2])
			)
			planeDir.applyAxisAngle(
				new Vector3(1, 0, 0),
				degrees_to_radians(data.current[1])
			)

			stackHelper.current.slice.planeDirection = planeDir
			stackHelper.current.slice.planePosition.z = data.current[0]

			let distance = 200
			if (_zoom.current === 1) {
				distance = 150
			} else if (_zoom.current === 2) {
				distance = 100
			}

			camera.current.position.x =
				stackHelper.current.slice.planePosition.x + planeDir.x * distance
			camera.current.position.y =
				stackHelper.current.slice.planePosition.y + planeDir.y * distance
			camera.current.position.z =
				stackHelper.current.slice.planePosition.z + planeDir.z * distance

			camera.current.lookAt(stackHelper.current.slice.planePosition)
			camera.current.updateProjectionMatrix()
		}
	}, [])

	const animate = useCallback(() => {
		updateGeometries()
		render()

		// request new frame
		setTimeout(() => requestAnimationFrame(animate), 1000 / 30)
	}, [updateGeometries])

	useEffect(() => {
		if (!threeDContainer.current) {
			return
		}

		renderer.current.setSize(
			threeDContainer.current.offsetWidth,
			threeDContainer.current.offsetHeight
		)
		renderer.current.setClearColor("black", 1)
		renderer.current.setPixelRatio(0.5)
		threeDContainer.current.appendChild(renderer.current.domElement)

		camera.current = new PerspectiveCamera(
			45,
			threeDContainer.current.offsetWidth /
				threeDContainer.current.offsetHeight,
			0.01,
			10000000
		)
		camera.current.up.set(0, -1, 0)

		// @ts-ignore
		const loader = new VolumeLoader(threeDContainer.current, ProgressBar)

		let url = patient.volumeUrl
		if (url) {
			loader.load(url).then(() => {
				const series = loader.data[0].mergeSeries(loader.data)[0]
				const stack = series.stack[0]
				// @ts-ignore
				const StackHelper = stackHelperFactory(THREE)

				stackHelper.current = new StackHelper(stack)
				stackHelper.current.bbox._visible = false
				stackHelper.current.border._visible = false
				const centerLPS = stackHelper.current.stack.worldCenter()
				stackHelper.current.slice.aabbSpace = "LPS"
				stackHelper.current.slice.planePosition.x = centerLPS.x
				stackHelper.current.slice.planePosition.y = centerLPS.y
				stackHelper.current.slice.planePosition.z = centerLPS.z
				stackHelper.current.slice.thickness = 0
				stackHelper.current.slice.spacing = 1
				stackHelper.current.slice.interpolation = 1
				scene.current.add(stackHelper.current)
				stackHelper.current.slice.planeDirection = new THREE.Vector3(0, 0, 1)

				if (camera.current) {
					camera.current.lookAt(centerLPS.x, centerLPS.y, centerLPS.z)
					camera.current.updateProjectionMatrix()
				}
				animate()
			})
		}
	}, [threeDContainer, animate, patient.volumeUrl])

	useEffect(() =>
		usePatientStore.subscribe((state) => {
			if (state.mode === "done") setIsLoading(false)
		})
	)

	const render = () => {
		if (!camera || !camera.current) return

		renderer.current.render(scene.current, camera.current)
	}

	return (
		<div className="rounded-xl self-center w-full h-full bg-black flex items-center relative">
			<div className="absolute flex flex-col items-start top-7 left-7">
				<div className="rounded-xl bg-white flex py-1 px-2">
					<p className="w-24" ref={position}>
						Position:
					</p>
					<p className="w-12" ref={beta}>
						β:
					</p>
					<p className="w-12" ref={gamma}>
						γ:
					</p>
				</div>
			</div>

			<UserPatientInformation
				anamnesis={patient.descriptionHtml as string}
				code={code}
			/>

			<div className="flex flex-row items-center absolute bottom-7 left-7 space-x-2.5 text-white">
				<p>Vergrößern:</p>
				<div className="flex bg-white rounded-xl shadow-md">
					<SegmentedControl
						options={[
							{ title: "100%", value: 0 },
							{ title: "150%", value: 1 },
							{ title: "200%", value: 2 },
						]}
						value={zoom}
						onChange={(value) => {
							_zoom.current = value
							setZoom(value)
						}}
					/>
				</div>
			</div>

			<div
				className="w-full h-full overflow-hidden rounded-xl"
				ref={threeDContainer}
			/>

			{isLoading && <Loading />}

			{showTweaks && (
				<MirrorOptions
					mirroredFront={patient.mirroredEnds as boolean}
					mirroredLeft={patient.mirrored as boolean}
					onMirrorFront={(mirrorFront) =>
						(mirrorFrontBackRef.current = mirrorFront)
					}
					onMirrorLeft={(mirrorLeft) =>
						(mirrorLeftRightRef.current = mirrorLeft)
					}
				/>
			)}

			<div className="rounded-xl min-w-[250px] flex flex-col justify-between absolute bottom-3 right-3 overflow-hidden mt-5 bg-transparent opacity-80">
				<PatientLocation
					end={jsonVectorToTuple((patient.sonoPositions as JsonVector[])[1])}
					start={jsonVectorToTuple((patient.sonoPositions as JsonVector[])[0])}
					style={{ height: "400px" }}
				/>
			</div>
		</div>
	)
}

export default Patient
