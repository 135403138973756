import { useTranslation } from "react-i18next"
import { useSocketStore } from "../../../state/stores/socketStore"
import Button from "../../button/button"

const Sendoff = () => {
	const { t } = useTranslation()
	const quit = useSocketStore((state) => state.emit.quit)

	return (
		<div className="flex flex-col items-center flex-1 space-y-5">
			<h2>{t("quit_practinar")}</h2>
			<Button onClick={quit}>{t("quit_short")}</Button>
		</div>
	)
}

export default Sendoff
