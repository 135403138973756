import { forwardRef } from "react"
import { Link } from "react-router-dom"
import { sidebarIcons } from "./sidebarIcons"

interface NavigationPointProps {
	active: boolean
	title: string
	pathname: string
	icon: string
	onMouseOver: () => void
}

const NavigationPoint = forwardRef<HTMLAnchorElement, NavigationPointProps>(
	({ active, title, pathname, icon, onMouseOver }, ref) => {
		return (
			<Link
				className={`font-normal block mb-5 leading-[23px] ${
					active ? "text-white" : "text-s-black"
				} cursor-pointer transition-all`}
				ref={ref}
				style={{ transitionDelay: active ? "50ms" : "0" }}
				to={pathname}
				onMouseEnter={onMouseOver}
			>
				<div className="flex flex-row items-center">
					<svg
						className={`transition-all w-5 h-5 mr-2 ${
							active ? "delay-75" : "delay-[0ms]"
						}`}
						fill={`${active ? "white" : "rgb(42, 47, 53)"}`}
						height="20"
						viewBox="0 0 20 20"
						width="20"
						xmlns="http://www.w3.org/2000/svg"
					>
						{sidebarIcons(icon)}
					</svg>
					{title}
				</div>
			</Link>
		)
	}
)

export default NavigationPoint
