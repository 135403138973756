import { DetailedHTMLProps, InputHTMLAttributes } from "react"

const Input = ({
	className,
	...props
}: Omit<
	DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
	"style"
>) => {
	return (
		<input
			className={`appearance-none px-4 py-3 focus:p-[11px] border border-slate-400 shadow-md rounded-xl flex-1 min-w-0 text-sm transition-all outline-none hover:border-s-blue focus:border-2 focus:border-s-blue ${className}`}
			{...props}
		/>
	)
}

export default Input
