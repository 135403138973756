import { useFrame } from "@react-three/fiber"
import { RefObject, useEffect, useRef } from "react"
import {
	BufferGeometry,
	CatmullRomCurve3,
	Intersection,
	Mesh,
	Object3D,
	Raycaster,
	Vector3,
	WebGLRenderer,
} from "three"
import { degrees_to_radians } from "../../utils/math"
import { VectorTuple } from "../../utils/vector"
import AnchorPoint from "../selectPosition/anchorPoint"

interface SelectPathProps {
	start: VectorTuple
	end: VectorTuple
	body: RefObject<Object3D>
	showAnchors?: boolean
	onChange?: (start: VectorTuple, end: VectorTuple) => void
}

const SelectPath = ({
	start = [0, 0, 0],
	end = [0, 0, 0],
	body,
	showAnchors = false,
	onChange,
}: SelectPathProps) => {
	const width = 0.03
	const ref = useRef<BufferGeometry>(null)

	const _start = useRef(start)
	const _end = useRef(start)

	const startAnchor = useRef<Mesh>(null)
	const endAnchor = useRef<Mesh>(null)

	useEffect(() => {
		_start.current = [start[0], 0.1, start[2]]
	}, [start])

	useEffect(() => {
		_end.current = [end[0], 0.1, end[2]]
	}, [end])

	useFrame(() => {
		if (body && body.current && start && end && ref && ref.current) {
			const zeroVector = new Vector3(
				_end.current[0] - _start.current[0],
				_end.current[1] - _start.current[1],
				_end.current[2] - _start.current[2]
			)
				.normalize()
				.applyAxisAngle(new Vector3(0, 1, 0), degrees_to_radians(90))

			const cornerPointsRaw = [
				new Vector3(
					_start.current[0] - zeroVector.x * width,
					_start.current[1],
					_start.current[2] - zeroVector.z * width
				),
				new Vector3(
					_start.current[0] + zeroVector.x * width,
					_start.current[1],
					_start.current[2] + zeroVector.z * width
				),
				new Vector3(
					_end.current[0] + zeroVector.x * width,
					_end.current[1],
					_end.current[2] + zeroVector.z * width
				),
				new Vector3(
					_end.current[0] - zeroVector.x * width,
					_end.current[1],
					_end.current[2] - zeroVector.z * width
				),
			]

			const endPoints = cornerPointsRaw.map((v) => new Vector3(v.x, 1, v.z))

			const raycasted = endPoints
				.map((point) => {
					const raycaster = new Raycaster()
					raycaster.set(point, new Vector3(0, -1, 0))

					if (!body.current) return undefined

					const intersections = raycaster
						.intersectObject(body.current)
						.sort((a: Intersection, b: Intersection) => a.distance - b.distance)

					return intersections.map((v) => v.point)[0]
				})
				.flat()
				.filter((element) => element !== undefined) as Vector3[]

			if (raycasted.length === 4) {
				const curve = new CatmullRomCurve3(raycasted, true)
				const cornerPoints = curve.getPoints(4)

				ref.current.setFromPoints(cornerPoints)
			}
		}

		if (startAnchor.current && _start.current) {
			startAnchor.current.position.x = _start.current[0]
			startAnchor.current.position.y = _start.current[1]
			startAnchor.current.position.z = _start.current[2]
		}

		if (endAnchor.current && _end.current) {
			endAnchor.current.position.x = _end.current[0]
			endAnchor.current.position.y = _end.current[1]
			endAnchor.current.position.z = _end.current[2]
		}
	})

	return (
		<>
			<line
				// @ts-ignore
				renderOrder={999}
				onBeforeRender={(r: WebGLRenderer) => r.clearDepth()}
			>
				<bufferGeometry attach="geometry" ref={ref} />
				<lineBasicMaterial
					attach="material"
					color={"#0071FF"}
					linejoin="round"
				/>
			</line>

			{showAnchors && (
				<>
					<AnchorPoint
						ref={startAnchor}
						isStart
						onChange={(point) => (_start.current = [point[0], 0.1, point[2]])}
						onPointerUp={() => {
							if (onChange) {
								onChange(_start.current, _end.current)
							}
						}}
					/>

					<AnchorPoint
						ref={endAnchor}
						onChange={(point) => (_end.current = [point[0], 0.1, point[2]])}
						onPointerUp={() => {
							if (onChange) {
								onChange(_start.current, _end.current)
							}
						}}
					/>
				</>
			)}
		</>
	)
}

export default SelectPath
