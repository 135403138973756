import { XYCoord } from "dnd-core"
import { MutableRefObject } from "react"
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd"

interface DragItem {
	index: number
	id: string
	type: string
}

const useDnd = (
	ref: MutableRefObject<HTMLDivElement>,
	id: string | number,
	index: number,
	move: (dragIndex: number, hoverIndex: number) => void,
	draggable = true
) => {
	const [, drop] = useDrop<DragItem, unknown, unknown>({
		accept: "CARD",
		hover(item: DragItem, monitor: DropTargetMonitor) {
			if (!ref.current) {
				return
			}

			const dragIndex = item.index
			const hoverIndex = index
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return
			}
			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect()
			// Get vertical middle
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			// Determine mouse position
			const clientOffset = monitor.getClientOffset()
			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return
			}
			// Time to actually perform the action
			move(dragIndex, hoverIndex)
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.

			item.index = hoverIndex
		},
	})

	const [{ isDragging }, drag] = useDrag({
		type: "CARD",
		item: () => {
			return { id, index }
		},
		canDrag: (monitor) => {
			const boundingClientRect = ref.current?.getBoundingClientRect()
			const mouseRect = monitor.getInitialClientOffset()
			const refMinX = boundingClientRect.x + boundingClientRect.width - 70
			const refMaxX = boundingClientRect.x + boundingClientRect.width
			const mouseX = (mouseRect as XYCoord).x

			return mouseX >= refMinX && mouseX <= refMaxX && draggable
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	})

	const opacity = isDragging ? 0 : 1
	drag(drop(ref))

	return opacity
}

export default useDnd
