import { useErrorStore } from "../state/stores/errorStore"
import { formatUrl } from "../utils/formatUrl"
import { GlobalHistory } from "../utils/history"

// General

const formatConfig = (
	body?: BodyInit | Record<string, unknown>,
	method?: string,
	token?: string
) => {
	const config: RequestInit = {
		method,
	}
	const headers: Record<string, string> = {}

	if (token) {
		headers["Authorization"] = `Bearer ${token}`
	}

	if (body) {
		if (typeof body === "string" || Object.keys(body).length > 0) {
			config.body = JSON.stringify(body)
		} else {
			config.body = body as BodyInit
		}
	}

	if ((config.body && typeof config.body === "string") || !config.body) {
		headers["Content-Type"] = "application/json"
	}

	config.headers = headers

	return config
}

// Normal fetching

export const fetchNormal = async (
	url: string,
	body?: BodyInit | Record<string, unknown>,
	method = "GET"
) => {
	try {
		const result = await fetch(url, formatConfig(body, method))
		const json = await result.json()
		if (json.message) {
			throw new Error(json.message)
		} else {
			return json
		}
	} catch (error) {
		useErrorStore.getState().update(error as Error)
	}
}

export const fetchApi = async (
	url: string,
	body?: BodyInit | Record<string, unknown>,
	method = "GET"
) => {
	return fetchNormal(formatUrl(url), body, method)
}

// Authorized fetching

const fetchAuthorized = async (
	url: string,
	token: string,
	body?: BodyInit | Record<string, unknown>,
	method = "GET"
) => {
	try {
		const result = await fetch(url, formatConfig(body, method, token))
		const json = await result.json()
		if (json.message) {
			throw new Error(json.message)
		} else {
			return json
		}
	} catch (error) {
		useErrorStore.getState().update(error as Error)
	}
}

const _fetchAuthorized = async (
	url: string,
	token: string,
	body?: BodyInit | Record<string, unknown>,
	method = "GET"
) => {
	return fetchAuthorized(formatUrl(url), token, body, method)
}

export const fetchApiAuthorized = async (
	url: string,
	token: string,
	expiresIn: number,
	body?: BodyInit | Record<string, unknown>,
	method = "GET"
) => {
	const now = new Date().getTime() / 1000 + 60

	if (expiresIn > now) {
		return _fetchAuthorized(url, token, body, method)
	} else {
		GlobalHistory.replace(
			GlobalHistory.location.pathname.includes("moderator")
				? "/moderator/login"
				: "/join"
		)
	}
}
