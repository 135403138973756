import io, { Socket } from "socket.io-client"
import create from "zustand"
import { BASE_URL } from "../../constants/constants"
import { GlobalHistory } from "../../utils/history"
import { ChatMessage, useChatStore } from "./chatStore"
import { useCommunicationStore } from "./communicationStore"
import { useErrorStore } from "./errorStore"
import { useLaserStore } from "./laserStore"
import { useMediaStore } from "./mediaStore"
import { useModeratorStore } from "./moderatorStore"
import { useQuizStore } from "./quizStore"
import { useSceneStore } from "./sceneStore"
import { useSensorStore } from "./sensorStore"

export enum IRole {
	Admin = "admin",
	Moderator = "moderator",
	Participant = "participant",
	Support = "support",
	Smartphone = "smartphone",
}

interface SocketEmitter extends Record<string, unknown> {
	scene: (index: number) => void
	chat: (isPrivate: boolean, message?: string, emote?: string) => void
	quiz: {
		onVotes: (votes: number[]) => void
		showResults: () => void
	}
	media: {
		onPlayPause: (isPlaying: boolean) => void
		setTime: (time: number) => void
	}
	laser: (positions: number[] | undefined) => void
	sensor: {
		onData: (data: number[]) => void
	}

	quit: () => void
}

interface SocketStore extends Record<string, unknown> {
	communicationToken: string | undefined

	socket: Socket | undefined

	join: (token: string, role: IRole, practinar: string) => void
	leave: (redirect?: string) => void
	emit: SocketEmitter
}

export const useSocketStore = create<SocketStore>((set, get) => {
	return {
		communicationToken: undefined,
		socket: undefined,

		join: (token, role, practinar) => {
			const socket = io(BASE_URL, {
				transports: ["websocket"],
				query: { token, role, practinar },
			})
			console.log("connecting to socket…")

			socket.on("apiError", ({ message }: Record<string, string>) => {
				console.log("apiError:", message)
				useErrorStore.getState().update(new Error(message))
			})

			socket.on("scene", ({ index }: Record<string, number>) => {
				useSceneStore.setState({ index })

				useMediaStore.getState().reset()
				useQuizStore.getState().reset()
			})

			socket.on("chat", (message: ChatMessage | ChatMessage[]) => {
				useChatStore.getState().onMessage(message)
			})

			socket.on("quiz", (data: Record<string, unknown>) => {
				useQuizStore.getState().onQuiz(data)
			})

			socket.on("media", (data: Record<string, unknown>) => {
				useMediaStore.getState().onMedia(data)
			})

			socket.on("laser", (data: Record<string, unknown>) => {
				useLaserStore.getState().onLaser(data)
			})

			socket.on("userUpdate", (data: Record<string, unknown>[]) => {
				useModeratorStore.getState().onUsers(data)
			})

			socket.on("sensor", ({ data }: { data: number[] }) => {
				useSensorStore.getState().onSensor(data)
			})

			socket.on("connection", (connected: boolean) => {
				useSensorStore.getState().connect(connected)
			})

			socket.on("communication", ({ data }: Record<string, any>) => {
				useCommunicationStore
					.getState()
					.join(practinar, data.token, role === IRole.Admin)
			})

			socket.on("leave", (data: Record<string, any>) => {
				console.log("User should leave", data)
				useSocketStore.getState().leave(data?.redirect)
			})

			set({ socket })
		},

		leave: (redirect) => {
			const { socket } = get()

			if (socket) {
				socket.disconnect()
			}

			useCommunicationStore.getState().leave()

			if (redirect) {
				GlobalHistory.push(redirect)
			}
		},

		emit: {
			scene: (index) => {
				const { socket } = get()

				if (socket) {
					socket.emit("scene", { index })
				}
			},

			chat: (isPrivate, message, emote) => {
				const { socket } = get()

				if (socket) {
					socket.emit("chat", { message, emote, isPrivate })
				}
			},

			quiz: {
				onVotes: (votes) => {
					const { socket } = get()

					if (socket) {
						socket.emit("quiz", { votes })
					}
				},

				showResults: () => {
					const { socket } = get()

					if (socket) {
						socket.emit("quiz", { showResults: true })
					}
				},
			},

			media: {
				onPlayPause: (isPlaying) => {
					const { socket } = get()

					if (socket) {
						socket.emit("media", { isPlaying })
					}
				},
				setTime: (time) => {
					const { socket } = get()

					if (socket) {
						socket.emit("media", { time })
					}
				},
			},

			sensor: {
				onData: (data) => {
					const { socket } = get()

					if (socket) {
						socket.emit("sensor", { data })
					}
				},
			},

			laser: (positions) => {
				const { socket } = get()

				if (socket) {
					socket.emit("laser", { positions })
				}
			},

			quit: () => {
				const { socket } = get()

				if (socket) {
					socket.emit("quit")
				}
			},
		},
	}
})
