import { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { QrReader } from "react-qr-reader"
import { useNavigate, useParams } from "react-router-dom"
import Button, { ButtonTheme } from "src/components/button/button"
import Input from "src/components/input/input"
import Body from "../../components/body/body"
import useHandleChange from "../../hooks/useHandleChange"
import { loginWithCode } from "../../network/auth"
import { fetchApiAuthorized } from "../../network/fetch"
import { useErrorStore } from "../../state/stores/errorStore"

const Scan = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [searching, setSearching] = useState(false)
	const [manualMode, setManualMode] = useState(false)
	const [inviteCode, setInviteCode] = useHandleChange("")
	const lastData = useRef<string | null>(null)
	const { code } = useParams() as Record<string, string>

	const handleScan = async (data: string) => {
		if (data) {
			const url = new URL(data)
			if (
				url &&
				url.hostname === "sonofun.live" &&
				url.pathname.includes("connect")
			) {
				if (lastData.current !== data) {
					lastData.current = data

					const inviteCode = url.pathname.replace("/connect/", "")
					enterCode(inviteCode)
				}
			}
		}
	}

	const enterCode = useCallback(
		async (inviteCode: string) => {
			const result = await loginWithCode(inviteCode)
			if (result !== undefined) {
				const practinar = await fetchApiAuthorized(
					`/webinars/code/${inviteCode}`,
					result.token,
					result.expiresIn
				)

				if (practinar !== undefined && practinar.running) {
					navigate(`/connection/${inviteCode}`)
				} else {
					useErrorStore.getState().show(undefined, t("practinar_not_start"))
				}
			}
		},
		[navigate]
	)

	useEffect(() => {
		if (code) {
			enterCode(code)
		}
	}, [code, enterCode])

	return (
		<Body isMobile>
			<h1 className="mt-5">{t("qr_scan_code")}</h1>
			<p className="mb-5">{t("qr_mobile_text")}</p>
			{searching ? (
				<QrReader
					constraints={{ facingMode: "environment" }}
					containerStyle={{ width: "100%" }}
					scanDelay={300}
					onResult={(result, error) => {
						if (!!result) {
							handleScan(result.getText())
						}

						if (!!error) {
							console.error(error)
						}
					}}
				/>
			) : (
				<Button onClick={() => setSearching(true)}>{t("qr_scan_qr")}</Button>
			)}
			<h2 className="mt-5 mb-2.5">{t("qr_no_cam")}</h2>
			<p className="mb-5">{t("qr_mobile_alternative")}</p>
			<Button theme={ButtonTheme.White} onClick={() => setManualMode(true)}>
				{t("qr_enter_manually")}
			</Button>
			{manualMode && (
				<>
					<Input
						className="my-5"
						name="name"
						placeholder={t("code")}
						value={inviteCode}
						onChange={setInviteCode}
					/>
					<Button
						disabled={inviteCode.length === 0}
						onClick={() => enterCode(inviteCode)}
					>
						{t("connect")}
					</Button>
				</>
			)}
		</Body>
	)
}

export default Scan
