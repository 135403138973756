import { forwardRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useKeyPressEvent } from "react-use"
import {
	showSettingsValue,
	useCameraSettingsStore,
} from "../../state/stores/cameraSettingsStore"
import Button from "../button/button"
import CameraSettings from "../cameraSettings/cameraSettings"

const CameraSettingsOverlay = forwardRef<HTMLDivElement, { camera: boolean }>(
	({ camera }, ref) => {
		const { t } = useTranslation()

		const [transform, setTransform] = useState("scale(0.9) rotateX(0deg)")

		const close = () => {
			setTransform("scale(0.9) rotateX(0deg)")

			useCameraSettingsStore.setState({ showSettings: showSettingsValue.none })
		}

		useKeyPressEvent("Enter", close)
		useKeyPressEvent("Escape", close)

		return (
			<div
				className="fixed left-0 right-0 top-0 bottom-0 z-50 flex items-center justify-center bg-black bg-opacity-20"
				ref={ref}
			>
				<div
					className="rounded-xl flex flex-col bg-white transition-all duration-300 overflow-hidden"
					style={{ transform, transformOrigin: "top center" }}
				>
					<CameraSettings camera={camera} />

					<div className="py-3 px-5 flex justify-end flex-col-reverse md:flex-row">
						<Button onClick={close}>{t("close")}</Button>
					</div>
				</div>
			</div>
		)
	}
)

export default CameraSettingsOverlay
