import { Html } from "@react-three/drei"
import { forwardRef, useState } from "react"
import { Mesh } from "three"
import { vectorToTuple, VectorTuple } from "../../utils/vector"

interface AnchorPointProps {
	isStart?: boolean
	onChange: (position: VectorTuple) => void
	onPointerUp: () => void
}

const AnchorPoint = forwardRef<Mesh, AnchorPointProps>(
	({ isStart = false, onChange, onPointerUp }, ref) => {
		const [hovered, setHover] = useState(false)
		const [active, setActive] = useState(false)

		return (
			<mesh
				ref={ref}
				renderOrder={999}
				scale={active ? [1.2, 1.2, 1.2] : hovered ? [0.9, 0.9, 0.9] : [1, 1, 1]}
				onBeforeRender={(r) => r.clearDepth()}
				onPointerDown={() => setActive(true)}
				onPointerMove={(e) => {
					if (active) {
						onChange(vectorToTuple(e.point))
					}
				}}
				onPointerOut={() => setHover(false)}
				onPointerOver={() => setHover(true)}
				onPointerUp={() => {
					setActive(false)
					onPointerUp()
				}}
			>
				<sphereGeometry args={[0.01, 64, 64]} attach="geometry" />
				<meshBasicMaterial attach="material" color="#0071FF" />

				<Html className="text-s-blue pointer-events-none p-2.5">
					<p>{isStart ? "Start" : "End"}</p>
				</Html>
			</mesh>
		)
	}
)

export default AnchorPoint
