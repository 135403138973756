import useForwardedRef from "@bedrock-layout/use-forwarded-ref"
import {
	CSSProperties,
	forwardRef,
	useLayoutEffect,
	useRef,
	useState,
} from "react"
import { findDOMNode } from "react-dom"

interface ResponsiveVideoProps {
	url: string
	style?: CSSProperties
	padding?: number
	onMouseEnter?: () => void
	onMouseLeave?: () => void
}

const ResponsiveVideo = forwardRef<HTMLDivElement, ResponsiveVideoProps>(
	({ url, style, padding = 0, onMouseEnter, onMouseLeave }, ref) => {
		const container = useForwardedRef(ref)

		const [width, setWidth] = useState<number | undefined>(undefined)
		const [height, setHeight] = useState<number | undefined>(undefined)
		const [aspectRatio, setAspectRatio] = useState<number | undefined>(
			undefined
		)

		const containerParent = useRef<HTMLElement>()
		const video = useRef<HTMLVideoElement>(null)

		const onLoad = () => {
			if (video.current) {
				setAspectRatio(video.current.videoHeight / video.current.videoWidth)
			}
		}

		if (container && container.current) {
			const node = findDOMNode(container.current)
			if (node && node.parentElement) {
				containerParent.current = node.parentElement
			}
		}

		if (containerParent.current && !width) {
			setWidth(containerParent.current.offsetWidth - padding)
			setHeight(containerParent.current.offsetHeight - padding)
		}

		useLayoutEffect(() => {
			const updateSize = () => {
				if (containerParent.current) {
					setWidth(containerParent.current.offsetWidth - padding)
					setHeight(containerParent.current.offsetHeight - padding)
				}
			}

			window.addEventListener("resize", updateSize)

			updateSize()

			return () => {
				window.removeEventListener("resize", updateSize)
			}
		}, [padding])

		const isPortrait = (aspectRatio ?? 0) > 1

		let _width = "100%"
		if (
			width &&
			aspectRatio &&
			height &&
			(isPortrait || width * aspectRatio > height)
		) {
			_width = Math.min((1 / aspectRatio) * height, width) + "px"
		}

		let _height = "100%"
		if (
			width &&
			aspectRatio &&
			height &&
			(!isPortrait || height * aspectRatio > width)
		) {
			_height = Math.min(aspectRatio * width, height) + "px"
		}

		return (
			<div
				className="flex"
				ref={container}
				style={{
					maxHeight: "calc(100vh - 20px)",
					width: _width,
					height: _height,
					...style,
				}}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				<video
					className="block w-full h-full"
					height="auto"
					preload="auto"
					ref={video}
					src={url}
					style={{
						display: aspectRatio ? "block" : "none",
					}}
					width="auto"
					onCanPlay={onLoad}
				/>
			</div>
		)
	}
)

export default ResponsiveVideo
