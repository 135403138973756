import MarkdownIt from "markdown-it"
import { parse } from "qs"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import "react-markdown-editor-lite/lib/index.css"
import { useNavigate, useParams } from "react-router-dom"
import { useLocation } from "react-use"
import Editor from "src/components/editor/editor"
import Input from "src/components/input/input"
import useSWR from "swr"
import DashboardHeader from "../../../../components/dashboardHeader/dashboardHeader"
import { HasAuth } from "../../../../components/privateRoute/privateRoute"
import SegmentedControl from "../../../../components/segmentedControl/segmentedControl"
import PatientSelectPosition from "../../../../components/selectPosition/selectPosition"
import MediaUpload from "../../../../components/upload/upload"
import useHandleChange from "../../../../hooks/useHandleChange"
import { fetchApiAuthorized } from "../../../../network/fetch"
import {
	JsonVector,
	tupleToJsonVector,
	VectorTuple,
} from "../../../../utils/vector"

const CreatePatient = ({ auth }: HasAuth) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const mdParser = new MarkdownIt()
	const { search } = useLocation()
	const { id } = useParams() as Record<string, string>

	const [name, setName] = useHandleChange("")
	const [volume, setVolume] = useState<File | string | undefined>(undefined)
	const [preview, setPreview] = useState<File | undefined>(undefined)
	const [positions, setPositions] = useState([
		{ x: 0, y: 0.1, z: -0.1 },
		{ x: 0, y: 0.1, z: -0.3 },
	])
	const [descriptionRaw, setMarkdown] = useState("")
	const [descriptionHtml, setHtml] = useState("")
	const [mirrorSides, setMirrorSides] = useState(false)
	const [mirrorEnds, setMirrorEnds] = useState(false)

	const duplicate = useCallback(
		async (id: string) => {
			const patient = await fetchApiAuthorized(
				`/patients/${id}`,
				auth.token,
				auth.expiresIn,
				undefined,
				"GET"
			)

			if (patient) {
				setName(patient.name)
				setMarkdown(patient.descriptionRaw)
				setHtml(patient.descriptionHtml)
				if (
					patient.sonoPositions &&
					Array.isArray(patient.sonoPositions) &&
					(patient.sonoPositions as JsonVector[]).length > 0
				) {
					setPositions(patient.sonoPositions)
				}

				setPreview(patient.previewUrl)
				setVolume(patient.volumeKey)
				setMirrorSides(patient.mirrored)
				setMirrorEnds(patient.mirroredEnds)
			}
		},
		[auth.expiresIn, auth.token, setName]
	)

	useEffect(() => {
		if (search) {
			const query = parse(search.replace("?", ""))
			if (query.copy) {
				duplicate(query.copy as string)
			}
		}
	}, [search, duplicate])

	const { data: currentPatient } = useSWR(
		id ? [`/patients/${id}`, auth.token, auth.expiresIn] : null,
		fetchApiAuthorized
	)

	useEffect(() => {
		if (currentPatient) {
			setName(currentPatient.name)
			setMarkdown(currentPatient.descriptionRaw)
			setHtml(currentPatient.descriptionHtml)
			if (
				currentPatient.sonoPositions &&
				Array.isArray(currentPatient.sonoPositions) &&
				(currentPatient.sonoPositions as JsonVector[]).length > 0
			) {
				setPositions(currentPatient.sonoPositions)
			}

			setPreview(currentPatient.previewUrl)
			setVolume(currentPatient.volumeKey)
			setMirrorSides(currentPatient.mirrored)
			setMirrorEnds(currentPatient.mirroredEnds)
		}
	}, [currentPatient, setName])

	const create = async () => {
		let formData = new FormData()
		if (volume) {
			formData.append("volume", volume)
		}
		if (preview) {
			formData.append("preview", preview)
		}
		formData.append(
			"json",
			JSON.stringify({
				name,
				descriptionRaw,
				descriptionHtml,
				positions,
				mirrored: mirrorSides,
				mirroredEnds: mirrorEnds,
			})
		)

		const endpoint = id ? `/patients/${id}` : "/patients/create"

		const success = await fetchApiAuthorized(
			endpoint,
			auth.token,
			auth.expiresIn,
			formData,
			id ? "PUT" : "POST"
		)
		if (success) {
			navigate("/moderator/patient")
		}
	}

	const [titleBorder, setTitleBorder] = useState<string | undefined>(undefined)
	const [fileBorder, setFileBorder] = useState<string | undefined>(undefined)

	const check = () => {
		setTitleBorder(name.length === 0 ? "border-s-red" : undefined)
		if (!id) {
			setFileBorder(volume === undefined ? "border-s-red" : undefined)
		}

		if ((id && name.length > 0) || (name.length > 0 && volume !== undefined)) {
			create()
		}
	}

	return (
		<>
			<DashboardHeader
				buttonOnClick={check}
				buttonTitle={t("save") as string}
				cancelButtonOnClick={() => navigate("/moderator/patient")}
				cancelButtonTitle={t("cancel") as string}
				title={
					id ? (t("edit_patient") as string) : (t("create_patient") as string)
				}
			/>

			<div className="flex flex-col py-3 px-6 mx-2.5">
				<div className="flex flex-row items-baseline">
					<h2>{t("title")}</h2>
					<Input
						className={`flex-1 ml-2.5 mb-5 ${titleBorder}`}
						placeholder="…"
						value={name}
						onChange={setName}
					/>
				</div>

				<h2 className="mb-2.5">{t("anamnesis")}</h2>

				<Editor
					markdown={descriptionRaw}
					onChange={(markdown, html) => {
						setMarkdown(markdown)
						setHtml(html)
					}}
					onImageUpload={(f: File) => {
						return new Promise((resolve) => {
							const reader = new FileReader()
							reader.onload = (data) => {
								if (data && data.target) {
									resolve(data.target.result)
								}
							}
							reader.readAsDataURL(f)
						})
					}}
				/>

				<MediaUpload
					accept=".nrrd"
					borderColor={fileBorder ?? "none"}
					existingData={{
						type: "string",
						data: volume
							? typeof volume === "string"
								? volume.replace("patient/volume/", "")
								: volume.name
							: undefined,
					}}
					marginBottom="0px"
					title={t("file") as string}
					onChange={(file: File) => setVolume(file)}
				/>

				<MediaUpload
					accept="image/*"
					existingData={currentPatient && currentPatient.imageUrl}
					title={t("preview") as string}
					onChange={(file: File) => setPreview(file)}
				/>

				<div className="flex flex-col mb-5">
					<h2 className="w-24">{t("mirror")}</h2>
					<div className="flex flex-row items-center">
						<h2 className="w-28 my-5">{t("left_right")}</h2>
						<SegmentedControl
							options={[
								{ title: t("on") as string, value: true },
								{ title: t("off") as string, value: false },
							]}
							value={mirrorSides}
							onChange={setMirrorSides}
						/>
					</div>
					<div className="flex flex-row items-center">
						<h2 className="w-28">{t("front_back")}</h2>
						<SegmentedControl
							options={[
								{ title: t("on") as string, value: true },
								{ title: t("off") as string, value: false },
							]}
							value={mirrorEnds}
							onChange={setMirrorEnds}
						/>
					</div>
				</div>

				<PatientSelectPosition
					end={[positions[1].x, positions[1].y, positions[1].z]}
					start={[positions[0].x, positions[0].y, positions[0].z]}
					onChange={(start: VectorTuple, end: VectorTuple) =>
						setPositions([tupleToJsonVector(start), tupleToJsonVector(end)])
					}
				/>
			</div>
		</>
	)
}

export default CreatePatient
