/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from "@react-three/drei"
import { forwardRef } from "react"
import * as THREE from "three"
import { Group } from "three"
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader"

type GLTFResult = GLTF & {
	nodes: {
		Cube: THREE.Mesh
	}
	materials: {
		Blue: THREE.MeshStandardMaterial
	}
}

const ControllerModel = forwardRef<Group>((props, ref) => {
	const { nodes, materials } = useGLTF(
		"/models/controller.glb"
	) as unknown as GLTFResult

	return (
		<group {...props} dispose={null} ref={ref}>
			<mesh geometry={nodes.Cube.geometry} material={materials.Blue} />
		</group>
	)
})

export default ControllerModel

useGLTF.preload("/models/controller.glb")
