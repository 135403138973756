import create from "zustand"
import { persist } from "zustand/middleware"
import { useCommunicationStore } from "./communicationStore"

interface SettingsStore extends Record<string, unknown> {
	selectedMicrophone: string | undefined
	selectedSpeaker: string | undefined
	selectedCamera: string | undefined
	microphones: MediaDeviceInfo[]
	speakers: MediaDeviceInfo[]
	cameras: MediaDeviceInfo[]
	updateIO: (devices: MediaDeviceInfo[]) => void
	updateMicrophone: (id: string) => void
	updateSpeaker: (id: string) => void
	updateCamera: (id: string) => void

	completedTutorial: string[]
	completeTutorial: (code: string) => void
}

const updateDevice = (
	devices: MediaDeviceInfo[],
	selected: string | unknown,
	update: (id: string) => void
) => {
	if (devices.length > 0) {
		if (selected === undefined) {
			// prefer devices including "default"
			const defaultDevices = devices.filter(
				(device) => device.deviceId === "default"
			)[0]
			update(
				defaultDevices !== undefined
					? defaultDevices.deviceId
					: devices[0].deviceId
			)
		} else {
			// already has a device but ids get flushed out the cache at some point
			const microphone = devices.filter(
				(device) => device.deviceId === selected
			)
			if (microphone.length === 0) {
				update(devices[0].deviceId)
			}
		}
	}
}

export const useSettingsStore = create(
	persist<SettingsStore>(
		(set, get) => ({
			selectedMicrophone: undefined,
			selectedSpeaker: undefined,
			selectedCamera: undefined,
			microphones: [],
			speakers: [],
			cameras: [],

			updateIO: (devices: MediaDeviceInfo[]) => {
				const microphones = devices.filter(
					(device) => device.kind === "audioinput"
				)
				const speakers = devices.filter(
					(device) => device.kind === "audiooutput"
				)
				const cameras = devices.filter((device) => device.kind === "videoinput")

				set({
					microphones,
					speakers,
					cameras,
				})

				const {
					selectedMicrophone,
					selectedSpeaker,
					selectedCamera,
					updateMicrophone,
					updateSpeaker,
					updateCamera,
				} = get()

				updateDevice(microphones, selectedMicrophone, updateMicrophone)
				updateDevice(cameras, selectedCamera, updateCamera)
				updateDevice(speakers, selectedSpeaker, updateSpeaker)
			},
			updateMicrophone: (id) => {
				set({ selectedMicrophone: id })

				useCommunicationStore.getState().ioActions.updateMicrophone(id)
			},
			updateSpeaker: (id) => {
				set({ selectedSpeaker: id })
			},
			updateCamera: (id) => {
				set({ selectedCamera: id })

				useCommunicationStore.getState().ioActions.updateCamera(id)
			},

			completedTutorial: [],
			completeTutorial: (code) => {
				const { completedTutorial } = get()

				set({ completedTutorial: [...completedTutorial, code] })
			},
		}),
		{ name: "settings" }
	)
)
