import { PropsWithChildren, useState } from "react"
import { useTranslation } from "react-i18next"
import AddIcon from "../../assets/images/add.svg"
import CaseIcon from "../../assets/images/sidebarCase.svg"
import MediaIcon from "../../assets/images/sidebarMedia.svg"
import SonoIcon from "../../assets/images/sidebarPatient.svg"
import PresentationIcon from "../../assets/images/sidebarPresentation.svg"
import QuizIcon from "../../assets/images/sidebarQuiz.svg"

interface AddScheduleProps {
	onAdd: (data: Record<string, unknown>) => void
	canAddCase?: boolean
}

export const SchedulePart = ({
	children,
	onClick,
}: PropsWithChildren<{ onClick: () => void }>) => {
	return (
		<div className="cursor-pointer flex items-center" onClick={onClick}>
			{children}
		</div>
	)
}

const AddSchedule = ({ onAdd, canAddCase = true }: AddScheduleProps) => {
	const [optionsVisible, setOptionsVisible] = useState(false)
	const { t } = useTranslation()

	return (
		<div
			className="flex items-center justify-center border border-slate-400 rounded-xl bg-white py-3 px-6 relative"
			onMouseEnter={() => setOptionsVisible(true)}
			onMouseLeave={() => setOptionsVisible(false)}
		>
			<img alt="Add" className="mr-2" src={AddIcon} />
			<p className="font-semibold text-s-blue">{t("schedule_add")}</p>

			<div
				className={`rounded-xl transition-opacity ${
					optionsVisible ? "opacity-100" : "opacity-0"
				} absolute top-0 bottom-0 left-0 right-0 bg-white flex justify-center`}
			>
				<div className="flex flex-row justify-between flex-[0.5]">
					{canAddCase && (
						<SchedulePart onClick={() => onAdd({ type: "case" })}>
							<img alt="Case" className="mr-2" src={CaseIcon} width="20px" />
							{t("case")}
						</SchedulePart>
					)}
					<SchedulePart onClick={() => onAdd({ type: "presentation" })}>
						<img
							alt="Presentation"
							className="mr-2"
							src={PresentationIcon}
							width="20px"
						/>
						{t("presentation")}
					</SchedulePart>
					<SchedulePart onClick={() => onAdd({ type: "patient" })}>
						<img alt="Patient" className="mr-2" src={SonoIcon} width="20px" />
						{t("patient")}
					</SchedulePart>
					<SchedulePart onClick={() => onAdd({ type: "quiz" })}>
						<img alt="Quiz" className="mr-2" src={QuizIcon} width="20px" />
						{t("quiz")}
					</SchedulePart>
					<SchedulePart onClick={() => onAdd({ type: "media" })}>
						<img alt="Media" className="mr-2" src={MediaIcon} width="20px" />
						{t("media")}
					</SchedulePart>
				</div>
			</div>
		</div>
	)
}

export default AddSchedule
