import { useTranslation } from "react-i18next"
// @ts-ignore
import Scan from "../../../assets/videos/scanTutorial.mp4"

const ScanVideo = () => {
	const { t } = useTranslation()

	return (
		<>
			<div />
			<div className="flex flex-col items-center">
				<h2 className="mt-20 mb-5">{t("virtual_scan")}</h2>
				<p className="text-center mt-4.5 mb-5">{t("virtual_scan_info")}</p>

				<video
					autoPlay={true}
					className="rounded-xl"
					height="auto"
					loop={true}
					playsInline={true}
					src={Scan}
					width="800px"
					controls
					muted
				></video>
			</div>
		</>
	)
}

export default ScanVideo
