import { Vector3 } from "three"

export interface JsonVector {
	x: number
	y: number
	z: number
}

export const vectorToJson = (vector: Vector3): JsonVector => {
	return { x: vector.x, y: vector.y, z: vector.z }
}

export const jsonToVector = (json: JsonVector): Vector3 => {
	return new Vector3(json.x, json.y, json.z)
}

export type VectorTuple = [x: number, y: number, z: number]

export const tupleToVector = (tuple: VectorTuple): Vector3 => {
	return new Vector3(tuple[0], tuple[1], tuple[2])
}

export const vectorToTuple = (vector: Vector3): VectorTuple => {
	return [vector.x, vector.y, vector.z]
}

export const tupleToJsonVector = (tuple: VectorTuple): JsonVector => {
	return vectorToJson(tupleToVector(tuple))
}

export const jsonVectorToTuple = (json: JsonVector): VectorTuple => {
	return vectorToTuple(jsonToVector(json))
}
