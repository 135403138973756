import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import useSWR from "swr"
import Body from "../../components/body/body"
import Chat from "../../components/chat/chat"
import Controls from "../../components/controls/controls"
import Header from "../../components/header/header"
import Media from "../../components/media/user/media"
import MiniCam from "../../components/miniCam/miniCam"
import Patient from "../../components/patient/user/patient"
import Presentation from "../../components/presentation/user/presentation"
import { HasAuth } from "../../components/privateRoute/privateRoute"
import Quiz from "../../components/quiz/user/quiz"
import Sendoff from "../../components/sendoff/user/sendoff"
import { fetchApiAuthorized } from "../../network/fetch"
import { useChatStore } from "../../state/stores/chatStore"
import { useCommunicationStore } from "../../state/stores/communicationStore"
import { useSceneStore } from "../../state/stores/sceneStore"
import { useSettingsStore } from "../../state/stores/settingsStore"
import { IRole, useSocketStore } from "../../state/stores/socketStore"
import Tutorial from "./tutorial/tutorial"

// TODO: rename container and right
const Practinar = ({ auth }: HasAuth) => {
	const { code } = useParams() as Record<string, string>
	const { t } = useTranslation()

	const join = useSocketStore((state) => state.join)
	const leave = useSocketStore((state) => state.leave)
	const updateDevices = useSettingsStore((state) => state.updateIO)
	const clear = useChatStore((state) => state.clear)
	const connected = useCommunicationStore((state) => state.connected)

	const { data: practinar } = useSWR(
		[`/webinars/code/${code}`, auth.token, auth.expiresIn],
		fetchApiAuthorized
	)
	const scheduleIndex = useSceneStore((state) => state.index)

	const { data: user } = useSWR(
		["/users/current", auth.token, auth.expiresIn],
		fetchApiAuthorized
	)

	// tutorial
	const completedTutorial = useSettingsStore((state) => state.completedTutorial)
	const showTutorial = practinar && !completedTutorial.includes(practinar.id)

	useEffect(() => {
		if (auth.token && practinar && practinar.id && !showTutorial) {
			join(auth.token, IRole.Participant, practinar.id)
		}
	}, [auth.token, practinar, join, showTutorial])

	useEffect(() => {
		return () => clear()
	}, [clear])

	useEffect(() => {
		const onunload = () => {
			leave()
			clear()
		}

		window.addEventListener("unload", onunload)

		return () => window.removeEventListener("unload", onunload)
	}, [leave, clear])

	let scene = "presentation"
	if (
		practinar !== undefined &&
		scheduleIndex !== undefined &&
		scheduleIndex >= 0
	) {
		scene = (practinar.schedule as Record<string, unknown>[])[scheduleIndex]
			.type as string
	}

	const showSidebar = scene !== "sendoff"

	const showContent = () => {
		let scheduleData
		if (practinar && scheduleIndex) {
			scheduleData = practinar.schedule[scheduleIndex].data
		}

		switch (scene) {
			case "presentation":
				return <Presentation practinar={practinar} userId={user._id} />
			case "media":
				return <Media type={scheduleData.type} url={scheduleData.url} />
			case "patient":
				return (
					<Patient
						code={code}
						key={scheduleData.id}
						patient={scheduleData}
						showTweaks={user && user.email === "test@sonofun.live"}
					/>
				)
			case "quiz":
				return (
					<Quiz
						answers={scheduleData.answers}
						id={scheduleData.id}
						imageUrl={scheduleData.imageUrl}
						multiplePossible={scheduleData.multiplePossible}
						question={scheduleData.question}
						solution={scheduleData.solution}
					/>
				)
			case "sendoff":
				return <Sendoff />
			default:
				break
		}
	}

	useEffect(() => {
		const onDeviceChange = async () => {
			const devices = await navigator.mediaDevices.enumerateDevices()
			updateDevices(devices)
		}

		navigator.mediaDevices.addEventListener("devicechange", onDeviceChange)

		return () =>
			navigator.mediaDevices.removeEventListener("devicechange", onDeviceChange)
	}, [updateDevices])

	return practinar === undefined ? (
		<div>Loading…</div>
	) : showTutorial ? (
		<Tutorial code={practinar.id} />
	) : (
		<Body showHeader={false}>
			<div className="flex flex-1 h-full">
				{user !== undefined && (
					<div className="flex flex-[3] bg-slate-100 rounded-xl shadow-md relative overflow-scroll mr-2 p-2">
						{showContent()}
					</div>
				)}
				{showSidebar && (
					<div className="flex flex-col justify-between w-[400px]">
						<Header logos={false} />
						<MiniCam auth={auth} isModerator={false} />
						<div className="flex flex-row items-center justify-between py-1">
							<p>{t(connected ? "your_camera_is_off" : "video_loading")}</p>
							<Controls auth={auth} isModerator={false} />
						</div>
						<Chat />
					</div>
				)}
			</div>
		</Body>
	)
}

export default Practinar
