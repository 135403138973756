import {
	ButtonHTMLAttributes,
	DetailedHTMLProps,
	PropsWithChildren,
} from "react"
import LoadingBlue from "../../assets/images/loadingBlue.gif"
import LoadingWhite from "../../assets/images/loadingWhite.gif"

export enum ButtonTheme {
	Blue = 0,
	RedOnWhite,
	WhiteOnRed,
	White,
	Lila,
}

const classesForTheme = (theme?: ButtonTheme): string => {
	switch (theme) {
		case ButtonTheme.RedOnWhite:
			return "bg-white text-s-red"
		case ButtonTheme.WhiteOnRed:
			return "bg-s-red text-white"
		case ButtonTheme.White:
			return "bg-white text-s-blue"
		case ButtonTheme.Lila:
			return "bg-s-lila text-white"
		default:
			return "bg-s-blue text-white"
	}
}

interface ButtonProps {
	loading?: boolean
	theme?: ButtonTheme
	className?: string
}

const Button = ({
	loading = false,
	theme,
	children,
	...props
}: PropsWithChildren<
	Omit<
		DetailedHTMLProps<
			ButtonHTMLAttributes<HTMLButtonElement>,
			HTMLButtonElement
		>,
		"style"
	> &
		ButtonProps
>) => {
	return (
		<button
			{...props}
			className={`shadow-md rounded-xl px-2.5 py-2 font-semibold text-sm flex flex-row text-center items-center justify-center transition-all disabled:cursor-not-allowed disabled:pointer-events-none disabled:text-white disabled:bg-slate-400 outline-none overflow-hidden cursor-pointer relative active:scale-90 ${classesForTheme(
				theme
			)} ${props.className}`}
			disabled={props.disabled || loading}
		>
			{children}
			{loading && (
				<div className={`absolute inset-0 z-[2] ${classesForTheme(theme)}`}>
					<img
						alt="Loading"
						height="12px"
						src={
							classesForTheme(theme).includes("bg-s-blue")
								? LoadingWhite
								: LoadingBlue
						}
					/>
				</div>
			)}
		</button>
	)
}

export default Button
