import Select from "src/components/input/select"

interface ScheduleItemViewProps {
	header: string
	data: Record<string, unknown>[]
	value?: string
	onChange: (value: string) => void
	title: string
}

export interface ScheduleItemSubviewProps {
	value?: string
	onChange: (value: string) => void
}

const ScheduleItemView = ({
	header,
	data,
	value = undefined,
	onChange,
	title = "title",
}: ScheduleItemViewProps) => {
	return (
		<>
			<p className="w-20">{header}</p>

			<Select
				className="mr-5 w-full max-w-[90%]"
				value={value}
				onChange={onChange}
			>
				<option value="default" disabled>
					Select {header}
				</option>
				{data &&
					data.map((v: Record<string, unknown>) => {
						return (
							<option key={v.id as string} value={v.id as string}>
								{v[title] as string}
							</option>
						)
					})}
			</Select>
		</>
	)
}

export default ScheduleItemView
