import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ListContainer, ListRow } from "src/components/list/list"

interface MediaListProps {
	data: Record<string, Record<string, unknown> | Record<string, unknown>[]>
	loading: boolean
	setCase: (byCase: string) => void
}

const GroupedMediaList = ({ loading, data, setCase }: MediaListProps) => {
	const [keys, setKeys] = useState<string[]>([])
	const { t } = useTranslation()
	const [hasUnused, setHasUnused] = useState(false)

	useEffect(() => {
		if (data) {
			let _keys = Object.keys(data.used)
			if (
				data.unused &&
				(data.unused as Record<string, unknown>[]).length > 0
			) {
				setHasUnused(true)
				_keys.push(t("unused_media") as string)
			}
			setKeys(_keys)
		}
	}, [data, t])

	return (
		<>
			<ListContainer>
				{loading
					? "Loading…"
					: keys.map((v, i) => {
							return (
								<ListRow
									className="pt-2.5 pb-5 cursor-pointer"
									key={v}
									showDivider={i < keys.length - 1}
									onClick={() => setCase(v)}
								>
									<p
										className={`mr-3 ${
											hasUnused && i === keys.length - 1
												? "text-s-blue font-medium"
												: ""
										}`}
									>
										{v}
									</p>
								</ListRow>
							)
					  })}
			</ListContainer>
		</>
	)
}

export default GroupedMediaList
