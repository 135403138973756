import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import Input from "src/components/input/input"
import useSWR from "swr"
import DashboardHeader from "../../../../components/dashboardHeader/dashboardHeader"
import { HasAuth } from "../../../../components/privateRoute/privateRoute"
import MediaUpload from "../../../../components/upload/upload"
import useHandleChange from "../../../../hooks/useHandleChange"
import { fetchApiAuthorized } from "../../../../network/fetch"

const CreateMedia = ({ auth }: HasAuth) => {
	const { id } = useParams() as Record<string, string>
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [name, setName] = useHandleChange("")
	const [file, setFile] = useState<File | undefined>(undefined)

	const { data: currentMedia } = useSWR(
		id ? [`/medias/${id}`, auth.token, auth.expiresIn] : null,
		fetchApiAuthorized
	)

	useEffect(() => {
		if (currentMedia) {
			setName(currentMedia.name)
			setFile(currentMedia.key)
		}
	}, [currentMedia, setName])

	const create = async () => {
		let formData = new FormData()
		if (file) {
			formData.append("file", file)
		}
		formData.append("json", JSON.stringify({ name }))

		const endpoint = id ? `/medias/${id}` : "/medias/create"

		const success = await fetchApiAuthorized(
			endpoint,
			auth.token,
			auth.expiresIn,
			formData,
			id ? "PUT" : "POST"
		)
		if (success) {
			navigate("/moderator/media")
		}
	}

	return (
		<>
			<DashboardHeader
				buttonDisabled={name.length === 0 || file === undefined}
				buttonOnClick={create}
				buttonTitle={t("save") as string}
				cancelButtonOnClick={() => {
					navigate("/moderator/media")
				}}
				cancelButtonTitle={t("cancel") as string}
				title={id ? (t("edit_media") as string) : (t("create_media") as string)}
			/>

			<div className="flex flex-col py-2 px-5 m-2">
				<MediaUpload
					accept="image/*,video/*"
					existingData={currentMedia ? currentMedia : undefined}
					title="Datei"
					onChange={(file: File) => {
						const fileNameParts = file.name.match(/(.+?)(?:\.[^\\.]*$|$)/)
						if (fileNameParts && fileNameParts.length > 1) {
							setName({ target: { value: fileNameParts[1] } })
						}
						setFile(file)
					}}
				/>

				<div className="flex flex-row items-baseline">
					<h2 className="w-16">{t("name")}</h2>
					<Input placeholder="…" value={name} onChange={setName} />
				</div>
			</div>
		</>
	)
}

export default CreateMedia
