import create from "zustand"

interface LaserStore extends Record<string, unknown> {
	positions: number[] | undefined

	onLaser: (data: Record<string, unknown>) => void
}

export const useLaserStore = create<LaserStore>((set, get) => {
	return {
		positions: undefined,

		onLaser: (data) => {
			set({
				positions: data.positions as number[],
			})
		},

		reset: () => {
			set({ positions: undefined })
		},
	}
})
