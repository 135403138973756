import { CSSProperties } from "react"
import { processString } from "./processString"

const Linkify = ({
	content,
	style,
}: {
	content: string
	style?: CSSProperties
}) => {
	let config = [
		{
			regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/gim,
			fn: (key: string, result: string[]) => (
				<span key={key}>
					<a
						href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}
						rel="noreferrer"
						style={style}
						target="_blank"
					>
						{result[2]}.{result[3]}
						{result[4]}
					</a>
					{result[5]}
				</span>
			),
		},
		{
			regex: /(\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/gim,
			fn: (key: string, result: string[]) => (
				<span key={key}>
					<a
						href={`http://${result[1]}.${result[2]}${result[3]}`}
						rel="noreferrer"
						style={style}
						target="_blank"
					>
						{result[1]}.{result[2]}
						{result[3]}
					</a>
					{result[4]}
				</span>
			),
		},
	]

	let processed = processString(config)(content)

	return <div>{processed}</div>
}

export default Linkify
