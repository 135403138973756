import { PropsWithChildren } from "react"
import FullscreenButton from "../button/fullscreenButton"
import LanguageButton from "../button/languageButton"
import Logos from "../logos/logos"

interface HeaderProps {
	isMobile?: boolean
	logos?: boolean
	showFullscreen?: boolean
}

const Header = ({
	isMobile = false,
	logos = true,
	showFullscreen = true,
	children,
}: PropsWithChildren<HeaderProps>) => {
	const title = "SonoFun Live!"

	return isMobile ? (
		<div className="flex flex-row items-center justify-between">
			<h5 className="font-medium" style={{ fontWeight: 500 }}>
				SonoFun Live!
			</h5>
			{children}
		</div>
	) : (
		<div className="flex flex-row items-center justify-between mb-2.5">
			<h3 className="m-0">{title}</h3>
			<div className="flex flex-row items-center">
				{showFullscreen && <FullscreenButton className="mr-2.5" />}
				<LanguageButton />
				{logos && <Logos />}
			</div>
		</div>
	)
}

export default Header
