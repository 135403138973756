import { BASE_URL } from "../constants/constants"

export const formatUrl = (
	url: string,
	parameter?: Record<string, string | number | boolean>
) => {
	if (!parameter || parameter === null) {
		return BASE_URL + url
	}

	Object.keys(parameter).forEach((key) =>
		parameter[key] === undefined ? delete parameter[key] : {}
	)

	const esc = encodeURIComponent
	const query = Object.keys(parameter)
		.map((k) => {
			return esc(k) + "=" + esc(parameter[k])
		})
		.join("&")

	let final = url
	if (query.length > 0) {
		final += "?" + query
	}

	final = BASE_URL + final

	return final
}

export const formatObject = (obj: Record<string, unknown>) => {
	let copy = obj
	Object.keys(copy).forEach((key) =>
		copy[key] === undefined ? delete copy[key] : {}
	)

	return copy
}
