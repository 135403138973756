import { FormEvent, FunctionComponent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import Input from "src/components/input/input"
import Body from "../../../components/body/body"
import Button from "../../../components/button/button"
import useHandleChange from "../../../hooks/useHandleChange"
import { fetchApi } from "../../../network/fetch"
import { useErrorStore } from "../../../state/stores/errorStore"

const Activate: FunctionComponent = () => {
	const [password, setPassword] = useHandleChange("")
	const [password2, setPassword2] = useHandleChange("")
	const { t } = useTranslation()
	const { code } = useParams() as Record<string, string>
	const [activationCode, setCode] = useState<string | undefined>(undefined)
	const navigate = useNavigate()

	const register = async (e: FormEvent) => {
		if (activationCode) {
			e.preventDefault()

			const success = await fetchApi(
				"/users/moderators/register/setpassword",
				{ activationCode, password },
				"PUT"
			)
			if (success) {
				navigate("/moderator/")
			}
		} else {
			useErrorStore
				.getState()
				.update(new Error("No code, did you click on the link in the email?"))
		}
	}

	useEffect(() => {
		if (code) {
			setCode(code)
		} else {
			useErrorStore
				.getState()
				.update(new Error("No code, did you click on the link in the email?"))
		}
	}, [code])

	const disabled = () => {
		if (
			password.length === 0 ||
			password2.length === 0 ||
			password !== password2
		) {
			return true
		}
	}

	return (
		<Body>
			<div className="flex flex-col space-y-8">
				<h1>SonoFun Live! Moderator Registrierung</h1>
				<form onSubmit={register}>
					<div className="flex flex-col space-y-2.5">
						<Input
							name="password"
							placeholder={t("password")}
							type="password"
							value={password}
							onChange={setPassword}
						/>
						<Input
							name="password"
							placeholder={t("confirm_password")}
							type="password"
							value={password2}
							onChange={setPassword2}
						/>
						<Button className="mt-5" disabled={disabled()} type="submit">
							{t("register")}
						</Button>
					</div>
				</form>
			</div>
		</Body>
	)
}

export default Activate
