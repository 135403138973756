import {
	forwardRef,
	MouseEventHandler,
	SyntheticEvent,
	useCallback,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from "react"
import Pause from "../../assets/images/pause.svg"
import Play from "../../assets/images/play.svg"
import { map_numbers } from "../../utils/math"
import Button from "../button/button"

interface VideoPlayerProps {
	url: string
	onPlayStateChange: (isPlaying: boolean) => void
	onTimeUpdate: (time: number) => void
	onMouseEnter: () => void
	onMouseLeave: () => void
	containerWidth?: number
	containerHeight?: number

	playPause?: boolean
	time?: number
}

const VideoPlayer = forwardRef<HTMLDivElement, VideoPlayerProps>(
	(
		{
			url,
			onPlayStateChange,
			onTimeUpdate,
			onMouseEnter,
			onMouseLeave,
			containerWidth,
			containerHeight,
			playPause,
			time,
		},
		ref
	) => {
		const [isPlaying, setIsplaying] = useState(false)
		const [currentTime, setCurrentTime] = useState(0)
		const [duration, setDuration] = useState(-1)
		const [scrubbing, setScrubbing] = useState(false)
		const [aspectRatio, setAspectRatio] = useState<number | undefined>(
			undefined
		)
		const [width, setWidth] = useState<number | undefined>(undefined)
		const [height, setHeight] = useState<number | undefined>(undefined)

		const video = useRef<HTMLVideoElement>(null)
		const scrubber = useRef<HTMLDivElement>(null)

		const _playPause = () => {
			if (video && video.current) {
				if (isPlaying) {
					video.current.pause()
				} else {
					video.current.play()
				}

				onPlayStateChange(!isPlaying)
				setIsplaying(!isPlaying)
			}
		}

		useEffect(() => {
			if (playPause !== undefined) {
				setIsplaying(playPause)
			}
		}, [playPause])

		const afterLoad = () => {
			if (video.current) {
				setDuration(video.current.duration)
			}
		}

		const updateTime = () => {
			if (video.current) {
				setCurrentTime(video.current.currentTime)
			}
		}

		useEffect(() => {
			if (video.current && time !== undefined) {
				video.current.currentTime = time
			}
		}, [time])

		useEffect(() => {
			window.onmouseup = () => {
				setScrubbing(false)
			}
		}, [])

		// TODO: react slider
		const scrubInner: MouseEventHandler<"div"> &
			MouseEventHandler<HTMLDivElement> = useCallback(
			(event) => {
				if (event && scrubber.current) {
					const rect = scrubber.current.getBoundingClientRect()
					const x = event.clientX - rect.left
					const percentage = x / scrubber.current.offsetWidth

					let newTime = percentage * duration
					if (newTime < 0) {
						newTime = 0
					} else if (newTime > duration) {
						newTime = duration
					}

					if (video.current) {
						video.current.currentTime = newTime
					}

					setCurrentTime(newTime)
				}
			},
			[duration, video]
		)

		useEffect(() => {
			const scrub = (event: SyntheticEvent) => {
				if (!scrubbing) {
					return
				}

				// @ts-ignore
				scrubInner(event)
			}

			// @ts-ignore
			window.onmousemove = scrub
		}, [scrubbing, duration, scrubInner])

		let playPercentage = 0
		if (scrubber.current) {
			playPercentage = map_numbers(
				(currentTime / duration) * 100,
				0,
				100,
				0,
				scrubber.current.offsetWidth - 10
			)
		}

		useLayoutEffect(() => {
			const onResize = () => {
				if (aspectRatio && containerWidth && containerHeight) {
					let height = aspectRatio * containerWidth
					height = Math.min(height, containerHeight)
					setHeight(height)

					if (height >= containerHeight) {
						setWidth(height / aspectRatio)
					}
				}
			}

			window.addEventListener("resize", onResize)
			onResize()

			return () => window.removeEventListener("resize", onResize)
		}, [aspectRatio, containerWidth, containerHeight])

		return (
			<div
				className={`relative flex self-stretch `}
				ref={ref}
				style={{ maxWidth: width ? width + "px" : "initial" }}
			>
				<video
					preload="auto"
					ref={video}
					src={url}
					style={{ height: height ? height + "px" : "initial" }}
					width="100%"
					onCanPlayThrough={afterLoad}
					onLoadedMetadata={() => {
						if (video.current && containerWidth) {
							setAspectRatio(
								video.current.videoHeight / video.current.videoWidth
							)
						}
					}}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					onTimeUpdate={updateTime}
				/>

				<div className="overflow-hidden pb-[56.25%] bg-white">
					<div className="rounded-xl absolute left-2 bottom-2 right-2 z-10">
						<div className="flex flex-row items-center relative">
							<Button
								className="!bg-transparent mb-px shadow-none cursor-pointer p-0 disabled:grayscale disabled:pointer-events-none disabled:cursor-default"
								disabled={duration === -1}
								onClick={_playPause}
							>
								<img
									alt="Play/Pause"
									height="30px"
									src={isPlaying ? Pause : Play}
									style={{ position: "relative", top: "1px" }}
								/>
							</Button>
							<div className="rounded-xl my-0 mr-2 ml-1 w-32 backdrop-brightness-150">
								<h3 className="text-center">
									{new Date(currentTime * 1000).toISOString().substr(14, 5)}/
									{new Date(duration * 1000).toISOString().substr(14, 5)}
								</h3>
							</div>
							<div
								className="flex-1 h-7 flex items-center relative mr-5"
								ref={scrubber}
								onMouseDown={scrubInner}
							>
								<div className="h-1 bg-white flex-1 rounded-sm cursor-pointer pointer-events-none" />
								<div
									className="bg-s-blue cursor-pointer w-2 h-full rounded-xl absolute top-0 transition-all duration-[20] ease-linear"
									style={{
										left: `${playPercentage}px`,
									}}
									onMouseDown={() => setScrubbing(true)}
									onMouseUp={() => onTimeUpdate(currentTime)}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
)

export default VideoPlayer
