const segmentationFs = {
  0: { color: [0, 0, 0], opacity: 0, label: 'Unknown' },
  1: { color: [70, 130, 180], opacity: 1, label: 'Left-Cerebral-Exterior' },
  2: { color: [245, 245, 245], opacity: 1, label: 'Left-Cerebral-White-Matter' },
  3: { color: [205, 62, 78], opacity: 1, label: 'Left-Cerebral-Cortex' },
  4: { color: [120, 18, 134], opacity: 1, label: 'Left-Lateral-Ventricle' },
  5: { color: [196, 58, 250], opacity: 1, label: 'Left-Inf-Lat-Vent' },
  6: { color: [0, 148, 0], opacity: 1, label: 'Left-Cerebellum-Exterior' },
  7: { color: [220, 248, 164], opacity: 1, label: 'Left-Cerebellum-White-Matter' },
  8: { color: [230, 148, 34], opacity: 1, label: 'Left-Cerebellum-Cortex' },
  9: { color: [0, 118, 14], opacity: 1, label: 'Left-Thalamus' },
  10: { color: [0, 118, 14], opacity: 1, label: 'Left-Thalamus-Proper' },
  11: { color: [122, 186, 220], opacity: 1, label: 'Left-Caudate' },
  12: { color: [236, 13, 176], opacity: 1, label: 'Left-Putamen' },
  13: { color: [12, 48, 255], opacity: 1, label: 'Left-Pallidum' },
  14: { color: [204, 182, 142], opacity: 1, label: '3rd-Ventricle' },
  15: { color: [42, 204, 164], opacity: 1, label: '4th-Ventricle' },
  16: { color: [119, 159, 176], opacity: 1, label: 'Brain-Stem' },
  17: { color: [220, 216, 20], opacity: 1, label: 'Left-Hippocampus' },
  18: { color: [103, 255, 255], opacity: 1, label: 'Left-Amygdala' },
  19: { color: [80, 196, 98], opacity: 1, label: 'Left-Insula' },
  20: { color: [60, 58, 210], opacity: 1, label: 'Left-Operculum' },
  21: { color: [60, 58, 210], opacity: 1, label: 'Line-1' },
  22: { color: [60, 58, 210], opacity: 1, label: 'Line-2' },
  23: { color: [60, 58, 210], opacity: 1, label: 'Line-3' },
  24: { color: [60, 60, 60], opacity: 1, label: 'CSF' },
  25: { color: [255, 165, 0], opacity: 1, label: 'Left-Lesion' },
  26: { color: [255, 165, 0], opacity: 1, label: 'Left-Accumbens-area' },
  27: { color: [0, 255, 127], opacity: 1, label: 'Left-Substancia-Nigra' },
  28: { color: [165, 42, 42], opacity: 1, label: 'Left-VentralDC' },
  29: { color: [135, 206, 235], opacity: 1, label: 'Left-undetermined' },
  30: { color: [160, 32, 240], opacity: 1, label: 'Left-vessel' },
  31: { color: [0, 200, 200], opacity: 1, label: 'Left-choroid-plexus' },
  32: { color: [100, 50, 100], opacity: 1, label: 'Left-F3orb' },
  33: { color: [135, 50, 74], opacity: 1, label: 'Left-lOg' },
  34: { color: [122, 135, 50], opacity: 1, label: 'Left-aOg' },
  35: { color: [51, 50, 135], opacity: 1, label: 'Left-mOg' },
  36: { color: [74, 155, 60], opacity: 1, label: 'Left-pOg' },
  37: { color: [120, 62, 43], opacity: 1, label: 'Left-Stellate' },
  38: { color: [74, 155, 60], opacity: 1, label: 'Left-Porg' },
  39: { color: [122, 135, 50], opacity: 1, label: 'Left-Aorg' },
  40: { color: [70, 130, 180], opacity: 1, label: 'Right-Cerebral-Exterior' },
  41: { color: [245, 245, 245], opacity: 1, label: 'Right-Cerebral-White-Matter' },
  42: { color: [205, 62, 78], opacity: 1, label: 'Right-Cerebral-Cortex' },
  43: { color: [120, 18, 134], opacity: 1, label: 'Right-Lateral-Ventricle' },
  44: { color: [196, 58, 250], opacity: 1, label: 'Right-Inf-Lat-Vent' },
  45: { color: [0, 148, 0], opacity: 1, label: 'Right-Cerebellum-Exterior' },
  46: { color: [220, 248, 164], opacity: 1, label: 'Right-Cerebellum-White-Matter' },
  47: { color: [230, 148, 34], opacity: 1, label: 'Right-Cerebellum-Cortex' },
  48: { color: [0, 118, 14], opacity: 1, label: 'Right-Thalamus' },
  49: { color: [0, 118, 14], opacity: 1, label: 'Right-Thalamus-Proper' },
  50: { color: [122, 186, 220], opacity: 1, label: 'Right-Caudate' },
  51: { color: [236, 13, 176], opacity: 1, label: 'Right-Putamen' },
  52: { color: [13, 48, 255], opacity: 1, label: 'Right-Pallidum' },
  53: { color: [220, 216, 20], opacity: 1, label: 'Right-Hippocampus' },
  54: { color: [103, 255, 255], opacity: 1, label: 'Right-Amygdala' },
  55: { color: [80, 196, 98], opacity: 1, label: 'Right-Insula' },
  56: { color: [60, 58, 210], opacity: 1, label: 'Right-Operculum' },
  57: { color: [255, 165, 0], opacity: 1, label: 'Right-Lesion' },
  58: { color: [255, 165, 0], opacity: 1, label: 'Right-Accumbens-area' },
  59: { color: [0, 255, 127], opacity: 1, label: 'Right-Substancia-Nigra' },
  60: { color: [165, 42, 42], opacity: 1, label: 'Right-VentralDC' },
  61: { color: [135, 206, 235], opacity: 1, label: 'Right-undetermined' },
  62: { color: [160, 32, 240], opacity: 1, label: 'Right-vessel' },
  63: { color: [0, 200, 221], opacity: 1, label: 'Right-choroid-plexus' },
  64: { color: [100, 50, 100], opacity: 1, label: 'Right-F3orb' },
  65: { color: [135, 50, 74], opacity: 1, label: 'Right-lOg' },
  66: { color: [122, 135, 50], opacity: 1, label: 'Right-aOg' },
  67: { color: [51, 50, 135], opacity: 1, label: 'Right-mOg' },
  68: { color: [74, 155, 60], opacity: 1, label: 'Right-pOg' },
  69: { color: [120, 62, 43], opacity: 1, label: 'Right-Stellate' },
  70: { color: [74, 155, 60], opacity: 1, label: 'Right-Porg' },
  71: { color: [122, 135, 50], opacity: 1, label: 'Right-Aorg' },
  72: { color: [120, 190, 150], opacity: 1, label: '5th-Ventricle' },
  73: { color: [122, 135, 50], opacity: 1, label: 'Left-Interior' },
  74: { color: [122, 135, 50], opacity: 1, label: 'Right-Interior' },
  77: { color: [200, 70, 255], opacity: 1, label: 'WM-hypointensities' },
  78: { color: [255, 148, 10], opacity: 1, label: 'Left-WM-hypointensities' },
  79: { color: [255, 148, 10], opacity: 1, label: 'Right-WM-hypointensities' },
  80: { color: [164, 108, 226], opacity: 1, label: 'non-WM-hypointensities' },
  81: { color: [164, 108, 226], opacity: 1, label: 'Left-non-WM-hypointensities' },
  82: { color: [164, 108, 226], opacity: 1, label: 'Right-non-WM-hypointensities' },
  83: { color: [255, 218, 185], opacity: 1, label: 'Left-F1' },
  84: { color: [255, 218, 185], opacity: 1, label: 'Right-F1' },
  85: { color: [234, 169, 30], opacity: 1, label: 'Optic-Chiasm' },
  192: { color: [250, 255, 50], opacity: 1, label: 'Corpus_Callosum' },
  86: { color: [200, 120, 255], opacity: 1, label: 'Left_future_WMSA' },
  87: { color: [200, 121, 255], opacity: 1, label: 'Right_future_WMSA' },
  88: { color: [200, 122, 255], opacity: 1, label: 'future_WMSA' },
  96: { color: [205, 10, 125], opacity: 1, label: 'Left-Amygdala-Anterior' },
  97: { color: [205, 10, 125], opacity: 1, label: 'Right-Amygdala-Anterior' },
  98: { color: [160, 32, 240], opacity: 1, label: 'Dura' },
  100: { color: [124, 140, 178], opacity: 1, label: 'Left-wm-intensity-abnormality' },
  101: { color: [125, 140, 178], opacity: 1, label: 'Left-caudate-intensity-abnormality' },
  102: { color: [126, 140, 178], opacity: 1, label: 'Left-putamen-intensity-abnormality' },
  103: { color: [127, 140, 178], opacity: 1, label: 'Left-accumbens-intensity-abnormality' },
  104: { color: [124, 141, 178], opacity: 1, label: 'Left-pallidum-intensity-abnormality' },
  105: { color: [124, 142, 178], opacity: 1, label: 'Left-amygdala-intensity-abnormality' },
  106: { color: [124, 143, 178], opacity: 1, label: 'Left-hippocampus-intensity-abnormality' },
  107: { color: [124, 144, 178], opacity: 1, label: 'Left-thalamus-intensity-abnormality' },
  108: { color: [124, 140, 179], opacity: 1, label: 'Left-VDC-intensity-abnormality' },
  109: { color: [124, 140, 178], opacity: 1, label: 'Right-wm-intensity-abnormality' },
  110: { color: [125, 140, 178], opacity: 1, label: 'Right-caudate-intensity-abnormality' },
  111: { color: [126, 140, 178], opacity: 1, label: 'Right-putamen-intensity-abnormality' },
  112: { color: [127, 140, 178], opacity: 1, label: 'Right-accumbens-intensity-abnormality' },
  113: { color: [124, 141, 178], opacity: 1, label: 'Right-pallidum-intensity-abnormality' },
  114: { color: [124, 142, 178], opacity: 1, label: 'Right-amygdala-intensity-abnormality' },
  115: { color: [124, 143, 178], opacity: 1, label: 'Right-hippocampus-intensity-abnormality' },
  116: { color: [124, 144, 178], opacity: 1, label: 'Right-thalamus-intensity-abnormality' },
  117: { color: [124, 140, 179], opacity: 1, label: 'Right-VDC-intensity-abnormality' },
  118: { color: [255, 20, 147], opacity: 1, label: 'Epidermis' },
  119: { color: [205, 179, 139], opacity: 1, label: 'Conn-Tissue' },
  120: { color: [238, 238, 209], opacity: 1, label: 'SC-Fat-Muscle' },
  121: { color: [200, 200, 200], opacity: 1, label: 'Cranium' },
  122: { color: [74, 255, 74], opacity: 1, label: 'CSF-SA' },
  123: { color: [238, 0, 0], opacity: 1, label: 'Muscle' },
  124: { color: [0, 0, 139], opacity: 1, label: 'Ear' },
  125: { color: [173, 255, 47], opacity: 1, label: 'Adipose' },
  126: { color: [133, 203, 229], opacity: 1, label: 'Spinal-Cord' },
  127: { color: [26, 237, 57], opacity: 1, label: 'Soft-Tissue' },
  128: { color: [34, 139, 34], opacity: 1, label: 'Nerve' },
  129: { color: [30, 144, 255], opacity: 1, label: 'Bone' },
  130: { color: [147, 19, 173], opacity: 1, label: 'Air' },
  131: { color: [238, 59, 59], opacity: 1, label: 'Orbital-Fat' },
  132: { color: [221, 39, 200], opacity: 1, label: 'Tongue' },
  133: { color: [238, 174, 238], opacity: 1, label: 'Nasal-Structures' },
  134: { color: [255, 0, 0], opacity: 1, label: 'Globe' },
  135: { color: [72, 61, 139], opacity: 1, label: 'Teeth' },
  136: { color: [21, 39, 132], opacity: 1, label: 'Left-Caudate-Putamen' },
  137: { color: [21, 39, 132], opacity: 1, label: 'Right-Caudate-Putamen' },
  138: { color: [65, 135, 20], opacity: 1, label: 'Left-Claustrum' },
  139: { color: [65, 135, 20], opacity: 1, label: 'Right-Claustrum' },
  140: { color: [134, 4, 160], opacity: 1, label: 'Cornea' },
  142: { color: [221, 226, 68], opacity: 1, label: 'Diploe' },
  143: { color: [255, 255, 254], opacity: 1, label: 'Vitreous-Humor' },
  144: { color: [52, 209, 226], opacity: 1, label: 'Lens' },
  145: { color: [239, 160, 223], opacity: 1, label: 'Aqueous-Humor' },
  146: { color: [70, 130, 180], opacity: 1, label: 'Outer-Table' },
  147: { color: [70, 130, 181], opacity: 1, label: 'Inner-Table' },
  148: { color: [139, 121, 94], opacity: 1, label: 'Periosteum' },
  149: { color: [224, 224, 224], opacity: 1, label: 'Endosteum' },
  150: { color: [255, 0, 0], opacity: 1, label: 'R-C-S' },
  151: { color: [205, 205, 0], opacity: 1, label: 'Iris' },
  152: { color: [238, 238, 209], opacity: 1, label: 'SC-Adipose-Muscle' },
  153: { color: [139, 121, 94], opacity: 1, label: 'SC-Tissue' },
  154: { color: [238, 59, 59], opacity: 1, label: 'Orbital-Adipose' },
  155: { color: [238, 59, 59], opacity: 1, label: 'Left-IntCapsule-Ant' },
  156: { color: [238, 59, 59], opacity: 1, label: 'Right-IntCapsule-Ant' },
  157: { color: [62, 10, 205], opacity: 1, label: 'Left-IntCapsule-Pos' },
  158: { color: [62, 10, 205], opacity: 1, label: 'Right-IntCapsule-Pos' },
  159: { color: [0, 118, 14], opacity: 1, label: 'Left-Cerebral-WM-unmyelinated' },
  160: { color: [0, 118, 14], opacity: 1, label: 'Right-Cerebral-WM-unmyelinated' },
  161: { color: [220, 216, 21], opacity: 1, label: 'Left-Cerebral-WM-myelinated' },
  162: { color: [220, 216, 21], opacity: 1, label: 'Right-Cerebral-WM-myelinated' },
  163: { color: [122, 186, 220], opacity: 1, label: 'Left-Subcortical-Gray-Matter' },
  164: { color: [122, 186, 220], opacity: 1, label: 'Right-Subcortical-Gray-Matter' },
  165: { color: [120, 120, 120], opacity: 1, label: 'Skull' },
  166: { color: [14, 48, 255], opacity: 1, label: 'Posterior-fossa' },
  167: { color: [166, 42, 42], opacity: 1, label: 'Scalp' },
  168: { color: [121, 18, 134], opacity: 1, label: 'Hematoma' },
  169: { color: [236, 13, 127], opacity: 1, label: 'Left-Basal-Ganglia' },
  176: { color: [236, 13, 126], opacity: 1, label: 'Right-Basal-Ganglia' },
  170: { color: [119, 159, 176], opacity: 1, label: 'brainstem' },
  171: { color: [119, 0, 176], opacity: 1, label: 'DCG' },
  172: { color: [119, 100, 176], opacity: 1, label: 'Vermis' },
  173: { color: [242, 104, 76], opacity: 1, label: 'Midbrain' },
  174: { color: [206, 195, 58], opacity: 1, label: 'Pons' },
  175: { color: [119, 159, 176], opacity: 1, label: 'Medulla' },
  177: { color: [119, 50, 176], opacity: 1, label: 'Vermis-White-Matter' },
  178: { color: [142, 182, 0], opacity: 1, label: 'SCP' },
  179: { color: [19, 100, 176], opacity: 1, label: 'Floculus' },
  180: { color: [73, 61, 139], opacity: 1, label: 'Left-Cortical-Dysplasia' },
  181: { color: [73, 62, 139], opacity: 1, label: 'Right-Cortical-Dysplasia' },
  182: { color: [10, 100, 176], opacity: 1, label: 'CblumNodulus' },
  193: { color: [0, 196, 255], opacity: 1, label: 'Left-hippocampal_fissure' },
  194: { color: [255, 164, 164], opacity: 1, label: 'Left-CADG-head' },
  195: { color: [196, 196, 0], opacity: 1, label: 'Left-subiculum' },
  196: { color: [0, 100, 255], opacity: 1, label: 'Left-fimbria' },
  197: { color: [128, 196, 164], opacity: 1, label: 'Right-hippocampal_fissure' },
  198: { color: [0, 126, 75], opacity: 1, label: 'Right-CADG-head' },
  199: { color: [128, 96, 64], opacity: 1, label: 'Right-subiculum' },
  200: { color: [0, 50, 128], opacity: 1, label: 'Right-fimbria' },
  201: { color: [255, 204, 153], opacity: 1, label: 'alveus' },
  202: { color: [255, 128, 128], opacity: 1, label: 'perforant_pathway' },
  203: { color: [255, 255, 0], opacity: 1, label: 'parasubiculum' },
  204: { color: [64, 0, 64], opacity: 1, label: 'presubiculum' },
  205: { color: [0, 0, 255], opacity: 1, label: 'subiculum' },
  206: { color: [255, 0, 0], opacity: 1, label: 'CA1' },
  207: { color: [128, 128, 255], opacity: 1, label: 'CA2' },
  208: { color: [0, 128, 0], opacity: 1, label: 'CA3' },
  209: { color: [196, 160, 128], opacity: 1, label: 'CA4' },
  210: { color: [32, 200, 255], opacity: 1, label: 'GC-DG' },
  211: { color: [128, 255, 128], opacity: 1, label: 'HATA' },
  212: { color: [204, 153, 204], opacity: 1, label: 'fimbria' },
  213: { color: [121, 17, 136], opacity: 1, label: 'lateral_ventricle' },
  214: { color: [128, 0, 0], opacity: 1, label: 'molecular_layer_HP' },
  215: { color: [128, 32, 255], opacity: 1, label: 'hippocampal_fissure' },
  216: { color: [255, 204, 102], opacity: 1, label: 'entorhinal_cortex' },
  217: { color: [128, 128, 128], opacity: 1, label: 'molecular_layer_subiculum' },
  218: { color: [104, 255, 255], opacity: 1, label: 'Amygdala' },
  219: { color: [0, 226, 0], opacity: 1, label: 'Cerebral_White_Matter' },
  220: { color: [205, 63, 78], opacity: 1, label: 'Cerebral_Cortex' },
  221: { color: [197, 58, 250], opacity: 1, label: 'Inf_Lat_Vent' },
  222: { color: [33, 150, 250], opacity: 1, label: 'Perirhinal' },
  223: { color: [226, 0, 0], opacity: 1, label: 'Cerebral_White_Matter_Edge' },
  224: { color: [100, 100, 100], opacity: 1, label: 'Background' },
  225: { color: [197, 150, 250], opacity: 1, label: 'Ectorhinal' },
  226: { color: [170, 170, 255], opacity: 1, label: 'HP_tail' },
  250: { color: [255, 0, 0], opacity: 1, label: 'Fornix' },
  251: { color: [0, 0, 64], opacity: 1, label: 'CC_Posterior' },
  252: { color: [0, 0, 112], opacity: 1, label: 'CC_Mid_Posterior' },
  253: { color: [0, 0, 160], opacity: 1, label: 'CC_Central' },
  254: { color: [0, 0, 208], opacity: 1, label: 'CC_Mid_Anterior' },
  255: { color: [0, 0, 255], opacity: 1, label: 'CC_Anterior' },
  256: { color: [0, 0, 0], opacity: 1, label: 'Voxel-Unchanged' },
  257: { color: [60, 60, 60], opacity: 1, label: 'CSF-ExtraCerebral' },
  258: { color: [150, 150, 200], opacity: 1, label: 'Head-ExtraCerebral' },
  259: { color: [120, 120, 120], opacity: 1, label: 'SkullApprox' },
  260: { color: [119, 159, 176], opacity: 1, label: 'BoneOrAir' },
  261: { color: [120, 18, 134], opacity: 1, label: 'PossibleFluid' },
  262: { color: [119, 159, 176], opacity: 1, label: 'Sinus' },
  263: { color: [119, 159, 176], opacity: 1, label: 'Left-Eustachian' },
  264: { color: [119, 159, 176], opacity: 1, label: 'Right-Eustachian' },
  331: { color: [255, 0, 0], opacity: 1, label: 'Aorta' },
  332: { color: [255, 80, 0], opacity: 1, label: 'Left-Common-IliacA' },
  333: { color: [255, 160, 0], opacity: 1, label: 'Right-Common-IliacA' },
  334: { color: [255, 255, 0], opacity: 1, label: 'Left-External-IliacA' },
  335: { color: [0, 255, 0], opacity: 1, label: 'Right-External-IliacA' },
  336: { color: [255, 0, 160], opacity: 1, label: 'Left-Internal-IliacA' },
  337: { color: [255, 0, 255], opacity: 1, label: 'Right-Internal-IliacA' },
  338: { color: [255, 50, 80], opacity: 1, label: 'Left-Lateral-SacralA' },
  339: { color: [80, 255, 50], opacity: 1, label: 'Right-Lateral-SacralA' },
  340: { color: [160, 255, 50], opacity: 1, label: 'Left-ObturatorA' },
  341: { color: [160, 200, 255], opacity: 1, label: 'Right-ObturatorA' },
  342: { color: [0, 255, 160], opacity: 1, label: 'Left-Internal-PudendalA' },
  343: { color: [0, 0, 255], opacity: 1, label: 'Right-Internal-PudendalA' },
  344: { color: [80, 50, 255], opacity: 1, label: 'Left-UmbilicalA' },
  345: { color: [160, 0, 255], opacity: 1, label: 'Right-UmbilicalA' },
  346: { color: [255, 210, 0], opacity: 1, label: 'Left-Inf-RectalA' },
  347: { color: [0, 160, 255], opacity: 1, label: 'Right-Inf-RectalA' },
  348: { color: [255, 200, 80], opacity: 1, label: 'Left-Common-IliacV' },
  349: { color: [255, 200, 160], opacity: 1, label: 'Right-Common-IliacV' },
  350: { color: [255, 80, 200], opacity: 1, label: 'Left-External-IliacV' },
  351: { color: [255, 160, 200], opacity: 1, label: 'Right-External-IliacV' },
  352: { color: [30, 255, 80], opacity: 1, label: 'Left-Internal-IliacV' },
  353: { color: [80, 200, 255], opacity: 1, label: 'Right-Internal-IliacV' },
  354: { color: [80, 255, 200], opacity: 1, label: 'Left-ObturatorV' },
  355: { color: [195, 255, 200], opacity: 1, label: 'Right-ObturatorV' },
  356: { color: [120, 200, 20], opacity: 1, label: 'Left-Internal-PudendalV' },
  357: { color: [170, 10, 200], opacity: 1, label: 'Right-Internal-PudendalV' },
  358: { color: [20, 130, 180], opacity: 1, label: 'Pos-Lymph' },
  359: { color: [20, 180, 130], opacity: 1, label: 'Neg-Lymph' },
  400: { color: [206, 62, 78], opacity: 1, label: 'V1' },
  401: { color: [121, 18, 134], opacity: 1, label: 'V2' },
  402: { color: [199, 58, 250], opacity: 1, label: 'BA44' },
  403: { color: [1, 148, 0], opacity: 1, label: 'BA45' },
  404: { color: [221, 248, 164], opacity: 1, label: 'BA4a' },
  405: { color: [231, 148, 34], opacity: 1, label: 'BA4p' },
  406: { color: [1, 118, 14], opacity: 1, label: 'BA6' },
  407: { color: [120, 118, 14], opacity: 1, label: 'BA2' },
  408: { color: [123, 186, 221], opacity: 1, label: 'BA1_old' },
  409: { color: [238, 13, 177], opacity: 1, label: 'BAun2' },
  410: { color: [123, 186, 220], opacity: 1, label: 'BA1' },
  411: { color: [138, 13, 206], opacity: 1, label: 'BA2b' },
  412: { color: [238, 130, 176], opacity: 1, label: 'BA3a' },
  413: { color: [218, 230, 76], opacity: 1, label: 'BA3b' },
  414: { color: [38, 213, 176], opacity: 1, label: 'MT' },
  415: { color: [1, 225, 176], opacity: 1, label: 'AIPS_AIP_l' },
  416: { color: [1, 225, 176], opacity: 1, label: 'AIPS_AIP_r' },
  417: { color: [200, 2, 100], opacity: 1, label: 'AIPS_VIP_l' },
  418: { color: [200, 2, 100], opacity: 1, label: 'AIPS_VIP_r' },
  419: { color: [5, 200, 90], opacity: 1, label: 'IPL_PFcm_l' },
  420: { color: [5, 200, 90], opacity: 1, label: 'IPL_PFcm_r' },
  421: { color: [100, 5, 200], opacity: 1, label: 'IPL_PF_l' },
  422: { color: [25, 255, 100], opacity: 1, label: 'IPL_PFm_l' },
  423: { color: [25, 255, 100], opacity: 1, label: 'IPL_PFm_r' },
  424: { color: [230, 7, 100], opacity: 1, label: 'IPL_PFop_l' },
  425: { color: [230, 7, 100], opacity: 1, label: 'IPL_PFop_r' },
  426: { color: [100, 5, 200], opacity: 1, label: 'IPL_PF_r' },
  427: { color: [150, 10, 200], opacity: 1, label: 'IPL_PFt_l' },
  428: { color: [150, 10, 200], opacity: 1, label: 'IPL_PFt_r' },
  429: { color: [175, 10, 176], opacity: 1, label: 'IPL_PGa_l' },
  430: { color: [175, 10, 176], opacity: 1, label: 'IPL_PGa_r' },
  431: { color: [10, 100, 255], opacity: 1, label: 'IPL_PGp_l' },
  432: { color: [10, 100, 255], opacity: 1, label: 'IPL_PGp_r' },
  433: { color: [150, 45, 70], opacity: 1, label: 'Visual_V3d_l' },
  434: { color: [150, 45, 70], opacity: 1, label: 'Visual_V3d_r' },
  435: { color: [45, 200, 15], opacity: 1, label: 'Visual_V4_l' },
  436: { color: [45, 200, 15], opacity: 1, label: 'Visual_V4_r' },
  437: { color: [227, 45, 100], opacity: 1, label: 'Visual_V5_b' },
  438: { color: [227, 45, 100], opacity: 1, label: 'Visual_VP_l' },
  439: { color: [227, 45, 100], opacity: 1, label: 'Visual_VP_r' },
  498: { color: [143, 188, 143], opacity: 1, label: 'wmsa' },
  499: { color: [255, 248, 220], opacity: 1, label: 'other_wmsa' },
  500: { color: [17, 85, 136], opacity: 1, label: 'right_CA2_3' },
  501: { color: [119, 187, 102], opacity: 1, label: 'right_alveus' },
  502: { color: [204, 68, 34], opacity: 1, label: 'right_CA1' },
  503: { color: [204, 0, 255], opacity: 1, label: 'right_fimbria' },
  504: { color: [221, 187, 17], opacity: 1, label: 'right_presubiculum' },
  505: { color: [153, 221, 238], opacity: 1, label: 'right_hippocampal_fissure' },
  506: { color: [51, 17, 17], opacity: 1, label: 'right_CA4_DG' },
  507: { color: [0, 119, 85], opacity: 1, label: 'right_subiculum' },
  508: { color: [20, 100, 200], opacity: 1, label: 'right_fornix' },
  550: { color: [17, 85, 137], opacity: 1, label: 'left_CA2_3' },
  551: { color: [119, 187, 103], opacity: 1, label: 'left_alveus' },
  552: { color: [204, 68, 35], opacity: 1, label: 'left_CA1' },
  553: { color: [204, 0, 254], opacity: 1, label: 'left_fimbria' },
  554: { color: [221, 187, 16], opacity: 1, label: 'left_presubiculum' },
  555: { color: [153, 221, 239], opacity: 1, label: 'left_hippocampal_fissure' },
  556: { color: [51, 17, 18], opacity: 1, label: 'left_CA4_DG' },
  557: { color: [0, 119, 86], opacity: 1, label: 'left_subiculum' },
  558: { color: [20, 100, 201], opacity: 1, label: 'left_fornix' },
  600: { color: [254, 254, 254], opacity: 1, label: 'Tumor' },
  601: { color: [70, 130, 180], opacity: 1, label: 'Cbm_Left_I_IV' },
  602: { color: [245, 245, 245], opacity: 1, label: 'Cbm_Right_I_IV' },
  603: { color: [205, 62, 78], opacity: 1, label: 'Cbm_Left_V' },
  604: { color: [120, 18, 134], opacity: 1, label: 'Cbm_Right_V' },
  605: { color: [196, 58, 250], opacity: 1, label: 'Cbm_Left_VI' },
  606: { color: [0, 148, 0], opacity: 1, label: 'Cbm_Vermis_VI' },
  607: { color: [220, 248, 164], opacity: 1, label: 'Cbm_Right_VI' },
  608: { color: [230, 148, 34], opacity: 1, label: 'Cbm_Left_CrusI' },
  609: { color: [0, 118, 14], opacity: 1, label: 'Cbm_Vermis_CrusI' },
  610: { color: [0, 118, 14], opacity: 1, label: 'Cbm_Right_CrusI' },
  611: { color: [122, 186, 220], opacity: 1, label: 'Cbm_Left_CrusII' },
  612: { color: [236, 13, 176], opacity: 1, label: 'Cbm_Vermis_CrusII' },
  613: { color: [12, 48, 255], opacity: 1, label: 'Cbm_Right_CrusII' },
  614: { color: [204, 182, 142], opacity: 1, label: 'Cbm_Left_VIIb' },
  615: { color: [42, 204, 164], opacity: 1, label: 'Cbm_Vermis_VIIb' },
  616: { color: [119, 159, 176], opacity: 1, label: 'Cbm_Right_VIIb' },
  617: { color: [220, 216, 20], opacity: 1, label: 'Cbm_Left_VIIIa' },
  618: { color: [103, 255, 255], opacity: 1, label: 'Cbm_Vermis_VIIIa' },
  619: { color: [80, 196, 98], opacity: 1, label: 'Cbm_Right_VIIIa' },
  620: { color: [60, 58, 210], opacity: 1, label: 'Cbm_Left_VIIIb' },
  621: { color: [60, 58, 210], opacity: 1, label: 'Cbm_Vermis_VIIIb' },
  622: { color: [60, 58, 210], opacity: 1, label: 'Cbm_Right_VIIIb' },
  623: { color: [60, 58, 210], opacity: 1, label: 'Cbm_Left_IX' },
  624: { color: [60, 60, 60], opacity: 1, label: 'Cbm_Vermis_IX' },
  625: { color: [255, 165, 0], opacity: 1, label: 'Cbm_Right_IX' },
  626: { color: [255, 165, 0], opacity: 1, label: 'Cbm_Left_X' },
  627: { color: [0, 255, 127], opacity: 1, label: 'Cbm_Vermis_X' },
  628: { color: [165, 42, 42], opacity: 1, label: 'Cbm_Right_X' },
  640: { color: [204, 0, 0], opacity: 1, label: 'Cbm_Right_I_V_med' },
  641: { color: [255, 0, 0], opacity: 1, label: 'Cbm_Right_I_V_mid' },
  642: { color: [0, 0, 255], opacity: 1, label: 'Cbm_Right_VI_med' },
  643: { color: [30, 144, 255], opacity: 1, label: 'Cbm_Right_VI_mid' },
  644: { color: [100, 212, 237], opacity: 1, label: 'Cbm_Right_VI_lat' },
  645: { color: [218, 165, 32], opacity: 1, label: 'Cbm_Right_CrusI_med' },
  646: { color: [255, 215, 0], opacity: 1, label: 'Cbm_Right_CrusI_mid' },
  647: { color: [255, 255, 166], opacity: 1, label: 'Cbm_Right_CrusI_lat' },
  648: { color: [153, 0, 204], opacity: 1, label: 'Cbm_Right_CrusII_med' },
  649: { color: [153, 141, 209], opacity: 1, label: 'Cbm_Right_CrusII_mid' },
  650: { color: [204, 204, 255], opacity: 1, label: 'Cbm_Right_CrusII_lat' },
  651: { color: [31, 212, 194], opacity: 1, label: 'Cbm_Right_7med' },
  652: { color: [3, 255, 237], opacity: 1, label: 'Cbm_Right_7mid' },
  653: { color: [204, 255, 255], opacity: 1, label: 'Cbm_Right_7lat' },
  654: { color: [86, 74, 147], opacity: 1, label: 'Cbm_Right_8med' },
  655: { color: [114, 114, 190], opacity: 1, label: 'Cbm_Right_8mid' },
  656: { color: [184, 178, 255], opacity: 1, label: 'Cbm_Right_8lat' },
  657: { color: [126, 138, 37], opacity: 1, label: 'Cbm_Right_PUNs' },
  658: { color: [189, 197, 117], opacity: 1, label: 'Cbm_Right_TONs' },
  659: { color: [240, 230, 140], opacity: 1, label: 'Cbm_Right_FLOs' },
  660: { color: [204, 0, 0], opacity: 1, label: 'Cbm_Left_I_V_med' },
  661: { color: [255, 0, 0], opacity: 1, label: 'Cbm_Left_I_V_mid' },
  662: { color: [0, 0, 255], opacity: 1, label: 'Cbm_Left_VI_med' },
  663: { color: [30, 144, 255], opacity: 1, label: 'Cbm_Left_VI_mid' },
  664: { color: [100, 212, 237], opacity: 1, label: 'Cbm_Left_VI_lat' },
  665: { color: [218, 165, 32], opacity: 1, label: 'Cbm_Left_CrusI_med' },
  666: { color: [255, 215, 0], opacity: 1, label: 'Cbm_Left_CrusI_mid' },
  667: { color: [255, 255, 166], opacity: 1, label: 'Cbm_Left_CrusI_lat' },
  668: { color: [153, 0, 204], opacity: 1, label: 'Cbm_Left_CrusII_med' },
  669: { color: [153, 141, 209], opacity: 1, label: 'Cbm_Left_CrusII_mid' },
  670: { color: [204, 204, 255], opacity: 1, label: 'Cbm_Left_CrusII_lat' },
  671: { color: [31, 212, 194], opacity: 1, label: 'Cbm_Left_7med' },
  672: { color: [3, 255, 237], opacity: 1, label: 'Cbm_Left_7mid' },
  673: { color: [204, 255, 255], opacity: 1, label: 'Cbm_Left_7lat' },
  674: { color: [86, 74, 147], opacity: 1, label: 'Cbm_Left_8med' },
  675: { color: [114, 114, 190], opacity: 1, label: 'Cbm_Left_8mid' },
  676: { color: [184, 178, 255], opacity: 1, label: 'Cbm_Left_8lat' },
  677: { color: [126, 138, 37], opacity: 1, label: 'Cbm_Left_PUNs' },
  678: { color: [189, 197, 117], opacity: 1, label: 'Cbm_Left_TONs' },
  679: { color: [240, 230, 140], opacity: 1, label: 'Cbm_Left_FLOs' },
  690: { color: [122, 135, 50], opacity: 1, label: 'CbmWM_Gyri_Left' },
  691: { color: [122, 135, 50], opacity: 1, label: 'CbmWM_Gyri_Right' },
  701: { color: [120, 18, 134], opacity: 1, label: 'CSF-FSL-FAST' },
  702: { color: [205, 62, 78], opacity: 1, label: 'GrayMatter-FSL-FAST' },
  703: { color: [0, 225, 0], opacity: 1, label: 'WhiteMatter-FSL-FAST' },
  999: { color: [255, 100, 100], opacity: 1, label: 'SUSPICIOUS' },
  1000: { color: [25, 5, 25], opacity: 1, label: 'ctx-lh-unknown' },
  1001: { color: [25, 100, 40], opacity: 1, label: 'ctx-lh-bankssts' },
  1002: { color: [125, 100, 160], opacity: 1, label: 'ctx-lh-caudalanteriorcingulate' },
  1003: { color: [100, 25, 0], opacity: 1, label: 'ctx-lh-caudalmiddlefrontal' },
  1004: { color: [120, 70, 50], opacity: 1, label: 'ctx-lh-corpuscallosum' },
  1005: { color: [220, 20, 100], opacity: 1, label: 'ctx-lh-cuneus' },
  1006: { color: [220, 20, 10], opacity: 1, label: 'ctx-lh-entorhinal' },
  1007: { color: [180, 220, 140], opacity: 1, label: 'ctx-lh-fusiform' },
  1008: { color: [220, 60, 220], opacity: 1, label: 'ctx-lh-inferiorparietal' },
  1009: { color: [180, 40, 120], opacity: 1, label: 'ctx-lh-inferiortemporal' },
  1010: { color: [140, 20, 140], opacity: 1, label: 'ctx-lh-isthmuscingulate' },
  1011: { color: [20, 30, 140], opacity: 1, label: 'ctx-lh-lateraloccipital' },
  1012: { color: [35, 75, 50], opacity: 1, label: 'ctx-lh-lateralorbitofrontal' },
  1013: { color: [225, 140, 140], opacity: 1, label: 'ctx-lh-lingual' },
  1014: { color: [200, 35, 75], opacity: 1, label: 'ctx-lh-medialorbitofrontal' },
  1015: { color: [160, 100, 50], opacity: 1, label: 'ctx-lh-middletemporal' },
  1016: { color: [20, 220, 60], opacity: 1, label: 'ctx-lh-parahippocampal' },
  1017: { color: [60, 220, 60], opacity: 1, label: 'ctx-lh-paracentral' },
  1018: { color: [220, 180, 140], opacity: 1, label: 'ctx-lh-parsopercularis' },
  1019: { color: [20, 100, 50], opacity: 1, label: 'ctx-lh-parsorbitalis' },
  1020: { color: [220, 60, 20], opacity: 1, label: 'ctx-lh-parstriangularis' },
  1021: { color: [120, 100, 60], opacity: 1, label: 'ctx-lh-pericalcarine' },
  1022: { color: [220, 20, 20], opacity: 1, label: 'ctx-lh-postcentral' },
  1023: { color: [220, 180, 220], opacity: 1, label: 'ctx-lh-posteriorcingulate' },
  1024: { color: [60, 20, 220], opacity: 1, label: 'ctx-lh-precentral' },
  1025: { color: [160, 140, 180], opacity: 1, label: 'ctx-lh-precuneus' },
  1026: { color: [80, 20, 140], opacity: 1, label: 'ctx-lh-rostralanteriorcingulate' },
  1027: { color: [75, 50, 125], opacity: 1, label: 'ctx-lh-rostralmiddlefrontal' },
  1028: { color: [20, 220, 160], opacity: 1, label: 'ctx-lh-superiorfrontal' },
  1029: { color: [20, 180, 140], opacity: 1, label: 'ctx-lh-superiorparietal' },
  1030: { color: [140, 220, 220], opacity: 1, label: 'ctx-lh-superiortemporal' },
  1031: { color: [80, 160, 20], opacity: 1, label: 'ctx-lh-supramarginal' },
  1032: { color: [100, 0, 100], opacity: 1, label: 'ctx-lh-frontalpole' },
  1033: { color: [70, 70, 70], opacity: 1, label: 'ctx-lh-temporalpole' },
  1034: { color: [150, 150, 200], opacity: 1, label: 'ctx-lh-transversetemporal' },
  1035: { color: [255, 192, 32], opacity: 1, label: 'ctx-lh-insula' },
  2000: { color: [25, 5, 25], opacity: 1, label: 'ctx-rh-unknown' },
  2001: { color: [25, 100, 40], opacity: 1, label: 'ctx-rh-bankssts' },
  2002: { color: [125, 100, 160], opacity: 1, label: 'ctx-rh-caudalanteriorcingulate' },
  2003: { color: [100, 25, 0], opacity: 1, label: 'ctx-rh-caudalmiddlefrontal' },
  2004: { color: [120, 70, 50], opacity: 1, label: 'ctx-rh-corpuscallosum' },
  2005: { color: [220, 20, 100], opacity: 1, label: 'ctx-rh-cuneus' },
  2006: { color: [220, 20, 10], opacity: 1, label: 'ctx-rh-entorhinal' },
  2007: { color: [180, 220, 140], opacity: 1, label: 'ctx-rh-fusiform' },
  2008: { color: [220, 60, 220], opacity: 1, label: 'ctx-rh-inferiorparietal' },
  2009: { color: [180, 40, 120], opacity: 1, label: 'ctx-rh-inferiortemporal' },
  2010: { color: [140, 20, 140], opacity: 1, label: 'ctx-rh-isthmuscingulate' },
  2011: { color: [20, 30, 140], opacity: 1, label: 'ctx-rh-lateraloccipital' },
  2012: { color: [35, 75, 50], opacity: 1, label: 'ctx-rh-lateralorbitofrontal' },
  2013: { color: [225, 140, 140], opacity: 1, label: 'ctx-rh-lingual' },
  2014: { color: [200, 35, 75], opacity: 1, label: 'ctx-rh-medialorbitofrontal' },
  2015: { color: [160, 100, 50], opacity: 1, label: 'ctx-rh-middletemporal' },
  2016: { color: [20, 220, 60], opacity: 1, label: 'ctx-rh-parahippocampal' },
  2017: { color: [60, 220, 60], opacity: 1, label: 'ctx-rh-paracentral' },
  2018: { color: [220, 180, 140], opacity: 1, label: 'ctx-rh-parsopercularis' },
  2019: { color: [20, 100, 50], opacity: 1, label: 'ctx-rh-parsorbitalis' },
  2020: { color: [220, 60, 20], opacity: 1, label: 'ctx-rh-parstriangularis' },
  2021: { color: [120, 100, 60], opacity: 1, label: 'ctx-rh-pericalcarine' },
  2022: { color: [220, 20, 20], opacity: 1, label: 'ctx-rh-postcentral' },
  2023: { color: [220, 180, 220], opacity: 1, label: 'ctx-rh-posteriorcingulate' },
  2024: { color: [60, 20, 220], opacity: 1, label: 'ctx-rh-precentral' },
  2025: { color: [160, 140, 180], opacity: 1, label: 'ctx-rh-precuneus' },
  2026: { color: [80, 20, 140], opacity: 1, label: 'ctx-rh-rostralanteriorcingulate' },
  2027: { color: [75, 50, 125], opacity: 1, label: 'ctx-rh-rostralmiddlefrontal' },
  2028: { color: [20, 220, 160], opacity: 1, label: 'ctx-rh-superiorfrontal' },
  2029: { color: [20, 180, 140], opacity: 1, label: 'ctx-rh-superiorparietal' },
  2030: { color: [140, 220, 220], opacity: 1, label: 'ctx-rh-superiortemporal' },
  2031: { color: [80, 160, 20], opacity: 1, label: 'ctx-rh-supramarginal' },
  2032: { color: [100, 0, 100], opacity: 1, label: 'ctx-rh-frontalpole' },
  2033: { color: [70, 70, 70], opacity: 1, label: 'ctx-rh-temporalpole' },
  2034: { color: [150, 150, 200], opacity: 1, label: 'ctx-rh-transversetemporal' },
  2035: { color: [255, 192, 32], opacity: 1, label: 'ctx-rh-insula' },
  3000: { color: [230, 250, 230], opacity: 1, label: 'wm-lh-unknown' },
  3001: { color: [230, 155, 215], opacity: 1, label: 'wm-lh-bankssts' },
  3002: { color: [130, 155, 95], opacity: 1, label: 'wm-lh-caudalanteriorcingulate' },
  3003: { color: [155, 230, 255], opacity: 1, label: 'wm-lh-caudalmiddlefrontal' },
  3004: { color: [135, 185, 205], opacity: 1, label: 'wm-lh-corpuscallosum' },
  3005: { color: [35, 235, 155], opacity: 1, label: 'wm-lh-cuneus' },
  3006: { color: [35, 235, 245], opacity: 1, label: 'wm-lh-entorhinal' },
  3007: { color: [75, 35, 115], opacity: 1, label: 'wm-lh-fusiform' },
  3008: { color: [35, 195, 35], opacity: 1, label: 'wm-lh-inferiorparietal' },
  3009: { color: [75, 215, 135], opacity: 1, label: 'wm-lh-inferiortemporal' },
  3010: { color: [115, 235, 115], opacity: 1, label: 'wm-lh-isthmuscingulate' },
  3011: { color: [235, 225, 115], opacity: 1, label: 'wm-lh-lateraloccipital' },
  3012: { color: [220, 180, 205], opacity: 1, label: 'wm-lh-lateralorbitofrontal' },
  3013: { color: [30, 115, 115], opacity: 1, label: 'wm-lh-lingual' },
  3014: { color: [55, 220, 180], opacity: 1, label: 'wm-lh-medialorbitofrontal' },
  3015: { color: [95, 155, 205], opacity: 1, label: 'wm-lh-middletemporal' },
  3016: { color: [235, 35, 195], opacity: 1, label: 'wm-lh-parahippocampal' },
  3017: { color: [195, 35, 195], opacity: 1, label: 'wm-lh-paracentral' },
  3018: { color: [35, 75, 115], opacity: 1, label: 'wm-lh-parsopercularis' },
  3019: { color: [235, 155, 205], opacity: 1, label: 'wm-lh-parsorbitalis' },
  3020: { color: [35, 195, 235], opacity: 1, label: 'wm-lh-parstriangularis' },
  3021: { color: [135, 155, 195], opacity: 1, label: 'wm-lh-pericalcarine' },
  3022: { color: [35, 235, 235], opacity: 1, label: 'wm-lh-postcentral' },
  3023: { color: [35, 75, 35], opacity: 1, label: 'wm-lh-posteriorcingulate' },
  3024: { color: [195, 235, 35], opacity: 1, label: 'wm-lh-precentral' },
  3025: { color: [95, 115, 75], opacity: 1, label: 'wm-lh-precuneus' },
  3026: { color: [175, 235, 115], opacity: 1, label: 'wm-lh-rostralanteriorcingulate' },
  3027: { color: [180, 205, 130], opacity: 1, label: 'wm-lh-rostralmiddlefrontal' },
  3028: { color: [235, 35, 95], opacity: 1, label: 'wm-lh-superiorfrontal' },
  3029: { color: [235, 75, 115], opacity: 1, label: 'wm-lh-superiorparietal' },
  3030: { color: [115, 35, 35], opacity: 1, label: 'wm-lh-superiortemporal' },
  3031: { color: [175, 95, 235], opacity: 1, label: 'wm-lh-supramarginal' },
  3032: { color: [155, 255, 155], opacity: 1, label: 'wm-lh-frontalpole' },
  3033: { color: [185, 185, 185], opacity: 1, label: 'wm-lh-temporalpole' },
  3034: { color: [105, 105, 55], opacity: 1, label: 'wm-lh-transversetemporal' },
  3035: { color: [20, 220, 160], opacity: 1, label: 'wm-lh-insula' },
  4000: { color: [230, 250, 230], opacity: 1, label: 'wm-rh-unknown' },
  4001: { color: [230, 155, 215], opacity: 1, label: 'wm-rh-bankssts' },
  4002: { color: [130, 155, 95], opacity: 1, label: 'wm-rh-caudalanteriorcingulate' },
  4003: { color: [155, 230, 255], opacity: 1, label: 'wm-rh-caudalmiddlefrontal' },
  4004: { color: [135, 185, 205], opacity: 1, label: 'wm-rh-corpuscallosum' },
  4005: { color: [35, 235, 155], opacity: 1, label: 'wm-rh-cuneus' },
  4006: { color: [35, 235, 245], opacity: 1, label: 'wm-rh-entorhinal' },
  4007: { color: [75, 35, 115], opacity: 1, label: 'wm-rh-fusiform' },
  4008: { color: [35, 195, 35], opacity: 1, label: 'wm-rh-inferiorparietal' },
  4009: { color: [75, 215, 135], opacity: 1, label: 'wm-rh-inferiortemporal' },
  4010: { color: [115, 235, 115], opacity: 1, label: 'wm-rh-isthmuscingulate' },
  4011: { color: [235, 225, 115], opacity: 1, label: 'wm-rh-lateraloccipital' },
  4012: { color: [220, 180, 205], opacity: 1, label: 'wm-rh-lateralorbitofrontal' },
  4013: { color: [30, 115, 115], opacity: 1, label: 'wm-rh-lingual' },
  4014: { color: [55, 220, 180], opacity: 1, label: 'wm-rh-medialorbitofrontal' },
  4015: { color: [95, 155, 205], opacity: 1, label: 'wm-rh-middletemporal' },
  4016: { color: [235, 35, 195], opacity: 1, label: 'wm-rh-parahippocampal' },
  4017: { color: [195, 35, 195], opacity: 1, label: 'wm-rh-paracentral' },
  4018: { color: [35, 75, 115], opacity: 1, label: 'wm-rh-parsopercularis' },
  4019: { color: [235, 155, 205], opacity: 1, label: 'wm-rh-parsorbitalis' },
  4020: { color: [35, 195, 235], opacity: 1, label: 'wm-rh-parstriangularis' },
  4021: { color: [135, 155, 195], opacity: 1, label: 'wm-rh-pericalcarine' },
  4022: { color: [35, 235, 235], opacity: 1, label: 'wm-rh-postcentral' },
  4023: { color: [35, 75, 35], opacity: 1, label: 'wm-rh-posteriorcingulate' },
  4024: { color: [195, 235, 35], opacity: 1, label: 'wm-rh-precentral' },
  4025: { color: [95, 115, 75], opacity: 1, label: 'wm-rh-precuneus' },
  4026: { color: [175, 235, 115], opacity: 1, label: 'wm-rh-rostralanteriorcingulate' },
  4027: { color: [180, 205, 130], opacity: 1, label: 'wm-rh-rostralmiddlefrontal' },
  4028: { color: [235, 35, 95], opacity: 1, label: 'wm-rh-superiorfrontal' },
  4029: { color: [235, 75, 115], opacity: 1, label: 'wm-rh-superiorparietal' },
  4030: { color: [115, 35, 35], opacity: 1, label: 'wm-rh-superiortemporal' },
  4031: { color: [175, 95, 235], opacity: 1, label: 'wm-rh-supramarginal' },
  4032: { color: [155, 255, 155], opacity: 1, label: 'wm-rh-frontalpole' },
  4033: { color: [185, 185, 185], opacity: 1, label: 'wm-rh-temporalpole' },
  4034: { color: [105, 105, 55], opacity: 1, label: 'wm-rh-transversetemporal' },
  4035: { color: [20, 220, 160], opacity: 1, label: 'wm-rh-insula' },
  3201: { color: [235, 35, 95], opacity: 1, label: 'wm-lh-frontal-lobe' },
  3203: { color: [35, 75, 35], opacity: 1, label: 'wm-lh-cingulate-lobe' },
  3204: { color: [135, 155, 195], opacity: 1, label: 'wm-lh-occiptal-lobe' },
  3205: { color: [115, 35, 35], opacity: 1, label: 'wm-lh-temporal-lobe' },
  3206: { color: [35, 195, 35], opacity: 1, label: 'wm-lh-parietal-lobe' },
  3207: { color: [20, 220, 160], opacity: 1, label: 'wm-lh-insula-lobe' },
  4201: { color: [235, 35, 95], opacity: 1, label: 'wm-rh-frontal-lobe' },
  4203: { color: [35, 75, 35], opacity: 1, label: 'wm-rh-cingulate-lobe' },
  4204: { color: [135, 155, 195], opacity: 1, label: 'wm-rh-occiptal-lobe' },
  4205: { color: [115, 35, 35], opacity: 1, label: 'wm-rh-temporal-lobe' },
  4206: { color: [35, 195, 35], opacity: 1, label: 'wm-rh-parietal-lobe' },
  4207: { color: [20, 220, 160], opacity: 1, label: 'wm-rh-insula-lobe' },
  1100: { color: [0, 0, 0], opacity: 1, label: 'ctx-lh-Unknown' },
  1101: { color: [50, 50, 50], opacity: 1, label: 'ctx-lh-Corpus_callosum' },
  1102: { color: [180, 20, 30], opacity: 1, label: 'ctx-lh-G_and_S_Insula_ONLY_AVERAGE' },
  1103: { color: [60, 25, 25], opacity: 1, label: 'ctx-lh-G_cingulate-Isthmus' },
  1104: { color: [25, 60, 60], opacity: 1, label: 'ctx-lh-G_cingulate-Main_part' },
  1200: { color: [25, 60, 61], opacity: 1, label: 'ctx-lh-G_cingulate-caudal_ACC' },
  1201: { color: [25, 90, 60], opacity: 1, label: 'ctx-lh-G_cingulate-rostral_ACC' },
  1202: { color: [25, 120, 60], opacity: 1, label: 'ctx-lh-G_cingulate-posterior' },
  1205: { color: [25, 150, 60], opacity: 1, label: 'ctx-lh-S_cingulate-caudal_ACC' },
  1206: { color: [25, 180, 60], opacity: 1, label: 'ctx-lh-S_cingulate-rostral_ACC' },
  1207: { color: [25, 210, 60], opacity: 1, label: 'ctx-lh-S_cingulate-posterior' },
  1210: { color: [25, 150, 90], opacity: 1, label: 'ctx-lh-S_pericallosal-caudal' },
  1211: { color: [25, 180, 90], opacity: 1, label: 'ctx-lh-S_pericallosal-rostral' },
  1212: { color: [25, 210, 90], opacity: 1, label: 'ctx-lh-S_pericallosal-posterior' },
  1105: { color: [180, 20, 20], opacity: 1, label: 'ctx-lh-G_cuneus' },
  1106: { color: [220, 20, 100], opacity: 1, label: 'ctx-lh-G_frontal_inf-Opercular_part' },
  1107: { color: [140, 60, 60], opacity: 1, label: 'ctx-lh-G_frontal_inf-Orbital_part' },
  1108: { color: [180, 220, 140], opacity: 1, label: 'ctx-lh-G_frontal_inf-Triangular_part' },
  1109: { color: [140, 100, 180], opacity: 1, label: 'ctx-lh-G_frontal_middle' },
  1110: { color: [180, 20, 140], opacity: 1, label: 'ctx-lh-G_frontal_superior' },
  1111: { color: [140, 20, 140], opacity: 1, label: 'ctx-lh-G_frontomarginal' },
  1112: { color: [21, 10, 10], opacity: 1, label: 'ctx-lh-G_insular_long' },
  1113: { color: [225, 140, 140], opacity: 1, label: 'ctx-lh-G_insular_short' },
  1114: { color: [23, 60, 180], opacity: 1, label: 'ctx-lh-G_and_S_occipital_inferior' },
  1115: { color: [180, 60, 180], opacity: 1, label: 'ctx-lh-G_occipital_middle' },
  1116: { color: [20, 220, 60], opacity: 1, label: 'ctx-lh-G_occipital_superior' },
  1117: { color: [60, 20, 140], opacity: 1, label: 'ctx-lh-G_occipit-temp_lat-Or_fusiform' },
  1118: { color: [220, 180, 140], opacity: 1, label: 'ctx-lh-G_occipit-temp_med-Lingual_part' },
  1119: {
    color: [65, 100, 20],
    opacity: 1,
    label: 'ctx-lh-G_occipit-temp_med-Parahippocampal_part',
  },
  1120: { color: [220, 60, 20], opacity: 1, label: 'ctx-lh-G_orbital' },
  1121: { color: [60, 100, 60], opacity: 1, label: 'ctx-lh-G_paracentral' },
  1122: { color: [20, 60, 220], opacity: 1, label: 'ctx-lh-G_parietal_inferior-Angular_part' },
  1123: {
    color: [100, 100, 60],
    opacity: 1,
    label: 'ctx-lh-G_parietal_inferior-Supramarginal_part',
  },
  1124: { color: [220, 180, 220], opacity: 1, label: 'ctx-lh-G_parietal_superior' },
  1125: { color: [20, 180, 140], opacity: 1, label: 'ctx-lh-G_postcentral' },
  1126: { color: [60, 140, 180], opacity: 1, label: 'ctx-lh-G_precentral' },
  1127: { color: [25, 20, 140], opacity: 1, label: 'ctx-lh-G_precuneus' },
  1128: { color: [20, 60, 100], opacity: 1, label: 'ctx-lh-G_rectus' },
  1129: { color: [60, 220, 20], opacity: 1, label: 'ctx-lh-G_subcallosal' },
  1130: { color: [60, 20, 220], opacity: 1, label: 'ctx-lh-G_subcentral' },
  1131: { color: [220, 220, 100], opacity: 1, label: 'ctx-lh-G_temporal_inferior' },
  1132: { color: [180, 60, 60], opacity: 1, label: 'ctx-lh-G_temporal_middle' },
  1133: { color: [60, 60, 220], opacity: 1, label: 'ctx-lh-G_temp_sup-G_temp_transv_and_interm_S' },
  1134: { color: [220, 60, 220], opacity: 1, label: 'ctx-lh-G_temp_sup-Lateral_aspect' },
  1135: { color: [65, 220, 60], opacity: 1, label: 'ctx-lh-G_temp_sup-Planum_polare' },
  1136: { color: [25, 140, 20], opacity: 1, label: 'ctx-lh-G_temp_sup-Planum_tempolare' },
  1137: { color: [13, 0, 250], opacity: 1, label: 'ctx-lh-G_and_S_transverse_frontopolar' },
  1138: { color: [61, 20, 220], opacity: 1, label: 'ctx-lh-Lat_Fissure-ant_sgt-ramus_horizontal' },
  1139: { color: [61, 20, 60], opacity: 1, label: 'ctx-lh-Lat_Fissure-ant_sgt-ramus_vertical' },
  1140: { color: [61, 60, 100], opacity: 1, label: 'ctx-lh-Lat_Fissure-post_sgt' },
  1141: { color: [25, 25, 25], opacity: 1, label: 'ctx-lh-Medial_wall' },
  1142: { color: [140, 20, 60], opacity: 1, label: 'ctx-lh-Pole_occipital' },
  1143: { color: [220, 180, 20], opacity: 1, label: 'ctx-lh-Pole_temporal' },
  1144: { color: [63, 180, 180], opacity: 1, label: 'ctx-lh-S_calcarine' },
  1145: { color: [221, 20, 10], opacity: 1, label: 'ctx-lh-S_central' },
  1146: { color: [21, 220, 20], opacity: 1, label: 'ctx-lh-S_central_insula' },
  1147: {
    color: [183, 100, 20],
    opacity: 1,
    label: 'ctx-lh-S_cingulate-Main_part_and_Intracingulate',
  },
  1148: { color: [221, 20, 100], opacity: 1, label: 'ctx-lh-S_cingulate-Marginalis_part' },
  1149: { color: [221, 60, 140], opacity: 1, label: 'ctx-lh-S_circular_insula_anterior' },
  1150: { color: [221, 20, 220], opacity: 1, label: 'ctx-lh-S_circular_insula_inferior' },
  1151: { color: [61, 220, 220], opacity: 1, label: 'ctx-lh-S_circular_insula_superior' },
  1152: { color: [100, 200, 200], opacity: 1, label: 'ctx-lh-S_collateral_transverse_ant' },
  1153: { color: [10, 200, 200], opacity: 1, label: 'ctx-lh-S_collateral_transverse_post' },
  1154: { color: [221, 220, 20], opacity: 1, label: 'ctx-lh-S_frontal_inferior' },
  1155: { color: [141, 20, 100], opacity: 1, label: 'ctx-lh-S_frontal_middle' },
  1156: { color: [61, 220, 100], opacity: 1, label: 'ctx-lh-S_frontal_superior' },
  1157: { color: [21, 220, 60], opacity: 1, label: 'ctx-lh-S_frontomarginal' },
  1158: { color: [141, 60, 20], opacity: 1, label: 'ctx-lh-S_intermedius_primus-Jensen' },
  1159: {
    color: [143, 20, 220],
    opacity: 1,
    label: 'ctx-lh-S_intraparietal-and_Parietal_transverse',
  },
  1160: { color: [61, 20, 180], opacity: 1, label: 'ctx-lh-S_occipital_anterior' },
  1161: { color: [101, 60, 220], opacity: 1, label: 'ctx-lh-S_occipital_middle_and_Lunatus' },
  1162: {
    color: [21, 20, 140],
    opacity: 1,
    label: 'ctx-lh-S_occipital_superior_and_transversalis',
  },
  1163: { color: [221, 140, 20], opacity: 1, label: 'ctx-lh-S_occipito-temporal_lateral' },
  1164: {
    color: [141, 100, 220],
    opacity: 1,
    label: 'ctx-lh-S_occipito-temporal_medial_and_S_Lingual',
  },
  1165: { color: [101, 20, 20], opacity: 1, label: 'ctx-lh-S_orbital-H_shapped' },
  1166: { color: [221, 100, 20], opacity: 1, label: 'ctx-lh-S_orbital_lateral' },
  1167: { color: [181, 200, 20], opacity: 1, label: 'ctx-lh-S_orbital_medial-Or_olfactory' },
  1168: { color: [21, 180, 140], opacity: 1, label: 'ctx-lh-S_paracentral' },
  1169: { color: [101, 100, 180], opacity: 1, label: 'ctx-lh-S_parieto_occipital' },
  1170: { color: [181, 220, 20], opacity: 1, label: 'ctx-lh-S_pericallosal' },
  1171: { color: [21, 140, 200], opacity: 1, label: 'ctx-lh-S_postcentral' },
  1172: { color: [21, 20, 240], opacity: 1, label: 'ctx-lh-S_precentral-Inferior-part' },
  1173: { color: [21, 20, 200], opacity: 1, label: 'ctx-lh-S_precentral-Superior-part' },
  1174: { color: [61, 180, 60], opacity: 1, label: 'ctx-lh-S_subcentral_ant' },
  1175: { color: [61, 180, 250], opacity: 1, label: 'ctx-lh-S_subcentral_post' },
  1176: { color: [21, 20, 60], opacity: 1, label: 'ctx-lh-S_suborbital' },
  1177: { color: [101, 60, 60], opacity: 1, label: 'ctx-lh-S_subparietal' },
  1178: { color: [21, 220, 220], opacity: 1, label: 'ctx-lh-S_supracingulate' },
  1179: { color: [21, 180, 180], opacity: 1, label: 'ctx-lh-S_temporal_inferior' },
  1180: { color: [223, 220, 60], opacity: 1, label: 'ctx-lh-S_temporal_superior' },
  1181: { color: [221, 60, 60], opacity: 1, label: 'ctx-lh-S_temporal_transverse' },
  2100: { color: [0, 0, 0], opacity: 1, label: 'ctx-rh-Unknown' },
  2101: { color: [50, 50, 50], opacity: 1, label: 'ctx-rh-Corpus_callosum' },
  2102: { color: [180, 20, 30], opacity: 1, label: 'ctx-rh-G_and_S_Insula_ONLY_AVERAGE' },
  2103: { color: [60, 25, 25], opacity: 1, label: 'ctx-rh-G_cingulate-Isthmus' },
  2104: { color: [25, 60, 60], opacity: 1, label: 'ctx-rh-G_cingulate-Main_part' },
  2105: { color: [180, 20, 20], opacity: 1, label: 'ctx-rh-G_cuneus' },
  2106: { color: [220, 20, 100], opacity: 1, label: 'ctx-rh-G_frontal_inf-Opercular_part' },
  2107: { color: [140, 60, 60], opacity: 1, label: 'ctx-rh-G_frontal_inf-Orbital_part' },
  2108: { color: [180, 220, 140], opacity: 1, label: 'ctx-rh-G_frontal_inf-Triangular_part' },
  2109: { color: [140, 100, 180], opacity: 1, label: 'ctx-rh-G_frontal_middle' },
  2110: { color: [180, 20, 140], opacity: 1, label: 'ctx-rh-G_frontal_superior' },
  2111: { color: [140, 20, 140], opacity: 1, label: 'ctx-rh-G_frontomarginal' },
  2112: { color: [21, 10, 10], opacity: 1, label: 'ctx-rh-G_insular_long' },
  2113: { color: [225, 140, 140], opacity: 1, label: 'ctx-rh-G_insular_short' },
  2114: { color: [23, 60, 180], opacity: 1, label: 'ctx-rh-G_and_S_occipital_inferior' },
  2115: { color: [180, 60, 180], opacity: 1, label: 'ctx-rh-G_occipital_middle' },
  2116: { color: [20, 220, 60], opacity: 1, label: 'ctx-rh-G_occipital_superior' },
  2117: { color: [60, 20, 140], opacity: 1, label: 'ctx-rh-G_occipit-temp_lat-Or_fusiform' },
  2118: { color: [220, 180, 140], opacity: 1, label: 'ctx-rh-G_occipit-temp_med-Lingual_part' },
  2119: {
    color: [65, 100, 20],
    opacity: 1,
    label: 'ctx-rh-G_occipit-temp_med-Parahippocampal_part',
  },
  2120: { color: [220, 60, 20], opacity: 1, label: 'ctx-rh-G_orbital' },
  2121: { color: [60, 100, 60], opacity: 1, label: 'ctx-rh-G_paracentral' },
  2122: { color: [20, 60, 220], opacity: 1, label: 'ctx-rh-G_parietal_inferior-Angular_part' },
  2123: {
    color: [100, 100, 60],
    opacity: 1,
    label: 'ctx-rh-G_parietal_inferior-Supramarginal_part',
  },
  2124: { color: [220, 180, 220], opacity: 1, label: 'ctx-rh-G_parietal_superior' },
  2125: { color: [20, 180, 140], opacity: 1, label: 'ctx-rh-G_postcentral' },
  2126: { color: [60, 140, 180], opacity: 1, label: 'ctx-rh-G_precentral' },
  2127: { color: [25, 20, 140], opacity: 1, label: 'ctx-rh-G_precuneus' },
  2128: { color: [20, 60, 100], opacity: 1, label: 'ctx-rh-G_rectus' },
  2129: { color: [60, 220, 20], opacity: 1, label: 'ctx-rh-G_subcallosal' },
  2130: { color: [60, 20, 220], opacity: 1, label: 'ctx-rh-G_subcentral' },
  2131: { color: [220, 220, 100], opacity: 1, label: 'ctx-rh-G_temporal_inferior' },
  2132: { color: [180, 60, 60], opacity: 1, label: 'ctx-rh-G_temporal_middle' },
  2133: { color: [60, 60, 220], opacity: 1, label: 'ctx-rh-G_temp_sup-G_temp_transv_and_interm_S' },
  2134: { color: [220, 60, 220], opacity: 1, label: 'ctx-rh-G_temp_sup-Lateral_aspect' },
  2135: { color: [65, 220, 60], opacity: 1, label: 'ctx-rh-G_temp_sup-Planum_polare' },
  2136: { color: [25, 140, 20], opacity: 1, label: 'ctx-rh-G_temp_sup-Planum_tempolare' },
  2137: { color: [13, 0, 250], opacity: 1, label: 'ctx-rh-G_and_S_transverse_frontopolar' },
  2138: { color: [61, 20, 220], opacity: 1, label: 'ctx-rh-Lat_Fissure-ant_sgt-ramus_horizontal' },
  2139: { color: [61, 20, 60], opacity: 1, label: 'ctx-rh-Lat_Fissure-ant_sgt-ramus_vertical' },
  2140: { color: [61, 60, 100], opacity: 1, label: 'ctx-rh-Lat_Fissure-post_sgt' },
  2141: { color: [25, 25, 25], opacity: 1, label: 'ctx-rh-Medial_wall' },
  2142: { color: [140, 20, 60], opacity: 1, label: 'ctx-rh-Pole_occipital' },
  2143: { color: [220, 180, 20], opacity: 1, label: 'ctx-rh-Pole_temporal' },
  2144: { color: [63, 180, 180], opacity: 1, label: 'ctx-rh-S_calcarine' },
  2145: { color: [221, 20, 10], opacity: 1, label: 'ctx-rh-S_central' },
  2146: { color: [21, 220, 20], opacity: 1, label: 'ctx-rh-S_central_insula' },
  2147: {
    color: [183, 100, 20],
    opacity: 1,
    label: 'ctx-rh-S_cingulate-Main_part_and_Intracingulate',
  },
  2148: { color: [221, 20, 100], opacity: 1, label: 'ctx-rh-S_cingulate-Marginalis_part' },
  2149: { color: [221, 60, 140], opacity: 1, label: 'ctx-rh-S_circular_insula_anterior' },
  2150: { color: [221, 20, 220], opacity: 1, label: 'ctx-rh-S_circular_insula_inferior' },
  2151: { color: [61, 220, 220], opacity: 1, label: 'ctx-rh-S_circular_insula_superior' },
  2152: { color: [100, 200, 200], opacity: 1, label: 'ctx-rh-S_collateral_transverse_ant' },
  2153: { color: [10, 200, 200], opacity: 1, label: 'ctx-rh-S_collateral_transverse_post' },
  2154: { color: [221, 220, 20], opacity: 1, label: 'ctx-rh-S_frontal_inferior' },
  2155: { color: [141, 20, 100], opacity: 1, label: 'ctx-rh-S_frontal_middle' },
  2156: { color: [61, 220, 100], opacity: 1, label: 'ctx-rh-S_frontal_superior' },
  2157: { color: [21, 220, 60], opacity: 1, label: 'ctx-rh-S_frontomarginal' },
  2158: { color: [141, 60, 20], opacity: 1, label: 'ctx-rh-S_intermedius_primus-Jensen' },
  2159: {
    color: [143, 20, 220],
    opacity: 1,
    label: 'ctx-rh-S_intraparietal-and_Parietal_transverse',
  },
  2160: { color: [61, 20, 180], opacity: 1, label: 'ctx-rh-S_occipital_anterior' },
  2161: { color: [101, 60, 220], opacity: 1, label: 'ctx-rh-S_occipital_middle_and_Lunatus' },
  2162: {
    color: [21, 20, 140],
    opacity: 1,
    label: 'ctx-rh-S_occipital_superior_and_transversalis',
  },
  2163: { color: [221, 140, 20], opacity: 1, label: 'ctx-rh-S_occipito-temporal_lateral' },
  2164: {
    color: [141, 100, 220],
    opacity: 1,
    label: 'ctx-rh-S_occipito-temporal_medial_and_S_Lingual',
  },
  2165: { color: [101, 20, 20], opacity: 1, label: 'ctx-rh-S_orbital-H_shapped' },
  2166: { color: [221, 100, 20], opacity: 1, label: 'ctx-rh-S_orbital_lateral' },
  2167: { color: [181, 200, 20], opacity: 1, label: 'ctx-rh-S_orbital_medial-Or_olfactory' },
  2168: { color: [21, 180, 140], opacity: 1, label: 'ctx-rh-S_paracentral' },
  2169: { color: [101, 100, 180], opacity: 1, label: 'ctx-rh-S_parieto_occipital' },
  2170: { color: [181, 220, 20], opacity: 1, label: 'ctx-rh-S_pericallosal' },
  2171: { color: [21, 140, 200], opacity: 1, label: 'ctx-rh-S_postcentral' },
  2172: { color: [21, 20, 240], opacity: 1, label: 'ctx-rh-S_precentral-Inferior-part' },
  2173: { color: [21, 20, 200], opacity: 1, label: 'ctx-rh-S_precentral-Superior-part' },
  2174: { color: [61, 180, 60], opacity: 1, label: 'ctx-rh-S_subcentral_ant' },
  2175: { color: [61, 180, 250], opacity: 1, label: 'ctx-rh-S_subcentral_post' },
  2176: { color: [21, 20, 60], opacity: 1, label: 'ctx-rh-S_suborbital' },
  2177: { color: [101, 60, 60], opacity: 1, label: 'ctx-rh-S_subparietal' },
  2178: { color: [21, 220, 220], opacity: 1, label: 'ctx-rh-S_supracingulate' },
  2179: { color: [21, 180, 180], opacity: 1, label: 'ctx-rh-S_temporal_inferior' },
  2180: { color: [223, 220, 60], opacity: 1, label: 'ctx-rh-S_temporal_superior' },
  2181: { color: [221, 60, 60], opacity: 1, label: 'ctx-rh-S_temporal_transverse' },
  2200: { color: [25, 60, 61], opacity: 1, label: 'ctx-rh-G_cingulate-caudal_ACC' },
  2201: { color: [25, 90, 60], opacity: 1, label: 'ctx-rh-G_cingulate-rostral_ACC' },
  2202: { color: [25, 120, 60], opacity: 1, label: 'ctx-rh-G_cingulate-posterior' },
  2205: { color: [25, 150, 60], opacity: 1, label: 'ctx-rh-S_cingulate-caudal_ACC' },
  2206: { color: [25, 180, 60], opacity: 1, label: 'ctx-rh-S_cingulate-rostral_ACC' },
  2207: { color: [25, 210, 60], opacity: 1, label: 'ctx-rh-S_cingulate-posterior' },
  2210: { color: [25, 150, 90], opacity: 1, label: 'ctx-rh-S_pericallosal-caudal' },
  2211: { color: [25, 180, 90], opacity: 1, label: 'ctx-rh-S_pericallosal-rostral' },
  2212: { color: [25, 210, 90], opacity: 1, label: 'ctx-rh-S_pericallosal-posterior' },
  3100: { color: [0, 0, 0], opacity: 1, label: 'wm-lh-Unknown' },
  3101: { color: [50, 50, 50], opacity: 1, label: 'wm-lh-Corpus_callosum' },
  3102: { color: [180, 20, 30], opacity: 1, label: 'wm-lh-G_and_S_Insula_ONLY_AVERAGE' },
  3103: { color: [60, 25, 25], opacity: 1, label: 'wm-lh-G_cingulate-Isthmus' },
  3104: { color: [25, 60, 60], opacity: 1, label: 'wm-lh-G_cingulate-Main_part' },
  3105: { color: [180, 20, 20], opacity: 1, label: 'wm-lh-G_cuneus' },
  3106: { color: [220, 20, 100], opacity: 1, label: 'wm-lh-G_frontal_inf-Opercular_part' },
  3107: { color: [140, 60, 60], opacity: 1, label: 'wm-lh-G_frontal_inf-Orbital_part' },
  3108: { color: [180, 220, 140], opacity: 1, label: 'wm-lh-G_frontal_inf-Triangular_part' },
  3109: { color: [140, 100, 180], opacity: 1, label: 'wm-lh-G_frontal_middle' },
  3110: { color: [180, 20, 140], opacity: 1, label: 'wm-lh-G_frontal_superior' },
  3111: { color: [140, 20, 140], opacity: 1, label: 'wm-lh-G_frontomarginal' },
  3112: { color: [21, 10, 10], opacity: 1, label: 'wm-lh-G_insular_long' },
  3113: { color: [225, 140, 140], opacity: 1, label: 'wm-lh-G_insular_short' },
  3114: { color: [23, 60, 180], opacity: 1, label: 'wm-lh-G_and_S_occipital_inferior' },
  3115: { color: [180, 60, 180], opacity: 1, label: 'wm-lh-G_occipital_middle' },
  3116: { color: [20, 220, 60], opacity: 1, label: 'wm-lh-G_occipital_superior' },
  3117: { color: [60, 20, 140], opacity: 1, label: 'wm-lh-G_occipit-temp_lat-Or_fusiform' },
  3118: { color: [220, 180, 140], opacity: 1, label: 'wm-lh-G_occipit-temp_med-Lingual_part' },
  3119: {
    color: [65, 100, 20],
    opacity: 1,
    label: 'wm-lh-G_occipit-temp_med-Parahippocampal_part',
  },
  3120: { color: [220, 60, 20], opacity: 1, label: 'wm-lh-G_orbital' },
  3121: { color: [60, 100, 60], opacity: 1, label: 'wm-lh-G_paracentral' },
  3122: { color: [20, 60, 220], opacity: 1, label: 'wm-lh-G_parietal_inferior-Angular_part' },
  3123: {
    color: [100, 100, 60],
    opacity: 1,
    label: 'wm-lh-G_parietal_inferior-Supramarginal_part',
  },
  3124: { color: [220, 180, 220], opacity: 1, label: 'wm-lh-G_parietal_superior' },
  3125: { color: [20, 180, 140], opacity: 1, label: 'wm-lh-G_postcentral' },
  3126: { color: [60, 140, 180], opacity: 1, label: 'wm-lh-G_precentral' },
  3127: { color: [25, 20, 140], opacity: 1, label: 'wm-lh-G_precuneus' },
  3128: { color: [20, 60, 100], opacity: 1, label: 'wm-lh-G_rectus' },
  3129: { color: [60, 220, 20], opacity: 1, label: 'wm-lh-G_subcallosal' },
  3130: { color: [60, 20, 220], opacity: 1, label: 'wm-lh-G_subcentral' },
  3131: { color: [220, 220, 100], opacity: 1, label: 'wm-lh-G_temporal_inferior' },
  3132: { color: [180, 60, 60], opacity: 1, label: 'wm-lh-G_temporal_middle' },
  3133: { color: [60, 60, 220], opacity: 1, label: 'wm-lh-G_temp_sup-G_temp_transv_and_interm_S' },
  3134: { color: [220, 60, 220], opacity: 1, label: 'wm-lh-G_temp_sup-Lateral_aspect' },
  3135: { color: [65, 220, 60], opacity: 1, label: 'wm-lh-G_temp_sup-Planum_polare' },
  3136: { color: [25, 140, 20], opacity: 1, label: 'wm-lh-G_temp_sup-Planum_tempolare' },
  3137: { color: [13, 0, 250], opacity: 1, label: 'wm-lh-G_and_S_transverse_frontopolar' },
  3138: { color: [61, 20, 220], opacity: 1, label: 'wm-lh-Lat_Fissure-ant_sgt-ramus_horizontal' },
  3139: { color: [61, 20, 60], opacity: 1, label: 'wm-lh-Lat_Fissure-ant_sgt-ramus_vertical' },
  3140: { color: [61, 60, 100], opacity: 1, label: 'wm-lh-Lat_Fissure-post_sgt' },
  3141: { color: [25, 25, 25], opacity: 1, label: 'wm-lh-Medial_wall' },
  3142: { color: [140, 20, 60], opacity: 1, label: 'wm-lh-Pole_occipital' },
  3143: { color: [220, 180, 20], opacity: 1, label: 'wm-lh-Pole_temporal' },
  3144: { color: [63, 180, 180], opacity: 1, label: 'wm-lh-S_calcarine' },
  3145: { color: [221, 20, 10], opacity: 1, label: 'wm-lh-S_central' },
  3146: { color: [21, 220, 20], opacity: 1, label: 'wm-lh-S_central_insula' },
  3147: {
    color: [183, 100, 20],
    opacity: 1,
    label: 'wm-lh-S_cingulate-Main_part_and_Intracingulate',
  },
  3148: { color: [221, 20, 100], opacity: 1, label: 'wm-lh-S_cingulate-Marginalis_part' },
  3149: { color: [221, 60, 140], opacity: 1, label: 'wm-lh-S_circular_insula_anterior' },
  3150: { color: [221, 20, 220], opacity: 1, label: 'wm-lh-S_circular_insula_inferior' },
  3151: { color: [61, 220, 220], opacity: 1, label: 'wm-lh-S_circular_insula_superior' },
  3152: { color: [100, 200, 200], opacity: 1, label: 'wm-lh-S_collateral_transverse_ant' },
  3153: { color: [10, 200, 200], opacity: 1, label: 'wm-lh-S_collateral_transverse_post' },
  3154: { color: [221, 220, 20], opacity: 1, label: 'wm-lh-S_frontal_inferior' },
  3155: { color: [141, 20, 100], opacity: 1, label: 'wm-lh-S_frontal_middle' },
  3156: { color: [61, 220, 100], opacity: 1, label: 'wm-lh-S_frontal_superior' },
  3157: { color: [21, 220, 60], opacity: 1, label: 'wm-lh-S_frontomarginal' },
  3158: { color: [141, 60, 20], opacity: 1, label: 'wm-lh-S_intermedius_primus-Jensen' },
  3159: {
    color: [143, 20, 220],
    opacity: 1,
    label: 'wm-lh-S_intraparietal-and_Parietal_transverse',
  },
  3160: { color: [61, 20, 180], opacity: 1, label: 'wm-lh-S_occipital_anterior' },
  3161: { color: [101, 60, 220], opacity: 1, label: 'wm-lh-S_occipital_middle_and_Lunatus' },
  3162: { color: [21, 20, 140], opacity: 1, label: 'wm-lh-S_occipital_superior_and_transversalis' },
  3163: { color: [221, 140, 20], opacity: 1, label: 'wm-lh-S_occipito-temporal_lateral' },
  3164: {
    color: [141, 100, 220],
    opacity: 1,
    label: 'wm-lh-S_occipito-temporal_medial_and_S_Lingual',
  },
  3165: { color: [101, 20, 20], opacity: 1, label: 'wm-lh-S_orbital-H_shapped' },
  3166: { color: [221, 100, 20], opacity: 1, label: 'wm-lh-S_orbital_lateral' },
  3167: { color: [181, 200, 20], opacity: 1, label: 'wm-lh-S_orbital_medial-Or_olfactory' },
  3168: { color: [21, 180, 140], opacity: 1, label: 'wm-lh-S_paracentral' },
  3169: { color: [101, 100, 180], opacity: 1, label: 'wm-lh-S_parieto_occipital' },
  3170: { color: [181, 220, 20], opacity: 1, label: 'wm-lh-S_pericallosal' },
  3171: { color: [21, 140, 200], opacity: 1, label: 'wm-lh-S_postcentral' },
  3172: { color: [21, 20, 240], opacity: 1, label: 'wm-lh-S_precentral-Inferior-part' },
  3173: { color: [21, 20, 200], opacity: 1, label: 'wm-lh-S_precentral-Superior-part' },
  3174: { color: [61, 180, 60], opacity: 1, label: 'wm-lh-S_subcentral_ant' },
  3175: { color: [61, 180, 250], opacity: 1, label: 'wm-lh-S_subcentral_post' },
  3176: { color: [21, 20, 60], opacity: 1, label: 'wm-lh-S_suborbital' },
  3177: { color: [101, 60, 60], opacity: 1, label: 'wm-lh-S_subparietal' },
  3178: { color: [21, 220, 220], opacity: 1, label: 'wm-lh-S_supracingulate' },
  3179: { color: [21, 180, 180], opacity: 1, label: 'wm-lh-S_temporal_inferior' },
  3180: { color: [223, 220, 60], opacity: 1, label: 'wm-lh-S_temporal_superior' },
  3181: { color: [221, 60, 60], opacity: 1, label: 'wm-lh-S_temporal_transverse' },
  4100: { color: [0, 0, 0], opacity: 1, label: 'wm-rh-Unknown' },
  4101: { color: [50, 50, 50], opacity: 1, label: 'wm-rh-Corpus_callosum' },
  4102: { color: [180, 20, 30], opacity: 1, label: 'wm-rh-G_and_S_Insula_ONLY_AVERAGE' },
  4103: { color: [60, 25, 25], opacity: 1, label: 'wm-rh-G_cingulate-Isthmus' },
  4104: { color: [25, 60, 60], opacity: 1, label: 'wm-rh-G_cingulate-Main_part' },
  4105: { color: [180, 20, 20], opacity: 1, label: 'wm-rh-G_cuneus' },
  4106: { color: [220, 20, 100], opacity: 1, label: 'wm-rh-G_frontal_inf-Opercular_part' },
  4107: { color: [140, 60, 60], opacity: 1, label: 'wm-rh-G_frontal_inf-Orbital_part' },
  4108: { color: [180, 220, 140], opacity: 1, label: 'wm-rh-G_frontal_inf-Triangular_part' },
  4109: { color: [140, 100, 180], opacity: 1, label: 'wm-rh-G_frontal_middle' },
  4110: { color: [180, 20, 140], opacity: 1, label: 'wm-rh-G_frontal_superior' },
  4111: { color: [140, 20, 140], opacity: 1, label: 'wm-rh-G_frontomarginal' },
  4112: { color: [21, 10, 10], opacity: 1, label: 'wm-rh-G_insular_long' },
  4113: { color: [225, 140, 140], opacity: 1, label: 'wm-rh-G_insular_short' },
  4114: { color: [23, 60, 180], opacity: 1, label: 'wm-rh-G_and_S_occipital_inferior' },
  4115: { color: [180, 60, 180], opacity: 1, label: 'wm-rh-G_occipital_middle' },
  4116: { color: [20, 220, 60], opacity: 1, label: 'wm-rh-G_occipital_superior' },
  4117: { color: [60, 20, 140], opacity: 1, label: 'wm-rh-G_occipit-temp_lat-Or_fusiform' },
  4118: { color: [220, 180, 140], opacity: 1, label: 'wm-rh-G_occipit-temp_med-Lingual_part' },
  4119: {
    color: [65, 100, 20],
    opacity: 1,
    label: 'wm-rh-G_occipit-temp_med-Parahippocampal_part',
  },
  4120: { color: [220, 60, 20], opacity: 1, label: 'wm-rh-G_orbital' },
  4121: { color: [60, 100, 60], opacity: 1, label: 'wm-rh-G_paracentral' },
  4122: { color: [20, 60, 220], opacity: 1, label: 'wm-rh-G_parietal_inferior-Angular_part' },
  4123: {
    color: [100, 100, 60],
    opacity: 1,
    label: 'wm-rh-G_parietal_inferior-Supramarginal_part',
  },
  4124: { color: [220, 180, 220], opacity: 1, label: 'wm-rh-G_parietal_superior' },
  4125: { color: [20, 180, 140], opacity: 1, label: 'wm-rh-G_postcentral' },
  4126: { color: [60, 140, 180], opacity: 1, label: 'wm-rh-G_precentral' },
  4127: { color: [25, 20, 140], opacity: 1, label: 'wm-rh-G_precuneus' },
  4128: { color: [20, 60, 100], opacity: 1, label: 'wm-rh-G_rectus' },
  4129: { color: [60, 220, 20], opacity: 1, label: 'wm-rh-G_subcallosal' },
  4130: { color: [60, 20, 220], opacity: 1, label: 'wm-rh-G_subcentral' },
  4131: { color: [220, 220, 100], opacity: 1, label: 'wm-rh-G_temporal_inferior' },
  4132: { color: [180, 60, 60], opacity: 1, label: 'wm-rh-G_temporal_middle' },
  4133: { color: [60, 60, 220], opacity: 1, label: 'wm-rh-G_temp_sup-G_temp_transv_and_interm_S' },
  4134: { color: [220, 60, 220], opacity: 1, label: 'wm-rh-G_temp_sup-Lateral_aspect' },
  4135: { color: [65, 220, 60], opacity: 1, label: 'wm-rh-G_temp_sup-Planum_polare' },
  4136: { color: [25, 140, 20], opacity: 1, label: 'wm-rh-G_temp_sup-Planum_tempolare' },
  4137: { color: [13, 0, 250], opacity: 1, label: 'wm-rh-G_and_S_transverse_frontopolar' },
  4138: { color: [61, 20, 220], opacity: 1, label: 'wm-rh-Lat_Fissure-ant_sgt-ramus_horizontal' },
  4139: { color: [61, 20, 60], opacity: 1, label: 'wm-rh-Lat_Fissure-ant_sgt-ramus_vertical' },
  4140: { color: [61, 60, 100], opacity: 1, label: 'wm-rh-Lat_Fissure-post_sgt' },
  4141: { color: [25, 25, 25], opacity: 1, label: 'wm-rh-Medial_wall' },
  4142: { color: [140, 20, 60], opacity: 1, label: 'wm-rh-Pole_occipital' },
  4143: { color: [220, 180, 20], opacity: 1, label: 'wm-rh-Pole_temporal' },
  4144: { color: [63, 180, 180], opacity: 1, label: 'wm-rh-S_calcarine' },
  4145: { color: [221, 20, 10], opacity: 1, label: 'wm-rh-S_central' },
  4146: { color: [21, 220, 20], opacity: 1, label: 'wm-rh-S_central_insula' },
  4147: {
    color: [183, 100, 20],
    opacity: 1,
    label: 'wm-rh-S_cingulate-Main_part_and_Intracingulate',
  },
  4148: { color: [221, 20, 100], opacity: 1, label: 'wm-rh-S_cingulate-Marginalis_part' },
  4149: { color: [221, 60, 140], opacity: 1, label: 'wm-rh-S_circular_insula_anterior' },
  4150: { color: [221, 20, 220], opacity: 1, label: 'wm-rh-S_circular_insula_inferior' },
  4151: { color: [61, 220, 220], opacity: 1, label: 'wm-rh-S_circular_insula_superior' },
  4152: { color: [100, 200, 200], opacity: 1, label: 'wm-rh-S_collateral_transverse_ant' },
  4153: { color: [10, 200, 200], opacity: 1, label: 'wm-rh-S_collateral_transverse_post' },
  4154: { color: [221, 220, 20], opacity: 1, label: 'wm-rh-S_frontal_inferior' },
  4155: { color: [141, 20, 100], opacity: 1, label: 'wm-rh-S_frontal_middle' },
  4156: { color: [61, 220, 100], opacity: 1, label: 'wm-rh-S_frontal_superior' },
  4157: { color: [21, 220, 60], opacity: 1, label: 'wm-rh-S_frontomarginal' },
  4158: { color: [141, 60, 20], opacity: 1, label: 'wm-rh-S_intermedius_primus-Jensen' },
  4159: {
    color: [143, 20, 220],
    opacity: 1,
    label: 'wm-rh-S_intraparietal-and_Parietal_transverse',
  },
  4160: { color: [61, 20, 180], opacity: 1, label: 'wm-rh-S_occipital_anterior' },
  4161: { color: [101, 60, 220], opacity: 1, label: 'wm-rh-S_occipital_middle_and_Lunatus' },
  4162: { color: [21, 20, 140], opacity: 1, label: 'wm-rh-S_occipital_superior_and_transversalis' },
  4163: { color: [221, 140, 20], opacity: 1, label: 'wm-rh-S_occipito-temporal_lateral' },
  4164: {
    color: [141, 100, 220],
    opacity: 1,
    label: 'wm-rh-S_occipito-temporal_medial_and_S_Lingual',
  },
  4165: { color: [101, 20, 20], opacity: 1, label: 'wm-rh-S_orbital-H_shapped' },
  4166: { color: [221, 100, 20], opacity: 1, label: 'wm-rh-S_orbital_lateral' },
  4167: { color: [181, 200, 20], opacity: 1, label: 'wm-rh-S_orbital_medial-Or_olfactory' },
  4168: { color: [21, 180, 140], opacity: 1, label: 'wm-rh-S_paracentral' },
  4169: { color: [101, 100, 180], opacity: 1, label: 'wm-rh-S_parieto_occipital' },
  4170: { color: [181, 220, 20], opacity: 1, label: 'wm-rh-S_pericallosal' },
  4171: { color: [21, 140, 200], opacity: 1, label: 'wm-rh-S_postcentral' },
  4172: { color: [21, 20, 240], opacity: 1, label: 'wm-rh-S_precentral-Inferior-part' },
  4173: { color: [21, 20, 200], opacity: 1, label: 'wm-rh-S_precentral-Superior-part' },
  4174: { color: [61, 180, 60], opacity: 1, label: 'wm-rh-S_subcentral_ant' },
  4175: { color: [61, 180, 250], opacity: 1, label: 'wm-rh-S_subcentral_post' },
  4176: { color: [21, 20, 60], opacity: 1, label: 'wm-rh-S_suborbital' },
  4177: { color: [101, 60, 60], opacity: 1, label: 'wm-rh-S_subparietal' },
  4178: { color: [21, 220, 220], opacity: 1, label: 'wm-rh-S_supracingulate' },
  4179: { color: [21, 180, 180], opacity: 1, label: 'wm-rh-S_temporal_inferior' },
  4180: { color: [223, 220, 60], opacity: 1, label: 'wm-rh-S_temporal_superior' },
  4181: { color: [221, 60, 60], opacity: 1, label: 'wm-rh-S_temporal_transverse' },
  5001: { color: [20, 30, 40], opacity: 1, label: 'Left-UnsegmentedWhiteMatter' },
  5002: { color: [20, 30, 40], opacity: 1, label: 'Right-UnsegmentedWhiteMatter' },
  5100: { color: [204, 102, 102], opacity: 1, label: 'fmajor' },
  5101: { color: [204, 102, 102], opacity: 1, label: 'fminor' },
  5102: { color: [255, 255, 102], opacity: 1, label: 'lh.atr' },
  5103: { color: [153, 204, 0], opacity: 1, label: 'lh.cab' },
  5104: { color: [0, 153, 153], opacity: 1, label: 'lh.ccg' },
  5105: { color: [204, 153, 255], opacity: 1, label: 'lh.cst' },
  5106: { color: [255, 153, 51], opacity: 1, label: 'lh.ilf' },
  5107: { color: [204, 204, 204], opacity: 1, label: 'lh.slfp' },
  5108: { color: [153, 255, 255], opacity: 1, label: 'lh.slft' },
  5109: { color: [102, 153, 255], opacity: 1, label: 'lh.unc' },
  5110: { color: [255, 255, 102], opacity: 1, label: 'rh.atr' },
  5111: { color: [153, 204, 0], opacity: 1, label: 'rh.cab' },
  5112: { color: [0, 153, 153], opacity: 1, label: 'rh.ccg' },
  5113: { color: [204, 153, 255], opacity: 1, label: 'rh.cst' },
  5114: { color: [255, 153, 51], opacity: 1, label: 'rh.ilf' },
  5115: { color: [204, 204, 204], opacity: 1, label: 'rh.slfp' },
  5116: { color: [153, 255, 255], opacity: 1, label: 'rh.slft' },
  5117: { color: [102, 153, 255], opacity: 1, label: 'rh.unc' },
  5200: { color: [204, 102, 102], opacity: 1, label: 'CC-ForcepsMajor' },
  5201: { color: [204, 102, 102], opacity: 1, label: 'CC-ForcepsMinor' },
  5202: { color: [255, 255, 102], opacity: 1, label: 'LAntThalRadiation' },
  5203: { color: [153, 204, 0], opacity: 1, label: 'LCingulumAngBundle' },
  5204: { color: [0, 153, 153], opacity: 1, label: 'LCingulumCingGyrus' },
  5205: { color: [204, 153, 255], opacity: 1, label: 'LCorticospinalTract' },
  5206: { color: [255, 153, 51], opacity: 1, label: 'LInfLongFas' },
  5207: { color: [204, 204, 204], opacity: 1, label: 'LSupLongFasParietal' },
  5208: { color: [153, 255, 255], opacity: 1, label: 'LSupLongFasTemporal' },
  5209: { color: [102, 153, 255], opacity: 1, label: 'LUncinateFas' },
  5210: { color: [255, 255, 102], opacity: 1, label: 'RAntThalRadiation' },
  5211: { color: [153, 204, 0], opacity: 1, label: 'RCingulumAngBundle' },
  5212: { color: [0, 153, 153], opacity: 1, label: 'RCingulumCingGyrus' },
  5213: { color: [204, 153, 255], opacity: 1, label: 'RCorticospinalTract' },
  5214: { color: [255, 153, 51], opacity: 1, label: 'RInfLongFas' },
  5215: { color: [204, 204, 204], opacity: 1, label: 'RSupLongFasParietal' },
  5216: { color: [153, 255, 255], opacity: 1, label: 'RSupLongFasTemporal' },
  5217: { color: [102, 153, 255], opacity: 1, label: 'RUncinateFas' },
  6000: { color: [0, 255, 0], opacity: 1, label: 'CST-orig' },
  6001: { color: [255, 255, 0], opacity: 1, label: 'CST-hammer' },
  6002: { color: [0, 255, 255], opacity: 1, label: 'CST-CVS' },
  6003: { color: [0, 0, 255], opacity: 1, label: 'CST-flirt' },
  6010: { color: [236, 16, 231], opacity: 1, label: 'Left-SLF1' },
  6020: { color: [237, 18, 232], opacity: 1, label: 'Right-SLF1' },
  6030: { color: [236, 13, 227], opacity: 1, label: 'Left-SLF3' },
  6040: { color: [236, 17, 228], opacity: 1, label: 'Right-SLF3' },
  6050: { color: [1, 255, 1], opacity: 1, label: 'Left-CST' },
  6060: { color: [2, 255, 1], opacity: 1, label: 'Right-CST' },
  6070: { color: [236, 14, 230], opacity: 1, label: 'Left-SLF2' },
  6080: { color: [237, 14, 230], opacity: 1, label: 'Right-SLF2' },
  7001: { color: [72, 132, 181], opacity: 1, label: 'Lateral-nucleus' },
  7002: { color: [243, 243, 243], opacity: 1, label: 'Basolateral-nucleus' },
  7003: { color: [207, 63, 79], opacity: 1, label: 'Basal-nucleus' },
  7004: { color: [121, 20, 135], opacity: 1, label: 'Centromedial-nucleus' },
  7005: { color: [197, 60, 248], opacity: 1, label: 'Central-nucleus' },
  7006: { color: [2, 149, 2], opacity: 1, label: 'Medial-nucleus' },
  7007: { color: [221, 249, 166], opacity: 1, label: 'Cortical-nucleus' },
  7008: { color: [232, 146, 35], opacity: 1, label: 'Accessory-Basal-nucleus' },
  7009: { color: [20, 60, 120], opacity: 1, label: 'Corticoamygdaloid-transitio' },
  7010: { color: [250, 250, 0], opacity: 1, label: 'Anterior-amygdaloid-area-AAA' },
  7011: { color: [122, 187, 222], opacity: 1, label: 'Fusion-amygdala-HP-FAH' },
  7012: { color: [237, 12, 177], opacity: 1, label: 'Hippocampal-amygdala-transition-HATA' },
  7013: { color: [10, 49, 255], opacity: 1, label: 'Endopiriform-nucleus' },
  7014: { color: [205, 184, 144], opacity: 1, label: 'Lateral-nucleus-olfactory-tract' },
  7015: { color: [45, 205, 165], opacity: 1, label: 'Paralaminar-nucleus' },
  7016: { color: [117, 160, 175], opacity: 1, label: 'Intercalated-nucleus' },
  7017: { color: [221, 217, 21], opacity: 1, label: 'Prepiriform-cortex' },
  7018: { color: [20, 60, 120], opacity: 1, label: 'Periamygdaloid-cortex' },
  7019: { color: [141, 21, 100], opacity: 1, label: 'Envelope-Amygdala' },
  7020: { color: [225, 140, 141], opacity: 1, label: 'Extranuclear-Amydala' },
  7100: { color: [42, 201, 168], opacity: 1, label: 'Brainstem-inferior-colliculus' },
  7101: { color: [168, 104, 162], opacity: 1, label: 'Brainstem-cochlear-nucleus' },
  8001: { color: [74, 130, 181], opacity: 1, label: 'Thalamus-Anterior' },
  8002: { color: [242, 241, 240], opacity: 1, label: 'Thalamus-Ventral-anterior' },
  8003: { color: [206, 65, 78], opacity: 1, label: 'Thalamus-Lateral-dorsal' },
  8004: { color: [120, 21, 133], opacity: 1, label: 'Thalamus-Lateral-posterior' },
  8005: { color: [195, 61, 246], opacity: 1, label: 'Thalamus-Ventral-lateral' },
  8006: { color: [3, 147, 6], opacity: 1, label: 'Thalamus-Ventral-posterior-medial' },
  8007: { color: [220, 251, 163], opacity: 1, label: 'Thalamus-Ventral-posterior-lateral' },
  8008: { color: [232, 146, 33], opacity: 1, label: 'Thalamus-intralaminar' },
  8009: { color: [4, 114, 14], opacity: 1, label: 'Thalamus-centromedian' },
  8010: { color: [121, 184, 220], opacity: 1, label: 'Thalamus-mediodorsal' },
  8011: { color: [235, 11, 175], opacity: 1, label: 'Thalamus-medial' },
  8012: { color: [12, 46, 250], opacity: 1, label: 'Thalamus-pulvinar' },
  8013: { color: [203, 182, 143], opacity: 1, label: 'Thalamus-lateral-geniculate' },
  8014: { color: [42, 204, 167], opacity: 1, label: 'Thalamus-medial-geniculate' },
  9000: { color: [50, 100, 30], opacity: 1, label: 'ctx-lh-prefrontal' },
  9001: { color: [30, 100, 45], opacity: 1, label: 'ctx-lh-primary-motor' },
  9002: { color: [130, 100, 165], opacity: 1, label: 'ctx-lh-premotor' },
  9003: { color: [105, 25, 5], opacity: 1, label: 'ctx-lh-temporal' },
  9004: { color: [125, 70, 55], opacity: 1, label: 'ctx-lh-posterior-parietal' },
  9005: { color: [225, 20, 105], opacity: 1, label: 'ctx-lh-prim-sec-somatosensory' },
  9006: { color: [225, 20, 15], opacity: 1, label: 'ctx-lh-occipital' },
  9500: { color: [50, 200, 30], opacity: 1, label: 'ctx-rh-prefrontal' },
  9501: { color: [30, 150, 45], opacity: 1, label: 'ctx-rh-primary-motor' },
  9502: { color: [130, 150, 165], opacity: 1, label: 'ctx-rh-premotor' },
  9503: { color: [105, 75, 5], opacity: 1, label: 'ctx-rh-temporal' },
  9504: { color: [125, 120, 55], opacity: 1, label: 'ctx-rh-posterior-parietal' },
  9505: { color: [225, 70, 105], opacity: 1, label: 'ctx-rh-prim-sec-somatosensory' },
  9506: { color: [225, 70, 15], opacity: 1, label: 'ctx-rh-occipital' },
  11100: { color: [0, 0, 0], opacity: 1, label: 'ctx_lh_Unknown' },
  11101: { color: [23, 220, 60], opacity: 1, label: 'ctx_lh_G_and_S_frontomargin' },
  11102: { color: [23, 60, 180], opacity: 1, label: 'ctx_lh_G_and_S_occipital_inf' },
  11103: { color: [63, 100, 60], opacity: 1, label: 'ctx_lh_G_and_S_paracentral' },
  11104: { color: [63, 20, 220], opacity: 1, label: 'ctx_lh_G_and_S_subcentral' },
  11105: { color: [13, 0, 250], opacity: 1, label: 'ctx_lh_G_and_S_transv_frontopol' },
  11106: { color: [26, 60, 0], opacity: 1, label: 'ctx_lh_G_and_S_cingul-Ant' },
  11107: { color: [26, 60, 75], opacity: 1, label: 'ctx_lh_G_and_S_cingul-Mid-Ant' },
  11108: { color: [26, 60, 150], opacity: 1, label: 'ctx_lh_G_and_S_cingul-Mid-Post' },
  11109: { color: [25, 60, 250], opacity: 1, label: 'ctx_lh_G_cingul-Post-dorsal' },
  11110: { color: [60, 25, 25], opacity: 1, label: 'ctx_lh_G_cingul-Post-ventral' },
  11111: { color: [180, 20, 20], opacity: 1, label: 'ctx_lh_G_cuneus' },
  11112: { color: [220, 20, 100], opacity: 1, label: 'ctx_lh_G_front_inf-Opercular' },
  11113: { color: [140, 60, 60], opacity: 1, label: 'ctx_lh_G_front_inf-Orbital' },
  11114: { color: [180, 220, 140], opacity: 1, label: 'ctx_lh_G_front_inf-Triangul' },
  11115: { color: [140, 100, 180], opacity: 1, label: 'ctx_lh_G_front_middle' },
  11116: { color: [180, 20, 140], opacity: 1, label: 'ctx_lh_G_front_sup' },
  11117: { color: [23, 10, 10], opacity: 1, label: 'ctx_lh_G_Ins_lg_and_S_cent_ins' },
  11118: { color: [225, 140, 140], opacity: 1, label: 'ctx_lh_G_insular_short' },
  11119: { color: [180, 60, 180], opacity: 1, label: 'ctx_lh_G_occipital_middle' },
  11120: { color: [20, 220, 60], opacity: 1, label: 'ctx_lh_G_occipital_sup' },
  11121: { color: [60, 20, 140], opacity: 1, label: 'ctx_lh_G_oc-temp_lat-fusifor' },
  11122: { color: [220, 180, 140], opacity: 1, label: 'ctx_lh_G_oc-temp_med-Lingual' },
  11123: { color: [65, 100, 20], opacity: 1, label: 'ctx_lh_G_oc-temp_med-Parahip' },
  11124: { color: [220, 60, 20], opacity: 1, label: 'ctx_lh_G_orbital' },
  11125: { color: [20, 60, 220], opacity: 1, label: 'ctx_lh_G_pariet_inf-Angular' },
  11126: { color: [100, 100, 60], opacity: 1, label: 'ctx_lh_G_pariet_inf-Supramar' },
  11127: { color: [220, 180, 220], opacity: 1, label: 'ctx_lh_G_parietal_sup' },
  11128: { color: [20, 180, 140], opacity: 1, label: 'ctx_lh_G_postcentral' },
  11129: { color: [60, 140, 180], opacity: 1, label: 'ctx_lh_G_precentral' },
  11130: { color: [25, 20, 140], opacity: 1, label: 'ctx_lh_G_precuneus' },
  11131: { color: [20, 60, 100], opacity: 1, label: 'ctx_lh_G_rectus' },
  11132: { color: [60, 220, 20], opacity: 1, label: 'ctx_lh_G_subcallosal' },
  11133: { color: [60, 60, 220], opacity: 1, label: 'ctx_lh_G_temp_sup-G_T_transv' },
  11134: { color: [220, 60, 220], opacity: 1, label: 'ctx_lh_G_temp_sup-Lateral' },
  11135: { color: [65, 220, 60], opacity: 1, label: 'ctx_lh_G_temp_sup-Plan_polar' },
  11136: { color: [25, 140, 20], opacity: 1, label: 'ctx_lh_G_temp_sup-Plan_tempo' },
  11137: { color: [220, 220, 100], opacity: 1, label: 'ctx_lh_G_temporal_inf' },
  11138: { color: [180, 60, 60], opacity: 1, label: 'ctx_lh_G_temporal_middle' },
  11139: { color: [61, 20, 220], opacity: 1, label: 'ctx_lh_Lat_Fis-ant-Horizont' },
  11140: { color: [61, 20, 60], opacity: 1, label: 'ctx_lh_Lat_Fis-ant-Vertical' },
  11141: { color: [61, 60, 100], opacity: 1, label: 'ctx_lh_Lat_Fis-post' },
  11142: { color: [25, 25, 25], opacity: 1, label: 'ctx_lh_Medial_wall' },
  11143: { color: [140, 20, 60], opacity: 1, label: 'ctx_lh_Pole_occipital' },
  11144: { color: [220, 180, 20], opacity: 1, label: 'ctx_lh_Pole_temporal' },
  11145: { color: [63, 180, 180], opacity: 1, label: 'ctx_lh_S_calcarine' },
  11146: { color: [221, 20, 10], opacity: 1, label: 'ctx_lh_S_central' },
  11147: { color: [221, 20, 100], opacity: 1, label: 'ctx_lh_S_cingul-Marginalis' },
  11148: { color: [221, 60, 140], opacity: 1, label: 'ctx_lh_S_circular_insula_ant' },
  11149: { color: [221, 20, 220], opacity: 1, label: 'ctx_lh_S_circular_insula_inf' },
  11150: { color: [61, 220, 220], opacity: 1, label: 'ctx_lh_S_circular_insula_sup' },
  11151: { color: [100, 200, 200], opacity: 1, label: 'ctx_lh_S_collat_transv_ant' },
  11152: { color: [10, 200, 200], opacity: 1, label: 'ctx_lh_S_collat_transv_post' },
  11153: { color: [221, 220, 20], opacity: 1, label: 'ctx_lh_S_front_inf' },
  11154: { color: [141, 20, 100], opacity: 1, label: 'ctx_lh_S_front_middle' },
  11155: { color: [61, 220, 100], opacity: 1, label: 'ctx_lh_S_front_sup' },
  11156: { color: [141, 60, 20], opacity: 1, label: 'ctx_lh_S_interm_prim-Jensen' },
  11157: { color: [143, 20, 220], opacity: 1, label: 'ctx_lh_S_intrapariet_and_P_trans' },
  11158: { color: [101, 60, 220], opacity: 1, label: 'ctx_lh_S_oc_middle_and_Lunatus' },
  11159: { color: [21, 20, 140], opacity: 1, label: 'ctx_lh_S_oc_sup_and_transversal' },
  11160: { color: [61, 20, 180], opacity: 1, label: 'ctx_lh_S_occipital_ant' },
  11161: { color: [221, 140, 20], opacity: 1, label: 'ctx_lh_S_oc-temp_lat' },
  11162: { color: [141, 100, 220], opacity: 1, label: 'ctx_lh_S_oc-temp_med_and_Lingual' },
  11163: { color: [221, 100, 20], opacity: 1, label: 'ctx_lh_S_orbital_lateral' },
  11164: { color: [181, 200, 20], opacity: 1, label: 'ctx_lh_S_orbital_med-olfact' },
  11165: { color: [101, 20, 20], opacity: 1, label: 'ctx_lh_S_orbital-H_Shaped' },
  11166: { color: [101, 100, 180], opacity: 1, label: 'ctx_lh_S_parieto_occipital' },
  11167: { color: [181, 220, 20], opacity: 1, label: 'ctx_lh_S_pericallosal' },
  11168: { color: [21, 140, 200], opacity: 1, label: 'ctx_lh_S_postcentral' },
  11169: { color: [21, 20, 240], opacity: 1, label: 'ctx_lh_S_precentral-inf-part' },
  11170: { color: [21, 20, 200], opacity: 1, label: 'ctx_lh_S_precentral-sup-part' },
  11171: { color: [21, 20, 60], opacity: 1, label: 'ctx_lh_S_suborbital' },
  11172: { color: [101, 60, 60], opacity: 1, label: 'ctx_lh_S_subparietal' },
  11173: { color: [21, 180, 180], opacity: 1, label: 'ctx_lh_S_temporal_inf' },
  11174: { color: [223, 220, 60], opacity: 1, label: 'ctx_lh_S_temporal_sup' },
  11175: { color: [221, 60, 60], opacity: 1, label: 'ctx_lh_S_temporal_transverse' },
  12100: { color: [0, 0, 0], opacity: 1, label: 'ctx_rh_Unknown' },
  12101: { color: [23, 220, 60], opacity: 1, label: 'ctx_rh_G_and_S_frontomargin' },
  12102: { color: [23, 60, 180], opacity: 1, label: 'ctx_rh_G_and_S_occipital_inf' },
  12103: { color: [63, 100, 60], opacity: 1, label: 'ctx_rh_G_and_S_paracentral' },
  12104: { color: [63, 20, 220], opacity: 1, label: 'ctx_rh_G_and_S_subcentral' },
  12105: { color: [13, 0, 250], opacity: 1, label: 'ctx_rh_G_and_S_transv_frontopol' },
  12106: { color: [26, 60, 0], opacity: 1, label: 'ctx_rh_G_and_S_cingul-Ant' },
  12107: { color: [26, 60, 75], opacity: 1, label: 'ctx_rh_G_and_S_cingul-Mid-Ant' },
  12108: { color: [26, 60, 150], opacity: 1, label: 'ctx_rh_G_and_S_cingul-Mid-Post' },
  12109: { color: [25, 60, 250], opacity: 1, label: 'ctx_rh_G_cingul-Post-dorsal' },
  12110: { color: [60, 25, 25], opacity: 1, label: 'ctx_rh_G_cingul-Post-ventral' },
  12111: { color: [180, 20, 20], opacity: 1, label: 'ctx_rh_G_cuneus' },
  12112: { color: [220, 20, 100], opacity: 1, label: 'ctx_rh_G_front_inf-Opercular' },
  12113: { color: [140, 60, 60], opacity: 1, label: 'ctx_rh_G_front_inf-Orbital' },
  12114: { color: [180, 220, 140], opacity: 1, label: 'ctx_rh_G_front_inf-Triangul' },
  12115: { color: [140, 100, 180], opacity: 1, label: 'ctx_rh_G_front_middle' },
  12116: { color: [180, 20, 140], opacity: 1, label: 'ctx_rh_G_front_sup' },
  12117: { color: [23, 10, 10], opacity: 1, label: 'ctx_rh_G_Ins_lg_and_S_cent_ins' },
  12118: { color: [225, 140, 140], opacity: 1, label: 'ctx_rh_G_insular_short' },
  12119: { color: [180, 60, 180], opacity: 1, label: 'ctx_rh_G_occipital_middle' },
  12120: { color: [20, 220, 60], opacity: 1, label: 'ctx_rh_G_occipital_sup' },
  12121: { color: [60, 20, 140], opacity: 1, label: 'ctx_rh_G_oc-temp_lat-fusifor' },
  12122: { color: [220, 180, 140], opacity: 1, label: 'ctx_rh_G_oc-temp_med-Lingual' },
  12123: { color: [65, 100, 20], opacity: 1, label: 'ctx_rh_G_oc-temp_med-Parahip' },
  12124: { color: [220, 60, 20], opacity: 1, label: 'ctx_rh_G_orbital' },
  12125: { color: [20, 60, 220], opacity: 1, label: 'ctx_rh_G_pariet_inf-Angular' },
  12126: { color: [100, 100, 60], opacity: 1, label: 'ctx_rh_G_pariet_inf-Supramar' },
  12127: { color: [220, 180, 220], opacity: 1, label: 'ctx_rh_G_parietal_sup' },
  12128: { color: [20, 180, 140], opacity: 1, label: 'ctx_rh_G_postcentral' },
  12129: { color: [60, 140, 180], opacity: 1, label: 'ctx_rh_G_precentral' },
  12130: { color: [25, 20, 140], opacity: 1, label: 'ctx_rh_G_precuneus' },
  12131: { color: [20, 60, 100], opacity: 1, label: 'ctx_rh_G_rectus' },
  12132: { color: [60, 220, 20], opacity: 1, label: 'ctx_rh_G_subcallosal' },
  12133: { color: [60, 60, 220], opacity: 1, label: 'ctx_rh_G_temp_sup-G_T_transv' },
  12134: { color: [220, 60, 220], opacity: 1, label: 'ctx_rh_G_temp_sup-Lateral' },
  12135: { color: [65, 220, 60], opacity: 1, label: 'ctx_rh_G_temp_sup-Plan_polar' },
  12136: { color: [25, 140, 20], opacity: 1, label: 'ctx_rh_G_temp_sup-Plan_tempo' },
  12137: { color: [220, 220, 100], opacity: 1, label: 'ctx_rh_G_temporal_inf' },
  12138: { color: [180, 60, 60], opacity: 1, label: 'ctx_rh_G_temporal_middle' },
  12139: { color: [61, 20, 220], opacity: 1, label: 'ctx_rh_Lat_Fis-ant-Horizont' },
  12140: { color: [61, 20, 60], opacity: 1, label: 'ctx_rh_Lat_Fis-ant-Vertical' },
  12141: { color: [61, 60, 100], opacity: 1, label: 'ctx_rh_Lat_Fis-post' },
  12142: { color: [25, 25, 25], opacity: 1, label: 'ctx_rh_Medial_wall' },
  12143: { color: [140, 20, 60], opacity: 1, label: 'ctx_rh_Pole_occipital' },
  12144: { color: [220, 180, 20], opacity: 1, label: 'ctx_rh_Pole_temporal' },
  12145: { color: [63, 180, 180], opacity: 1, label: 'ctx_rh_S_calcarine' },
  12146: { color: [221, 20, 10], opacity: 1, label: 'ctx_rh_S_central' },
  12147: { color: [221, 20, 100], opacity: 1, label: 'ctx_rh_S_cingul-Marginalis' },
  12148: { color: [221, 60, 140], opacity: 1, label: 'ctx_rh_S_circular_insula_ant' },
  12149: { color: [221, 20, 220], opacity: 1, label: 'ctx_rh_S_circular_insula_inf' },
  12150: { color: [61, 220, 220], opacity: 1, label: 'ctx_rh_S_circular_insula_sup' },
  12151: { color: [100, 200, 200], opacity: 1, label: 'ctx_rh_S_collat_transv_ant' },
  12152: { color: [10, 200, 200], opacity: 1, label: 'ctx_rh_S_collat_transv_post' },
  12153: { color: [221, 220, 20], opacity: 1, label: 'ctx_rh_S_front_inf' },
  12154: { color: [141, 20, 100], opacity: 1, label: 'ctx_rh_S_front_middle' },
  12155: { color: [61, 220, 100], opacity: 1, label: 'ctx_rh_S_front_sup' },
  12156: { color: [141, 60, 20], opacity: 1, label: 'ctx_rh_S_interm_prim-Jensen' },
  12157: { color: [143, 20, 220], opacity: 1, label: 'ctx_rh_S_intrapariet_and_P_trans' },
  12158: { color: [101, 60, 220], opacity: 1, label: 'ctx_rh_S_oc_middle_and_Lunatus' },
  12159: { color: [21, 20, 140], opacity: 1, label: 'ctx_rh_S_oc_sup_and_transversal' },
  12160: { color: [61, 20, 180], opacity: 1, label: 'ctx_rh_S_occipital_ant' },
  12161: { color: [221, 140, 20], opacity: 1, label: 'ctx_rh_S_oc-temp_lat' },
  12162: { color: [141, 100, 220], opacity: 1, label: 'ctx_rh_S_oc-temp_med_and_Lingual' },
  12163: { color: [221, 100, 20], opacity: 1, label: 'ctx_rh_S_orbital_lateral' },
  12164: { color: [181, 200, 20], opacity: 1, label: 'ctx_rh_S_orbital_med-olfact' },
  12165: { color: [101, 20, 20], opacity: 1, label: 'ctx_rh_S_orbital-H_Shaped' },
  12166: { color: [101, 100, 180], opacity: 1, label: 'ctx_rh_S_parieto_occipital' },
  12167: { color: [181, 220, 20], opacity: 1, label: 'ctx_rh_S_pericallosal' },
  12168: { color: [21, 140, 200], opacity: 1, label: 'ctx_rh_S_postcentral' },
  12169: { color: [21, 20, 240], opacity: 1, label: 'ctx_rh_S_precentral-inf-part' },
  12170: { color: [21, 20, 200], opacity: 1, label: 'ctx_rh_S_precentral-sup-part' },
  12171: { color: [21, 20, 60], opacity: 1, label: 'ctx_rh_S_suborbital' },
  12172: { color: [101, 60, 60], opacity: 1, label: 'ctx_rh_S_subparietal' },
  12173: { color: [21, 180, 180], opacity: 1, label: 'ctx_rh_S_temporal_inf' },
  12174: { color: [223, 220, 60], opacity: 1, label: 'ctx_rh_S_temporal_sup' },
  12175: { color: [221, 60, 60], opacity: 1, label: 'ctx_rh_S_temporal_transverse' },
  13100: { color: [0, 0, 0], opacity: 1, label: 'wm_lh_Unknown' },
  13101: { color: [23, 220, 60], opacity: 1, label: 'wm_lh_G_and_S_frontomargin' },
  13102: { color: [23, 60, 180], opacity: 1, label: 'wm_lh_G_and_S_occipital_inf' },
  13103: { color: [63, 100, 60], opacity: 1, label: 'wm_lh_G_and_S_paracentral' },
  13104: { color: [63, 20, 220], opacity: 1, label: 'wm_lh_G_and_S_subcentral' },
  13105: { color: [13, 0, 250], opacity: 1, label: 'wm_lh_G_and_S_transv_frontopol' },
  13106: { color: [26, 60, 0], opacity: 1, label: 'wm_lh_G_and_S_cingul-Ant' },
  13107: { color: [26, 60, 75], opacity: 1, label: 'wm_lh_G_and_S_cingul-Mid-Ant' },
  13108: { color: [26, 60, 150], opacity: 1, label: 'wm_lh_G_and_S_cingul-Mid-Post' },
  13109: { color: [25, 60, 250], opacity: 1, label: 'wm_lh_G_cingul-Post-dorsal' },
  13110: { color: [60, 25, 25], opacity: 1, label: 'wm_lh_G_cingul-Post-ventral' },
  13111: { color: [180, 20, 20], opacity: 1, label: 'wm_lh_G_cuneus' },
  13112: { color: [220, 20, 100], opacity: 1, label: 'wm_lh_G_front_inf-Opercular' },
  13113: { color: [140, 60, 60], opacity: 1, label: 'wm_lh_G_front_inf-Orbital' },
  13114: { color: [180, 220, 140], opacity: 1, label: 'wm_lh_G_front_inf-Triangul' },
  13115: { color: [140, 100, 180], opacity: 1, label: 'wm_lh_G_front_middle' },
  13116: { color: [180, 20, 140], opacity: 1, label: 'wm_lh_G_front_sup' },
  13117: { color: [23, 10, 10], opacity: 1, label: 'wm_lh_G_Ins_lg_and_S_cent_ins' },
  13118: { color: [225, 140, 140], opacity: 1, label: 'wm_lh_G_insular_short' },
  13119: { color: [180, 60, 180], opacity: 1, label: 'wm_lh_G_occipital_middle' },
  13120: { color: [20, 220, 60], opacity: 1, label: 'wm_lh_G_occipital_sup' },
  13121: { color: [60, 20, 140], opacity: 1, label: 'wm_lh_G_oc-temp_lat-fusifor' },
  13122: { color: [220, 180, 140], opacity: 1, label: 'wm_lh_G_oc-temp_med-Lingual' },
  13123: { color: [65, 100, 20], opacity: 1, label: 'wm_lh_G_oc-temp_med-Parahip' },
  13124: { color: [220, 60, 20], opacity: 1, label: 'wm_lh_G_orbital' },
  13125: { color: [20, 60, 220], opacity: 1, label: 'wm_lh_G_pariet_inf-Angular' },
  13126: { color: [100, 100, 60], opacity: 1, label: 'wm_lh_G_pariet_inf-Supramar' },
  13127: { color: [220, 180, 220], opacity: 1, label: 'wm_lh_G_parietal_sup' },
  13128: { color: [20, 180, 140], opacity: 1, label: 'wm_lh_G_postcentral' },
  13129: { color: [60, 140, 180], opacity: 1, label: 'wm_lh_G_precentral' },
  13130: { color: [25, 20, 140], opacity: 1, label: 'wm_lh_G_precuneus' },
  13131: { color: [20, 60, 100], opacity: 1, label: 'wm_lh_G_rectus' },
  13132: { color: [60, 220, 20], opacity: 1, label: 'wm_lh_G_subcallosal' },
  13133: { color: [60, 60, 220], opacity: 1, label: 'wm_lh_G_temp_sup-G_T_transv' },
  13134: { color: [220, 60, 220], opacity: 1, label: 'wm_lh_G_temp_sup-Lateral' },
  13135: { color: [65, 220, 60], opacity: 1, label: 'wm_lh_G_temp_sup-Plan_polar' },
  13136: { color: [25, 140, 20], opacity: 1, label: 'wm_lh_G_temp_sup-Plan_tempo' },
  13137: { color: [220, 220, 100], opacity: 1, label: 'wm_lh_G_temporal_inf' },
  13138: { color: [180, 60, 60], opacity: 1, label: 'wm_lh_G_temporal_middle' },
  13139: { color: [61, 20, 220], opacity: 1, label: 'wm_lh_Lat_Fis-ant-Horizont' },
  13140: { color: [61, 20, 60], opacity: 1, label: 'wm_lh_Lat_Fis-ant-Vertical' },
  13141: { color: [61, 60, 100], opacity: 1, label: 'wm_lh_Lat_Fis-post' },
  13142: { color: [25, 25, 25], opacity: 1, label: 'wm_lh_Medial_wall' },
  13143: { color: [140, 20, 60], opacity: 1, label: 'wm_lh_Pole_occipital' },
  13144: { color: [220, 180, 20], opacity: 1, label: 'wm_lh_Pole_temporal' },
  13145: { color: [63, 180, 180], opacity: 1, label: 'wm_lh_S_calcarine' },
  13146: { color: [221, 20, 10], opacity: 1, label: 'wm_lh_S_central' },
  13147: { color: [221, 20, 100], opacity: 1, label: 'wm_lh_S_cingul-Marginalis' },
  13148: { color: [221, 60, 140], opacity: 1, label: 'wm_lh_S_circular_insula_ant' },
  13149: { color: [221, 20, 220], opacity: 1, label: 'wm_lh_S_circular_insula_inf' },
  13150: { color: [61, 220, 220], opacity: 1, label: 'wm_lh_S_circular_insula_sup' },
  13151: { color: [100, 200, 200], opacity: 1, label: 'wm_lh_S_collat_transv_ant' },
  13152: { color: [10, 200, 200], opacity: 1, label: 'wm_lh_S_collat_transv_post' },
  13153: { color: [221, 220, 20], opacity: 1, label: 'wm_lh_S_front_inf' },
  13154: { color: [141, 20, 100], opacity: 1, label: 'wm_lh_S_front_middle' },
  13155: { color: [61, 220, 100], opacity: 1, label: 'wm_lh_S_front_sup' },
  13156: { color: [141, 60, 20], opacity: 1, label: 'wm_lh_S_interm_prim-Jensen' },
  13157: { color: [143, 20, 220], opacity: 1, label: 'wm_lh_S_intrapariet_and_P_trans' },
  13158: { color: [101, 60, 220], opacity: 1, label: 'wm_lh_S_oc_middle_and_Lunatus' },
  13159: { color: [21, 20, 140], opacity: 1, label: 'wm_lh_S_oc_sup_and_transversal' },
  13160: { color: [61, 20, 180], opacity: 1, label: 'wm_lh_S_occipital_ant' },
  13161: { color: [221, 140, 20], opacity: 1, label: 'wm_lh_S_oc-temp_lat' },
  13162: { color: [141, 100, 220], opacity: 1, label: 'wm_lh_S_oc-temp_med_and_Lingual' },
  13163: { color: [221, 100, 20], opacity: 1, label: 'wm_lh_S_orbital_lateral' },
  13164: { color: [181, 200, 20], opacity: 1, label: 'wm_lh_S_orbital_med-olfact' },
  13165: { color: [101, 20, 20], opacity: 1, label: 'wm_lh_S_orbital-H_Shaped' },
  13166: { color: [101, 100, 180], opacity: 1, label: 'wm_lh_S_parieto_occipital' },
  13167: { color: [181, 220, 20], opacity: 1, label: 'wm_lh_S_pericallosal' },
  13168: { color: [21, 140, 200], opacity: 1, label: 'wm_lh_S_postcentral' },
  13169: { color: [21, 20, 240], opacity: 1, label: 'wm_lh_S_precentral-inf-part' },
  13170: { color: [21, 20, 200], opacity: 1, label: 'wm_lh_S_precentral-sup-part' },
  13171: { color: [21, 20, 60], opacity: 1, label: 'wm_lh_S_suborbital' },
  13172: { color: [101, 60, 60], opacity: 1, label: 'wm_lh_S_subparietal' },
  13173: { color: [21, 180, 180], opacity: 1, label: 'wm_lh_S_temporal_inf' },
  13174: { color: [223, 220, 60], opacity: 1, label: 'wm_lh_S_temporal_sup' },
  13175: { color: [221, 60, 60], opacity: 1, label: 'wm_lh_S_temporal_transverse' },
  14100: { color: [0, 0, 0], opacity: 1, label: 'wm_rh_Unknown' },
  14101: { color: [23, 220, 60], opacity: 1, label: 'wm_rh_G_and_S_frontomargin' },
  14102: { color: [23, 60, 180], opacity: 1, label: 'wm_rh_G_and_S_occipital_inf' },
  14103: { color: [63, 100, 60], opacity: 1, label: 'wm_rh_G_and_S_paracentral' },
  14104: { color: [63, 20, 220], opacity: 1, label: 'wm_rh_G_and_S_subcentral' },
  14105: { color: [13, 0, 250], opacity: 1, label: 'wm_rh_G_and_S_transv_frontopol' },
  14106: { color: [26, 60, 0], opacity: 1, label: 'wm_rh_G_and_S_cingul-Ant' },
  14107: { color: [26, 60, 75], opacity: 1, label: 'wm_rh_G_and_S_cingul-Mid-Ant' },
  14108: { color: [26, 60, 150], opacity: 1, label: 'wm_rh_G_and_S_cingul-Mid-Post' },
  14109: { color: [25, 60, 250], opacity: 1, label: 'wm_rh_G_cingul-Post-dorsal' },
  14110: { color: [60, 25, 25], opacity: 1, label: 'wm_rh_G_cingul-Post-ventral' },
  14111: { color: [180, 20, 20], opacity: 1, label: 'wm_rh_G_cuneus' },
  14112: { color: [220, 20, 100], opacity: 1, label: 'wm_rh_G_front_inf-Opercular' },
  14113: { color: [140, 60, 60], opacity: 1, label: 'wm_rh_G_front_inf-Orbital' },
  14114: { color: [180, 220, 140], opacity: 1, label: 'wm_rh_G_front_inf-Triangul' },
  14115: { color: [140, 100, 180], opacity: 1, label: 'wm_rh_G_front_middle' },
  14116: { color: [180, 20, 140], opacity: 1, label: 'wm_rh_G_front_sup' },
  14117: { color: [23, 10, 10], opacity: 1, label: 'wm_rh_G_Ins_lg_and_S_cent_ins' },
  14118: { color: [225, 140, 140], opacity: 1, label: 'wm_rh_G_insular_short' },
  14119: { color: [180, 60, 180], opacity: 1, label: 'wm_rh_G_occipital_middle' },
  14120: { color: [20, 220, 60], opacity: 1, label: 'wm_rh_G_occipital_sup' },
  14121: { color: [60, 20, 140], opacity: 1, label: 'wm_rh_G_oc-temp_lat-fusifor' },
  14122: { color: [220, 180, 140], opacity: 1, label: 'wm_rh_G_oc-temp_med-Lingual' },
  14123: { color: [65, 100, 20], opacity: 1, label: 'wm_rh_G_oc-temp_med-Parahip' },
  14124: { color: [220, 60, 20], opacity: 1, label: 'wm_rh_G_orbital' },
  14125: { color: [20, 60, 220], opacity: 1, label: 'wm_rh_G_pariet_inf-Angular' },
  14126: { color: [100, 100, 60], opacity: 1, label: 'wm_rh_G_pariet_inf-Supramar' },
  14127: { color: [220, 180, 220], opacity: 1, label: 'wm_rh_G_parietal_sup' },
  14128: { color: [20, 180, 140], opacity: 1, label: 'wm_rh_G_postcentral' },
  14129: { color: [60, 140, 180], opacity: 1, label: 'wm_rh_G_precentral' },
  14130: { color: [25, 20, 140], opacity: 1, label: 'wm_rh_G_precuneus' },
  14131: { color: [20, 60, 100], opacity: 1, label: 'wm_rh_G_rectus' },
  14132: { color: [60, 220, 20], opacity: 1, label: 'wm_rh_G_subcallosal' },
  14133: { color: [60, 60, 220], opacity: 1, label: 'wm_rh_G_temp_sup-G_T_transv' },
  14134: { color: [220, 60, 220], opacity: 1, label: 'wm_rh_G_temp_sup-Lateral' },
  14135: { color: [65, 220, 60], opacity: 1, label: 'wm_rh_G_temp_sup-Plan_polar' },
  14136: { color: [25, 140, 20], opacity: 1, label: 'wm_rh_G_temp_sup-Plan_tempo' },
  14137: { color: [220, 220, 100], opacity: 1, label: 'wm_rh_G_temporal_inf' },
  14138: { color: [180, 60, 60], opacity: 1, label: 'wm_rh_G_temporal_middle' },
  14139: { color: [61, 20, 220], opacity: 1, label: 'wm_rh_Lat_Fis-ant-Horizont' },
  14140: { color: [61, 20, 60], opacity: 1, label: 'wm_rh_Lat_Fis-ant-Vertical' },
  14141: { color: [61, 60, 100], opacity: 1, label: 'wm_rh_Lat_Fis-post' },
  14142: { color: [25, 25, 25], opacity: 1, label: 'wm_rh_Medial_wall' },
  14143: { color: [140, 20, 60], opacity: 1, label: 'wm_rh_Pole_occipital' },
  14144: { color: [220, 180, 20], opacity: 1, label: 'wm_rh_Pole_temporal' },
  14145: { color: [63, 180, 180], opacity: 1, label: 'wm_rh_S_calcarine' },
  14146: { color: [221, 20, 10], opacity: 1, label: 'wm_rh_S_central' },
  14147: { color: [221, 20, 100], opacity: 1, label: 'wm_rh_S_cingul-Marginalis' },
  14148: { color: [221, 60, 140], opacity: 1, label: 'wm_rh_S_circular_insula_ant' },
  14149: { color: [221, 20, 220], opacity: 1, label: 'wm_rh_S_circular_insula_inf' },
  14150: { color: [61, 220, 220], opacity: 1, label: 'wm_rh_S_circular_insula_sup' },
  14151: { color: [100, 200, 200], opacity: 1, label: 'wm_rh_S_collat_transv_ant' },
  14152: { color: [10, 200, 200], opacity: 1, label: 'wm_rh_S_collat_transv_post' },
  14153: { color: [221, 220, 20], opacity: 1, label: 'wm_rh_S_front_inf' },
  14154: { color: [141, 20, 100], opacity: 1, label: 'wm_rh_S_front_middle' },
  14155: { color: [61, 220, 100], opacity: 1, label: 'wm_rh_S_front_sup' },
  14156: { color: [141, 60, 20], opacity: 1, label: 'wm_rh_S_interm_prim-Jensen' },
  14157: { color: [143, 20, 220], opacity: 1, label: 'wm_rh_S_intrapariet_and_P_trans' },
  14158: { color: [101, 60, 220], opacity: 1, label: 'wm_rh_S_oc_middle_and_Lunatus' },
  14159: { color: [21, 20, 140], opacity: 1, label: 'wm_rh_S_oc_sup_and_transversal' },
  14160: { color: [61, 20, 180], opacity: 1, label: 'wm_rh_S_occipital_ant' },
  14161: { color: [221, 140, 20], opacity: 1, label: 'wm_rh_S_oc-temp_lat' },
  14162: { color: [141, 100, 220], opacity: 1, label: 'wm_rh_S_oc-temp_med_and_Lingual' },
  14163: { color: [221, 100, 20], opacity: 1, label: 'wm_rh_S_orbital_lateral' },
  14164: { color: [181, 200, 20], opacity: 1, label: 'wm_rh_S_orbital_med-olfact' },
  14165: { color: [101, 20, 20], opacity: 1, label: 'wm_rh_S_orbital-H_Shaped' },
  14166: { color: [101, 100, 180], opacity: 1, label: 'wm_rh_S_parieto_occipital' },
  14167: { color: [181, 220, 20], opacity: 1, label: 'wm_rh_S_pericallosal' },
  14168: { color: [21, 140, 200], opacity: 1, label: 'wm_rh_S_postcentral' },
  14169: { color: [21, 20, 240], opacity: 1, label: 'wm_rh_S_precentral-inf-part' },
  14170: { color: [21, 20, 200], opacity: 1, label: 'wm_rh_S_precentral-sup-part' },
  14171: { color: [21, 20, 60], opacity: 1, label: 'wm_rh_S_suborbital' },
  14172: { color: [101, 60, 60], opacity: 1, label: 'wm_rh_S_subparietal' },
  14173: { color: [21, 180, 180], opacity: 1, label: 'wm_rh_S_temporal_inf' },
  14174: { color: [223, 220, 60], opacity: 1, label: 'wm_rh_S_temporal_sup' },
  14175: { color: [221, 60, 60], opacity: 1, label: 'wm_rh_S_temporal_transverse' },
};

export default segmentationFs;
