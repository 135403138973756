import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import "react-markdown-editor-lite/lib/index.css"
import Button, { ButtonTheme } from "src/components/button/button"
import Editor from "src/components/editor/editor"
import useSWR, { mutate } from "swr"
import DashboardBody from "../../../components/dashboardBody/dashboardBody"
import DashboardHeader from "../../../components/dashboardHeader/dashboardHeader"
import { LogoVisibility } from "../../../components/logos/logos"
import { HasAuth } from "../../../components/privateRoute/privateRoute"
import SegmentedControl from "../../../components/segmentedControl/segmentedControl"
import { fetchApiAuthorized } from "../../../network/fetch"
import { useErrorStore } from "../../../state/stores/errorStore"

const Config = ({ auth }: HasAuth) => {
	const { t } = useTranslation()

	const restartServerConfirmation = useErrorStore(
		(state) => state.restartServerConfirmation
	)

	const [md, setMarkdown] = useState("")
	const [html, setHtml] = useState("")
	const [previousMd, setPreviousMarkdown] = useState("")

	const [logoVisibility, setLogoVisibility] = useState(LogoVisibility.Takeda)
	const [previousLogoVisibility, setPreviousLogoVisibility] = useState(
		LogoVisibility.Takeda
	)

	const { data } = useSWR(
		["/config/admin", auth.token, auth.expiresIn],
		fetchApiAuthorized
	)

	useEffect(() => {
		if (data) {
			setMarkdown(data.md)
			setPreviousMarkdown(data.md)

			setHtml(data.html)
			setLogoVisibility(data.logoVisibility)
			setPreviousLogoVisibility(data.logoVisibility)
		}
	}, [data])

	return (
		<DashboardBody>
			<DashboardHeader
				buttonDisabled={
					previousMd === md && previousLogoVisibility === logoVisibility
				}
				buttonOnClick={async () => {
					const success = await fetchApiAuthorized(
						"/config/",
						auth.token,
						auth.expiresIn,
						{ md, html, logoVisibility },
						"PUT"
					)

					if (success) {
						setPreviousMarkdown(md)
						setPreviousLogoVisibility(logoVisibility)

						mutate("/config/")
					}
				}}
				buttonTitle={t("save")}
				title={t("config") as string}
			/>

			<div style={{ padding: "20px" }}>
				<Editor
					markdown={md}
					onChange={(markdown, html) => {
						setMarkdown(markdown)
						setHtml(html)
					}}
				/>

				<div className="flex flex-row items-center justify-between border-t border-t-slate-200 pt-2.5 mt-5">
					<p>{t("restart_server")}:</p>

					<Button
						theme={ButtonTheme.WhiteOnRed}
						onClick={() =>
							restartServerConfirmation(async () => {
								await fetchApiAuthorized(
									`/config/restart`,
									auth.token,
									auth.expiresIn,
									undefined,
									"POST"
								)
							})
						}
					>
						{t("restart")}
					</Button>
				</div>

				<div className="flex flex-row items-center justify-between border-t border-t-slate-200 pt-2.5 mt-5">
					<p>Logo:</p>

					<SegmentedControl
						options={[
							{
								title: "Takeda",
								value: LogoVisibility.Takeda,
							},
							{ title: "MSD", value: LogoVisibility.MSD },
							{ title: "No Logo", value: LogoVisibility.NoLogo },
						]}
						value={logoVisibility}
						onChange={setLogoVisibility}
					/>
				</div>
			</div>
		</DashboardBody>
	)
}

export default Config
