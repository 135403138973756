import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import useSWR from "swr"
import DashboardBody from "../../../components/dashboardBody/dashboardBody"
import DashboardHeader from "../../../components/dashboardHeader/dashboardHeader"
import GroupedMediaList from "../../../components/list/groupedMediaList"
import ListView from "../../../components/list/list"
import ListSwitch from "../../../components/list/listSwitch"
import { HasAuth } from "../../../components/privateRoute/privateRoute"
import { fetchApiAuthorized } from "../../../network/fetch"
import { useErrorStore } from "../../../state/stores/errorStore"

const Medias = ({ auth }: HasAuth) => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [showAll, setShowAll] = useState(false)
	const [byCase, setByCase] = useState("")

	const { data: medias, mutate } = useSWR(
		[`/medias${showAll ? "" : "/grouped"}`, auth.token, auth.expiresIn],
		fetchApiAuthorized
	)

	const deletionConfirmation = useErrorStore(
		(state) => state.deleteConfirmation
	)

	useEffect(() => {
		if (showAll) {
			setByCase("")
		}
	}, [showAll])

	return (
		<DashboardBody>
			<DashboardHeader
				additional={
					byCase.length > 0 ? (
						""
					) : (
						<ListSwitch setShowAll={setShowAll} showAll={showAll} />
					)
				}
				buttonOnClick={() => navigate("/moderator/media/create")}
				buttonTitle={t("create_media") as string}
				title={t("media") as string}
				titleAddition={
					byCase.length > 0 ? (
						<div className="flex flex-row">
							<svg
								className="h-6 w-6 relative top-0.5"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M13 7l5 5m0 0l-5 5m5-5H6"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
							&nbsp; {byCase}
						</div>
					) : undefined
				}
				titleOnClick={byCase.length > 0 ? () => setByCase("") : undefined}
			/>

			{showAll ? (
				<ListView
					data={medias}
					isMedia={true}
					loading={medias === undefined}
					title="name"
					onDelete={(v) => {
						deletionConfirmation(async () => {
							await fetchApiAuthorized(
								`/medias/${v.id}`,
								auth.token,
								auth.expiresIn,
								undefined,
								"DELETE"
							)

							mutate()
						})
					}}
					onEdit={(v: Record<string, unknown>) =>
						navigate(`/moderator/media/${v.id}`)
					}
				/>
			) : byCase ? (
				<ListView
					data={
						byCase === (t("unused_media") as string)
							? medias.unused
							: medias.used[byCase]
					}
					isMedia={true}
					loading={medias === undefined}
					title="name"
					onDelete={(v) => {
						deletionConfirmation(async () => {
							await fetchApiAuthorized(
								`/medias/${v.id}`,
								auth.token,
								auth.expiresIn,
								undefined,
								"DELETE"
							)

							mutate()
						})
					}}
					onEdit={(v: Record<string, unknown>) =>
						navigate(`/moderator/media/${v.id}`)
					}
				/>
			) : (
				<GroupedMediaList
					data={medias}
					loading={medias === undefined}
					setCase={setByCase}
				/>
			)}
		</DashboardBody>
	)
}

export default Medias
