import create from "zustand"

interface ModeratorStore extends Record<string, unknown> {
	users: Record<string, unknown>[]

	onUsers: (data: Record<string, unknown>[]) => void
}

export const useModeratorStore = create<ModeratorStore>((set, get) => {
	return {
		users: [],

		onUsers: (data) => {
			set({ users: data })
		},
	}
})
