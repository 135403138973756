import { map_numbers } from "./math"

export const quizColors = [
	"#60ACEE",
	"#E2702F",
	"#4FC4AF",
	"#D668A5",
	"#9E4476",
	"#274060",
	"#F1C40F",
	"#854CC7",
]

export const quizLetters = ["A", "B", "C", "D", "E", "F", "G", "H"]

export const mapVotes = (answers: string[], votes: number[]): number[] => {
	const min = Math.min(...votes)
	const max = Math.max(...votes)

	let mappedVotes = answers.map((_, i) => {
		const width = map_numbers(votes[i], min, max, min, 98)
		if (isNaN(width)) {
			return 0
		} else {
			return width
		}
	})

	if (mappedVotes.length === 0) {
		mappedVotes = new Array(answers.length).fill(0)
	}

	return mappedVotes
}
