import { useEffect, useState } from "react"
import { isChrome, isFirefox, isSafari, isTablet } from "react-device-detect"
import { useTranslation } from "react-i18next"
import waitingNo from "../../../assets/images/waitingNo.svg"
import waitingYes from "../../../assets/images/waitingYes.svg"

interface CheckProps {
	onCheck: (capable: boolean) => void
	showHeader?: boolean
}

const Check = ({ onCheck, showHeader = true }: CheckProps) => {
	const { t } = useTranslation()

	const browserOk = isChrome || isSafari || isFirefox

	const [animate, setAnimate] = useState(false)
	const [clickCount, setClickCount] = useState(0)

	// const gpu = useDetectGPU()
	// const gpuGoodEnough = gpu && (gpu.tier >= 2 || gpu.type === "FALLBACK")

	// useEffect(() => {
	// 	setAnimate(true)

	// 	if (onCheck) {
	// 		setTimeout(() => {
	// 			if (clickCount > 4 || (isTablet && gpu && gpuGoodEnough)) {
	// 				onCheck(false)
	// 			} else {
	// 				onCheck(
	// 					!browserOk || window.screen.width < 1200 || !gpu || !gpuGoodEnough
	// 				)
	// 			}
	// 		}, 1200)
	// 	}
	// }, [onCheck, browserOk, gpu, clickCount, gpuGoodEnough])

	useEffect(() => {
		setAnimate(true)

		if (onCheck) {
			setTimeout(() => {
				if (clickCount > 4 || isTablet) {
					onCheck(false)
				} else {
					onCheck(!browserOk || window.screen.width < 1200)
				}
			}, 1200)
		}
	}, [onCheck, browserOk, clickCount])

	return (
		<>
			{showHeader && (
				<div className="flex flex-col">
					<h2
						className="mt-20 mb-5"
						onClick={() => setClickCount((count) => count + 1)}
					>
						{t("tutorial_check_1")}
					</h2>
					<p>{t("tutorial_check_2")}</p>
				</div>
			)}
			<div className="flex flex-col items-stretch mb-24 w-[600px]">
				<div
					className={`flex flex-col mb-7 transition-all duration-300 ease-in-out ${
						animate ? "opacity-100 scale-100" : "opacity-0 scale-125"
					} delay-[600ms]`}
				>
					<div className="flex flex-row items-center justify-between">
						<div className="flex flex-row">
							<h5 className="font-normal">{t("tutorial_check_6")}:&nbsp;</h5>
							<h5 className={`${browserOk ? "text-s-blue" : "text-s-red"}`}>
								{browserOk ? t("usable") : t("not_usable")}
							</h5>
						</div>
						<img
							alt={browserOk ? "Yes" : "No"}
							src={browserOk ? waitingYes : waitingNo}
						/>
					</div>
					{!browserOk && (
						<h5 className="text-slate-400">{t("tutorial_check_3")}</h5>
					)}
				</div>
				<div
					className={`flex flex-col mb-7 transition-all duration-300 ease-in-out ${
						animate ? "opacity-100 scale-100" : "opacity-0 scale-125"
					} delay-[900ms]`}
				>
					<div className="flex flex-row items-center justify-between">
						<div className="flex flex-row">
							<h5 className="font-normal">{t("tutorial_check_7")}:&nbsp;</h5>
							<h5
								className={`${
									window.screen.width > 1200 ? "text-s-blue" : "text-s-red"
								}`}
							>
								{window.screen.width > 1200
									? t("big_enough")
									: t("not_big_enough")}
							</h5>
						</div>
						<img
							alt={window.screen.width < 1200 ? "No" : "Yes"}
							src={window.screen.width < 1200 ? waitingNo : waitingYes}
						/>
					</div>
					{window.screen.width < 1200 && (
						<h5 className="text-slate-400">{t("tutorial_check_4")}</h5>
					)}
				</div>
				{/* <div
					className={`flex flex-col mb-7 transition-all duration-300 ease-in-out ${
						animate ? "opacity-100 scale-100" : "opacity-0 scale-125"
					} delay-[1200ms]`}
				>
					<div className="flex flex-row items-center justify-between">
						<div className="flex flex-row">
							<h5 className="font-normal">{t("tutorial_check_8")}:&nbsp;</h5>
							{gpu ? (
								<h5
									className={`${gpuGoodEnough ? "text-s-blue" : "text-s-red"}`}
								>
									{gpuGoodEnough ? t("fast_enough") : t("too_slow")}
								</h5>
							) : (
								<h5>Loading…</h5>
							)}
						</div>
						{gpu && (
							<img
								alt={!gpuGoodEnough ? "No" : "Yes"}
								src={!gpuGoodEnough ? waitingNo : waitingYes}
							/>
						)}
					</div>
					{gpuGoodEnough === false && (
						<h5 className="text-slate-400">{t("tutorial_check_9")}</h5>
					)}
					{gpu && Number(gpu.tier) === 2 && (
						<h5 className="text-slate-400">{t("tutorial_check_10")}</h5>
					)}
				</div> */}
				{/* {!browserOk || window.screen.width < 1200 || !gpuGoodEnough ? ( */}
				{!browserOk || window.screen.width < 1200 ? (
					<div
						className={`text-s-red transition-all duration-300 ease-in-out  ${
							animate ? "opacity-100" : "opacity-0"
						} delay-[1500ms]`}
					>
						{t("tutorial_check_5")}
					</div>
				) : null}
			</div>
		</>
	)
}

export default Check
