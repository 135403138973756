const Dot = ({ state }: { state: number }) => {
	return (
		<div
			className={`${state === 2 ? "w-5 h-5" : "w-3 h-3"} rounded-full ${
				state === 0 ? "bg-slate-200" : "bg-s-blue"
			} transition-all`}
		/>
	)
}

const Line = ({ state }: { state: number }) => {
	return (
		<div
			className={`h-0.5 flex-1 transition-all ${
				state === 0 ? "bg-slate-200" : "bg-s-blue"
			}`}
		/>
	)
}

const Step = ({ step = 0 }) => {
	let a = 0,
		b = 0,
		c = 0,
		d = 0,
		e = 0,
		f = 0,
		g = 0,
		h = 0,
		i = 0
	switch (step) {
		case 0:
			a = 2
			b = 0
			c = 0
			d = 0
			e = 0
			f = 0
			g = 0
			h = 0
			i = 0
			break
		case 1:
			a = 1
			b = 2
			c = 0
			d = 0
			e = 0
			f = 1
			g = 0
			h = 0
			i = 0
			break
		case 2:
			a = 1
			b = 1
			c = 2
			d = 0
			e = 0
			f = 1
			g = 1
			h = 0
			i = 0
			break
		case 3:
			a = 1
			b = 1
			c = 1
			d = 2
			e = 0
			f = 1
			g = 1
			h = 1
			i = 0
			break
		case 4:
			a = 1
			b = 1
			c = 1
			d = 1
			e = 2
			f = 1
			g = 1
			h = 1
			i = 1
			break
		case 5:
			a = 1
			b = 1
			c = 1
			d = 1
			e = 1
			f = 1
			g = 1
			h = 1
			i = 1
			break

		default:
			break
	}

	return (
		<div className="absolute left-0 right-0 top-0 h-16 pointer-events-none flex items-center justify-center">
			<div className="w-72 h-5 relative flex items-center px-0 py-2">
				<Line state={f} />
				<Line state={g} />
				<Line state={h} />
				<Line state={i} />
				<div className="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-between ">
					<Dot state={a} />
					<Dot state={b} />
					<Dot state={c} />
					<Dot state={d} />
					<Dot state={e} />
				</div>
			</div>
		</div>
	)
}

export default Step
