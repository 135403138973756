import create from "zustand"

interface PatientStore extends Record<string, unknown> {
	percentage: number
	mode: string
}

export const usePatientStore = create<PatientStore>((set, get) => {
	return {
		percentage: 0,
		mode: "loading",
	}
})
