import { DetailedHTMLProps, SelectHTMLAttributes } from "react"

const Select = ({
	onChange,
	className,
	...props
}: Omit<
	DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
	"onChange" | "style"
> & { onChange: (value: string) => void }) => {
	return (
		<select
			//TODO: focus checken
			className={`appearance-none px-4 py-3 focus:p-[11px] border border-slate-400 shadow-md rounded-xl flex-1 min-w-0 text-s-black text-sm outline-none focus:border-2 focus:border-s-blue placeholder:text-slate-400 transition-all hover:border-s-blue ${className}`}
			{...props}
			onChange={({ target: { value } }: { target: { value: string } }) => {
				onChange(value)
			}}
		/>
	)
}

export default Select
