import { usePatientStore } from "../../state/stores/patientStore"

export default class ProgressBar {
	update: (value: number, total: number, mode: string) => void

	constructor() {
		this.update = function (value, total, mode) {
			let _mode = "loading"
			if (mode === "parse") {
				if (value === total) {
					_mode = "done"
				} else {
					_mode = "parsing"
				}
			}

			usePatientStore.setState({ percentage: value / total, mode: _mode })
		}
	}
}
