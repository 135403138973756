import moment from "moment"

interface WaitingProps {
	startDate?: Date
}

const Waiting = ({ startDate }: WaitingProps) => {
	return (
		<div className="flex flex-col flex-1">
			<div className="flex flex-row items-baseline self-center">
				<h2>
					Das Practinar startet &nbsp;
					<span className="text-s-blue">
						{startDate ? moment(startDate).fromNow() : "…"}
					</span>
				</h2>
			</div>
		</div>
	)
}

export default Waiting
