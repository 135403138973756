import {
	ButtonHTMLAttributes,
	DetailedHTMLProps,
	PropsWithChildren,
	useEffect,
	useState,
} from "react"
import Button, { ButtonTheme } from "src/components/button/button"
import { iconForEmote } from "../../utils/iconForEmote"

interface EmotesProps {
	onEmote: (type: string) => void
	disableInput: boolean
}

export const CustomButton = ({
	children,
	...props
}: PropsWithChildren<
	DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
>) => {
	return (
		<Button
			{...props}
			className={`w-11 h-12 !p-0 transition-all duration-200 ease-in-out  ${props.className}`}
			theme={ButtonTheme.White}
		>
			{children}
		</Button>
	)
}

const Emotes = ({ onEmote, disableInput }: EmotesProps) => {
	const [onHitDetection, setOnHitDetection] = useState(false)
	const [onButton, setOnButton] = useState(false)

	const visible = onHitDetection || onButton

	useEffect(() => {
		const keyEvent = (event: KeyboardEvent) => {
			if (disableInput) {
				if (event.keyCode === 32 || event.keyCode === 13) {
					event.preventDefault()
				}
			}
		}

		document.addEventListener("keydown", keyEvent)
		document.addEventListener("keyup", keyEvent)

		return () => {
			document.removeEventListener("keydown", keyEvent)
			document.removeEventListener("keyup", keyEvent)
		}
	}, [disableInput])

	return (
		// original height & width 45:34
		<div className="relative w-11 h-11 mr-2">
			<CustomButton
				onClick={() => setOnButton(!onButton)}
				onMouseEnter={() => setOnButton(true)}
				onMouseLeave={() => setOnButton(false)}
			>
				{visible ? "…" : <img alt="Raise hand" src={iconForEmote()} />}
			</CustomButton>

			<CustomButton
				className={`${
					visible
						? "opacity-100 pointer-events-auto -top-28"
						: "opacity-0 pointer-events-none top-0"
				} z-[5]`}
				onClick={() => onEmote && onEmote("yes")}
				onMouseEnter={() => setOnButton(true)}
				onMouseLeave={() => setOnButton(false)}
			>
				<img alt="Yes" src={iconForEmote("yes")} />
			</CustomButton>

			<CustomButton
				className={`${
					visible
						? "opacity-100 pointer-events-auto left-[53px] "
						: "opacity-0 pointer-events-none left-0"
				} z-[4] -top-40 delay-[50]`}
				onClick={() => onEmote && onEmote("no")}
				onMouseEnter={() => setOnButton(true)}
				onMouseLeave={() => setOnButton(false)}
			>
				<img alt="No" src={iconForEmote("no")} />
			</CustomButton>

			<CustomButton
				className={`${
					visible
						? "opacity-100 pointer-events-auto left-[106px]"
						: "opacity-0 pointer-events-none left-0"
				} z-[3] delay-[80] -top-52`}
				onClick={() => onEmote && onEmote("raise_hand")}
				onMouseEnter={() => setOnButton(true)}
				onMouseLeave={() => setOnButton(false)}
			>
				<img alt="Raise hand" src={iconForEmote("")} />
			</CustomButton>

			<CustomButton
				className={`${
					visible
						? "opacity-100 pointer-events-auto left-[159px]"
						: "opacity-0 pointer-events-none left-0"
				} z-[2] -top-64 delay-[110]`}
				onClick={() => onEmote && onEmote("slower")}
				onMouseEnter={() => setOnButton(true)}
				onMouseLeave={() => setOnButton(false)}
			>
				<img alt="Slower" src={iconForEmote("slower")} />
			</CustomButton>

			<CustomButton
				className={`${
					visible
						? "opacity-100 pointer-events-auto left-[212px]"
						: "opacity-0 pointer-events-none left-0"
				}  -top-[304px] delay-[140] z-[2]`}
				onClick={() => onEmote && onEmote("question")}
				onMouseEnter={() => setOnButton(true)}
				onMouseLeave={() => setOnButton(false)}
			>
				<img alt="Question" src={iconForEmote("question")} />
			</CustomButton>

			<CustomButton
				className={`${
					visible
						? "opacity-100 pointer-events-auto left-[265px]"
						: "opacity-0 pointer-events-none left-0"
				} z-[5] -top-[352px] delay-[170]`}
				onClick={() => onEmote && onEmote("clap")}
				onMouseEnter={() => setOnButton(true)}
				onMouseLeave={() => setOnButton(false)}
			>
				<img alt="Clap" src={iconForEmote("clap")} />
			</CustomButton>

			{visible && (
				<div
					className="absolute -left-5 bottom-6 -right-56 -top-16 pointer-events-auto"
					onMouseEnter={() => setOnHitDetection(true)}
					onMouseLeave={() => setOnHitDetection(false)}
				/>
			)}
		</div>
	)
}

export default Emotes
