export const sidebarIcons = (icon: string) => {
	switch (icon) {
		case "next":
			return (
				<path
					clipRule="evenodd"
					d="M0 1C0 0.447715 0.447715 0 1 0H19C19.5523 0 20 0.447715 20 1C20 1.55228 19.5523 2 19 2H1C0.447715 2 0 1.55228 0 1ZM4 3C2.89543 3 2 3.89543 2 5V13C2 14.1046 2.89543 15 4 15H16C17.1046 15 18 14.1046 18 13V5C18 3.89543 17.1046 3 16 3H4ZM8 6.80425V10.6958C8 11.4812 8.86395 11.96 9.53 11.5438L12.6432 9.598C13.2699 9.20633 13.2699 8.29367 12.6432 7.902L9.53 5.95625C8.86395 5.53997 8 6.01881 8 6.80425ZM1 16C0.447715 16 0 16.4477 0 17C0 17.5523 0.447715 18 1 18H5C5.55228 18 6 18.4477 6 19C6 19.5523 6.44772 20 7 20H9H11H13C13.5523 20 14 19.5523 14 19C14 18.4477 14.4477 18 15 18H19C19.5523 18 20 17.5523 20 17C20 16.4477 19.5523 16 19 16H1Z"
					fillRule="evenodd"
				/>
			)
		case "done":
			return (
				<path
					clipRule="evenodd"
					d="M0 1C0 0.447715 0.447715 0 1 0H19C19.5523 0 20 0.447715 20 1C20 1.55228 19.5523 2 19 2H1C0.447715 2 0 1.55228 0 1ZM0 17C0 16.4477 0.447715 16 1 16H4.23218L7.06641 18.7836C7.56199 19.2703 8.23773 19.5289 8.93163 19.4974C9.62554 19.4659 10.2751 19.1471 10.7245 18.6174L12.9453 16H19C19.5523 16 20 16.4477 20 17C20 17.5523 19.5523 18 19 18H15C14.4477 18 14 18.4477 14 19C14 19.5523 13.5523 20 13 20H11H9H7C6.44772 20 6 19.5523 6 19C6 18.4477 5.55228 18 5 18H1C0.447715 18 0 17.5523 0 17ZM16 15H13.7938L18 10.0427V13C18 14.1046 17.1046 15 16 15ZM16 3C16.4172 3 16.8046 3.12774 17.1251 3.34625C17.1146 3.35823 17.1041 3.37033 17.0937 3.38256L13.0107 8.19468C12.9433 8.11189 12.8608 8.03801 12.7633 7.97705L9.53 5.95625C8.86395 5.53997 8 6.01881 8 6.80425V10.882C8 11.6253 8.78231 12.1088 9.44721 11.7764L10.3581 11.3209L8.65156 13.3322L4.75177 9.50209C4.00242 8.76613 2.90716 8.59827 2 8.9937V5C2 3.89543 2.89543 3 4 3H16ZM20.1438 5.97047C20.6797 5.33878 20.6021 4.39221 19.9705 3.85624C19.3388 3.32026 18.3922 3.39785 17.8562 4.02953L8.71821 14.7993L4.05106 10.2155C3.46002 9.63506 2.51031 9.64361 1.92983 10.2347C1.34934 10.8257 1.3579 11.7754 1.94894 12.3559L7.76712 18.0702C8.06447 18.3622 8.46991 18.5174 8.88625 18.4985C9.3026 18.4795 9.6923 18.2883 9.96195 17.9705L20.1438 5.97047Z"
					fillRule="evenodd"
				/>
			)
		case "case":
			return (
				<path
					clipRule="evenodd"
					d="M0 3C0 1.34315 1.34315 0 3 0H17C18.6569 0 20 1.34315 20 3V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17V3ZM3 5C3 3.89543 3.89543 3 5 3H7C8.10457 3 9 3.89543 9 5V7C9 8.10457 8.10457 9 7 9H5C3.89543 9 3 8.10457 3 7V5ZM13 3C11.8954 3 11 3.89543 11 5V7C11 8.10457 11.8954 9 13 9H15C16.1046 9 17 8.10457 17 7V5C17 3.89543 16.1046 3 15 3H13ZM11 13C11 11.8954 11.8954 11 13 11H15C16.1046 11 17 11.8954 17 13V15C17 16.1046 16.1046 17 15 17H13C11.8954 17 11 16.1046 11 15V13ZM5 11C3.89543 11 3 11.8954 3 13V15C3 16.1046 3.89543 17 5 17H7C8.10457 17 9 16.1046 9 15V13C9 11.8954 8.10457 11 7 11H5Z"
					fillRule="evenodd"
				/>
			)
		case "quiz":
			return (
				<path
					clipRule="evenodd"
					d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.895431 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.895431 19.1046 0 18 0H2ZM8.71971 12.574C8.68454 13.4447 9.40982 14.0792 10.1934 13.992C10.9771 13.9048 11.4938 13.1097 11.6954 12.2671C11.8805 11.4932 12.3066 10.8499 13.0275 10.2635C14.3456 9.20399 15.1503 7.90547 14.9765 5.99207C14.7176 3.14233 12.4553 1.71626 9.48275 2.04698C7.29373 2.29052 5.52127 3.50134 5.03365 5.66875C4.82406 6.60036 5.62786 7.32826 6.49101 7.23223C7.18958 7.15451 7.68575 6.49263 8.15192 5.87077C8.2376 5.75648 8.32226 5.64354 8.40697 5.53583C8.73247 5.122 9.20686 4.87397 9.71925 4.81696C10.7301 4.7045 11.6128 5.34649 11.7156 6.47824C11.8125 7.54486 11.1655 8.33237 10.3022 9.06991C9.16385 10.038 8.7746 11.2151 8.71971 12.574ZM11.9614 16.55C11.9614 17.5165 11.1779 18.3 10.2114 18.3C9.24493 18.3 8.46143 17.5165 8.46143 16.55C8.46143 15.5836 9.24493 14.8 10.2114 14.8C11.1779 14.8 11.9614 15.5836 11.9614 16.55Z"
					fillRule="evenodd"
				/>
			)
		case "patient":
			return (
				<path
					clipRule="evenodd"
					d="M3 0C1.34315 0 0 1.34315 0 3V17C0 18.6569 1.34315 20 3 20H17C18.6569 20 20 18.6569 20 17V3C20 1.34315 18.6569 0 17 0H3ZM10.0003 2C7.16998 2 5.79267 3.83265 5.46696 4.67949C5.36783 4.93723 5.07854 5.0658 4.8208 4.96667C4.56306 4.86754 4.43449 4.57825 4.53362 4.32051C4.97714 3.16735 6.67676 1 10.0003 1C13.3238 1 15.0234 3.16735 15.467 4.32051C15.5661 4.57825 15.4375 4.86754 15.1798 4.96667C14.922 5.0658 14.6327 4.93723 14.5336 4.67949C14.2079 3.83265 12.8306 2 10.0003 2ZM7.41138 4.78461C7.51682 4.63231 8.25269 4 10.0003 4C11.7479 4 12.4838 4.63231 12.5892 4.78461C12.7464 5.01165 13.0579 5.06828 13.2849 4.9111C13.5119 4.75391 13.5686 4.44244 13.4114 4.21539C13.0553 3.70102 11.945 3 10.0003 3C8.05558 3 6.9453 3.70102 6.58919 4.21539C6.43201 4.44244 6.48864 4.75391 6.71568 4.9111C6.94273 5.06828 7.2542 5.01165 7.41138 4.78461ZM8.46302 17.4412L9.28737 18.2775C9.67902 18.6748 10.3201 18.6748 10.7117 18.2775L11.5361 17.4412C12.0894 16.8799 12.3995 16.1234 12.3995 15.3352V11.5496C12.3995 10.6814 13.1952 10.031 14.0461 10.2036C14.7924 10.3551 15.5194 9.8704 15.6667 9.12329L15.7928 8.4837C15.9221 7.82747 15.7085 7.15545 15.1443 6.79623C13.9346 6.02599 11.7985 5 9.99954 5C8.22101 5 6.22282 6.00282 4.98166 6.76988C4.36351 7.15191 4.08863 7.88667 4.22918 8.59962L4.33242 9.12329C4.47971 9.87041 5.20673 10.3551 5.95301 10.2036C6.80393 10.031 7.59954 10.6814 7.59954 11.5496V15.3352C7.59954 16.1234 7.90972 16.8799 8.46302 17.4412Z"
					fillRule="evenodd"
				/>
			)

		case "media":
			return (
				<path
					clipRule="evenodd"
					d="M0 3C0 1.34315 1.34315 0 3 0H17C18.6569 0 20 1.34315 20 3V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17V3ZM4.84415 6.84231L2.3555 15.7304C2.17695 16.368 2.65628 17 3.31846 17H10H17.1683C17.9595 17 18.4373 16.1248 18.0095 15.4592L15.127 10.9754C14.7967 10.4616 14.087 10.3613 13.6274 10.7636L11.4315 12.6849C10.9522 13.1043 10.2082 12.9747 9.89887 12.418L6.68127 6.6263C6.24737 5.84527 5.08506 5.98194 4.84415 6.84231ZM13 7C14.1046 7 15 6.10457 15 5C15 3.89543 14.1046 3 13 3C11.8954 3 11 3.89543 11 5C11 6.10457 11.8954 7 13 7Z"
					fillRule="evenodd"
				/>
			)
		case "user":
			return (
				<path
					clipRule="evenodd"
					d="M10 10C12.7614 10 15 7.76142 15 5C15 2.23858 12.7614 0 10 0C7.23858 0 5 2.23858 5 5C5 7.76142 7.23858 10 10 10ZM0 17V16.2321C0 14.9073 0.869004 13.7393 2.13796 13.3586L5.68978 12.2931C8.50136 11.4496 11.4986 11.4496 14.3102 12.2931L17.862 13.3586C19.131 13.7393 20 14.9073 20 16.2321V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17Z"
					fillRule="evenodd"
				/>
			)
		case "config":
			return (
				<path
					clipRule="evenodd"
					d="M9 0C8.44771 0 8 0.447715 8 1V2.56877C8 2.99658 7.71288 3.36825 7.31776 3.53229V3.53229C6.9225 3.6964 6.46228 3.63386 6.15966 3.33123L5.05025 2.22183C4.65973 1.8313 4.02656 1.8313 3.63604 2.22183L2.22183 3.63604C1.8313 4.02656 1.8313 4.65973 2.22183 5.05025L3.33123 6.15966C3.63386 6.46228 3.6964 6.9225 3.53229 7.31776V7.31776C3.36825 7.71288 2.99658 8 2.56876 8H1C0.447715 8 0 8.44771 0 9V11C0 11.5523 0.447715 12 1 12H2.56875C2.99657 12 3.36825 12.2871 3.5323 12.6823V12.6823C3.69641 13.0775 3.63387 13.5378 3.33124 13.8404L2.22171 14.9499C1.83119 15.3404 1.83119 15.9736 2.22171 16.3641L3.63592 17.7783C4.02645 18.1689 4.65961 18.1689 5.05014 17.7783L6.15971 16.6688C6.46233 16.3662 6.92253 16.3036 7.31778 16.4677V16.4677C7.71288 16.6318 8 17.0034 8 17.4312V19C8 19.5523 8.44771 20 9 20H11C11.5523 20 12 19.5523 12 19V17.4312C12 17.0034 12.2871 16.6317 12.6822 16.4677V16.4677C13.0775 16.3036 13.5377 16.3661 13.8403 16.6688L14.9497 17.7782C15.3403 18.1687 15.9734 18.1687 16.364 17.7782L17.7782 16.364C18.1687 15.9734 18.1687 15.3403 17.7782 14.9497L16.6688 13.8403C16.3661 13.5377 16.3036 13.0775 16.4677 12.6822V12.6822C16.6317 12.2871 17.0034 12 17.4312 12H19C19.5523 12 20 11.5523 20 11V9C20 8.44771 19.5523 8 19 8H17.4312C17.0034 8 16.6318 7.71288 16.4677 7.31778V7.31778C16.3036 6.92253 16.3662 6.46233 16.6688 6.15971L17.7781 5.05043C18.1686 4.6599 18.1686 4.02674 17.7781 3.63621L16.3638 2.222C15.9733 1.83148 15.3402 1.83148 14.9496 2.222L13.8404 3.33124C13.5378 3.63387 13.0775 3.69641 12.6823 3.5323V3.5323C12.2871 3.36825 12 2.99657 12 2.56875V1C12 0.447715 11.5523 0 11 0H9ZM10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14Z"
					fillRule="evenodd"
				/>
			)

		default:
			return (
				<path
					clipRule="evenodd"
					d="M0 1C0 0.447715 0.447715 0 1 0H19C19.5523 0 20 0.447715 20 1C20 1.55228 19.5523 2 19 2H1C0.447715 2 0 1.55228 0 1ZM0 17C0 16.4477 0.447715 16 1 16H4.23218L7.06641 18.7836C7.56199 19.2703 8.23773 19.5289 8.93163 19.4974C9.62554 19.4659 10.2751 19.1471 10.7245 18.6174L12.9453 16H19C19.5523 16 20 16.4477 20 17C20 17.5523 19.5523 18 19 18H15C14.4477 18 14 18.4477 14 19C14 19.5523 13.5523 20 13 20H11H9H7C6.44772 20 6 19.5523 6 19C6 18.4477 5.55228 18 5 18H1C0.447715 18 0 17.5523 0 17ZM16 15H13.7938L18 10.0427V13C18 14.1046 17.1046 15 16 15ZM16 3C16.4172 3 16.8046 3.12774 17.1251 3.34625C17.1146 3.35823 17.1041 3.37033 17.0937 3.38256L13.0107 8.19468C12.9433 8.11189 12.8608 8.03801 12.7633 7.97705L9.53 5.95625C8.86395 5.53997 8 6.01881 8 6.80425V10.882C8 11.6253 8.78231 12.1088 9.44721 11.7764L10.3581 11.3209L8.65156 13.3322L4.75177 9.50209C4.00242 8.76613 2.90716 8.59827 2 8.9937V5C2 3.89543 2.89543 3 4 3H16ZM20.1438 5.97047C20.6797 5.33878 20.6021 4.39221 19.9705 3.85624C19.3388 3.32026 18.3922 3.39785 17.8562 4.02953L8.71821 14.7993L4.05106 10.2155C3.46002 9.63506 2.51031 9.64361 1.92983 10.2347C1.34934 10.8257 1.3579 11.7754 1.94894 12.3559L7.76712 18.0702C8.06447 18.3622 8.46991 18.5174 8.88625 18.4985C9.3026 18.4795 9.6923 18.2883 9.96195 17.9705L20.1438 5.97047Z"
					fillRule="evenodd"
				/>
			)
	}
}
