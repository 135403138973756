import { useTranslation } from "react-i18next"

const NotFound = () => {
	const { t } = useTranslation()

	return (
		<div className="flex items-center justify-center flex-col h-screen">
			<h4 className="mb-4">{t("404")}</h4>
			<a
				className="text-s-blue flex flex-row items-center space-x-2 cursor-pointer"
				href="./"
			>
				<svg
					className="h-6 w-6"
					fill="none"
					stroke="currentColor"
					stroke-width="2"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>

				<p className="text-lg font-medium">SonoFun Live!</p>
			</a>
		</div>
	)
}

export default NotFound
