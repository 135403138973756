import { Canvas } from "@react-three/fiber"
import { CSSProperties, Suspense, useRef } from "react"
import { Mesh, NoToneMapping, sRGBEncoding } from "three"
import BodyModel from "../../assets/models/body"
import { VectorTuple } from "../../utils/vector"
import Environment from "../environment/environment"
import Controller from "../models/controller"
import SelectPath from "../models/selectPath"

interface PatientLocationProps {
	start: VectorTuple
	end: VectorTuple
	style?: CSSProperties
	showPath?: boolean
	allowRotation?: boolean
}

const PatientLocation = ({
	start = [0, 0, 0],
	end = [0, 0, 0],
	style,
	showPath = true,
	allowRotation = true,
}: PatientLocationProps) => {
	const body = useRef<Mesh>(null)

	return (
		<Canvas
			camera={{
				fov: 30,
				position: [-0.8, 1.4, 0.4],
				rotation: [-0.96, -Math.PI / 6, -0.72],
			}}
			dpr={1}
			style={style}
			onCreated={({ gl }) => {
				gl.toneMapping = NoToneMapping
				gl.outputEncoding = sRGBEncoding
			}}
		>
			<Suspense fallback={null}>
				<Environment />

				<BodyModel ref={body} />

				<Controller
					allowRotation={allowRotation}
					body={body}
					end={end}
					start={start}
				/>

				{showPath && <SelectPath body={body} end={end} start={start} />}
			</Suspense>
		</Canvas>
	)
}

export default PatientLocation
