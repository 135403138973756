import useSWR from "swr"
import LoadingBlue from "../../assets/images/loadingBlue.gif"
import { fetchApiAuthorized } from "../../network/fetch"
import { AuthData } from "../../state/stores/authStore"
import { useCommunicationStore } from "../../state/stores/communicationStore"
import Controls from "../controls/controls"
import MediaStream from "../video/mediaStream"

interface MiniCamProps {
	isModerator: boolean
	auth: AuthData
}

const MiniCam = ({ isModerator = false, auth }: MiniCamProps) => {
	const participants = useCommunicationStore((state) => state.participants)
	const connected = useCommunicationStore((state) => state.connected)

	const { data } = useSWR(
		["/users/current", auth.token, auth.expiresIn],
		fetchApiAuthorized
	)
	const role = data && data.role

	const adminVideo = participants["admin"]
	const moderatorVideo = participants["moderator"]

	return (
		<div
			className={`${
				isModerator ? "mb-2.5" : ""
			} shadow-md rounded-xl relative h-0 overflow-hidden pb-[56.25%] bg-white `}
		>
			<div className="flex flex-row absolute inset-0 bg-slate-100 items-center justify-center">
				{adminVideo !== undefined && (
					<MediaStream
						isOwner={role === "admin"}
						key={"admin"}
						participant={adminVideo}
					/>
				)}

				{moderatorVideo !== undefined && (
					<MediaStream
						isOwner={role === "moderator"}
						key={"moderator"}
						participant={moderatorVideo}
					/>
				)}

				{!connected && <img alt="Loading" src={LoadingBlue} width="80px" />}
			</div>

			{isModerator && (
				<div
					className="absolute left-2 bottom-2 right-2 z-10"
					style={{ opacity: connected ? 1 : 0 }}
				>
					<div className="flex flex-row items-end justify-between">
						<Controls auth={auth} isModerator={isModerator} />
					</div>
				</div>
			)}
		</div>
	)
}

export default MiniCam
