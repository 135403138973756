import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import useSWR from "swr"
import DashboardBody from "../../../components/dashboardBody/dashboardBody"
import DashboardHeader from "../../../components/dashboardHeader/dashboardHeader"
import ListView from "../../../components/list/list"
import { HasAuth } from "../../../components/privateRoute/privateRoute"
import { fetchApiAuthorized } from "../../../network/fetch"
import { useErrorStore } from "../../../state/stores/errorStore"

const Cases = ({ auth }: HasAuth) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { data: cases, mutate } = useSWR(
		["/cases", auth.token, auth.expiresIn],
		fetchApiAuthorized
	)

	const deletionConfirmation = useErrorStore(
		(state) => state.deleteConfirmation
	)

	return (
		<DashboardBody>
			<DashboardHeader
				buttonOnClick={() => navigate("/moderator/case/create")}
				buttonTitle={t("create_case") as string}
				title={t("cases") as string}
			/>

			<ListView
				data={cases}
				loading={cases === undefined}
				title="name"
				onDelete={(v) => {
					deletionConfirmation(async () => {
						await fetchApiAuthorized(
							`/cases/${v.id}`,
							auth.token,
							auth.expiresIn,
							undefined,
							"DELETE"
						)

						mutate()
					})
				}}
				onDublicate={(v) => navigate(`/moderator/case/create?copy=${v.id}`)}
				onEdit={(v) => navigate(`/moderator/case/${v.id}`)}
			/>
		</DashboardBody>
	)
}

export default Cases
