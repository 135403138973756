import { Canvas } from "@react-three/fiber"
import {
	CSSProperties,
	MutableRefObject,
	Ref,
	Suspense,
	useEffect,
	useState,
} from "react"
import AmiContext from "src/components/ami/context"
import FrameLimiter from "src/components/frameLimiter/frameLimiter"
import Loading from "src/components/patient/user/loading"
import { usePatientStore } from "src/state/stores/patientStore"
import { Clock, Vector3 } from "three"

const AmiView = ({
	container,
	volumeUrl,
	zoom,
	clock,
	externalData,
	style,
	mirrorLeftRight,
	mirrorFrontBack,
}: {
	container: Ref<HTMLDivElement>
	volumeUrl: string
	zoom: MutableRefObject<number>
	clock?: MutableRefObject<Clock>
	externalData?: MutableRefObject<number[]>
	style?: CSSProperties
	mirrorLeftRight: boolean
	mirrorFrontBack: boolean
}) => {
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() =>
		usePatientStore.subscribe((state) => {
			if (state.mode === "done") setIsLoading(false)
		})
	)

	return (
		<>
			<Canvas
				camera={{ fov: 45, near: 0.01, far: 1000, up: new Vector3(0, -1, 0) }}
				dpr={1}
				frameloop="demand"
				style={{
					width: "100%",
					height: "100%",
					borderRadius: "16px",
					overflow: "hidden",
					...style,
				}}
				onCreated={(state) => state.gl.setClearColor("black")}
			>
				<Suspense fallback={null}>
					<AmiContext
						clock={clock}
						container={container}
						externalData={externalData}
						mirroredFrontBack={mirrorFrontBack}
						mirroredLeftRight={mirrorLeftRight}
						volumeUrl={volumeUrl}
						zoom={zoom}
					/>

					<FrameLimiter fps={30} />
					{/* <OrbitControls /> */}
				</Suspense>
			</Canvas>
			{isLoading && <Loading />}
		</>
	)
}

export default AmiView
