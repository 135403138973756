import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSettingsStore } from "../../state/stores/settingsStore"
import CameraSettings from "../cameraSettings/cameraSettings"

const CameraAuth = () => {
	const { t } = useTranslation()

	const [showPermissionOverlay, setShowPermissionOverlay] = useState(false)
	const [hasPermissions, setHasPermissions] = useState(false)

	const updateDevices = useSettingsStore((state) => state.updateIO)

	const checkPermission = useCallback(async () => {
		let hasPermissions = false
		const devices = await navigator.mediaDevices.enumerateDevices()
		devices.forEach((device) => {
			if (device.label && device.label.length > 0) {
				hasPermissions = true
			}
		})

		setHasPermissions(hasPermissions)
		setShowPermissionOverlay(!hasPermissions)

		if (hasPermissions) {
			updateDevices(devices)
		}
	}, [updateDevices])

	const askForPermission = useCallback(async () => {
		await navigator.mediaDevices.getUserMedia({ audio: true, video: false })

		checkPermission()
	}, [checkPermission])

	useEffect(() => {
		checkPermission()

		askForPermission()
	}, [checkPermission, askForPermission])

	return (
		<>
			{showPermissionOverlay && (
				<div className="bg-s-overlay-bg absolute top-0 left-0 right-0 bottom-0 z-10 flex items-center justify-center">
					<div className="flex flex-col items-start space-y-2.5 shadow-md bg-white p-4 rounded-lg w-full md:max-w-lg mx-5 md:mx-0">
						<h3>{t("camera_permission_1")}</h3>
						<p>{t("camera_permission_2")}</p>
						<p>
							{t("camera_permission_3")}
							<a
								href="https://help.doxy.me/en/articles/95877-allow-access-to-camera-mic-for-all-browsers-operating-systems"
								rel="noopener noreferrer"
								target="_blank"
							>
								{t("camera_permission_4")}
							</a>
						</p>
					</div>
				</div>
			)}

			{hasPermissions && (
				<>
					<h2 className="mt-20 mb-5">{t("camera_settings")}</h2>
					<CameraSettings camera={false} />
				</>
			)}
		</>
	)
}

export default CameraAuth
