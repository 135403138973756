import create from "zustand"
import {
	DeletionConfimationAlert,
	RestartServerAlert,
} from "../../components/alert/deletionConfirmationAlert"
import { GlobalHistory } from "../../utils/history"
import i18n from "../../utils/translation"

export class CustomError {
	title: string
	message: string
	component?: any
	destructive: boolean

	constructor(
		title?: string,
		message?: string,
		component?: any,
		destructive?: boolean
	) {
		this.title = title ? title : "Error"
		this.message = message ? message : "Unknown error"
		this.component = component
		this.destructive = destructive ?? false
	}
}

interface ErrorStore extends Record<string, unknown> {
	error?: CustomError

	update: (error: Error) => void
	show: (title?: string, message?: string, component?: any) => void
	deleteConfirmation: (onDelete: () => void, message?: string) => void
	restartServerConfirmation: (onYes: () => void) => void
	clear: () => void
}

export const useErrorStore = create<ErrorStore>((set, get) => {
	return {
		error: undefined,

		update: (error: Error) => {
			console.error(error.name, error.message)
			set({ error: new CustomError(error.name, error.message) })

			if (error.message === "Webinar doesnt exists") {
				GlobalHistory.replace("/")
			}
		},

		show: (title, message, component) => {
			set({ error: new CustomError(title, message, component) })
		},

		deleteConfirmation: (onDelete: () => void, message?: string) => {
			const { clear } = get()

			set({
				error: new CustomError(
					i18n.t("atention"),
					message ? message : i18n.t("confirm_deletion"),
					<DeletionConfimationAlert onClear={clear} onDelete={onDelete} />,
					true
				),
			})
		},

		restartServerConfirmation: (onYes: () => void) => {
			const { clear } = get()

			set({
				error: new CustomError(
					i18n.t("restart_server_title"),
					i18n.t("restart_server_description"),
					<RestartServerAlert onClear={clear} onDelete={onYes} />,
					true
				),
			})
		},

		clear: () => {
			set({ error: undefined })
		},
	}
})
