import create from "zustand"

interface MediaStore extends Record<string, unknown> {
	isPlaying: boolean
	time: number

	onMedia: (data: Record<string, unknown>) => void
	reset: () => void
}

export const useMediaStore = create<MediaStore>((set, get) => {
	return {
		isPlaying: false,
		time: 0,

		onMedia: (data) => {
			if (data.isPlaying !== undefined) {
				set({
					isPlaying: data.isPlaying as boolean,
				})
			}

			if (data.time !== undefined) {
				set({
					time: data.time as number,
				})
			}
		},

		reset: () => {
			set({ isPlaying: false, time: 0 })
		},
	}
})
