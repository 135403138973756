import create from "zustand"

interface FullscreenStore extends Record<string, unknown> {
	container: HTMLDivElement | undefined
	setContainer: (container: HTMLDivElement) => void
	isFullscreen: boolean
	setIsFullscreen: (isFullscreen: boolean) => void
	toggle: () => void
}

declare global {
	interface Document {
		mozCancelFullScreen?: () => Promise<void>
		msExitFullscreen?: () => Promise<void>
		webkitExitFullscreen?: () => Promise<void>
		mozFullScreenElement?: Element
		msFullscreenElement?: Element
		webkitFullscreenElement?: Element
	}

	interface HTMLElement {
		msRequestFullscreen?: () => Promise<void>
		mozRequestFullscreen?: () => Promise<void>
		webkitRequestFullscreen?: () => Promise<void>
	}
}

export const useFullscreen = create<FullscreenStore>((set, get) => {
	return {
		container: undefined,
		setContainer: (container) => {
			set({ container })
		},

		isFullscreen: false,
		setIsFullscreen: (isFullscreen) => {
			set({ isFullscreen })
		},

		toggle: () => {
			const { container, isFullscreen } = get()

			if (container) {
				if (isFullscreen) {
					if (document.exitFullscreen) {
						document.exitFullscreen()
					} else if (document.webkitExitFullscreen) {
						document.webkitExitFullscreen()
					}
				} else {
					if (container.requestFullscreen) {
						container.requestFullscreen()
					} else if (container.webkitRequestFullscreen) {
						container.webkitRequestFullscreen()
					}
				}
			}
		},
	}
})
