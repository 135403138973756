import { useTranslation } from "react-i18next"
import Button, { ButtonTheme } from "src/components/button/button"
import DE from "../../assets/images/de.png"
import EN from "../../assets/images/en.png"

const LanguageButton = ({ className }: { className?: string }) => {
	const { i18n } = useTranslation()
	const { language } = i18n

	const changeLanguage = () => {
		const newLanguage = language === "de" ? "en" : "de"
		localStorage.setItem("language", newLanguage)
		i18n.changeLanguage(newLanguage)
	}

	return (
		<Button
			className={`mr-2.5 ${className}`}
			theme={ButtonTheme.White}
			onClick={changeLanguage}
		>
			<img
				alt={language === "en" ? "Deutsch" : "English"}
				className="h-4"
				src={language === "en" ? DE : EN}
			/>
		</Button>
	)
}

export default LanguageButton
