import create from "zustand"

export enum showSettingsValue {
	user,
	moderator,
	none,
}

interface cameraSettingsStore extends Record<string, unknown> {
	showSettings: showSettingsValue
}

export const useCameraSettingsStore = create<cameraSettingsStore>(
	(set, get) => {
		return {
			showSettings: showSettingsValue.none,
		}
	}
)
