import { useModeratorStore } from "../../state/stores/moderatorStore"
import Participant from "../participant/participant"

interface ParticipantsProps {
	canMuteParticipants?: boolean
	selfUserId?: string
}

const Participants = ({
	canMuteParticipants = false,
	selfUserId = undefined,
}: ParticipantsProps) => {
	const users = useModeratorStore((state) => state.users)

	const singleUsers = [...new Set(users.map((user) => user.email))]
		.map((email) => users.filter((user) => user.email === email)[0])
		.filter((user) => user._id !== selfUserId)

	return (
		<div
			className="grid gap-2 flex-1 p-8"
			style={{
				gridTemplateColumns: new Array(Math.min(singleUsers.length, 4))
					.fill(0)
					.map(() => "1fr ")
					.join(""),
			}}
		>
			{singleUsers.map((v, i) => (
				<Participant
					data={v}
					key={v.id as string}
					mutable={canMuteParticipants}
				/>
			))}
		</div>
	)
}

export default Participants
