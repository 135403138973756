interface ListSwitchProps {
	showAll: boolean
	setShowAll: (showAll: boolean) => void
}
const ListSwitch = ({ showAll, setShowAll }: ListSwitchProps) => {
	return (
		<div className="flex flex-row relative py-1.5 space-x-3">
			<svg
				className={`w-6 h-6 z-[2] mr-0 cursor-pointer transition-all`}
				fill={showAll ? "white" : "#2A2F35"}
				height="30"
				viewBox="0 0 30 30"
				width="30"
				xmlns="http://www.w3.org/2000/svg"
				onClick={() => setShowAll(true)}
			>
				<path
					clipRule="evenodd"
					d="M3 24C3 22.8954 3.89543 22 5 22H25.0084C26.113 22 27.0084 22.8954 27.0084 24C27.0084 25.1046 26.113 26 25.0084 26H5C3.89543 26 3 25.1046 3 24Z"
					fillRule="evenodd"
				/>
				<path
					clipRule="evenodd"
					d="M3 15C3 13.8954 3.89543 13 5 13H25.0084C26.113 13 27.0084 13.8954 27.0084 15C27.0084 16.1046 26.113 17 25.0084 17H5C3.89543 17 3 16.1046 3 15Z"
					fillRule="evenodd"
				/>
				<path
					clipRule="evenodd"
					d="M3 6C3 4.89543 3.89543 4 5 4H25.0084C26.113 4 27.0084 4.89543 27.0084 6C27.0084 7.10457 26.113 8 25.0084 8H5C3.89543 8 3 7.10457 3 6Z"
					fillRule="evenodd"
				/>
			</svg>

			<svg
				className={`w-6 h-6 z-[2] mr-0 cursor-pointer transition-all`}
				fill={!showAll ? "white" : "#2A2F35"}
				height="30"
				viewBox="0 0 30 30"
				width="30"
				xmlns="http://www.w3.org/2000/svg"
				onClick={() => setShowAll(false)}
			>
				<path
					clipRule="evenodd"
					d="M3 6C3 4.34315 4.34315 3 6 3H24C25.6569 3 27 4.34315 27 6V24C27 25.6569 25.6569 27 24 27H6C4.34315 27 3 25.6569 3 24V6ZM6.6 8.6C6.6 7.49543 7.49543 6.6 8.6 6.6H11.8C12.9046 6.6 13.8 7.49543 13.8 8.6V11.8C13.8 12.9046 12.9046 13.8 11.8 13.8H8.6C7.49543 13.8 6.6 12.9046 6.6 11.8V8.6ZM18.2 6.6C17.0954 6.6 16.2 7.49543 16.2 8.6V11.8C16.2 12.9046 17.0954 13.8 18.2 13.8H21.4C22.5046 13.8 23.4 12.9046 23.4 11.8V8.6C23.4 7.49543 22.5046 6.6 21.4 6.6H18.2ZM16.2 18.2C16.2 17.0954 17.0954 16.2 18.2 16.2H21.4C22.5046 16.2 23.4 17.0954 23.4 18.2V21.4C23.4 22.5046 22.5046 23.4 21.4 23.4H18.2C17.0954 23.4 16.2 22.5046 16.2 21.4V18.2ZM8.6 16.2C7.49543 16.2 6.6 17.0954 6.6 18.2V21.4C6.6 22.5046 7.49543 23.4 8.6 23.4H11.8C12.9046 23.4 13.8 22.5046 13.8 21.4V18.2C13.8 17.0954 12.9046 16.2 11.8 16.2H8.6Z"
					fillRule="evenodd"
				/>
			</svg>

			<div
				className={`bg-s-blue absolute top-0 bottom-0 w-9 z-[1] rounded-lg transition-all ${
					showAll ? "-left-[18px]" : "left-[18px]"
				}`}
			/>
		</div>
	)
}

export default ListSwitch
