import { useTranslation } from "react-i18next"
import Body from "../components/body/body"
import Check from "./practinar/tutorial/check"

const HardwareTest = () => {
	const { t } = useTranslation()
	return (
		<Body showFullscreen={false}>
			<div className="flex flex-col flex-1 justify-center items-center overflow-scroll transition-all duration-300 ease-in-out w-full">
				<div className="flex flex-col items-start mb-10 max-w-[600px]">
					<h2 className="mb-2.5">{t("hardware_test")}</h2>
					<h5>{t("hardware_test_text")}</h5>
				</div>
				<Check showHeader={false} onCheck={() => {}} />
			</div>
		</Body>
	)
}

export default HardwareTest
