import { Cookies } from "react-cookie"
import { CookieSetOptions } from "universal-cookie"
import { GetState, SetState, StateCreator, StoreApi } from "zustand"

const persistCookie =
	<T extends Record<string, unknown>>(config: StateCreator<T>) =>
	(set: SetState<T>, get: GetState<T>, api: StoreApi<T>, mutations: []): T => {
		const state = config(
			(payload) => {
				set(payload)

				if (typeof payload === "object") {
					Object.entries(payload).forEach(([key, value]) => {
						const config: CookieSetOptions = {
							expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7),
							path: "/",
							secure: process.env.NODE_ENV === "production",
							domain:
								process.env.NODE_ENV === "production" ? ".sonofun.live" : "",
							sameSite: "lax",
						}

						if (value === undefined) {
							new Cookies().remove(key, config)
						} else {
							new Cookies().set(key, value, config)
						}
					})
				}
			},
			get,
			api,
			mutations
		)

		return {
			...state,
			...new Cookies().getAll(),
		}
	}

export default persistCookie
