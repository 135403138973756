import moment from "moment"
import "moment/locale/de"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useLocation } from "react-use"
import Button, { ButtonTheme } from "src/components/button/button"
import { ListContainer, ListRow } from "src/components/list/list"
import useSWR from "swr"
import TrashIcon from "../../../assets/images/trash.svg"
import DashboardBody from "../../../components/dashboardBody/dashboardBody"
import DashboardHeader from "../../../components/dashboardHeader/dashboardHeader"
import { HasAuth } from "../../../components/privateRoute/privateRoute"
import { fetchApiAuthorized } from "../../../network/fetch"
import { useErrorStore } from "../../../state/stores/errorStore"

const Webinars = ({ auth }: HasAuth) => {
	const { pathname } = useLocation()
	const isUpcoming = pathname && pathname.includes("upcoming")

	const { data: webinars, mutate } = useSWR(
		[
			isUpcoming ? "/webinars/upcoming" : "/webinars/completed",
			auth.token,
			auth.expiresIn,
		],
		fetchApiAuthorized
	)
	const uniqueYears = webinars
		? Array.from(
				new Set(
					webinars.map(
						(webinar: Record<string, any>) =>
							(webinar.date as string).split(".")[0]
					)
				)
		  )
		: []
	const yearIndices = webinars
		? uniqueYears.map((year) =>
				(webinars as Record<string, any>[]).findIndex(
					(v) => v.date.split(".")[0] === year
				)
		  )
		: []

	const navigate = useNavigate()
	const { t } = useTranslation()

	moment().locale("de")

	const deletionConfirmation = useErrorStore(
		(state) => state.deleteConfirmation
	)

	return (
		<DashboardBody>
			<DashboardHeader
				buttonOnClick={() =>
					isUpcoming ? navigate("/moderator/webinar/create") : undefined
				}
				buttonTitle="Practinar Erstellen"
				title={
					isUpcoming
						? (t("webinars_upcoming") as string)
						: (t("webinars_completed") as string)
				}
			/>

			<ListContainer className="pt-0 px-0">
				{webinars === undefined
					? "Loading…"
					: webinars.map((c: Record<string, unknown>, y: number) => (
							<Fragment key={c.id as string}>
								{yearIndices.includes(y) && (
									<h3 className="bg-white border-b border-b-slate-200 py-3 px-6 sticky top-20 z-40">
										{new Date(
											parseInt((c.date as string).split(".")[0]),
											1
										).toLocaleString("de", { year: "numeric" })}
									</h3>
								)}
								<div
									className="flex flex-col space-y-4 px-6"
									key={c.month as string}
								>
									<h3>
										{new Date(
											2020,
											parseInt((c.date as string).split(".")[1]) - 1
										).toLocaleString("de", { month: "long" })}
									</h3>
									{(c.data as Record<string, unknown>[]).map(
										(v: Record<string, unknown>, i: number) => (
											<ListRow
												className="mb-5"
												key={v.id as string}
												showDivider={
													i < (c.data as Record<string, unknown>[]).length - 1
												}
											>
												<div className="flex flex-row space-x-5">
													<>
														<p>
															{moment(v.date as string).format(
																"DoMoYYYY, HH:mm"
															)}
															h
														</p>
														{v.title && (
															<p className="text-s-blue">{v.title as string}</p>
														)}
													</>
												</div>
												<div className="flex flex-row space-x-5">
													{isUpcoming && (
														<div className="flex flex-row">
															<Button
																theme={
																	moment(v.date as string).isSame(moment(), "d")
																		? ButtonTheme.Blue
																		: ButtonTheme.White
																}
																onClick={() =>
																	navigate(`/moderator/webinar/live/${v.id}`)
																}
															>
																{t("start_webinar")}
															</Button>
														</div>
													)}
													<Button
														theme={ButtonTheme.White}
														onClick={() =>
															navigate(`/moderator/webinar/create?copy=${v.id}`)
														}
													>
														Duplizieren
													</Button>
													<Button
														theme={ButtonTheme.White}
														onClick={() =>
															window.open(
																`/moderator/webinar/schedule/${v.id}`,
																"_blank"
															)
														}
													>
														Programm
													</Button>
													<img
														alt="Delete"
														className="cursor-pointer"
														src={TrashIcon}
														// TODO: Error kram
														onClick={() =>
															deletionConfirmation(async () => {
																await fetchApiAuthorized(
																	`/webinars/${v.id}`,
																	auth.token,
																	auth.expiresIn,
																	undefined,
																	"DELETE"
																)

																mutate()
															})
														}
													/>
													<Button
														theme={ButtonTheme.White}
														onClick={() =>
															navigate(`/moderator/webinar/${v.id}`)
														}
													>
														{t("edit")}
													</Button>
												</div>
											</ListRow>
										)
									)}
								</div>
							</Fragment>
					  ))}
			</ListContainer>
		</DashboardBody>
	)
}

export default Webinars
