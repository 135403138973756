import update from "immutability-helper"
import { parse } from "qs"
import { useCallback, useEffect, useState } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { useLocation } from "react-use"
import Input from "src/components/input/input"
import useSWR from "swr"
import AddSchedule from "../../../../components/addSchedule/addSchedule"
import DashboardHeader from "../../../../components/dashboardHeader/dashboardHeader"
import { HasAuth } from "../../../../components/privateRoute/privateRoute"
import ScheduleItem from "../../../../components/scheduleItem/scheduleItem"
import ScheduleItemMediaView from "../../../../components/scheduleItemView/mediaView"
import ScheduleItemPatientView from "../../../../components/scheduleItemView/patientView"
import ScheduleItemQuizView from "../../../../components/scheduleItemView/quizView"
import useHandleChange from "../../../../hooks/useHandleChange"
import { fetchApiAuthorized } from "../../../../network/fetch"
import { useErrorStore } from "../../../../state/stores/errorStore"
import { formatName } from "../../../../utils/formatName"

const CreateCase = ({ auth }: HasAuth) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { id } = useParams() as Record<string, string>
	const { search } = useLocation()

	const [name, setName] = useHandleChange("")
	const [schedule, setSchedule] = useState<Record<string, unknown>[]>([
		{ id: 1, type: "patient", data: undefined },
		{ id: 2, type: "quiz", data: undefined },
	])

	console.log(id)

	const duplicate = useCallback(
		async (id: string) => {
			const dubCase = await fetchApiAuthorized(
				`/cases/${id}`,
				auth.token,
				auth.expiresIn,
				undefined,
				"GET"
			)

			if (dubCase) {
				setName(dubCase.name ?? "")
				setSchedule(
					dubCase.schedule.map((v: Record<string, unknown>, i: number) => {
						if (v.data) {
							return {
								id: i,
								type: v.type,
								data: (v.data as Record<string, unknown>).id,
							}
						} else {
							return { id: i, type: v.type }
						}
					})
				)
			}
		},
		[auth.expiresIn, auth.token, setName]
	)

	useEffect(() => {
		if (search) {
			const query = parse(search.replace("?", ""))
			if (query.copy) {
				duplicate(query.copy as string)
			}
		}
	}, [search, duplicate])

	const { data: currentCase } = useSWR(
		id ? [`/cases/${id}`, auth.token, auth.expiresIn] : null,
		fetchApiAuthorized
	)

	const [nameBorder, setNameBorder] = useState<string | undefined>(undefined)
	const [missingData, setMissingData] = useState<string[]>([])

	useEffect(() => {
		if (currentCase) {
			if (id) {
				setName(currentCase.name ?? "")
			}
			setSchedule(
				currentCase.schedule.map((v: Record<string, unknown>, i: number) => {
					if (v.data) {
						return {
							id: i,
							type: v.type,
							data: (v.data as Record<string, unknown>).id,
						}
					} else {
						return { id: i, type: v.type }
					}
				})
			)
		}
	}, [currentCase, id, setName, search])

	const movePart = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const dragCard = schedule[dragIndex]
			setSchedule(
				update(schedule, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragCard],
					],
				})
			)
		},
		[schedule]
	)

	const upload = async () => {
		const endpoint = id ? `/cases/${id}` : "/cases/create"

		const success = await fetchApiAuthorized(
			endpoint,
			auth.token,
			auth.expiresIn,
			{ name, schedule },
			id ? "PUT" : "POST"
		)
		if (success) {
			navigate("/moderator/case")
		}
	}

	const check = () => {
		if (
			schedule.length === 0 ||
			schedule.filter((v) => {
				if (["patient", "quiz", "media"].includes(v.type as string)) {
					return v.data === undefined
				} else {
					return false
				}
			}).length > 0
		) {
			if (schedule.length === 0) {
				useErrorStore.getState().update(new Error("fehlender Input"))
			}

			setNameBorder("border-s-red")

			const missingData = schedule
				.filter((v) => {
					if (["patient", "quiz", "media"].includes(v.type as string)) {
						return v.data === undefined
					} else {
						return false
					}
				})
				.map((v) => v.id as string)

			setMissingData(missingData)
		} else {
			upload()
			setNameBorder(undefined)
			setMissingData([])
		}
	}

	return (
		<>
			<DashboardHeader
				buttonOnClick={check}
				buttonTitle="Speichern"
				cancelButtonOnClick={() => navigate("/moderator/case")}
				cancelButtonTitle="Abrechen"
				title="Fall erstellen"
			/>
			<div className="flex flex-col p-4 my-0 mx-1">
				<div className="flex flex-row items-baseline">
					<p className="w-16 mb-7">{t("name")}</p>
					<Input
						className={nameBorder}
						placeholder="Name"
						type="text"
						value={name}
						onChange={setName}
					/>
				</div>

				<p className="mb-2.5">{t("parts")}</p>
				<DndProvider backend={HTML5Backend}>
					{schedule.map((v, i) => {
						let content
						switch (v.type) {
							case "patient":
								content = (
									<ScheduleItemPatientView
										value={v.data as string}
										onChange={(v) => {
											setSchedule((schedule) => {
												schedule[i].data = v
												return [...schedule]
											})
										}}
									/>
								)
								break
							case "quiz":
								content = (
									<ScheduleItemQuizView
										value={v.data as string}
										onChange={(v) => {
											setSchedule((schedule) => {
												schedule[i].data = v
												return [...schedule]
											})
										}}
									/>
								)
								break
							case "media":
								content = (
									<ScheduleItemMediaView
										value={v.data as string}
										onChange={(v) => {
											setSchedule((schedule) => {
												schedule[i].data = v
												return [...schedule]
											})
										}}
									/>
								)
								break

							default:
								break
						}

						return (
							<ScheduleItem
								content={content}
								data={v.data as string}
								deletable={true}
								id={v.id as string}
								index={i}
								indexOffset={1}
								key={v.id as string}
								movable={true}
								move={movePart}
								redBorder={missingData.includes(v.id as string)}
								text={formatName(v.type as string)}
								type={v.type as string}
								onDelete={() => {
									setSchedule((v) => v.filter((_, idx) => idx !== i))
								}}
							/>
						)
					})}
				</DndProvider>
				<AddSchedule
					canAddCase={false}
					onAdd={({ type }: Record<string, unknown>) =>
						setSchedule((v) => [...v, { id: v.length + 1, type }])
					}
				/>
			</div>
		</>
	)
}

export default CreateCase
