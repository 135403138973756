import { useCommunicationStore } from "../../../state/stores/communicationStore";
import Participants from "../../participants/participants";
import UserWaiting from "../../waiting/waiting";

interface PresentationProps {
	practinar: Record<string, unknown> | undefined;
	userId: string;
}

const Presentation = ({ practinar, userId }: PresentationProps) => {
	const room = useCommunicationStore((state) => state.room);

	return (
		<div className="w-full h-full flex flex-1 items-center">
			<img
				src="/presentation.png"
				className="rounded-md"
				width="auto"
				height="100%"
				alt="Presentation"
			/>

			{room ? (
				<Participants selfUserId={userId} />
			) : (
				<UserWaiting
					startDate={
						practinar && practinar.startDate
							? (practinar.startDate as Date)
							: undefined
					}
				/>
			)}
		</div>
	);
};

export default Presentation;
