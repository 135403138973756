import { forwardRef } from "react"
import useSWR from "swr"
import { fetchApiAuthorized } from "../../network/fetch"
import { useAuthStore } from "../../state/stores/authStore"
import { usePreviewStore } from "../../state/stores/previewStore"
import Button from "../button/button"

interface PreviewProps {
	preview: [type: string, data: string]
}

const Preview = forwardRef<HTMLDivElement, PreviewProps>(({ preview }, ref) => {
	const close = usePreviewStore((state) => state.closePreview)

	const token = useAuthStore((state) => state.adminToken)
	const expiresIn = useAuthStore((state) => state.adminExpiresIn)
	const { data } = useSWR(
		[`/${preview[0]}s/${preview[1]}`, token, expiresIn],
		fetchApiAuthorized
	)

	return (
		<div
			className="fixed inset-0 w-full h-full z-50 flex items-center justify-center bg-white bg-opacity-80"
			ref={ref}
		>
			{data && (
				<img
					className="max-w-full max-h-full"
					src={data.url ?? data.imageUrl}
				/>
			)}

			<Button className="absolute right-5 top-5.5" onClick={close}>
				Close
			</Button>
		</div>
	)
})

export default Preview
