import { useCallback, useEffect, useRef, useState } from "react"
import Draggable from "react-draggable"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import Button, { ButtonTheme } from "src/components/button/button"
import useSWR from "swr"
import ResetRotation from "../../assets/images/resetRotation.svg"
import Body from "../../components/body/body"
import { HasAuth } from "../../components/privateRoute/privateRoute"
import { fetchApiAuthorized } from "../../network/fetch"
import { IRole, useSocketStore } from "../../state/stores/socketStore"
import { map_numbers } from "../../utils/math"

const MobileSono = ({ auth }: HasAuth) => {
	const { t } = useTranslation()
	const { code } = useParams() as Record<string, string>

	const { data: practinar } = useSWR(
		[`/webinars/code/${code}`, auth.token, auth.expiresIn],
		fetchApiAuthorized
	)

	const { data: user } = useSWR(
		["/users/current", auth.token, auth.expiresIn],
		fetchApiAuthorized
	)

	// stores
	const connected = useSocketStore((state) => state.socket?.connected)
	const join = useSocketStore((state) => state.join)
	const send = useSocketStore((state) => state.emit.sensor.onData)

	// state
	const [hasPermission, setHasPermission] = useState(
		process.env.NODE_ENV === "development" ? true : false
	)
	const [didDrag, setDidDrag] = useState(false)

	// refs
	const slider = useRef<HTMLDivElement>(null)
	const controller = useRef<HTMLDivElement>(null)

	// data
	const data = useRef({ z: 0, b: 0, g: 0 })
	const offset = useRef({ b: 90, g: 0 })
	const lastData = useRef({ z: 0, b: 0, g: 0 })
	const lastUpdate = useRef<number>()

	useEffect(() => {
		if (auth.token && practinar && practinar.id) {
			join(auth.token, IRole.Smartphone, practinar.id)
		}
	}, [auth.token, practinar, join])

	const update = useCallback(() => {
		const _update = () => {
			const newData = {
				z: data.current.z,
				b: data.current.b - offset.current.b,
				g: data.current.g - offset.current.g,
			}

			let newDataShouldBeSend = false
			if (lastData.current) {
				newDataShouldBeSend =
					Math.abs(lastData.current.z - newData.z) > 0.01 ||
					Math.abs(lastData.current.b - newData.b) > 0.5 ||
					Math.abs(lastData.current.g - newData.g) > 0.5
			}

			if (lastData.current === undefined || newDataShouldBeSend) {
				lastData.current = newData

				send([
					newData.z,
					Math.round(map_numbers(newData.b, -90, 90, -50, 50)),
					Math.round(map_numbers(newData.g, -90, 90, -30, 30)),
				])
			}
		}

		const now = new Date().getTime()
		if (!lastUpdate.current) {
			lastUpdate.current = now
			_update()
		} else if (Math.abs(now - lastUpdate.current) > 50) {
			lastUpdate.current = now
			_update()
		}
	}, [send])

	const onDrag = useCallback(
		(_: any, _data: { y: number }) => {
			const { y } = _data

			if (!controller.current) return

			const percentage =
				Math.round((y / (controller.current.offsetHeight - 70)) * 100) / 100

			if (data.current.z !== percentage) {
				data.current.z = percentage

				update()
			}

			setDidDrag(true)
		},
		[update]
	)

	const requestPermission = useCallback(() => {
		// @ts-ignore
		DeviceOrientationEvent.requestPermission().then(
			(permissionState: PermissionState) => {
				if (permissionState === "granted") {
					setHasPermission(true)
				}
			}
		)
	}, [])

	useEffect(() => {
		const onRotation = (e: DeviceOrientationEvent) => {
			const { beta, gamma } = e

			if (beta && gamma) {
				data.current.b = beta
				data.current.g = gamma
			}

			update()
		}

		if (hasPermission) {
			window.addEventListener("deviceorientation", onRotation)
		}

		return () => window.removeEventListener("deviceorientation", onRotation)
	}, [hasPermission, update])

	useEffect(() => {
		// @ts-ignore
		if (
			DeviceOrientationEvent !== undefined &&
			// @ts-ignore
			typeof DeviceOrientationEvent.requestPermission === "function"
		) {
			requestPermission()
		} else {
			setHasPermission(true)
		}
	}, [requestPermission])

	return (
		<Body
			headerChildren={
				<div className="flex flex-row items-center">
					<div style={{ marginRight: "6px" }}>
						<h5 className="text-right">
							{/* // FIXME: Translte */}
							{connected ? "Verbunden" : "Verbinden…"}
						</h5>
						{connected && (
							<h5 className="text-slate-400">
								{t("as")} {user ? user.name : "…"}
							</h5>
						)}
					</div>
					<div className="w-2 h-2 rounded bg-[#41e817]" />
				</div>
			}
			isFullscreen
			isMobile
		>
			<Button
				className="w-12 h-12 p-0 absolute top-5 left-5 z-10"
				theme={ButtonTheme.White}
				onClick={() => {
					offset.current = { b: data.current.b, g: data.current.g }
				}}
			>
				<img alt="Reset Rotation" src={ResetRotation} />
			</Button>

			<div className="flex-1 mt-3 relative h-full" ref={controller}>
				<div className="border-2 border-s-blue h-full w-20 my-0 mx-auto box-border rounded-xl" />
				<Draggable
					axis="y"
					bounds="parent"
					defaultPosition={{ x: 0, y: 0 }}
					handle=".handle"
					nodeRef={slider}
					position={
						!didDrag && controller.current
							? {
									x: 0,
									y: (controller.current.offsetHeight - 70) / 2,
							  }
							: undefined
					}
					scale={1}
					onDrag={onDrag}
				>
					<div
						className="absolute left-0 top-0 right-0 flex justify-center"
						ref={slider}
					>
						<div className="handle shadow-md rounded-xl w-48 h-16 bg-white" />
					</div>
				</Draggable>
			</div>

			{!hasPermission && (
				<div className="absolute top-0 left-0 bottom-0 right-0 bg-white z-50 flex justify-center p-5">
					<div className="flex flex-col flex-1 justify-center">
						<h2 className="mb-10">{t("gyroscope_permission")}</h2>
						<Button onClick={requestPermission}>{t("permit")}</Button>
					</div>
				</div>
			)}
		</Body>
	)
}

export default MobileSono
