import { KeyboardEvent, useCallback, useEffect, useRef, useState } from "react"
import Button, { ButtonTheme } from "src/components/button/button"
import Input from "src/components/input/input"
import SendArrow from "../../assets/images/sendArrow.svg"
import Linkify from "../../components/linkify/linkify"
import useHandleChange from "../../hooks/useHandleChange"
import { useChatStore } from "../../state/stores/chatStore"
import { useSocketStore } from "../../state/stores/socketStore"
import { iconForEmote } from "../../utils/iconForEmote"
import ChatEmotes from "./emotes"

interface ChatProps {
	moderatorData?: Record<string, unknown>
	isModerator?: boolean
}

// TODO: refactor
const Chat = ({ moderatorData, isModerator = false }: ChatProps) => {
	const messages = useChatStore((state) => state.messages)
	const sendMessage = useSocketStore((state) => state.emit.chat)

	const [newMessage, setNewMessage] = useHandleChange("")
	const [hasFocus, setHasFocus] = useState(false)
	const messagesRef = useRef<HTMLDivElement>(null)

	const send = (isPrivate: boolean = false) => {
		sendMessage(isPrivate, newMessage, undefined)
		setNewMessage("")

		scrollToBottom()
	}

	const onEmote = (emote: string) => {
		sendMessage(false, undefined, emote)

		scrollToBottom()
	}

	const scrollToBottom = useCallback(() => {
		if (messagesRef.current) {
			messagesRef.current.scrollTop = 1000000000
		}
	}, [])

	useEffect(() => {
		scrollToBottom()
	}, [messages, scrollToBottom])

	let sendButton = ButtonTheme.Blue
	if (moderatorData && moderatorData.role === "support") {
		sendButton = ButtonTheme.Lila
	}

	return (
		<div className="bg-slate-100 rounded-xl shadow-md flex flex-1 h-24 flex-col relative min-w-[400px] transition-all duration-500 ease-out overflow-hidden">
			<div className="absolute left-0 top-0 right-0 h-12 flex items-center pl-3 rounded-xl bg-chat-head">
				<h4>Chat</h4>
			</div>

			{/* Messages */}
			<div
				className="flex flex-col h-full overflow-scroll pt-12 px-3 noScrollBar"
				ref={messagesRef}
			>
				{messages.map((v, i) => {
					let backgroundColor: string
					if (v.isPrivate) {
						backgroundColor = "bg-s-red"
					} else if (v.isMod) {
						backgroundColor = "bg-s-blue"
					} else if (v.isSupport) {
						backgroundColor = "bg-s-lila"
					} else {
						backgroundColor = "bg-white"
					}

					if (v.emote) {
						return (
							<div className="rounded-xl shadow-md p-3 mb-3 bg-white" key={i}>
								<p>
									<span style={{ fontWeight: 600 }}>{v.name}</span>
									{": "}
									<img
										alt="Emote"
										height="30px"
										src={iconForEmote(v.emote, true)}
									/>
								</p>
							</div>
						)
					} else {
						return (
							<div
								className={`${
									v.isMod || v.isPrivate || v.isSupport ? "text-white" : ""
								} space-y-2.5 ${backgroundColor} rounded-xl shadow-md p-3 mb-3`}
								key={i}
							>
								<h5>{v.name}:</h5>
								<p>{v.message && <Linkify content={v.message} />}</p>
							</div>
						)
					}
				})}
				<div
					style={{
						marginBottom: "70px",
					}}
				/>
			</div>

			{/* chatInput */}
			<div className="absolute left-0 right-0 bottom-0 px-3 pb-3 pt-5 bg-chat-input rounded-b-xl flex">
				<ChatEmotes
					disableInput={newMessage.length === 0 || !hasFocus}
					onEmote={onEmote}
				/>
				<Input
					name="newMessage"
					placeholder="..."
					value={newMessage}
					onBlur={() => setHasFocus(false)}
					onChange={setNewMessage}
					onFocus={() => setHasFocus(true)}
					onKeyPress={({ key }: KeyboardEvent<HTMLInputElement>) => {
						if (key === "Enter") {
							send()
						}
					}}
				/>
				{isModerator && (
					<Button
						className="ml-2.5"
						disabled={newMessage.length === 0}
						theme={ButtonTheme.WhiteOnRed}
						onClick={() => send(true)}
					>
						<img alt="Send" src={SendArrow} />
					</Button>
				)}
				<Button
					className="ml-2.5"
					disabled={newMessage.length === 0}
					theme={sendButton}
					onClick={() => send(false)}
				>
					<img alt="Send" src={SendArrow} />
				</Button>
			</div>
		</div>
	)
}

export default Chat
