import { AuthData, useAuthStore } from "../state/stores/authStore"
import { GlobalHistory } from "../utils/history"
import { fetchApi } from "./fetch"

export const register = async (
	email: string,
	password: string,
	name: string
) => {
	const result = await fetchApi(
		"/users/register",
		{ email, password, name },
		"POST"
	)
	if (result) {
		return true
	}
}

export const loginWithCode = async (code: string) => {
	const result = await fetchApi("/users/authenticate", { code }, "POST")
	if (result && (result as AuthData)) {
		useAuthStore.getState().update(result, false)

		return result
	}

	return false
}

export const login = async (email: string, password: string) => {
	const result = await fetchApi(
		"/users/authenticate",
		{ email, password },
		"POST"
	)
	if (result && (result as AuthData)) {
		useAuthStore.getState().update(result, true)

		return true
	}

	return false
}

export const logout = () => {
	useAuthStore.getState().remove()

	GlobalHistory.push("/")
}

// FIXME:
// export const activate = async (code: string, password: string) => {
// 	const result = await fetchApi(
// 		"/users/activate",
// 		{ activationCode: code, password },
// 		"PUT"
// 	)
// 	if (result && (result as AuthData)) {
// 		useAuthStore.getState().update(result)

// 		return true
// 	}
// }
