import DailyIframe, {
	DailyCall,
	DailyEventObjectActiveSpeakerChange,
	DailyEventObjectParticipant,
	DailyParticipant,
	DailyParticipantsObject,
} from "@daily-co/daily-js"
import create from "zustand"
import { useSettingsStore } from "./settingsStore"

type CommunicationStore = {
	room: DailyCall | undefined
	baseURL: string

	state: SocketConnectionState
	participants: Record<string, DailyParticipant>
	activeSpeakerId: string | undefined
	connected: boolean

	join: (eventId: string, token: string, video: boolean) => void
	leave: () => void

	muteAudio: boolean

	ioActions: {
		updateMicrophone: (id: string) => void
		updateCamera: (id: string) => void
		muteAudio: (mute: boolean) => void
		muteVideo: (mute: boolean) => void
	}
}

export enum SocketConnectionState {
	disconnected = "Disconnected",
	connecting = "Connecting",
	connected = "Connected",
	reconnecting = "Reconnecting",
}

export const useCommunicationStore = create<CommunicationStore>((set, get) => {
	return {
		room: undefined,
		baseURL: "https://sonofun.daily.co/",

		participants: {},
		activeSpeakerId: undefined,
		connected: false,

		state: SocketConnectionState.disconnected,

		muteAudio: false,

		join: async (eventId, token, video) => {
			let { room, baseURL, participants } = get()

			set({ state: SocketConnectionState.connecting })

			const microphone = useSettingsStore.getState().selectedMicrophone
			const camera = useSettingsStore.getState().selectedCamera

			room = DailyIframe.createCallObject({
				subscribeToTracksAutomatically: true,
				dailyConfig: {
					experimentalChromeVideoMuteLightOff: true,
				},
			})

			const roomURL = baseURL + eventId

			await room.join({
				url: roomURL,
				token: token,
				videoSource: video ? camera : false,
			})

			await room.setNetworkTopology({ topology: "sfu" })
			await room.setInputDevicesAsync({
				audioDeviceId: microphone,
				videoDeviceId: video ? camera : false,
			}) // TODO: Need to create own Mediastream?

			Object.entries(room.participants() as DailyParticipantsObject).forEach(
				([id, participant]) => {
					participants[participant.user_id] = participant
				}
			)

			// Input devices

			room.on(
				"participant-joined",
				(event: DailyEventObjectParticipant | undefined) => {
					if (!event) return // TODO: Log error

					const { participants } = get()
					const { participant } = event

					if (participant.user_id) {
						participants[participant.user_id] = participant
					}

					// TODO: Maybe filter similar to twilio?
					set({ participants: { ...participants } })
				}
			)

			room.on(
				"participant-updated",
				(event: DailyEventObjectParticipant | undefined) => {
					if (!event) return // TODO: Log error

					const { participants } = get()
					const { participant } = event

					if (participant.user_id) {
						participants[participant.user_id] = participant
					}

					// TODO: Maybe filter similar to twilio?
					set({ participants: { ...participants } })
				}
			)

			room.on(
				"participant-left",
				(event: DailyEventObjectParticipant | undefined) => {
					if (!event) return // TODO: Log error

					const { participants } = get()
					const { participant } = event

					if (participant.user_id) {
						delete participants[participant.user_id]
					}

					// TODO: Maybe filter similar to twilio?
					set({ participants: { ...participants } })
				}
			)

			room.on(
				"active-speaker-change",
				(event: DailyEventObjectActiveSpeakerChange | undefined) => {
					if (event) {
						const id = event.activeSpeaker.peerId
						set({ activeSpeakerId: id })
					}
				}
			)

			// room.on("track-started", (event: DailyEventObjectTrack | undefined) => {
			// 	event.
			// })

			const _mute = get().muteAudio
			if (_mute) {
				room.setLocalAudio(!_mute)
			}

			set({ room, participants: { ...participants }, connected: true })
		},
		leave: () => {
			const { room } = get()

			if (room) {
				room.leave()
				room.destroy()

				set({ room: undefined })
			}
		},

		ioActions: {
			updateMicrophone: (id: string) => {
				const { room } = get()

				if (room) {
					// TODO: working?
					room.setInputDevicesAsync({ audioDeviceId: id })
				}
			},

			updateCamera: (id: string) => {
				const { room } = get()

				if (room) {
					room.setInputDevicesAsync({ videoDeviceId: id })
				}
			},

			muteAudio: (mute: boolean) => {
				const { room } = get()

				if (room) {
					room.setLocalAudio(!mute)
				}

				set({ muteAudio: mute })
			},

			muteVideo: (mute: boolean) => {
				const { room } = get()

				if (room) {
					room.setLocalVideo(!mute)
				}
			},
		},
	}
})
