import { Canvas } from "@react-three/fiber"
import { Suspense, useRef } from "react"
import { Mesh, NoToneMapping, sRGBEncoding } from "three"
import BodyModel from "../../assets/models/body"
import { VectorTuple } from "../../utils/vector"
import Environment from "../environment/environment"
import SelectPath from "../models/selectPath"

interface SelectPositionProps {
	start: VectorTuple
	end: VectorTuple
	onChange: (start: VectorTuple, end: VectorTuple) => void
	width?: number
}

const SelectPosition = ({
	start,
	end,
	onChange,
	width = undefined,
}: SelectPositionProps) => {
	const body = useRef<Mesh>(null)

	return (
		<div
			className="overflow-hidden border border-slate-400 shadow-md"
			style={{
				width: width,
			}}
		>
			<Canvas
				camera={{
					fov: 15,
					position: [0, 2, -0.3],
					rotation: [-Math.PI / 2, 0, 0],
				}}
				dpr={1}
				style={{
					height: "600px",
				}}
				onCreated={({ gl }) => {
					gl.toneMapping = NoToneMapping
					gl.outputEncoding = sRGBEncoding
				}}
			>
				<Suspense fallback={null}>
					<Environment />

					<BodyModel ref={body} />

					<SelectPath
						body={body}
						end={end}
						start={start}
						showAnchors
						onChange={onChange}
					/>
				</Suspense>
			</Canvas>
		</div>
	)
}

export default SelectPosition
