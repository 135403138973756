import { forwardRef, PropsWithChildren } from "react"

const DndContainer = forwardRef<
	HTMLDivElement,
	PropsWithChildren<{ opacity?: number; className?: string }>
>(({ children, className, opacity = 1 }, ref) => {
	return (
		<div
			className={`relative mb-5 border border-slate-400 rounded-xl transition-all bg-white shadow-md py-4 px-6 flex flex-1 items-center justify-between ${className}`}
			ref={ref}
			style={{ opacity }}
		>
			{children}
		</div>
	)
})

export default DndContainer
