import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useLaserStore } from "../../../state/stores/laserStore"
import { useMediaStore } from "../../../state/stores/mediaStore"
import { useSocketStore } from "../../../state/stores/socketStore"
import ResponsiveImage from "../../responsiveImage/responsiveImage"
import CustomVideo from "../../videoPlayer/videoPlayer"

export enum MediaType {
	video = "video",
	image = "image",
}

interface MediaProps {
	type: MediaType
	name: string
	url: string
}

const Media = ({ type, name, url }: MediaProps) => {
	const laser = useLaserStore((state) => state.positions)
	const emit = useSocketStore((state) => state.emit.media)
	const onLaser = useSocketStore((state) => state.emit.laser)
	const playPause = useMediaStore((state) => state.isPlaying)
	const time = useMediaStore((state) => state.time)

	const laserContainer = useRef<HTMLDivElement>(null)
	const headline = useRef<HTMLDivElement>(null)
	const videoRef = useRef<HTMLDivElement>(null)
	const imageRef = useRef<HTMLImageElement>(null)
	const [trackMouse, setTrackMouse] = useState(false)

	const lastUpdate = useRef(0)
	const tracking = useRef(false)

	const [containerWidth, setContainerWidth] = useState<number | undefined>(
		undefined
	)
	const [containerHeight, setContainerHeight] = useState<number | undefined>(
		undefined
	)

	useEffect(() => {
		const onMove = (event: MouseEvent) => {
			const { clientX, clientY } = event
			const ref = videoRef && videoRef.current ? videoRef : imageRef
			const isVideo = videoRef !== undefined && videoRef.current !== null

			if (!ref.current) return

			const rect = isVideo
				? ref.current.children[0].getBoundingClientRect()
				: ref.current.getBoundingClientRect()
			const percentageX = (clientX - rect.left) / rect.width
			const percentageY = (clientY - rect.top) / rect.height

			const now = new Date().getTime()
			if (now - lastUpdate.current > 25) {
				onLaser([percentageX, percentageY])
				lastUpdate.current = now
			}
		}

		if (trackMouse && (videoRef.current || imageRef.current)) {
			document.addEventListener("mousemove", onMove)
			tracking.current = true
		}

		return () => {
			document.removeEventListener("mousemove", onMove)

			if (tracking.current) {
				onLaser(undefined)
				tracking.current = false
			}
		}
	}, [trackMouse, onLaser])

	useLayoutEffect(() => {
		const onResize = () => {
			if (laserContainer.current && headline.current) {
				setContainerWidth(laserContainer.current.offsetWidth)
				setContainerHeight(
					laserContainer.current.offsetHeight - headline.current.offsetHeight
				)
			}
		}

		window.addEventListener("resize", onResize)
		onResize()

		return () => window.removeEventListener("resize", onResize)
	}, [laserContainer, headline])

	useEffect(() => {
		if (videoRef.current) {
			const video = videoRef.current.children[0] as HTMLVideoElement
			if (!video) return

			if (playPause) {
				video.play()
			} else {
				video.pause()
			}
		}
	}, [playPause])

	useEffect(() => {
		if (videoRef.current) {
			const video = videoRef.current.children[0] as HTMLVideoElement
			if (!video) return

			video.currentTime = time
		}
	}, [time])

	const ref = videoRef && videoRef.current ? videoRef : imageRef

	let _position = undefined
	if (laser !== undefined && ref.current) {
		_position = {
			left:
				ref.current.offsetWidth * laser[0] + ref.current.offsetLeft - 10 + "px",
			top:
				ref.current.offsetHeight * laser[1] + ref.current.offsetTop - 10 + "px",
		}
	}

	return (
		<div
			className="relative w-full h-full flex items-center flex-col"
			ref={laserContainer}
			style={{ cursor: trackMouse ? "none" : "default" }}
		>
			<h3 className="self-start" ref={headline}>
				{name}
			</h3>

			{type === "video" ? (
				<CustomVideo
					containerHeight={containerHeight}
					containerWidth={containerWidth}
					playPause={playPause}
					ref={videoRef}
					time={time}
					url={url}
					onMouseEnter={() => setTrackMouse(true)}
					onMouseLeave={() => setTrackMouse(false)}
					onPlayStateChange={(isPlaying) => emit.onPlayPause(isPlaying)}
					onTimeUpdate={(time) => emit.setTime(time)}
				/>
			) : (
				<ResponsiveImage
					ref={imageRef}
					url={url}
					onMouseEnter={() => setTrackMouse(true)}
					onMouseLeave={() => setTrackMouse(false)}
				/>
			)}
			<div
				className="w-5 h-5  absolute pointer-events-none transition-all duration-100 ease-in-out"
				style={{
					opacity: laser !== undefined ? 1.0 : 0.0,
					left: _position?.left ?? "initial",
					top: _position?.top ?? "initial",
				}}
			>
				<svg
					fill="none"
					height="20"
					viewBox="0 0 20 20"
					width="20"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="10" cy="10" fill="url(#paint0_radial)" r="10" />
					<g filter="url(#filter0_d)">
						<circle cx="10" cy="10" r="3" stroke="#F42C04" stroke-width="2" />
					</g>
					<defs>
						<filter
							color-interpolation-filters="sRGB"
							filterUnits="userSpaceOnUse"
							height="18"
							id="filter0_d"
							width="18"
							x="1"
							y="1"
						>
							<feFlood flood-opacity="0" result="BackgroundImageFix" />
							<feColorMatrix
								in="SourceAlpha"
								type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							/>
							<feOffset />
							<feGaussianBlur stdDeviation="2.5" />
							<feColorMatrix
								type="matrix"
								values="0 0 0 0 0.956863 0 0 0 0 0.172549 0 0 0 0 0.0156863 0 0 0 0.5 0"
							/>
							<feBlend
								in2="BackgroundImageFix"
								mode="normal"
								result="effect1_dropShadow"
							/>
							<feBlend
								in="SourceGraphic"
								in2="effect1_dropShadow"
								mode="normal"
								result="shape"
							/>
						</filter>
						<radialGradient
							cx="0"
							cy="0"
							gradientTransform="translate(10 10) rotate(90) scale(10)"
							gradientUnits="userSpaceOnUse"
							id="paint0_radial"
							r="1"
						>
							<stop stop-color="#F42C04" stop-opacity="0.5" />
							<stop offset="1" stop-color="#F42C04" stop-opacity="0" />
						</radialGradient>
					</defs>
				</svg>
			</div>
		</div>
	)
}

export default Media
