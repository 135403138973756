import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import Button, { ButtonTheme } from "src/components/button/button"
import { useQuizStore } from "../../../state/stores/quizStore"
import { useSocketStore } from "../../../state/stores/socketStore"
import { mapVotes, quizColors, quizLetters } from "../../../utils/formatQuiz"

// TODO: always show correct answer

interface QuizProps {
	data: Record<string, unknown>
}

const Quiz = ({ data }: QuizProps) => {
	const { t } = useTranslation()

	const onShowResults = useSocketStore((state) => state.emit.quiz.showResults)
	const showResultsAfterTime = useQuizStore((state) => state.showResults)

	const votes = useQuizStore((state) => state.votes)
	const showResults = useQuizStore((state) => state.showResults)
	const voters = useQuizStore((state) => state.voters)
	const numberOfParticipants = useQuizStore((state) => state.participants)

	const [time, setTime] = useState(5)
	const timerRef = useRef<NodeJS.Timeout>()
	const showResultsTimerRef = useRef<NodeJS.Timeout>()
	const _time = useRef(5)

	useEffect(() => {
		const countdown = () => {
			_time.current -= 1
			setTime(_time.current)
		}

		if (showResultsAfterTime && timerRef.current === undefined) {
			_time.current = 5
			timerRef.current = setInterval(countdown, 1000)
			setTime(5)
		}
	}, [showResultsAfterTime])

	useEffect(() => {
		return () => {
			_time.current = 10
		}
	}, [])

	useEffect(() => {
		const _showResultsTimerRef = showResultsTimerRef.current

		return () => {
			if (showResults) {
				if (timerRef.current) {
					clearInterval(timerRef.current)
					timerRef.current = undefined
				}

				if (_showResultsTimerRef) {
					clearTimeout(_showResultsTimerRef)
				}
			}
		}
	}, [showResults])

	useEffect(() => {
		const _showResultsTimerRef = showResultsTimerRef.current

		return () => {
			if (showResultsAfterTime) {
				if (timerRef.current) {
					clearInterval(timerRef.current)
					timerRef.current = undefined
				}

				if (_showResultsTimerRef) {
					clearTimeout(_showResultsTimerRef)
				}
			}
		}
	}, [showResultsAfterTime, showResults])

	return (
		<div className="flex flex-col self-start flex-1">
			<div className="flex flex-row justify-between w-full mb-5">
				<>
					<div>
						<h3>{data.question as string}</h3>
						<p className="mt-2">
							{voters} / {numberOfParticipants} haben abgestimmt
						</p>

						<Button
							className="mt-2.5 w-48"
							disabled={showResults}
							theme={showResults ? ButtonTheme.WhiteOnRed : undefined}
							onClick={onShowResults}
						>
							{showResults
								? time > 0
									? `sichtbar in: ${time}`
									: "Ergebnisse sichtbar"
								: t("show_results")}
						</Button>
					</div>
					{data.imageUrl && (
						<img
							alt="quizbild"
							className="object-contain"
							src={data.imageUrl as string}
							width="200px"
						/>
					)}
				</>
			</div>

			<div className="border-l-2 border-s-blue py-2 self-start w-full  my-2 mx-0 relative">
				{mapVotes(data.answers as string[], votes).map((v, i) => (
					<div
						className="flex items-center"
						key={i}
						style={{
							marginBottom:
								i !== (data.answers as string[]).length - 1 ? "10px" : 0,
						}}
					>
						<div className="absolute flex flex-row items-center pl-1 space-x-2">
							<h4>{quizLetters[i]}</h4>
							<p>{(data.answers as string[])[i]}</p>
							{(data.solution as number[]).includes(i) && (
								<div className="rounded-xl border-2 border-green-500 py-1 px-2 uppercase text-[10px] text-green-500 -right-2 bg-white">
									{t("correct_answer")}
								</div>
							)}
						</div>
						<div
							className="rounded-r-xl !border-l-0 flex p-1 min-w-[24px] h-8 flex-col items-end justify-center"
							color={quizColors[i]}
							key={i}
							style={{ border: "3px solid" + quizColors[i], width: v + "%" }}
						>
							{votes[i] !== 0 && <p>{votes[i]}</p>}
						</div>
					</div>
				))}
			</div>
			<div className="flex flex-row justify-between w-full" />
		</div>
	)
}

export default Quiz
