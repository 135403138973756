/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from "@react-three/drei"
import { forwardRef } from "react"
import * as THREE from "three"
import { Mesh } from "three"
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader"

type GLTFResult = GLTF & {
	nodes: {
		Male001: THREE.Mesh
		Cylinder: THREE.Mesh
	}
	materials: {
		["default"]: THREE.MeshStandardMaterial
		Material: THREE.MeshStandardMaterial
	}
}

const BodyModel = forwardRef<Mesh>((props, ref) => {
	const { nodes, materials } = useGLTF(
		"/models/body.glb"
	) as unknown as GLTFResult

	return (
		<group dispose={null} {...props}>
			<mesh
				geometry={nodes.Male001.geometry}
				material={materials["default"]}
				ref={ref}
			/>
			<mesh geometry={nodes.Cylinder.geometry} material={materials.Material} />
		</group>
	)
})

export default BodyModel

useGLTF.preload("/models/body.glb")
