import { FormEvent } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import Button from "src/components/button/button"
import Input from "src/components/input/input"
import Body from "../../../components/body/body"
import useHandleChange from "../../../hooks/useHandleChange"
import { login } from "../../../network/auth"

const Login = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const location = useLocation()

	const [email, setEmail] = useHandleChange("")
	const [password, setPassword] = useHandleChange("")

	const from = (location.state as any)?.from?.pathname || "/"

	const _login = async (e: FormEvent) => {
		e.preventDefault()

		const success = await login(email, password)
		if (success) {
			navigate(from)
		}
	}

	return (
		<Body>
			<div className="flex flex-1 items-center justify-center">
				<div className="flex flex-col space-y-8 w-96">
					<h2>Moderator Login</h2>
					<form onSubmit={_login}>
						<div className="flex flex-col">
							<Input
								name="email"
								placeholder={t("email")}
								value={email}
								onChange={setEmail}
							/>
							<Input
								className="mt-2.5 mb-5"
								name="password"
								placeholder={t("password")}
								type="password"
								value={password}
								onChange={setPassword}
							/>
							<Button
								disabled={email.length === 0 || password.length === 0}
								type="submit"
							>
								{t("log_in")}
							</Button>
						</div>
					</form>
				</div>
			</div>
		</Body>
	)
}

export default Login
