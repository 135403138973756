import { CSSProperties } from "react"
import UnChecked from "../../assets/images/checkBox.svg"
import Checked from "../../assets/images/checkBoxSelected.svg"

interface CheckBoxProps {
	title: string
	checked: boolean
	onCheck: (checked: boolean) => void
	style?: CSSProperties
}

const CheckBox = ({
	title,
	checked,
	onCheck,
	style = undefined,
}: CheckBoxProps) => {
	return (
		<div
			className="flex flex-row items-center cursor-pointer"
			style={style}
			onClick={() => onCheck(!checked)}
		>
			<p>{title}</p>
			<img
				alt={checked ? "Checked" : "Not checked"}
				src={checked ? Checked : UnChecked}
				style={{ marginLeft: "5px" }}
			/>
		</div>
	)
}

export default CheckBox
