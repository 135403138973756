import { PropsWithChildren, ReactElement } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import Button, { ButtonTheme } from "src/components/button/button"
import Icon, { IconImage } from "src/components/iconImage/iconImage"
import ImageIcon from "../../assets/images/image.svg"
import VideoIcon from "../../assets/images/video.svg"

interface ListProps {
	title: string
	components?: (value: Record<string, unknown>) => ReactElement | boolean
	data: Record<string, unknown>[]
	onEdit: (value: Record<string, unknown>) => void
	isMedia?: boolean
	onDelete: (value: Record<string, unknown>) => void
	showDelete?: (value: Record<string, unknown>) => boolean
	loading: boolean
	onDublicate?: (value: Record<string, unknown>) => void
}

export const ListContainer = ({
	children,
	className,
}: PropsWithChildren<{ className?: string }>) => {
	return (
		<div className={`flex flex-col py-3 px-6 space-y-2.5 ${className}`}>
			{children}
		</div>
	)
}

export const ListRow = ({
	children,
	showDivider,
	className,
	onClick,
}: PropsWithChildren<{
	showDivider: boolean
	className?: string
	onClick?: () => void
}>) => {
	return (
		<div
			className={`flex flex-row items-center justify-between ${
				showDivider ? "border-b border-b-slate-200 mb-2.5" : ""
			} pb-2.5 ${className}`}
			onClick={onClick}
		>
			{children}
		</div>
	)
}

const List = ({
	title = "title",
	components,
	data,
	onEdit,
	isMedia = false,
	onDelete,
	showDelete,
	loading,
	onDublicate,
}: ListProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	return (
		<ListContainer>
			{loading
				? "Loading…"
				: data.map((v, i) => {
						let showDeleteButton = onDelete !== undefined
						if (showDelete) {
							showDeleteButton = showDelete(v)
						}

						return (
							<ListRow key={v.id as string} showDivider={i < data.length - 1}>
								<div className="flex flex-row items-center">
									{isMedia ? (
										<>
											<Icon
												className="w-48"
												src={
													v.type === "video"
														? (v.thumbnailUrl as string)
														: (v.url as string)
												}
												onClick={() => window.open(v.url as string, "_blank")}
											/>
											<img
												alt={v.type as string}
												src={v.type === "video" ? VideoIcon : ImageIcon}
												style={{ marginRight: "10px" }}
												width="24px"
											/>
										</>
									) : null}
									<p className="mr-2.5">{v[title] as string}</p>
									{components && components(v)}
								</div>
								<div className="flex flex-row">
									{onDublicate && (
										<Button
											className="mr-2"
											theme={ButtonTheme.White}
											onClick={() => onDublicate(v)}
										>
											Duplizieren
										</Button>
									)}
									{showDeleteButton && (
										<Icon
											className="mr-5"
											iconImage={IconImage.Trash}
											onClick={() => onDelete(v)}
										/>
									)}
									<Button theme={ButtonTheme.White} onClick={() => onEdit(v)}>
										{t("edit")}
									</Button>
								</div>
							</ListRow>
						)
				  })}
		</ListContainer>
	)
}

export default List
