import { DailyParticipant, DailyTrackState } from "@daily-co/daily-js"
import { useEffect, useMemo, useRef, useState } from "react"

function getTrackUnavailableMessage(
	kind: string,
	trackState?: DailyTrackState
) {
	if (!trackState) return
	switch (trackState.state) {
		case "blocked":
			if (trackState.blocked?.byPermissions) {
				return `${kind} permission denied`
			} else if (trackState.blocked?.byDeviceMissing) {
				return `${kind} device missing`
			}
			break
		case "off":
			if (trackState.off?.byUser) {
				return `${kind} muted`
			} else if (trackState.off?.byBandwidth) {
				return `${kind} muted to save bandwidth`
			}
			break
		case "sendable":
			return `${kind} not subscribed`
		case "loading":
			return `${kind} loading...`
		case "interrupted":
			return `${kind} interrupted`
		case "playable":
			return null
	}
}

export enum PresenceState {
	Normal,
	Connecting,
	Blocked,
}

const presenceStateForTrackState = (
	isMegaphoneSpeaker: boolean,
	audioTrackState?: DailyTrackState,
	videoTrackState?: DailyTrackState
): PresenceState => {
	if (!audioTrackState || !videoTrackState) return PresenceState.Connecting

	if (
		audioTrackState.state === "interrupted" ||
		videoTrackState.state === "interrupted" ||
		audioTrackState.state === "blocked" ||
		videoTrackState.state === "blocked"
	) {
		return PresenceState.Blocked
	}

	if (
		audioTrackState.state === "loading" ||
		videoTrackState.state === "loading"
	) {
		return PresenceState.Connecting
	}

	return PresenceState.Normal
}

interface AvatarTileProps {
	participant: DailyParticipant
	isOwner: boolean
	showVideo?: boolean
	enableAudio?: boolean

	onAudioMute?: (mute: boolean) => void
	onVideoMute?: (mute: boolean) => void
}

const AvatarTile = ({
	participant,
	isOwner,
	showVideo = true,
	enableAudio = true,
	onAudioMute = undefined,
	onVideoMute = undefined,
}: AvatarTileProps) => {
	const videoEl = useRef<HTMLVideoElement>(null)
	const audioEl = useRef<HTMLAudioElement>(null)

	const [videoTrackState, setVideoTrackState] = useState<
		DailyTrackState | undefined
	>(undefined)
	const [audioTrackState, setAudioTrackState] = useState<
		DailyTrackState | undefined
	>(undefined)

	useEffect(() => {
		if (participant) {
			setVideoTrackState(participant.tracks.video)
			setAudioTrackState(participant.tracks.audio)
		}
	}, [participant])

	const videoTrack = useMemo(() => {
		// TODO: Support Low Quality

		return videoTrackState && videoTrackState.state === "playable"
			? videoTrackState.track
			: null
	}, [videoTrackState])

	const audioTrack = useMemo(() => {
		return !isOwner && audioTrackState && audioTrackState.state === "playable"
			? audioTrackState.track
			: null
	}, [audioTrackState, isOwner])

	useEffect(() => {
		if (!onAudioMute) return

		if (audioTrackState?.off) {
			onAudioMute(true)
		} else {
			onAudioMute(false)
		}
	}, [audioTrackState, onAudioMute])

	useEffect(() => {
		if (!onVideoMute) return

		if (videoTrackState?.off) {
			onVideoMute(true)
		} else {
			onVideoMute(false)
		}
	}, [videoTrackState, onVideoMute])

	/**
	 * When video track changes, update video srcObject
	 */
	useEffect(() => {
		// console.log("Reload Video Track for: " + id)
		videoTrack &&
			videoEl.current &&
			(videoEl.current.srcObject = new MediaStream([videoTrack]))
	}, [videoTrack])

	/**
	 * When audio track changes, update audio srcObject
	 */

	useEffect(() => {
		//console.log("Reload Audio Track for: " + id)
		audioTrack &&
			audioEl.current &&
			(audioEl.current.srcObject = new MediaStream([audioTrack]))
	}, [audioTrack])

	useEffect(() => {
		if (audioEl && audioEl.current) {
			audioEl.current.volume = enableAudio ? 1 : 0
		}
	}, [enableAudio, audioTrack])

	return (
		<div className="flex flex-1 h-full">
			{videoTrack && showVideo && (
				<video
					className="w-full h-full object-cover object-media-stream"
					ref={videoEl}
					style={{ transform: isOwner ? "rotateY(180deg)" : "" }}
					autoPlay
					muted
					playsInline
				/>
			)}
			{audioTrack && <audio ref={audioEl} autoPlay playsInline />}
		</div>
	)
}

export default AvatarTile
