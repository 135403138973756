import { useControls } from "leva"
import { useEffect } from "react"

interface MirrorOptionsProps {
	mirroredLeft: boolean
	mirroredFront: boolean
	onMirrorLeft: (mirror: boolean) => void
	onMirrorFront: (mirror: boolean) => void
}

const MirrorOptions = ({
	mirroredLeft,
	mirroredFront,
	onMirrorLeft,
	onMirrorFront,
}: MirrorOptionsProps) => {
	const { mirrorLeftRight, mirrorFrontBack } = useControls({
		mirrorLeftRight: { value: mirroredLeft },
		mirrorFrontBack: { value: mirroredFront },
	})

	useEffect(() => {
		onMirrorLeft(mirrorLeftRight)
	}, [mirrorLeftRight, onMirrorLeft])

	useEffect(() => {
		onMirrorFront(mirrorFrontBack)
	}, [mirrorFrontBack, onMirrorFront])

	return <div />
}

export default MirrorOptions
