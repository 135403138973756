interface PatientProps {
	data: Record<string, unknown>
}

const Patient = ({ data }: PatientProps) => {
	return (
		<div className="flex flex-col items-stretch justify-start w-full h-full">
			<div className="flex flex-row items-start flex-1">
				<div className="flex flex-col justify-start flex-[2] mt-5">
					<h2>
						<>Name: {data && data.name}</>
					</h2>
					<div
						dangerouslySetInnerHTML={{ __html: data.descriptionHtml as string }}
						style={{ margin: "10px 10px 0px 0px" }}
					/>
				</div>
				{/* // TODO: no percentage pls */}
				<img
					alt="Vorschau"
					src={data && (data.imageUrl as string)}
					style={{
						maxHeight: "400px",
						objectFit: "contain",
					}}
					width="50%"
				/>
			</div>
		</div>
	)
}

export default Patient
