import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import de from "../assets/translations/de.json"
import en from "../assets/translations/en.json"

// the translations
const resources = {
	en: {
		translation: en,
	},
	de: {
		translation: de,
	},
}

let lng = "en"
// @ts-ignore
const browserLanguage = navigator.language || navigator.userLanguage
const savedLanguage = localStorage.getItem("language")
if (savedLanguage) {
	lng = savedLanguage
} else {
	lng = /^de\b/.test(browserLanguage) ? "de" : "en"
}

i18n.use(initReactI18next).init({
	resources,
	lng,
	fallbackLng: "en", // use en if detected lng is not available

	keySeparator: false, // we do not use keys in form messages.welcome

	interpolation: {
		escapeValue: false, // react already safes from xss
	},
})

export default i18n
