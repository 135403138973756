import create from "zustand"
import persistCookie from "../../utils/persistCookie"

export interface AuthData extends Record<string, unknown> {
	token: string
	expiresIn: number
}

interface AuthStore extends Record<string, unknown> {
	token?: string
	expiresIn?: number

	adminToken?: string
	adminExpiresIn?: number

	update: (data: AuthData, admin: boolean) => void
	remove: () => void
}

export const useAuthStore = create<AuthStore>(
	persistCookie((set, get) => {
		return {
			token: undefined,
			expiresIn: undefined,

			adminToken: undefined,
			adminExpiresIn: undefined,

			update(data: AuthData, admin: boolean) {
				if (admin) {
					set({
						adminToken: data.token,
						adminExpiresIn: data.expiresIn,
					})
				} else {
					set({
						token: data.token,
						expiresIn: data.expiresIn,
					})
				}
			},

			remove() {
				set({
					token: undefined,
					expiresIn: undefined,
					adminToken: undefined,
					adminExpiresIn: undefined,
				})
			},
		}
	})
)

export const authFromStore = (admin: boolean) => {
	const { token, expiresIn, adminToken, adminExpiresIn } =
		useAuthStore.getState()

	return {
		token: admin ? adminToken : token,
		expiresIn: admin ? adminExpiresIn : expiresIn,
	}
}
