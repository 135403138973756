import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import dotenv from "dotenv"
import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import "./index.css"
import "./utils/translation"

dotenv.config()

if (process.env.NODE_ENV === "production") {
	Sentry.init({
		dsn: "https://b73975943024471c9ffae03c14bb4a2d@o489651.ingest.sentry.io/5552209",
		autoSessionTracking: true,
		integrations: [new Integrations.BrowserTracing()],

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0,
	})
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)
