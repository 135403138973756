import create from "zustand"

interface SceneStore extends Record<string, unknown> {
	index: number
}

export const useSceneStore = create<SceneStore>((set, get) => {
	return {
		index: 0,
	}
})
