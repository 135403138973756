import { useLoader, useThree } from "@react-three/fiber"
import { PMREMGenerator } from "three"
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader"

const useEquirectangularHDR = (url: string) => {
	const { gl, scene } = useThree()
	const pmremGenerator = new PMREMGenerator(gl)
	pmremGenerator.compileEquirectangularShader()

	// @ts-ignore
	const hdrEquirect = useLoader(RGBELoader, url)

	const hdrCubeRenderTarget = pmremGenerator.fromEquirectangular(hdrEquirect)
	hdrEquirect.dispose()
	pmremGenerator.dispose()

	scene.environment = hdrCubeRenderTarget.texture
	// scene.background = hdrCubeRenderTarget.texture

	return hdrCubeRenderTarget.texture
}

export default useEquirectangularHDR
