import Button, { ButtonTheme } from "src/components/button/button"
import FullscreenOpen from "../../assets/images/fullscreen.svg"
import FullscreenClose from "../../assets/images/fullscreenClose.svg"
import { useFullscreen } from "../../state/stores/fullscreenStore"

const FullscreenButton = ({ className }: { className: string }) => {
	const toggle = useFullscreen((state) => state.toggle)
	const isFullscreen = useFullscreen((state) => state.isFullscreen)

	return (
		<Button
			className={`px-2.5 py-3 ${className}`}
			theme={ButtonTheme.White}
			onClick={toggle}
		>
			<img
				alt="Fullscreen"
				height="23"
				src={isFullscreen ? FullscreenClose : FullscreenOpen}
				width="23"
			/>
		</Button>
	)
}

export default FullscreenButton
