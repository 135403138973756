import { useFrame } from "@react-three/fiber"
import { useState } from "react"
import { Clock } from "three"

const FrameLimiter = ({ fps }: { fps: number }) => {
	const [clock] = useState(new Clock())

	useFrame((state) => {
		const timeUntilNextFrame = 1000 / fps - clock.getDelta()

		setTimeout(() => {
			state.invalidate()
		}, Math.max(0, timeUntilNextFrame))
	})

	return null
}

export default FrameLimiter
