import { buttonGroup, useControls } from "leva"
import { nanoid } from "nanoid"
import QRCode from "qrcode.react"
import { useEffect, useRef, useState } from "react"
import AmiView from "src/components/ami/amiView"
import { Clock } from "three"
import { FRONT_BASE_URL } from "../../constants/constants"
import { loginWithCode } from "../../network/auth"
import { IRole, useSocketStore } from "../../state/stores/socketStore"

const FiveD = () => {
	const threeDContainer = useRef<HTMLDivElement>(null)
	const [code] = useState(`$5d-${nanoid(8)}`)
	const [token, setToken] = useState<string | undefined>(undefined)

	const join = useSocketStore((state) => state.join)

	useEffect(() => {
		const getToken = async () => {
			const result = await loginWithCode(code)
			if (result) {
				setToken(result.token)
			}
		}

		getToken()
	}, [code])

	useEffect(() => {
		if (token !== undefined) {
			join(token, IRole.Participant, "5d-demo")
		}
	}, [join, token])

	useControls({
		position: {
			value: 0.5,
			min: 0,
			max: 1,
			step: 0.01,
			onChange: (value) => {
				data.current[0] = value
			},
			transient: true,
		},
		rotationA: {
			value: 0,
			min: -30,
			max: 30,
			step: 1,
			onChange: (value) => {
				data.current[1] = value
			},
			transient: true,
		},
		rotationB: {
			value: 0,
			min: -30,
			max: 30,
			step: 1,
			onChange: (value) => {
				data.current[2] = value
			},
			transient: true,
		},
		zoom: {
			value: 2,
			min: 1,
			max: 3,
			step: 1,
			onChange: (value) => {
				zoom.current = value
			},
			transient: true,
		},
		" ": buttonGroup({
			PLAY: () => {
				clock.current.startTime = 0
				clock.current.start()
			},
			PAUSE: () => {
				clock.current.stop()
			},
			">>": () => {
				clock.current.elapsedTime = clock.current.elapsedTime + 1
			},
		}),
	})

	const clock = useRef(new Clock(false))
	const data = useRef([0, 0, 0])
	const zoom = useRef(1)

	return (
		<div ref={threeDContainer} style={{ width: "100vw", height: "100vh" }}>
			<QRCode
				style={{ position: "absolute", top: "20px", left: "20px", zIndex: 300 }}
				value={`${FRONT_BASE_URL}/connect/${code}`}
			/>

			<AmiView
				clock={clock}
				container={threeDContainer}
				externalData={data}
				mirrorFrontBack={false}
				mirrorLeftRight={false}
				volumeUrl="https://sonolive.s3.eu-west-1.amazonaws.com/test/5d_volume_test_1.nrrd"
				zoom={zoom}
			/>
		</div>
	)
}

export default FiveD
