import { usePatientStore } from "../../../state/stores/patientStore"

const Loading = () => {
	const percentage = usePatientStore((state) => state.percentage)
	const mode = usePatientStore((state) => state.mode)

	return (
		<div className="absolute left-0 right-0 top-0 bottom-0 bg-black bg-opacity-60 flex items-center justify-center">
			<div className="flex flex-row text-white">
				<h3 color="white">
					Daten werden {mode === "loading" ? "heruntergeladen" : "verarbeitet"}
					:&nbsp;
				</h3>
				<h3 className="tabular-nums w-16 text-right">
					{Math.round(percentage * 100)}%
				</h3>
			</div>
		</div>
	)
}

export default Loading
