import useSWR from "swr"
import useHandleChange from "../../hooks/useHandleChange"
import { fetchApiAuthorized } from "../../network/fetch"
import { useAuthStore } from "../../state/stores/authStore"
import ScheduleItemView, { ScheduleItemSubviewProps } from "./scheduleItemView"

const ScheduleItemMediaView = ({
	value,
	onChange,
}: ScheduleItemSubviewProps) => {
	const [_value, setValue] = useHandleChange(value ?? "default", onChange)

	const token = useAuthStore((state) => state.adminToken)
	const expiresIn = useAuthStore((state) => state.adminExpiresIn)
	const { data: media } = useSWR(
		["/medias", token, expiresIn],
		fetchApiAuthorized
	)

	return (
		<ScheduleItemView
			data={media}
			header="Media"
			title="name"
			value={_value}
			onChange={setValue}
		/>
	)
}

export default ScheduleItemMediaView
