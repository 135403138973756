import { FormEvent, useEffect, useState } from "react"
import { isMobileOnly } from "react-device-detect"
import { useTranslation } from "react-i18next"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import Button, { ButtonTheme } from "src/components/button/button"
import Input from "src/components/input/input"
import useSWR from "swr"
import Body from "../../components/body/body"
import LanguageButton from "../../components/button/languageButton"
import Logos from "../../components/logos/logos"
import useHandleChange from "../../hooks/useHandleChange"
import { loginWithCode } from "../../network/auth"
import { fetchApi, fetchApiAuthorized } from "../../network/fetch"
import { useErrorStore } from "../../state/stores/errorStore"

const Join = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { code } = useParams() as Record<string, string>

	const [inviteCode, setInviteCode] = useHandleChange("")
	const [name, setName] = useHandleChange("")
	const [isAdditional, setIsAdditional] = useState(false)

	const { data } = useSWR("/config/", fetchApi)

	const _login = async (e: FormEvent) => {
		e.preventDefault()

		const result = await loginWithCode(inviteCode)
		if (result !== undefined) {
			const practinar = await fetchApiAuthorized(
				`/webinars/code/${inviteCode}`,
				result.token,
				result.expiresIn
			)

			if (practinar) {
				console.log(practinar)
				if (practinar && practinar.running) {
					if (isAdditional) {
						const changeName = await fetchApiAuthorized(
							`/webinars/changeName`,
							result.token,
							result.expiresIn,
							{ name },
							"POST"
						)

						if (changeName) {
							navigate(`/practinar/${inviteCode}`)
						}
					} else {
						if (practinar.isSuccessor) {
							setIsAdditional(true)
						} else {
							navigate(`/practinar/${inviteCode}`)
						}
					}
				} else {
					// FIXME: translte
					useErrorStore
						.getState()
						.show(undefined, "Practinar has not started yet")
				}
			} else {
				useErrorStore.getState().show(undefined, "Practinar not found")
			}
		}
	}

	useEffect(() => {
		if (code) {
			setInviteCode(code)
		}
	}, [code, setInviteCode])

	return isMobileOnly ? (
		<Navigate to="/scan" replace />
	) : (
		<Body padding={"0"} showFullscreen={false} showHeader={false}>
			<div className="flex flex-1 flex-row">
				<div className="flex flex-col bg-s-blue p-5 flex-1">
					<div className="flex flex-col flex-1 text-white">
						<h1>SonoFun.live</h1>

						<h2 className="mt-5">{t("webinars_upcoming")}</h2>

						{data !== undefined && (
							<p
								dangerouslySetInnerHTML={{ __html: data.frontPageHtml }}
								style={{
									color: "white",
									margin: "20px",
									lineHeight: "1.8",
								}}
							/>
						)}

						<Button
							theme={ButtonTheme.White}
							onClick={() => navigate("/hardwaretest")}
						>
							{t("hardware_test")}
						</Button>
					</div>

					<Logos color="white" />
				</div>

				<div className="flex flex-col flex-[2] p-[10px] items-start">
					<div className="flex flex-row items-center self-end">
						<a
							className="no-underline text-s-black"
							href="/moderator/dashboard"
							style={{ marginRight: "20px" }}
						>
							Moderator login
						</a>
						<LanguageButton />
					</div>

					<div className="flex flex-col items-stretch justify-between m-auto pb-12 w-[400px]">
						<h1 className="mb-3">{t("start_welcome")}</h1>
						<h4 className="mb-8">
							{isAdditional
								? t("enter_your_name")
								: t("enter_your_code_description")}
						</h4>

						<form onSubmit={_login}>
							<div className="flex flex-row">
								{isAdditional ? (
									<Input
										name="name"
										placeholder={t("name")}
										value={name}
										onChange={setName}
									/>
								) : (
									<Input
										name="inviteCode"
										placeholder={t("code")}
										value={inviteCode}
										onChange={setInviteCode}
									/>
								)}
								<Button
									className="ml-2.5"
									disabled={
										isAdditional ? name.length === 0 : inviteCode.length === 0
									}
									type="submit"
								>
									{t("take_part")}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Body>
	)
}

export default Join
