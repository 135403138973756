import { PropsWithChildren } from "react"
import Sidebar from "../sidebar/sidebar"

const DashboardBody = ({ children }: PropsWithChildren<{}>) => {
	return (
		<div className="flex flex-row min-h-screen">
			<Sidebar />
			<div className="flex flex-col flex-1 pl-80">{children}</div>
		</div>
	)
}

export default DashboardBody
