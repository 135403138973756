import { Suspense, useEffect, useRef } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { CSSTransition } from "react-transition-group"
import Alert from "src/components/alert/alert"
import CameraSettingsOverlay from "src/components/alert/cameraSettingsOverlay"
import Preview from "src/components/preview/preview"
import Volume from "src/routes/test/volume"
import {
	AdminRoute,
	PrivateRoute,
} from "./components/privateRoute/privateRoute"
import NotFoundPage from "./routes/404/404"
import Connection from "./routes/connection/connection"
import HardwareTest from "./routes/hardwareTest"
import UserLogin from "./routes/join/join"
import ModeratorRegistration from "./routes/moderator/activate/activate"
import Cases from "./routes/moderator/case/cases"
import CreateCase from "./routes/moderator/case/create/createCase"
import Config from "./routes/moderator/config/config"
import Login from "./routes/moderator/login/login"
import CreateMedia from "./routes/moderator/media/create/createMedia"
import Media from "./routes/moderator/media/medias"
import CreatePatient from "./routes/moderator/patient/create/createPatient"
import Patients from "./routes/moderator/patient/patients"
import CreateQuiz from "./routes/moderator/quiz/create/createQuiz"
import Quizzes from "./routes/moderator/quiz/quizzes"
import CreateUser from "./routes/moderator/user/create/createUser"
import Users from "./routes/moderator/user/users"
import CreateWebinar from "./routes/moderator/webinar/create/createWebinar"
import Live from "./routes/moderator/webinar/live/live"
import Schedule from "./routes/moderator/webinar/schedule/schedule"
import Webinars from "./routes/moderator/webinar/webinars"
import WebinarsRunning from "./routes/moderator/webinar/webinarsRunning"
import Practinar from "./routes/practinar/practinar"
import Tutorial from "./routes/practinar/tutorial/tutorial"
import Scan from "./routes/scan/scan"
import FiveD from "./routes/test/5d"
import {
	showSettingsValue,
	useCameraSettingsStore,
} from "./state/stores/cameraSettingsStore"
import { useErrorStore } from "./state/stores/errorStore"
import { useFullscreen } from "./state/stores/fullscreenStore"
import { usePreviewStore } from "./state/stores/previewStore"
import { GlobalHistory } from "./utils/history"
import { HistoryRouter } from "./utils/historyRouter"

const App = () => {
	const error = useErrorStore((state) => state.error)
	const showSettings = useCameraSettingsStore((state) => state.showSettings)

	const top = useRef<HTMLDivElement>(null)

	const setContainer = useFullscreen((state) => state.setContainer)
	const setIsFullscreen = useFullscreen((state) => state.setIsFullscreen)

	const showPreview = usePreviewStore((state) => state.showPreview)
	const preview = usePreviewStore((state) => state.preview)

	const alertRef = useRef(null)
	const cameraSettingsOverlayRef = useRef(null)
	const previewRef = useRef(null)

	useEffect(() => {
		if (top.current) {
			setContainer(top.current)
		}
	}, [top, setContainer])

	useEffect(() => {
		const exitHandler = () => {
			if (
				!document.fullscreenElement &&
				// @ts-ignore
				!document.webkitIsFullScreen &&
				// @ts-ignore
				!document.mozFullScreen &&
				!document.msFullscreenElement
			) {
				setIsFullscreen(false)
			} else {
				setIsFullscreen(true)
			}
		}

		document.addEventListener("fullscreenchange", exitHandler)
		document.addEventListener("webkitfullscreenchange", exitHandler)
		document.addEventListener("mozfullscreenchange", exitHandler)
		document.addEventListener("MSFullscreenChange", exitHandler)

		return () => {
			document.removeEventListener("fullscreenchange", exitHandler)
			document.removeEventListener("webkitfullscreenchange", exitHandler)
			document.removeEventListener("mozfullscreenchange", exitHandler)
			document.removeEventListener("MSFullscreenChange", exitHandler)
		}
	}, [setIsFullscreen])

	return (
		<Suspense fallback={null}>
			<div className="bg-white" ref={top}>
				<CSSTransition
					classNames={"alert-background"}
					in={error !== undefined}
					ref={alertRef}
					timeout={300}
					unmountOnExit
				>
					<Alert error={error} ref={alertRef} />
				</CSSTransition>
				<CSSTransition
					classNames={"alert-background"}
					in={showSettings !== showSettingsValue.none}
					ref={cameraSettingsOverlayRef}
					timeout={300}
					unmountOnExit
				>
					<CameraSettingsOverlay
						camera={showSettings === showSettingsValue.moderator}
						ref={cameraSettingsOverlayRef}
					/>
				</CSSTransition>
				<CSSTransition
					classNames={"alert-background"}
					in={showPreview}
					timeout={300}
					unmountOnExit
				>
					<Preview
						preview={preview as [type: string, data: string]}
						ref={previewRef}
					/>
				</CSSTransition>

				<HistoryRouter history={GlobalHistory}>
					<Routes>
						<Route element={<Navigate to="/join" />} index />
						<Route element={<UserLogin />} path="/join">
							<Route element={<UserLogin />} path=":code" />
						</Route>
						<Route
							element={<Tutorial code="123" />}
							path="/practinar/tutorial"
						/>
						<Route element={<HardwareTest />} path="/hardwaretest" />
						<Route
							element={<PrivateRoute component={Practinar} />}
							path="/practinar/:code"
						/>

						{/* behind the scenes */}
						<Route path="/moderator">
							<Route element={<Navigate to="/moderator/webinar" />} index />
							<Route
								element={<Navigate to="/moderator/webinar" />}
								path="dashboard"
							/>

							<Route
								element={<AdminRoute component={Schedule} />}
								path="webinar/schedule/:id"
							/>

							<Route element={<Login />} path="login" />
							<Route element={<ModeratorRegistration />} path="register">
								<Route element={<ModeratorRegistration />} path=":code" />
							</Route>

							<Route path="webinar">
								<Route
									element={<AdminRoute component={Webinars} />}
									path="upcoming"
								/>
								<Route
									element={<AdminRoute component={WebinarsRunning} />}
									path="running"
								/>
								<Route
									element={<AdminRoute component={Live} />}
									path="live/:id"
								/>
								<Route
									element={<AdminRoute component={Webinars} />}
									path="completed"
								/>
								<Route
									element={<AdminRoute component={CreateWebinar} />}
									path="create"
								/>
								<Route
									element={<AdminRoute component={CreateWebinar} />}
									path=":id"
								/>

								<Route element={<Navigate to="upcoming" />} index />
							</Route>

							<Route path="case">
								<Route element={<AdminRoute component={Cases} />} index />
								<Route
									element={<AdminRoute component={CreateCase} />}
									path="create"
								/>
								<Route
									element={<AdminRoute component={CreateCase} />}
									path=":id"
								/>
							</Route>

							<Route path="quiz">
								<Route element={<AdminRoute component={Quizzes} />} index />
								<Route
									element={<AdminRoute component={CreateQuiz} />}
									path="create"
								/>
								<Route
									element={<AdminRoute component={CreateQuiz} />}
									path=":id"
								/>
							</Route>

							<Route path="patient">
								<Route element={<AdminRoute component={Patients} />} index />
								<Route
									element={<AdminRoute component={CreatePatient} />}
									path="create"
								/>
								<Route
									element={<AdminRoute component={CreatePatient} />}
									path=":id"
								/>
							</Route>

							<Route path="user">
								<Route element={<AdminRoute component={Users} />} index />
								<Route
									element={<AdminRoute component={CreateUser} />}
									path="create"
								/>
								<Route
									element={<AdminRoute component={CreateUser} />}
									path=":id"
								/>
							</Route>

							<Route path="media">
								<Route element={<AdminRoute component={Media} />} index />
								<Route
									element={<AdminRoute component={CreateMedia} />}
									path="create"
								/>
								<Route
									element={<AdminRoute component={CreateMedia} />}
									path=":id"
								/>
							</Route>

							<Route
								element={<AdminRoute component={Config} />}
								path="config"
							/>
						</Route>

						{/* mobile screens */}
						<Route element={<Scan />} path="/scan" />
						<Route element={<Scan />} path="/connect/:code" />
						<Route
							element={<PrivateRoute component={Connection} />}
							path="/connection/:code"
						/>

						{/* Test screens  */}
						<Route element={<FiveD />} path="/5d" />
						<Route element={<AdminRoute component={Volume} />} path="/volume" />

						<Route element={<NotFoundPage />} path="/404" />
						<Route element={<Navigate to="/404" />} path="*" />
					</Routes>
				</HistoryRouter>
			</div>
		</Suspense>
	)
}

export default App
