import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import useSWR from "swr"
import DashboardBody from "../../../components/dashboardBody/dashboardBody"
import DashboardHeader from "../../../components/dashboardHeader/dashboardHeader"
import ListView from "../../../components/list/list"
import { HasAuth } from "../../../components/privateRoute/privateRoute"
import { fetchApiAuthorized } from "../../../network/fetch"
import { useErrorStore } from "../../../state/stores/errorStore"

const Patients = ({ auth }: HasAuth) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { data: patients, mutate } = useSWR(
		["/patients", auth.token, auth.expiresIn],
		fetchApiAuthorized
	)
	const deletionConfirmation = useErrorStore(
		(state) => state.deleteConfirmation
	)

	return (
		<DashboardBody>
			<DashboardHeader
				buttonOnClick={() => navigate("/moderator/patient/create")}
				buttonTitle="Datensatz hinzufügen"
				title={t("patients") as string}
			/>
			<ListView
				data={patients}
				loading={patients === undefined}
				title="name"
				onDelete={(v) => {
					deletionConfirmation(async () => {
						await fetchApiAuthorized(
							`/patients/${v.id}`,
							auth.token,
							auth.expiresIn,
							undefined,
							"DELETE"
						)

						mutate()
					})
				}}
				onDublicate={(v) => navigate(`/moderator/patient/create?copy=${v.id}`)}
				onEdit={(v) => navigate(`/moderator/patient/${v.id}`)}
			/>
		</DashboardBody>
	)
}

export default Patients
