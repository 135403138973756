const locations = {
	live: "https://api.sonofun.live",
	beta: "https://api.beta.sonofun.live",
	local: `http://${window.location.host.split(":")[0]}:3000`,
}

const frontendLocations = {
	live: "https://sonofun.live",
	beta: "https://beta.sonofun.live",
	local: `http://${window.location.host.split(":")[0]}:3001`,
}

let location = locations.local

let frontendLocation = frontendLocations.local
if (process.env.NODE_ENV === "production") {
	if (process.env.REACT_APP_STAGING === "1") {
		location = locations.beta
		frontendLocation = frontendLocations.beta
	} else {
		location = locations.live
		frontendLocation = frontendLocations.live
	}
}

export const BASE_URL = location
export const FRONT_BASE_URL = frontendLocation
