import create from "zustand"

export interface ChatMessage extends Record<string, unknown> {
	id: string
	name: string
	timestamp: Date
	message?: string
	emote?: string
	isMod: boolean
	isPrivate?: boolean
}

interface ChatStore extends Record<string, unknown> {
	messages: ChatMessage[]
	onMessage: (message: ChatMessage | ChatMessage[]) => void
	clear: () => void
}

export const useChatStore = create<ChatStore>((set, get) => {
	return {
		messages: [],

		onMessage: (message) => {
			const { messages } = get()

			if (Array.isArray(message)) {
				set({ messages: [...messages, ...message] })
			} else {
				set({ messages: [...messages, message] })
			}
		},

		clear: () => {
			set({ messages: [] })
		},
	}
})
