import { nanoid } from "nanoid"
import { useEffect, useRef, useState } from "react"
import AmiView from "src/components/ami/amiView"
import OptionsSidebar from "src/components/optionsSidebar/optionsSidebar"
import SegmentedControl from "src/components/segmentedControl/segmentedControl"
import { HasAuth } from "../../components/privateRoute/privateRoute"
import { loginWithCode } from "../../network/auth"
import { IRole, useSocketStore } from "../../state/stores/socketStore"

const Volume = ({ auth }: HasAuth) => {
	const threeDContainer = useRef<HTMLDivElement>(null)
	const [code] = useState(`$scan-only-${nanoid(8)}`)
	const [token, setToken] = useState<string | undefined>(undefined)
	const [volumeUrl, setVolumeUrl] = useState<string | undefined>(undefined)
	const [mirrorLeftRight, setMirrorLeftRight] = useState<boolean>(false)
	const [mirrorFrontBack, setMirrorFrontBack] = useState<boolean>(false)

	const join = useSocketStore((state) => state.join)

	useEffect(() => {
		const getToken = async () => {
			const result = await loginWithCode(code)
			if (result) {
				setToken(result.token)
			}
		}

		getToken()
	}, [code])

	useEffect(() => {
		if (token !== undefined) {
			join(token, IRole.Participant, "scan-only")
		}
	}, [join, token])

	const zoom = useRef(1)
	const [_zoom, setZoom] = useState(1)

	return (
		<div
			ref={threeDContainer}
			style={{ width: "100vw", height: "100vh", background: "black" }}
		>
			<OptionsSidebar
				auth={auth}
				code={code}
				onChangePatient={(volumeUrl, mirroredLeftRight, mirroredFrontBack) => {
					setVolumeUrl(volumeUrl)
					setMirrorLeftRight(mirroredLeftRight)
					setMirrorFrontBack(mirroredFrontBack)
				}}
			/>

			<div className="flex flex-row items-center justify-between absolute bottom-3 left-3 bg-white rounded-xl z-[1000]">
				<p className="mx-2.5">Zoom:</p>

				<SegmentedControl
					options={[
						{ title: "100%", value: 0 },
						{ title: "150%", value: 1 },
						{ title: "200%", value: 2 },
					]}
					value={_zoom}
					onChange={(value) => {
						zoom.current = value
						setZoom(value)
					}}
				/>
			</div>

			{volumeUrl && (
				<AmiView
					container={threeDContainer}
					key={volumeUrl}
					mirrorFrontBack={mirrorFrontBack}
					mirrorLeftRight={mirrorLeftRight}
					volumeUrl={volumeUrl}
					zoom={zoom}
				/>
			)}
		</div>
	)
}

export default Volume
