import QRCode from "qrcode.react"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import Button, { ButtonTheme } from "src/components/button/button"
import { FRONT_BASE_URL } from "../../constants/constants"

interface PatientInformationProps {
	code: string
	anamnesis: string
}

enum PanelState {
	nothing = 0,
	info = 1,
	connection = 2,
}

const PatientInformation = ({ code, anamnesis }: PatientInformationProps) => {
	const { t } = useTranslation()

	const [panelState, setPanelState] = useState(PanelState.nothing)

	const wrapper = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const onHandleClick = (event: MouseEvent) => {
			if (
				wrapper.current &&
				event.target &&
				// @ts-ignore
				!wrapper.current.contains(event.target)
			) {
				setPanelState(0)
			}
		}

		document.addEventListener("mousedown", onHandleClick)

		return () => document.removeEventListener("mousedown", onHandleClick)
	}, [])

	return (
		<>
			<div
				className="absolute top-7 right-7 left-7 z-20 flex flex-col items-end"
				ref={wrapper}
			>
				<div className="flex flex-row justify-end mb-2">
					<Button
						theme={panelState === 2 ? ButtonTheme.Blue : ButtonTheme.White}
						onClick={() => {
							setPanelState((v) => (v === 2 ? 0 : 2))
						}}
					>
						{panelState === 2 ? t("close") : t("reconnect")}
					</Button>
					<Button
						className="ml-2.5 whitespace-nowrap w-40"
						theme={panelState === 1 ? ButtonTheme.Blue : ButtonTheme.White}
						onClick={() => {
							setPanelState((v) => (v === 1 ? 0 : 1))
						}}
					>
						{panelState === 1 ? t("close_info") : t("patient_info")}
					</Button>
				</div>
				{panelState === 1 && (
					<div className="self-strech lg:w-[500px] lg:self-end bg-white rounded-xl p-2">
						<h3 className="my-2">Anamnese:</h3>
						<div
							className="m-5"
							dangerouslySetInnerHTML={{ __html: anamnesis }}
						/>
					</div>
				)}

				{panelState === 2 && (
					<div className="rounded-xl bg-white flex max-h-[400px] flex-col">
						<QRCode
							bgColor={"#ffffff"}
							fgColor={"#000000"}
							includeMargin={false}
							level={"L"}
							renderAs={"svg"}
							size={256}
							style={{ margin: "20px" }}
							value={`${FRONT_BASE_URL}/connect/${code}`}
						/>
					</div>
				)}
			</div>
			{panelState === 1 && (
				<div className="absolute left-0 top-0 right-0 bottom-0 backdrop-blur-sm backdrop-brightness-50 z-10 rounded-xl" />
			)}
		</>
	)
}

export default PatientInformation
