import Clap from "../assets/images/clap.svg"
import ClapSmall from "../assets/images/clapSmall.svg"
import No from "../assets/images/no.svg"
import NoSmall from "../assets/images/noSmall.svg"
import Question from "../assets/images/question.svg"
import QuestionSmall from "../assets/images/questionSmall.svg"
import RaiseHand from "../assets/images/raiseHand.svg"
import RaiseHandSmall from "../assets/images/raiseHandSmall.svg"
import Slower from "../assets/images/slower.svg"
import SlowerSmall from "../assets/images/slowerSmall.svg"
import Yes from "../assets/images/yes.svg"
import YesSmall from "../assets/images/yesSmall.svg"

// TODO: enum
export const iconForEmote = (emote?: string, small = false) => {
	switch (emote) {
		case "yes":
			if (small) {
				return YesSmall
			} else {
				return Yes
			}
		case "no":
			if (small) {
				return NoSmall
			} else {
				return No
			}
		case "slower":
			if (small) {
				return SlowerSmall
			} else {
				return Slower
			}
		case "question":
			if (small) {
				return QuestionSmall
			} else {
				return Question
			}
		case "clap":
			if (small) {
				return ClapSmall
			} else {
				return Clap
			}
		default:
			if (small) {
				return RaiseHandSmall
			} else {
				return RaiseHand
			}
	}
}
