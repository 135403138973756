import { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import useSWR from "swr"
import { logout } from "../../network/auth"
import { fetchApiAuthorized } from "../../network/fetch"
import { useAuthStore } from "../../state/stores/authStore"
import Button, { ButtonTheme } from "../button/button"
import LanguageButton from "../button/languageButton"
import Logos from "../logos/logos"
import NavigationPoint from "./navigationPoint"

const Sidebar = () => {
	const { pathname } = useLocation()

	const indexForPathname = useCallback((): number | undefined => {
		let index
		switch (pathname) {
			case "/moderator/webinar/running":
				index = 0
				break
			case "/moderator/webinar/upcoming":
				index = 1
				break
			case "/moderator/webinar/completed":
				index = 2
				break
			case "/moderator/case":
				index = 3
				break
			case "/moderator/quiz":
				index = 4
				break
			case "/moderator/patient":
				index = 5
				break
			case "/moderator/media":
				index = 6
				break
			case "/moderator/user":
				index = 7
				break
			case "/moderator/config":
				index = 8
				break

			default:
				break
		}

		return index
	}, [pathname])

	const { t } = useTranslation()
	const [offset, setOffset] = useState<number | undefined>(undefined)
	const [activeIndex, setActiveIndex] = useState(indexForPathname())
	const navigationPoints = useRef<HTMLElement[]>(new Array(7))

	const token = useAuthStore((state) => state.adminToken)
	const expiresIn = useAuthStore((state) => state.adminExpiresIn)

	const { data: moderator } = useSWR(
		["/users/current", token, expiresIn],
		fetchApiAuthorized
	)

	useEffect(() => {
		const index = indexForPathname()
		if (index === undefined) return

		const ref = navigationPoints.current[index]

		setActiveIndex(index)

		if (ref) {
			setOffset(ref.offsetTop - ref.getBoundingClientRect().height / 2)
		}
	}, [pathname, indexForPathname])

	const navigationChange = useCallback((index: number | undefined) => {
		setActiveIndex(index)

		if (index === undefined) return

		const ref = navigationPoints.current[index]
		if (ref) {
			setOffset(ref.offsetTop - ref.getBoundingClientRect().height / 2)
		}
	}, [])

	const onHover = useCallback(
		(index: number | undefined) => {
			navigationChange(index)
		},
		[navigationChange]
	)

	const onOut = useCallback(() => {
		navigationChange(indexForPathname())
	}, [indexForPathname, navigationChange])

	return (
		<div className="bg-slate-50 box-border w-80 py-5 pr-3 pl-7 z-10 fixed overflow-scroll min-h-screen flex flex-col">
			<div className="flex flex-row items-center justify-between mb-7 w-full">
				<h2 className="text-s-blue mb-0">SonoFun Live!</h2>
				<LanguageButton className="mr-0" />
			</div>

			{offset !== undefined && (
				<div
					className={`bg-s-blue rounded-xl transition-all h-11 w-72 -z-10 absolute left-4 ${
						offset ? "opacity-100" : "opacity-0"
					}`}
					style={{ top: offset ? offset + "px" : "0px" }}
				/>
			)}

			<div onMouseLeave={onOut}>
				<div className="pb-5">
					<h5 className="text-slate-400 pb-4 font-normal">{t("webinars")}</h5>

					<NavigationPoint
						active={activeIndex === 0}
						icon="next"
						pathname="/moderator/webinar/running"
						ref={(r) => r && (navigationPoints.current[0] = r)}
						title={t("running") as string}
						onMouseOver={() => onHover(0)}
					/>

					<NavigationPoint
						active={activeIndex === 1}
						icon="next"
						pathname="/moderator/webinar/upcoming"
						ref={(r) => r && (navigationPoints.current[1] = r)}
						title={t("upcoming") as string}
						onMouseOver={() => onHover(1)}
					/>

					<NavigationPoint
						active={activeIndex === 2}
						icon="done"
						pathname="/moderator/webinar/completed"
						ref={(r) => r && (navigationPoints.current[2] = r)}
						title={t("completed") as string}
						onMouseOver={() => onHover(2)}
					/>
				</div>

				<div className="pb-5">
					<h5 className="text-slate-400 pb-4 font-normal">{t("content")}</h5>

					<NavigationPoint
						active={activeIndex === 3}
						icon="case"
						pathname="/moderator/case"
						ref={(r) => r && (navigationPoints.current[3] = r)}
						title={t("cases") as string}
						onMouseOver={() => onHover(3)}
					/>

					<NavigationPoint
						active={activeIndex === 4}
						icon="quiz"
						pathname="/moderator/quiz"
						ref={(r) => r && (navigationPoints.current[4] = r)}
						title={t("quiz") as string}
						onMouseOver={() => onHover(4)}
					/>

					<NavigationPoint
						active={activeIndex === 5}
						icon="patient"
						pathname="/moderator/patient"
						ref={(r) => r && (navigationPoints.current[5] = r)}
						title={t("patients") as string}
						onMouseOver={() => onHover(5)}
					/>

					<NavigationPoint
						active={activeIndex === 6}
						icon="media"
						pathname="/moderator/media"
						ref={(r) => r && (navigationPoints.current[6] = r)}
						title={t("media") as string}
						onMouseOver={() => onHover(6)}
					/>
				</div>

				<div className="pb-5">
					<h5 className="text-slate-400 pb-4 font-normal">{t("management")}</h5>

					<NavigationPoint
						active={activeIndex === 7}
						icon="user"
						pathname="/moderator/user"
						ref={(r) => r && (navigationPoints.current[7] = r)}
						title={t("user") as string}
						onMouseOver={() => onHover(7)}
					/>

					<NavigationPoint
						active={activeIndex === 8}
						icon="config"
						pathname="/moderator/config"
						ref={(r) => r && (navigationPoints.current[8] = r)}
						title={t("config") as string}
						onMouseOver={() => onHover(8)}
					/>
				</div>
			</div>

			<div style={{ flex: 1 }} />

			<div className="flex flex-row justify-between w-full mb-10">
				<div className="flex flex-col">
					<p className="text-slate-400 mb-2.5">{t("logged_in_as")}</p>
					<p className="text-base">
						{moderator && `${moderator.title} ${moderator.name}`}
					</p>
				</div>
				<Button
					className="self-center"
					theme={ButtonTheme.White}
					onClick={logout}
				>
					{t("log_out")}
				</Button>
			</div>
			<Logos />
		</div>
	)
}

export default Sidebar
