import AddImage from "src/assets/images/add.svg"
import MoveImage from "src/assets/images/move.svg"
import PreviewImage from "src/assets/images/preview.svg"
import TrashImage from "src/assets/images/trash.svg"

export enum IconImage {
	Move = "Move",
	Trash = "Trash",
	Preview = "Preview",
	Add = "Add",
}

const Icon = ({
	iconImage,
	className,
	onClick,
	src,
}: {
	iconImage?: IconImage
	className?: string
	onClick?: () => void
	src?: string
}) => {
	const _src = () => {
		if (src) {
			return src
		}

		switch (iconImage) {
			case IconImage.Move:
				return MoveImage
			case IconImage.Trash:
				return TrashImage
			case IconImage.Preview:
				return PreviewImage
			case IconImage.Add:
				return AddImage

			default:
				break
		}
	}

	return (
		<img
			alt={iconImage}
			className={`mr-2.5 cursor-pointer ${className}`}
			draggable={false}
			src={_src()}
			onClick={onClick}
		/>
	)
}

export const MoveIcon = () => {
	return (
		<Icon
			className="cursor-move hover:opacity-0 transition-opacity"
			iconImage={IconImage.Move}
		/>
	)
}

export default Icon
