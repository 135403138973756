import { CSSProperties, PropsWithChildren, RefObject } from "react"

interface LaserProps {
	childRef: RefObject<HTMLDivElement | undefined>
	positions: number[] | undefined
	style?: CSSProperties
}

const Laser = ({
	childRef,
	children,
	positions,
	style,
}: PropsWithChildren<LaserProps>) => {
	let _position = undefined
	if (positions !== undefined && childRef.current) {
		_position = {
			left:
				childRef.current.offsetWidth * positions[0] +
				childRef.current.offsetLeft -
				10 +
				"px",
			top:
				childRef.current.offsetHeight * positions[1] +
				childRef.current.offsetTop -
				10 +
				"px",
		}
	}

	return (
		<div
			className="relative flex-1 flex self-stretch items-center justify-center"
			style={style}
		>
			{children}
			<div
				className={`w-5 h-5 absolute pointer-events-none z-50 ${
					positions !== undefined ? `opacity-100` : "opacity-0"
				}`}
				style={{
					left: _position?.left ?? "initial",
					top: _position?.top ?? "initial",
				}}
			>
				<svg
					fill="none"
					height="20"
					viewBox="0 0 20 20"
					width="20"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="10" cy="10" fill="url(#paint0_radial)" r="10" />
					<g filter="url(#filter0_d)">
						<circle cx="10" cy="10" r="3" stroke="#F42C04" stroke-width="2" />
					</g>
					<defs>
						<filter
							color-interpolation-filters="sRGB"
							filterUnits="userSpaceOnUse"
							height="18"
							id="filter0_d"
							width="18"
							x="1"
							y="1"
						>
							<feFlood flood-opacity="0" result="BackgroundImageFix" />
							<feColorMatrix
								in="SourceAlpha"
								type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							/>
							<feOffset />
							<feGaussianBlur stdDeviation="2.5" />
							<feColorMatrix
								type="matrix"
								values="0 0 0 0 0.956863 0 0 0 0 0.172549 0 0 0 0 0.0156863 0 0 0 0.5 0"
							/>
							<feBlend
								in2="BackgroundImageFix"
								mode="normal"
								result="effect1_dropShadow"
							/>
							<feBlend
								in="SourceGraphic"
								in2="effect1_dropShadow"
								mode="normal"
								result="shape"
							/>
						</filter>
						<radialGradient
							cx="0"
							cy="0"
							gradientTransform="translate(10 10) rotate(90) scale(10)"
							gradientUnits="userSpaceOnUse"
							id="paint0_radial"
							r="1"
						>
							<stop stop-color="#F42C04" stop-opacity="0.5" />
							<stop offset="1" stop-color="#F42C04" stop-opacity="0" />
						</radialGradient>
					</defs>
				</svg>
			</div>
		</div>
	)
}

export default Laser
