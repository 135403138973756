import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import useSWR from "swr"
import DashboardBody from "../../../components/dashboardBody/dashboardBody"
import DashboardHeader from "../../../components/dashboardHeader/dashboardHeader"
import ListView from "../../../components/list/list"
import { HasAuth } from "../../../components/privateRoute/privateRoute"
import { fetchApiAuthorized } from "../../../network/fetch"
import { useErrorStore } from "../../../state/stores/errorStore"

const Quizzes = ({ auth }: HasAuth) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { data: quizzes, mutate } = useSWR(
		["/quizzes", auth.token, auth.expiresIn],
		fetchApiAuthorized
	)

	const deletionConfirmation = useErrorStore(
		(state) => state.deleteConfirmation
	)

	return (
		<DashboardBody>
			<DashboardHeader
				buttonOnClick={() => navigate("/moderator/quiz/create")}
				buttonTitle={t("add_question") as string}
				title={t("questions") as string}
			/>

			<ListView
				data={quizzes}
				loading={quizzes === undefined}
				title="question"
				onDelete={(v) => {
					deletionConfirmation(async () => {
						await fetchApiAuthorized(
							`/quizzes/${v.id}`,
							auth.token,
							auth.expiresIn,
							undefined,
							"DELETE"
						)

						mutate()
					})
				}}
				onEdit={(v) => navigate(`/moderator/quiz/${v.id}`)}
			/>
		</DashboardBody>
	)
}

export default Quizzes
