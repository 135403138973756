import moment from "moment"
import { useCallback, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import onBeforeGetContent, { useReactToPrint } from "react-to-print"
import Number from "src/components/number/number"
import useSWR from "swr"
import logo from "../../../../assets/images/logoBw.svg"
import DashboardHeader from "../../../../components/dashboardHeader/dashboardHeader"
import { HasAuth } from "../../../../components/privateRoute/privateRoute"
import { useQuery } from "../../../../hooks/useQuery"
import { fetchApiAuthorized } from "../../../../network/fetch"
import { formatName } from "../../../../utils/formatName"
import { quizLetters } from "../../../../utils/formatQuiz"

const Schedule = ({ auth }: HasAuth) => {
	const { id } = useParams() as Record<string, string>
	const { t } = useTranslation()
	const query = useQuery()

	const printable = !window.location.href.includes("?")

	const printRef = useRef<HTMLDivElement>(null)
	const handlePrint = useReactToPrint({
		//@ts-ignore
		content: () => printRef.current,
	})

	const { data: currentWebinar } = useSWR(
		id ? [`/webinars/${id}`, auth.token, auth.expiresIn] : null,
		fetchApiAuthorized
	)

	const content = (type: string, data: Record<string, unknown>) => {
		switch (type) {
			case "waiting":
				return null
			case "presentation":
				return null
			case "patient":
				return (
					<div className="flex flex-row flex-1">
						<div className="flex flex-col justify-start flex-[2]">
							<h4 className="mt-1">
								<>Name: {data.name}</>
							</h4>
							<div
								dangerouslySetInnerHTML={{
									__html: data.descriptionHtml as string,
								}}
							/>
						</div>
						<img alt="Vorschau" src={data.imageUrl as string} width="30%" />
					</div>
				)
			case "quiz":
				return (
					<div className="flex flex-row justify-between flex-1">
						<div className="flex flex-col mt-[3.4px]">
							<p className="mt-1.5">
								<>
									Frage: {data.question} (
									{data.multiplePossible
										? "Mehrere Antworten sind möglich"
										: "Eine Antwort ist richtig"}
									)
								</>
							</p>
							{(data.answers as string[]).map((v, i) => (
								<p
									className={`${
										(data.solution as number[]).includes(i) ? "text-s-blue" : ""
									} ml-8 mt-1`}
									key={i}
								>
									{quizLetters[i]}: {v}
								</p>
							))}
						</div>
						<img alt="Vorschau" src={data.imageUrl as string} width="30%" />
					</div>
				)
			case "media":
				return (
					<div className="flex flex-col items-start">
						<p className="mr-5">
							<>Name: {data.name}</>
						</p>
						<img
							alt={data.name as string}
							height="400px"
							src={
								data.type === "video"
									? (data.thumbnailUrl as string)
									: (data.url as string)
							}
						/>
					</div>
				)
			case "sendoff":
				return null

			default:
				return null
		}
	}

	const close = useCallback(() => {
		window.close()
	}, [])

	useEffect(() => {
		const update = () => {
			const index = query.get("index")
			if (!index || !printRef || !printRef.current) return

			const element = document.getElementById(index)
			if (!element) return

			window.scrollTo(0, element.offsetTop + 80)
		}

		if (!currentWebinar) return

		setTimeout(() => {
			window.requestAnimationFrame(update)
		}, 100)
	}, [query, printRef, currentWebinar])

	return (
		<>
			<DashboardHeader
				// TODO: translte
				buttonOnClick={printable ? handlePrint : close}
				buttonTitle={printable ? "Drucken" : "Schliessen"}
				cancelButtonOnClick={printable ? close : undefined}
				cancelButtonTitle={t("cancel") as string}
				title="Practinar Programm"
			/>
			<div className="overflow-scroll">
				{currentWebinar && (
					<div className="flex flex-col" ref={printRef}>
						<div className="flex flex-col items-start m-5">
							{currentWebinar.title && <h2>{currentWebinar.title}</h2>}
							<div className="flex flex-row">
								<p>
									{moment(currentWebinar.date).format("DD. MMMM YYYY, HH:mm")}
								</p>
								<p className="text-s-blue ml-1.5">
									{currentWebinar.participants.length} Teilnehmer
								</p>
							</div>
						</div>

						<div
							className={`${
								onBeforeGetContent ? "block" : "hidden"
							} absolute right-5 top-5`}
						>
							<img alt="SonoFun Live" src={logo} width="100px" />
						</div>

						<div className="flex flex-col relative ">
							<div className="bg-s-blue w-1 absolute left-[26px] top-7 bottom-7" />
							{currentWebinar.schedule.map(
								(v: Record<string, unknown>, i: number) => (
									<div
										className={`flex flex-col px-4 py-2 ${
											query.get("index") === i.toString()
												? "rgba(0,113,255,0.3)"
												: currentWebinar.schedule
														.filter((_x: any, i: number) => i % 2)
														.includes(v)
												? "bg-slate-50"
												: ""
										} ${
											v.type === "quiz" ? "justify-start" : "justify-between"
										}`}
										key={i}
									>
										<div className="flex flex-row items-center justify-start">
											<Number className="z-[100]">{i + 1}</Number>
											<h3>{formatName(v.type as string)}</h3>
										</div>
										<div className="ml-9">
											{content(
												v.type as string,
												v.data as Record<string, string>
											)}
										</div>
									</div>
								)
							)}
						</div>
					</div>
				)}
			</div>
		</>
	)
}

export default Schedule
