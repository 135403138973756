import { Property } from "csstype"
import { ChangeEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Button, { ButtonTheme } from "src/components/button/button"
import Input from "src/components/input/input"

interface MediaUploadProps {
	title: string
	onChange: (file: File) => void
	accept: string
	existingData: any
	marginBottom?: Property.MarginBottom
	borderColor?: Property.BorderColor
	onDelete?: () => void
}

enum DataType {
	image = 0,
	video = 1,
	string = 2,
}

const MediaUpload = ({
	title,
	onChange,
	accept,
	existingData,
	marginBottom,
	borderColor,
	onDelete,
}: MediaUploadProps) => {
	const [file, setFile] = useState<File | undefined>(undefined)
	const [source, setSource] = useState(undefined)
	const [dataType, setDataType] = useState<DataType | undefined>(undefined)
	const { t } = useTranslation()

	useEffect(() => {
		let source
		let dataType
		if (file) {
			if (file["type"].split("/")[0] === "image") {
				dataType = 0
				source = URL.createObjectURL(file)
			} else if (file["type"].split("/")[0] === "video") {
				dataType = 1
				source = URL.createObjectURL(file)
			} else if (file.name) {
				dataType = 2
				source = file.name
			}
			// TODO: else case
		} else if (existingData) {
			source = existingData.url
			if (existingData.type === "video") {
				dataType = 1
			} else if (existingData.type === "image") {
				dataType = 0
			} else if (existingData.type === "string") {
				dataType = 2
				source = existingData.data
			} else {
				dataType = 0
				source = existingData
			}
		}

		setSource(source)
		setDataType(dataType)
	}, [file, existingData])

	let preview
	let changeText
	if (source) {
		switch (dataType) {
			case 0:
				preview = (
					<img
						alt="preview"
						className="mb-5 ml-20"
						src={source}
						width="300px"
					/>
				)
				changeText = "Bild"
				break
			case 1:
				preview = (
					<video
						controls={true}
						loop={true}
						playsInline={true}
						src={source}
						style={{ margin: "20px 0px 20px 90px" }}
						width="300px"
						muted
					/>
				)
				changeText = "Video"
				break
			case 2:
				preview = <p className="my-5 ml-24">{source}</p>
				changeText = "Volumen"
				break
			default:
				break
		}
	}

	return (
		<>
			{preview}

			<div
				className="flex flex-row items-baseline mt-5 relative"
				// FIXME:
				style={{
					marginBottom: marginBottom ? marginBottom : "20px",
				}}
			>
				<p className="w-24">{title}</p>
				<div
					style={{
						position: "relative",
						flex: 1,
					}}
				>
					<Input
						accept={accept}
						className={`${borderColor} w-full`}
						type="file"
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							const { files } = e.target
							if (files && files.length > 0) {
								const file = files[0]

								setFile(file)
								onChange(file)
							}
						}}
					/>
					{changeText && (
						<div className="rounded-xl absolute top-1 bottom-1 left-1 right-1 pointer-events-none bg-white flex items-center px-2 py-0">
							<h2>Um das {changeText} zu ändern</h2>
						</div>
					)}
				</div>
				{onDelete && existingData && (
					<Button
						className="ml-2.5"
						theme={ButtonTheme.WhiteOnRed}
						onClick={onDelete}
					>
						{t("delete")}
					</Button>
				)}
			</div>
		</>
	)
}

export default MediaUpload
