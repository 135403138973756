import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import Button, { ButtonTheme } from "src/components/button/button"
import Number from "src/components/number/number"
import useSWR from "swr"
import Body from "../../../../components/body/body"
import Chat from "../../../../components/chat/chat"
import Media, { MediaType } from "../../../../components/media/moderator/media"
import MiniCam from "../../../../components/miniCam/miniCam"
import Participants from "../../../../components/participants/participants"
import Patient from "../../../../components/patient/moderator/patient"
import { HasAuth } from "../../../../components/privateRoute/privateRoute"
import Quiz from "../../../../components/quiz/moderator/quiz"
import Sendoff from "../../../../components/sendoff/moderator/sendoff"
import { fetchApiAuthorized } from "../../../../network/fetch"
import { useChatStore } from "../../../../state/stores/chatStore"
import { useSceneStore } from "../../../../state/stores/sceneStore"
import { useSettingsStore } from "../../../../state/stores/settingsStore"
import { IRole, useSocketStore } from "../../../../state/stores/socketStore"

const Live = ({ auth }: HasAuth) => {
	const { t } = useTranslation()
	const { id } = useParams() as Record<string, string>

	const join = useSocketStore((state) => state.join)
	const onScene = useSocketStore((state) => state.emit.scene)
	const leave = useSocketStore((state) => state.leave)
	const updateDevices = useSettingsStore((state) => state.updateIO)
	const clear = useChatStore((state) => state.clear)

	const [showPermissionOverlay, setShowPermissionOverlay] = useState(false)
	const [hasPermissions, setHasPermissions] = useState(false)

	const { data: practinar } = useSWR(
		[`/webinars/${id}`, auth.token, auth.expiresIn],
		fetchApiAuthorized
	)
	const scheduleIndex = useSceneStore((state) => state.index)

	const { data: moderator } = useSWR(
		["/users/current", auth.token, auth.expiresIn],
		fetchApiAuthorized
	)

	let status = "presentation"
	if (scheduleIndex && practinar && practinar.schedule[scheduleIndex]) {
		status = practinar.schedule[scheduleIndex].type as string
	}

	useEffect(() => {
		return () => clear()
	}, [clear])

	useEffect(() => {
		const onunload = () => {
			leave()
			clear()
		}

		window.addEventListener("unload", onunload)

		return () => window.removeEventListener("unload", onunload)
	}, [leave, clear])

	useEffect(() => {
		if (auth.token && id) {
			join(auth.token, IRole.Admin, id)
		}
	}, [auth.token, id, join])

	const _setStatus = (isNext: boolean) => {
		if (!practinar || !practinar.schedule) return

		let index: number | undefined = undefined
		if (isNext && scheduleIndex < practinar.schedule.length - 1) {
			index = scheduleIndex + 1
		} else if (!isNext && scheduleIndex > 0) {
			index = scheduleIndex - 1
		}

		if (index !== undefined) {
			onScene(index)
		}
	}

	const renderCurrentScheduleItem = () => {
		if (!practinar || !practinar.schedule) return

		const currentItem = practinar.schedule[scheduleIndex] as Record<
			string,
			unknown
		>
		if (currentItem === undefined) return <div />

		const data = currentItem.data as Record<string, unknown>

		let content
		switch (currentItem.type) {
			case "media":
				content = (
					<Media
						name={data.name as string}
						type={data.type as MediaType}
						url={data.url as string}
					/>
				)
				break
			case "quiz":
				content = <Quiz data={data} />
				break
			case "patient":
				content = <Patient data={data} />
				break
			case "sendoff":
				content = <Sendoff />
				break

			default:
				break
		}

		return (
			<div className="flex flex-[6] bg-slate-100 rounded-xl shadow-md items-center justify-start relative overflow-scroll mb-2 p-4">
				{content}
			</div>
		)
	}

	// TODO: cameraPermission and this same code refacotr hook
	const checkPermission = useCallback(async () => {
		let hasPermissions = false
		const devices = await navigator.mediaDevices.enumerateDevices()
		devices.forEach((device) => {
			if (device.label && device.label.length > 0) {
				hasPermissions = true
			}
		})

		setHasPermissions(hasPermissions)
		setShowPermissionOverlay(!hasPermissions)

		if (hasPermissions) {
			updateDevices(devices)
		}
	}, [updateDevices])

	const askForPermission = useCallback(async () => {
		await navigator.mediaDevices.getUserMedia({ audio: true, video: true })

		checkPermission()
	}, [checkPermission])

	useEffect(() => {
		checkPermission()

		askForPermission()
	}, [checkPermission, askForPermission])

	useEffect(() => {
		const onDeviceChange = async () => {
			const devices = await navigator.mediaDevices.enumerateDevices()
			updateDevices(devices)
		}

		navigator.mediaDevices.addEventListener("devicechange", onDeviceChange)

		return () =>
			navigator.mediaDevices.removeEventListener("devicechange", onDeviceChange)
	}, [updateDevices])

	return practinar === undefined ? (
		<div>Loading…</div>
	) : showPermissionOverlay ? (
		<div className="bg-s-overlay-bg absolute top-0 left-0 right-0 bottom-0 z-10 flex items-center justify-center">
			<div className="flex flex-col items-start space-y-2.5 shadow-md bg-white p-4 rounded-lg w-full md:max-w-lg mx-5 md:mx-0">
				<h3>{t("camera_permission_1")}</h3>
				<p>{t("camera_permission_2")}</p>
				<p>
					{t("camera_permission_3")}{" "}
					<a
						href="https://help.doxy.me/en/articles/95877-allow-access-to-camera-mic-for-all-browsers-operating-systems"
						rel="noopener noreferrer"
						target="_blank"
					>
						{t("camera_permission_4")}
					</a>
				</p>
			</div>
		</div>
	) : (
		<Body showHeader={false}>
			<div className="flex flex-row flex-1 h-full">
				<div className="flex flex-col flex-1 pr-2">
					<div className="flex flex-row justify-between relative mb-2">
						<div
							style={{
								position: "absolute",
								left: 0,
								right: 0,
								top: 0,
								bottom: 0,
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<div className="flex flex-row items-center justify-start">
								<Number>{scheduleIndex + 1}</Number>
								<h2 className="text-s-blue m-0">{t(status)}</h2>
							</div>
						</div>

						<div className="flex flex-row space-x-2">
							{scheduleIndex > 0 && practinar.schedule[scheduleIndex - 1] && (
								<>
									<Button
										theme={ButtonTheme.White}
										onClick={() => _setStatus(false)}
									>
										◄ {t(practinar.schedule[scheduleIndex - 1].type as string)}
									</Button>
									{scheduleIndex < practinar.schedule.length - 2 && (
										<Button
											className=""
											theme={ButtonTheme.RedOnWhite}
											onClick={() => onScene(0)}
										>
											◄◄
										</Button>
									)}
								</>
							)}
							<Button
								theme={ButtonTheme.White}
								onClick={() =>
									window.open(
										`/moderator/webinar/schedule/${id}?index=${scheduleIndex}`,
										"_blank"
									)
								}
							>
								Programm
							</Button>
						</div>
						<div className="flex flex-row space-x-2">
							{scheduleIndex < practinar.schedule.length - 2 && (
								<Button
									theme={ButtonTheme.RedOnWhite}
									onClick={() => onScene(practinar.schedule.length - 2)}
								>
									►►
								</Button>
							)}

							{scheduleIndex < practinar.schedule.length - 1 ? (
								<Button onClick={() => _setStatus(true)}>
									{t(practinar.schedule[scheduleIndex + 1].type as string)} ►
								</Button>
							) : (
								<h3 className="self-center">Practinar im Bedienfeld beenden</h3>
							)}
						</div>
					</div>

					{status !== "presentation" && renderCurrentScheduleItem()}

					<div
						className="flex flex-[4] bg-slate-100 rounded-xl shadow-md items-center relative overflow-hidden"
						style={{
							height: "calc(100% - 45px)",
						}}
					>
						<Participants canMuteParticipants />
					</div>
				</div>

				<div className="flex flex-col justify-between w-[400px]">
					<MiniCam auth={auth} isModerator />
					<Chat moderatorData={moderator} isModerator />
				</div>
			</div>
		</Body>
	)
}

export default Live
