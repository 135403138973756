import MarkdownIt from "markdown-it"
import MdEditor from "react-markdown-editor-lite"

const Editor = ({
	markdown,
	onChange,
	onImageUpload,
}: {
	markdown: string
	onChange: (markdown: string, html: string) => void
	onImageUpload?: (file: File) => Promise<string | ArrayBuffer | null>
}) => {
	const mdParser = new MarkdownIt()

	return (
		<MdEditor
			allowPasteImage={onImageUpload !== undefined}
			className="h-[500px] rounded-xl border border-slate-400 shadow-md overflow-hidden relative"
			config={{
				canView: {
					menu: false,
					md: false,
					html: false,
					fullScreen: false,
					hideMenu: false,
				},
			}}
			renderHTML={(t) => mdParser.render(t)}
			value={markdown}
			onChange={({ text, html }) => {
				onChange(text, html)
			}}
			onImageUpload={onImageUpload}
		/>
	)
}

export default Editor
