import { useFrame, useThree } from "@react-three/fiber"
// @ts-ignore
import { stackHelperFactory, VolumeLoader } from "ami.js"
import { MutableRefObject, Ref, useEffect, useRef } from "react"
import ProgressBar from "src/components/progressBar/progressBar"
import { useSensorStore } from "src/state/stores/sensorStore"
import { degrees_to_radians, remapNumbers } from "src/utils/math"
import * as THREE from "three"
import { Clock, Vector3 } from "three"

const AmiContext = ({
	container,
	volumeUrl,
	mirroredLeftRight,
	mirroredFrontBack,
	zoom,
	clock,
	externalData,
}: {
	container: Ref<HTMLDivElement>
	volumeUrl: string
	mirroredLeftRight: boolean
	mirroredFrontBack: boolean
	zoom: MutableRefObject<number>
	clock?: MutableRefObject<Clock>
	externalData?: MutableRefObject<number[]>
}) => {
	const _data = useRef([0, 0, 0])

	useEffect(() =>
		useSensorStore.subscribe((state) => {
			if (state.data) {
				_data.current = [
					state.data[0],
					remapNumbers(state.data[1], -90, 90, -30, 30),
					state.data[2],
				]
			}
		})
	)

	const { camera, scene } = useThree()

	const stackHelper = useRef<any>(undefined)

	useFrame(({ camera }) => {
		if (stackHelper.current && camera && _data.current) {
			const worldBBox = stackHelper.current.stack.worldBoundingBox()

			const length = (worldBBox[5] - worldBBox[4]) / 6
			const distance = length * 0.05
			const normalDistance = (worldBBox[5] - worldBBox[4]) * 0.05

			let time = 0
			if (clock?.current) {
				time = Math.floor(clock.current.getElapsedTime() * 6) % 5
			}

			const data = externalData ? externalData : _data

			const remappedPosition =
				clock?.current !== undefined
					? remapNumbers(
							data.current[0],
							mirroredFrontBack ? 1 : 0,
							mirroredFrontBack ? 0 : 1,
							worldBBox[4] + distance,
							length - distance
					  )
					: remapNumbers(
							data.current[0],
							mirroredFrontBack ? 1 : 0,
							mirroredFrontBack ? 0 : 1,
							worldBBox[4] + normalDistance,
							worldBBox[5] - normalDistance
					  )

			const planeDir = new Vector3(0, 0, mirroredLeftRight ? 1 : -1)
			planeDir.applyAxisAngle(
				new Vector3(0, 1, 0),
				degrees_to_radians(data.current[2])
			)
			planeDir.applyAxisAngle(
				new Vector3(1, 0, 0),
				degrees_to_radians(data.current[1])
			)

			stackHelper.current.slice.planePosition.z =
				remappedPosition + time * length
			stackHelper.current.slice.planeDirection = planeDir

			const zoomMultiplier = (3 - zoom.current) * 100

			camera.position.x =
				stackHelper.current.slice.planePosition.x + planeDir.x * zoomMultiplier
			camera.position.y =
				stackHelper.current.slice.planePosition.y + planeDir.y * zoomMultiplier
			camera.position.z =
				stackHelper.current.slice.planePosition.z + planeDir.z * zoomMultiplier

			camera.lookAt(stackHelper.current.slice.planePosition)
			camera.updateProjectionMatrix()
		}
	})

	useEffect(() => {
		// @ts-ignore
		const loader = new VolumeLoader(container, ProgressBar)

		if (volumeUrl) {
			loader.load(volumeUrl).then(() => {
				const series = loader.data[0].mergeSeries(loader.data)[0]
				const stack = series.stack[0]

				const StackHelper = stackHelperFactory(THREE)

				if (stackHelper.current === undefined) {
					stackHelper.current = new StackHelper(stack)
					stackHelper.current.bbox._visible = false
					stackHelper.current.border._visible = false

					const centerLPS = stackHelper.current.stack.worldCenter()
					stackHelper.current.slice.aabbSpace = "LPS"
					stackHelper.current.slice.planePosition.x = centerLPS.x
					stackHelper.current.slice.planePosition.y = centerLPS.y
					stackHelper.current.slice.planePosition.z = centerLPS.z
					stackHelper.current.slice.thickness = 0
					stackHelper.current.slice.spacing = 1
					stackHelper.current.slice.interpolation = 1
					scene.add(stackHelper.current)

					stackHelper.current.slice.planeDirection = new Vector3(0, 0, 1)
				} else {
					stackHelper.current._stack = stack
				}

				if (camera) {
					const centerLPS = stackHelper.current.stack.worldCenter()

					camera.lookAt(centerLPS.x, centerLPS.y, centerLPS.z)
					camera.updateProjectionMatrix()
				}
			})
		}
	}, [camera, scene, container, volumeUrl])

	return null
}

export default AmiContext
