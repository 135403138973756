import moment from "moment"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import Button from "src/components/button/button"
import ArrowRight from "../../../assets/images/arrowRight.svg"
import Body from "../../../components/body/body"
import CameraPermission from "../../../components/cameraPermission/cameraPermission"
import Step from "../../../components/step/step"
import { useSettingsStore } from "../../../state/stores/settingsStore"
import ChatTutorial from "./chat"
import Check from "./check"
import Explanation from "./explanation"
import ScanVideo from "./scanVideo"

interface TutorialProps {
	code: string
}

const Tutorial = ({ code }: TutorialProps) => {
	const { t } = useTranslation()
	const [nextDisabled, setNextDisabled] = useState(true)
	const [step, setStep] = useState(0)

	moment().locale("de")

	let content
	switch (step) {
		case 1:
			content = <Explanation />
			break
		case 2:
			content = <CameraPermission />
			break
		case 3:
			content = <ChatTutorial />
			break
		case 4:
			content = <ScanVideo />
			break
		default:
			content = <Check onCheck={(capable) => setNextDisabled(capable)} />
	}

	return (
		<Body>
			<div className="flex flex-col flex-1 justify-between items-center overflow-scroll transition-all duration-300 ease-in-out w-full">
				<Step step={step} />
				{content}
				{step < 6 && (
					<Button
						className="self-center mb-12 min-h-[45px] w-36"
						disabled={nextDisabled}
						onClick={() => {
							if (step === 4) {
								useSettingsStore.getState().completeTutorial(code)
							} else {
								setStep(step + 1)
							}
						}}
					>
						{t("next")}
						<img
							alt="Continue"
							src={ArrowRight}
							style={{ marginLeft: "10px" }}
						/>
					</Button>
				)}
			</div>
		</Body>
	)
}

export default Tutorial
