import { useEffect, useRef } from "react"
import LoadingBlue from "../../../assets/images/loadingBlue.gif"
import { useLaserStore } from "../../../state/stores/laserStore"
import { useMediaStore } from "../../../state/stores/mediaStore"
import Laser from "../../laser/laser"
import ResponsiveImage from "../../responsiveImage/responsiveImage"
import ResponsiveVideo from "../../responsiveImage/responsiveVideo"
import { MediaType } from "../moderator/media"

interface UserMediaProps {
	type: MediaType
	url: string
}

const UserMedia = ({ type, url }: UserMediaProps) => {
	const playPause = useMediaStore((state) => state.isPlaying)
	const time = useMediaStore((state) => state.time)
	const laser = useLaserStore((state) => state.positions)

	const measureContainer = useRef<HTMLDivElement | undefined>(undefined)
	const video = useRef<HTMLVideoElement | undefined>(undefined)

	useEffect(() => {
		if (video && video.current) {
			if (playPause) {
				video.current.play()
			} else {
				video.current.pause()
			}
		}
	}, [playPause])

	useEffect(() => {
		if (video.current) {
			video.current.currentTime = time
		}
	}, [time])

	return (
		<Laser childRef={measureContainer} positions={laser}>
			<img
				alt="Loading"
				height="12px"
				src={LoadingBlue}
				style={{
					margin: "auto",
					position: "absolute",
					left: 0,
					right: 0,
					bottom: 0,
					top: 0,
				}}
			/>

			{type === "video" ? (
				<ResponsiveVideo
					ref={(r) => {
						if (r?.childNodes[0]) {
							measureContainer.current = r
							video.current = r?.childNodes[0] as HTMLVideoElement
						}
					}}
					style={{ zIndex: 1 }}
					url={url}
				/>
			) : (
				<ResponsiveImage
					ref={(r) => {
						if (r) {
							measureContainer.current = r
						}
					}}
					style={{ zIndex: 1 }}
					url={url}
				/>
			)}
		</Laser>
	)
}

export default UserMedia
