import { forwardRef } from "react"
import { use100vh } from "react-div-100vh"
import Header from "../header/header"

interface BodyProps {
	children: React.ReactNode
	showHeader?: boolean
	padding?: string
	isMobile?: boolean
	headerChildren?: React.ReactNode
	isFullscreen?: boolean
	showFullscreen?: boolean
}

const Body = forwardRef<HTMLDivElement, BodyProps>(
	(
		{
			children,
			showHeader = true,
			padding = "p-2",
			isMobile = false,
			headerChildren,
			isFullscreen = false,
			showFullscreen = true,
		},
		ref
	) => {
		const height = use100vh()

		return isMobile ? (
			//TODO:
			// -webkit-touch-callout: none;
			// -webkit-user-select: none;
			// -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			<div
				className={` w-full min-h-full p-3 box-border flex flex-col select-none`}
				ref={ref}
				style={{ height: isFullscreen ? `${height}px` : "" }}
			>
				{showHeader && <Header isMobile={isMobile}>{headerChildren}</Header>}
				{children}
			</div>
		) : (
			<div
				className={`flex flex-col h-screen box-border ${
					padding ? padding : "p-2"
				} relative overflow-hidden transition-all duration-500 ease-in-out`}
				ref={ref}
			>
				{showHeader && <Header showFullscreen={showFullscreen} />}
				{children}
			</div>
		)
	}
)

export default Body
