import { useTranslation } from "react-i18next"
import Webcam from "react-webcam"
import { useSettingsStore } from "src/state/stores/settingsStore"
import Select from "../input/select"

interface CameraSettingsProps {
	camera: boolean
}

const CameraSettings = ({ camera }: CameraSettingsProps) => {
	const { t } = useTranslation()

	const microphones = useSettingsStore((state) => state.microphones)
	const speakers = useSettingsStore((state) => state.speakers)
	const cameras = useSettingsStore((state) => state.cameras)

	const selectedMicrophone = useSettingsStore(
		(state) => state.selectedMicrophone
	)
	const selectedSpeaker = useSettingsStore((state) => state.selectedSpeaker)
	const selectedCamera = useSettingsStore((state) => state.selectedCamera)

	const setMicrophone = useSettingsStore((state) => state.updateMicrophone)
	const setSpeaker = useSettingsStore((state) => state.updateSpeaker)
	const setCamera = useSettingsStore((state) => state.updateCamera)

	return (
		<div className="flex flex-col space-y-4">
			{camera && (
				<Webcam
					audio={false}
					videoConstraints={{ deviceId: selectedCamera }}
					width={500}
					mirrored
				/>
			)}
			<div className={`flex flex-col space-y-2.5 p-4 ${camera ? "pt-0" : ""}`}>
				<div className="flex flex-row items-center">
					<p className="w-24">{t("microphone")}</p>
					<Select value={selectedMicrophone} onChange={setMicrophone}>
						{microphones.map((v) => (
							<option key={v.deviceId} value={v.deviceId}>
								{v.label}
							</option>
						))}
					</Select>
				</div>

				<div className="flex flex-row items-center">
					<p className="w-24">{t("speaker")}</p>
					{speakers.length > 0 ? (
						<Select value={selectedSpeaker} onChange={setSpeaker}>
							{speakers.map((v) => (
								<option key={v.deviceId} value={v.deviceId}>
									{v.label}
								</option>
							))}
						</Select>
					) : (
						<p>{t("change_in_settings")}</p>
					)}
				</div>

				{camera && (
					<div className="flex flex-row items-center">
						<p className="w-20">{t("camera")}</p>
						<Select value={selectedCamera} onChange={setCamera}>
							{cameras.map((v) => (
								<option key={v.deviceId} value={v.deviceId}>
									{v.label}
								</option>
							))}
						</Select>
					</div>
				)}
			</div>
		</div>
	)
}

export default CameraSettings
