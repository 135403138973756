import { PropsWithChildren, useEffect, useState } from "react"

interface ExpireProps {
	delay: number
}

const Expire = ({ delay = 2000, children }: PropsWithChildren<ExpireProps>) => {
	const [visible, setVisible] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			setVisible(false)
		}, delay)

		setVisible(true)

		return () => clearTimeout(timer)
	}, [children, delay])

	return (
		<div
			className={`transition-all duration-200 ease-in-out ${
				visible ? "opacity-100 scale-100" : "opacity-0 scale-[0.3]"
			}`}
		>
			{children}
		</div>
	)
}

export default Expire
