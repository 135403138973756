import { Navigate, useLocation } from "react-router-dom"
import {
	AuthData,
	authFromStore,
	useAuthStore,
} from "../../state/stores/authStore"

export interface HasAuth {
	auth: AuthData
}

interface PrivateRouteProps {
	component: any
	isAdmin?: boolean
}

export const PrivateRoute = ({
	component: RouteComponent,
	isAdmin = false,
}: PrivateRouteProps) => {
	let location = useLocation()

	const token = useAuthStore((state) =>
		isAdmin ? state.adminToken : state.token
	)
	const expires = useAuthStore((state) =>
		isAdmin ? state.adminExpiresIn : state.expiresIn
	)

	const to = isAdmin ? "/moderator/login" : "/join"
	if (token && expires && expires > new Date().getTime() / 1000) {
		return (
			// @ts-ignore
			<RouteComponent auth={authFromStore(isAdmin)} />
		)
	} else {
		return <Navigate state={{ from: location }} to={to} />
	}
}

export const AdminRoute = ({
	component: RouteComponent,
	...params
}: PrivateRouteProps) => {
	return <PrivateRoute component={RouteComponent} isAdmin={true} {...params} />
}
