import Logos from "../../logos/logos"

const Sendoff = () => {
	return (
		<div className="flex flex-col items-center justify-center flex-1">
			<h1>Vielen Dank für ihre Teilnahme!</h1>
			<Logos />
		</div>
	)
}

export default Sendoff
