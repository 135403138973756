import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuizStore } from "../../../state/stores/quizStore"
import { useSocketStore } from "../../../state/stores/socketStore"
import { mapVotes, quizColors, quizLetters } from "../../../utils/formatQuiz"
import ResponsiveImage from "../../responsiveImage/responsiveImage"

interface QuizProps {
	id: string
	question: string
	multiplePossible: boolean
	solution: number[]
	answers: string[]
	imageUrl: string
}

const Quiz = ({
	id,
	question,
	multiplePossible,
	solution = [],
	answers = [],
	imageUrl,
}: QuizProps) => {
	const { t } = useTranslation()

	const [selected, select] = useState<number[]>([])
	const [showResults, setShowResults] = useState(false)
	const [time, setTime] = useState(5)
	const timerRef = useRef<NodeJS.Timeout>()
	const showResultsTimerRef = useRef<NodeJS.Timeout>()
	const _time = useRef(5)

	const onVote = useSocketStore((state) => state.emit.quiz.onVotes)
	const votes = useQuizStore((state) => state.votes)
	const showResultsAfterTime = useQuizStore((state) => state.showResults)

	const [zoom, setZoom] = useState(false)

	const vote = (index: number) => {
		let _selected = selected
		if (_selected.includes(index)) {
			_selected = _selected.filter((v) => v !== index)
		} else {
			if (multiplePossible) {
				_selected = [..._selected, index]
			} else {
				_selected = [index]
			}
		}

		select(_selected)

		onVote(_selected)
	}

	const quizId = id
	useEffect(() => {
		select([])
	}, [quizId])

	useEffect(() => {
		if (showResultsAfterTime) {
			showResultsTimerRef.current = setTimeout(() => setShowResults(true), 5000)
		}
	}, [showResultsAfterTime])

	useEffect(() => {
		const countdown = () => {
			_time.current -= 1
			setTime(_time.current)
		}

		if (showResultsAfterTime && timerRef.current === undefined) {
			_time.current = 5
			timerRef.current = setInterval(countdown, 1000)
			setTime(5)
		}
	}, [showResultsAfterTime])

	useEffect(() => {
		return () => {
			_time.current = 10
		}
	}, [])

	useEffect(() => {
		return () => {
			if (showResults) {
				setShowResults(false)
				if (timerRef.current) {
					clearInterval(timerRef.current)
					timerRef.current = undefined
				}

				if (showResultsTimerRef.current) {
					clearTimeout(showResultsTimerRef.current)
				}
			}
		}
	}, [showResults])

	useEffect(() => {
		return () => {
			if (showResultsAfterTime) {
				if (showResults) {
					setShowResults(false)
				}
				if (timerRef.current) {
					clearInterval(timerRef.current)
					timerRef.current = undefined
				}

				if (showResultsTimerRef.current) {
					clearTimeout(showResultsTimerRef.current)
				}
			}
		}
	}, [showResultsAfterTime, showResults])

	const stats = (
		<div className="flex space-x-2 pr-0 h-[90%] items-end">
			{mapVotes(answers, votes).map((v: number, i: number) => (
				<div
					className="mt-5 w-full rounded-t-xl p-3 box-border flex flex-col justify-start items-start !border-b-0"
					key={i}
					style={{
						border: "2px solid" + quizColors[i],
						backgroundColor: selected.includes(i) ? quizColors[i] : undefined,
						height: v + "%",
					}}
				>
					<h2
						className={`${v < 20 ? "-mt-14" : "mt-0"} font-bold`}
						style={{
							color: selected.includes(i) && v >= 20 ? "white" : quizColors[i],
						}}
					>
						{votes[i]}
					</h2>
				</div>
			))}
		</div>
	)

	const showTimer = showResultsAfterTime && !showResults

	let top
	if (imageUrl) {
		top = (
			<>
				<div className="flex flex-col items-start flex-[2]">
					<div className="text-center leading-7 font-medium">
						{question} (
						{multiplePossible ? t("multiple_answers") : t("single_answer")})
					</div>
					{showTimer ? (
						<h3 className="quizTimer text-s-red font-medium">
							Antwortzeit endet in: {time}
						</h3>
					) : showResults ? null : (
						<br />
					)}
				</div>
				<div
					className="flex flex-col flex-1 items-end h-20"
					onClick={() => {
						if (!showResults) {
							setZoom(true)
						}
					}}
				>
					<ResponsiveImage
						style={{
							cursor: showResults ? "default" : "zoom-in",
						}}
						url={imageUrl}
					/>
					{!showResults && (
						<div className="cursor-pointer mt-1">Vergrößern</div>
					)}
				</div>
			</>
		)
	} else {
		top = (
			<div className="flex flex-col items-start">
				<div className="text-center leading-7 font-medium">
					{question} (
					{multiplePossible ? t("multiple_answers") : t("single_answer")})
				</div>
				{showTimer ? (
					<h3 className="quizTimer text-s-red font-medium">
						Antwortzeit endet in: {time}
					</h3>
				) : (
					<br />
				)}
			</div>
		)
	}

	return (
		<div className="flex flex-col justify-between flex-1 relative">
			<div className="box-border px-2 pt-2 h-36 flex self-stretch justify-between">
				{top}
			</div>
			<div className="flex flex-col justify-end h-full">
				{showResults ? stats : null}
				{showResults ? <div className="w-full h-1 bg-s-blue mb-2" /> : null}
			</div>
			<div
				className="self-center grid gap-2 w-full box-border"
				style={{
					gridTemplateColumns: showResults
						? new Array(answers.length).fill("minmax(0, 1fr) ").join("")
						: "1fr 1fr",
				}}
			>
				{answers.map((v, i) => (
					<div
						className=" rounded-xl p-3 box-border transition-all duration-75 cursor-pointer justify-between flex-1"
						key={i}
						style={{
							border: "2px solid" + quizColors[i],
							backgroundColor: selected.includes(i) ? quizColors[i] : undefined,
							pointerEvents: showResults ? "none" : undefined,
						}}
						onClick={() => vote(i)}
					>
						<div className="flex flex-row items-center justify-between">
							<h3
								style={{
									color: selected.includes(i) ? "white" : quizColors[i],
								}}
							>
								{quizLetters[i]}
							</h3>
							{showResults && solution.includes(i) && (
								<div className="rounded-xl border-2 border-green-500 py-px px-1 uppercase text-[10px] text-green-500 font-semibold relative -right-2 ml-2 bg-white">
									{t("correct_answer")}
								</div>
							)}
						</div>
						<p
							className="mt-2 font-medium"
							style={{
								color: selected.includes(i) ? "white" : quizColors[i],
							}}
						>
							{answers[i]}
						</p>
					</div>
				))}
			</div>
			{zoom && (
				<div
					className="absolute -inset-2 bg-white bg-opacity-60 flex flex-col p-2 items-center justify-center backdrop-blur-sm rounded-xl"
					onClick={() => setZoom(false)}
				>
					<ResponsiveImage
						padding={40}
						style={{ cursor: "zoom-out" }}
						url={imageUrl as string}
					/>
					<div className="cursor-pointer mt-1">Schliessen</div>
				</div>
			)}
		</div>
	)
}

export default Quiz
