import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import TutorialChat from "../../../assets/images/tutorialChat.svg"
import TutorialContent from "../../../assets/images/tutorialContent.svg"
import TutorialVideo from "../../../assets/images/tutorialVideo.svg"
import FullscreenButton from "../../../components/button/fullscreenButton"

const Explanation = () => {
	const { t } = useTranslation()
	const [animation, animate] = useState(false)

	useEffect(() => {
		setTimeout(() => animate(true), 200)
	}, [])

	return (
		<>
			<div />
			<div className="flex flex-col items-center justify-between w-full">
				<div className="flex flex-row w-[600px] my-14">
					<FullscreenButton className="mr-2.5" />
					<p>
						{t("tutorial_explanation_1")}{" "}
						<span className="text-s-blue">{t("tutorial_explanation_2")}</span>
					</p>
				</div>
				<div className="flex flex-row items-baseline justify-between w-full max-w-6xl">
					<div
						className="flex flex-col items-start w-72 transition-all"
						style={{
							opacity: animation ? 1 : 0,
							transform: animation ? "scale(1)" : "scale(1.3)",
						}}
					>
						<img alt="Content" src={TutorialContent} />
						<br />
						<h2 className="mb-2">{t("content")}</h2>
						<p>{t("tutorial_explanation_3")}</p>
					</div>

					<div
						className="flex flex-col items-start w-72 transition-all"
						style={{
							opacity: animation ? 1 : 0,
							transform: animation ? "scale(1)" : "scale(1.3)",
							transitionDelay: "300ms",
						}}
					>
						<img alt="Video" src={TutorialVideo} />
						<br />
						<h2 className="mb-2">Video</h2>
						<p>{t("tutorial_explanation_4")}</p>
					</div>

					<div
						className="flex flex-col items-start w-72 transition-all"
						style={{
							opacity: animation ? 1 : 0,
							transform: animation ? "scale(1)" : "scale(1.3)",
							transitionDelay: "600ms",
						}}
					>
						<img alt="Chat" src={TutorialChat} />
						<br />
						<h2 className="mb-2">Chat</h2>
						<p>{t("tutorial_explanation_5")}</p>
					</div>
				</div>
			</div>
		</>
	)
}

export default Explanation
