import { PropsWithChildren } from "react"

const Number = ({
	children,
	className,
}: PropsWithChildren<{ className?: string }>) => {
	return (
		<p
			className={`w-6 h-6 bg-s-blue rounded-full text-white font-semibold flex items-center justify-center mr-2.5 ${className}`}
		>
			{children}
		</p>
	)
}

export default Number
