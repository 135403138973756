import create from "zustand"

interface PreviewStore extends Record<string, unknown> {
	preview: [type: string, data: string] | undefined
	showPreview: boolean

	requestPreview: (type: string, data: string) => void
	closePreview: () => void
}

export const usePreviewStore = create<PreviewStore>((set, get) => {
	return {
		preview: undefined,
		showPreview: false,

		requestPreview: (type, data) => {
			set({ preview: [type, data], showPreview: true })
		},
		closePreview: () => {
			set({ showPreview: false })
		},
	}
})
