import { ReactNode, useRef } from "react"
import DndContainer from "src/components/dndContainer/dndContainer"
import Icon, { IconImage, MoveIcon } from "src/components/iconImage/iconImage"
import Number from "src/components/number/number"
import useDnd from "../../hooks/useDnd"
import { usePreviewStore } from "../../state/stores/previewStore"

interface ScheduleItemProps {
	id: string
	type: string
	data: string
	text: string
	index: number
	indexOffset?: number
	move: (dragIndex: any, hoverIndex: any) => void
	movable: boolean
	content: ReactNode
	deletable: boolean
	onDelete: () => void
	redBorder?: boolean
}

const ScheduleItem = ({
	id,
	text,
	data,
	index,
	indexOffset = 2,
	move,
	movable = true,
	content = undefined,
	deletable = true,
	onDelete,
	redBorder = false,
	type,
}: ScheduleItemProps) => {
	const ref = useRef<HTMLDivElement>(null!)
	const opacity = useDnd(ref, id, index, move, movable)

	const requestPreview = usePreviewStore((state) => state.requestPreview)

	return (
		<DndContainer
			className={`${redBorder ? "border-s-red" : ""}`}
			opacity={opacity}
			ref={ref}
		>
			<div className="flex flex-row items-center justify-start flex-1">
				<Number>{index + indexOffset}</Number>
				{content ? content : text}
			</div>
			<div className="flex flex-row items-center">
				{["patient", "media"].includes(type) && (
					<Icon
						iconImage={IconImage.Preview}
						onClick={() => {
							requestPreview(type, data)
						}}
					/>
				)}
				{deletable && <Icon iconImage={IconImage.Trash} onClick={onDelete} />}
				{movable && <MoveIcon />}
			</div>
		</DndContainer>
	)
}

export default ScheduleItem
