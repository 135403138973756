import create from "zustand"

interface QuizStore extends Record<string, unknown> {
	votes: number[]
	showResults: boolean
	voters: number
	participants: number

	onQuiz: (data: Record<string, unknown>) => void
	reset: () => void
}

export const useQuizStore = create<QuizStore>((set, get) => {
	return {
		votes: [],
		showResults: false,
		voters: 0,
		participants: 0,

		onQuiz: (data) => {
			set({
				votes: data.votes as number[],
				showResults: data.showResults as boolean,
				voters: data.voters as number,
				participants: data.participants as number,
			})
		},

		reset: () => {
			set({
				votes: [],
				showResults: false,
				voters: 0,
				participants: 0,
			})
		},
	}
})
