import update from "immutability-helper"
import { useCallback, useEffect, useState } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import Input from "src/components/input/input"
import useSWR, { useSWRConfig } from "swr"
import AddItem from "../../../../components/addItem/addItem"
import QuestionAnswer from "../../../../components/answer/answer"
import DashboardHeader from "../../../../components/dashboardHeader/dashboardHeader"
import { HasAuth } from "../../../../components/privateRoute/privateRoute"
import SegmentedControl from "../../../../components/segmentedControl/segmentedControl"
import MediaUpload from "../../../../components/upload/upload"
import useHandleChange from "../../../../hooks/useHandleChange"
import { fetchApiAuthorized } from "../../../../network/fetch"
import { useErrorStore } from "../../../../state/stores/errorStore"

const CreateQuiz = ({ auth }: HasAuth) => {
	const { t } = useTranslation()
	const { id } = useParams() as Record<string, string>
	const navigate = useNavigate()
	const { mutate } = useSWRConfig()

	const { data: currentQuiz, mutate: mutateLocally } = useSWR(
		id ? [`/quizzes/${id}`, auth.token, auth.expiresIn] : null,
		fetchApiAuthorized
	)

	const [question, setQuestion] = useHandleChange("")
	const [answers, setAnswers] = useState<Record<string, string | number>[]>([])
	const [solution, setSolution] = useState<number[]>([])
	const [image, setImage] = useState<File | undefined>(undefined)
	const [multiplePossible, setMultiplePossible] = useState(false)

	useEffect(() => {
		if (currentQuiz) {
			setQuestion(currentQuiz.question as string)

			setAnswers(
				currentQuiz.answers.map((v: any, i: any) => {
					return { id: i, text: v }
				})
			)
			setSolution(currentQuiz.solution)
			setImage(currentQuiz.imageUrl)
			setMultiplePossible(currentQuiz.multiplePossible)
		}
	}, [currentQuiz, setQuestion, setAnswers])

	const create = async () => {
		const _answers = answers.map((v: any) => v.text).filter((v) => v !== "")
		let formData = new FormData()
		//TODO: check if correct
		formData.append("file", image as Blob)
		formData.append(
			"json",
			JSON.stringify({
				question,
				answers: _answers,
				solution,
				multiplePossible,
			})
		)

		const endpoint = id ? `/quizzes/${id}` : "/quizzes/create"

		const success = await fetchApiAuthorized(
			endpoint,
			auth.token,
			auth.expiresIn,
			formData,
			id ? "PUT" : "POST"
		)

		if (success) {
			mutate("/quizzes")
			// trigger(["/quizzes", auth.token, auth.expiresIn], true)

			navigate("/moderator/quiz")
		}
	}

	const movePart = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const dragCard = answers[dragIndex]
			setAnswers(
				update(answers, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragCard],
					],
				})
			)
		},
		[answers]
	)

	useEffect(() => {
		if (solution.length === 1) {
			setMultiplePossible(false)
		} else if (solution.length > 1) {
			setMultiplePossible(true)
		}
	}, [solution])

	const deletionConfirmation = useErrorStore(
		(state) => state.deleteConfirmation
	)

	return (
		<>
			<DashboardHeader
				buttonDisabled={
					question.length === 0 ||
					answers.length === 0 ||
					answers.filter((v: any) => v.text.length === 0).length > 0
				}
				buttonOnClick={create}
				buttonTitle={t("save") as string}
				cancelButtonOnClick={() => {
					navigate("/moderator/quiz")
				}}
				cancelButtonTitle={t("cancel") as string}
				title={
					id ? (t("edit_question") as string) : (t("create_question") as string)
				}
			/>

			<div className="flex flex-col py-2 px-5 m-2">
				<div className="flex flex-row items-baseline">
					<p className="w-16">{t("question")}</p>
					<Input
						className="flex-1 mb-5"
						placeholder="…"
						value={question}
						onChange={setQuestion}
					/>
				</div>

				<MediaUpload
					accept="image/*"
					existingData={currentQuiz && currentQuiz.imageUrl}
					title={t("image") as string}
					onChange={(file: File) => setImage(file)}
					onDelete={() =>
						deletionConfirmation(async () => {
							await fetchApiAuthorized(
								`/quizzes/${id}/image`,
								auth.token,
								auth.expiresIn,
								undefined,
								"DELETE"
							)

							mutateLocally()
						})
					}
				/>

				<div className="flex flex-row items-center justify-between mb-3">
					<p>{t("answers")}</p>

					<SegmentedControl
						disabled={solution.length > 0}
						options={[
							{
								title:
									solution.length === 0
										? "Mehrere Antworten möglich"
										: "Mehrere richtige Antworten",
								value: true,
							},
							{
								title:
									solution.length === 0
										? "Eine Antwort möglich"
										: "Eine richtige Antwort",
								value: false,
							},
						]}
						value={multiplePossible}
						onChange={setMultiplePossible}
					/>
				</div>

				<>
					<DndProvider backend={HTML5Backend}>
						{answers.map((v: Record<string, string | number>, i) => {
							return (
								<QuestionAnswer
									id={v.id}
									index={i}
									initialValue={v.text as string}
									isSolution={solution.includes(i)}
									key={v.id}
									moveCard={movePart}
									setSolution={() =>
										setSolution(
											solution.includes(i)
												? solution.filter((v) => v !== i)
												: [...solution, i]
										)
									}
									onChangeAnswer={(v: string) => {
										setAnswers((answer) => {
											answers[i].text = v
											return [...answers]
										})
									}}
									onDelete={() => {
										setAnswers((v) => v.filter((_, idx) => idx !== i))
									}}
								/>
							)
						})}
					</DndProvider>
					<AddItem
						onClick={() => {
							setAnswers((v) => [...v, { text: "", id: v.length }])
						}}
					/>
				</>
			</div>
		</>
	)
}

export default CreateQuiz
