import { useTranslation } from "react-i18next"
import Button, { ButtonTheme } from "src/components/button/button"

interface DeletionConfimationAlertProps {
	onClear: () => void
	onDelete: () => void
}

export const DeletionConfimationAlert = ({
	onClear,
	onDelete,
}: DeletionConfimationAlertProps) => {
	const { t } = useTranslation()

	return (
		<>
			<Button
				className="min-w-[100px] mr-0 md:mr-2 mt-2 md:mt-0"
				theme={ButtonTheme.White}
				onClick={() => onClear()}
			>
				{t("cancel")}
			</Button>
			<Button
				className="min-w-[100px]"
				theme={ButtonTheme.WhiteOnRed}
				onClick={() => {
					onClear()

					onDelete()
				}}
			>
				{t("delete")}
			</Button>
		</>
	)
}

export const RestartServerAlert = ({
	onClear,
	onDelete,
}: DeletionConfimationAlertProps) => {
	const { t } = useTranslation()

	return (
		<>
			<Button
				className="min-w-[100px] mr-0 md:mr-2 mt-2 md:mt-0"
				theme={ButtonTheme.White}
				onClick={() => onClear()}
			>
				{t("no")}
			</Button>
			<Button
				className="min-w-[100px]"
				theme={ButtonTheme.WhiteOnRed}
				onClick={() => {
					onClear()

					onDelete()
				}}
			>
				{t("yes")}
			</Button>
		</>
	)
}
