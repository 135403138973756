import moment from "moment"
import { useState } from "react"
import LoadingBlue from "../../assets/images/loadingBlue.gif"
import MicrophoneOn from "../../assets/images/microphone.svg"
import MicrophoneOff from "../../assets/images/microphoneOff.svg"
import SpeakerOff from "../../assets/images/speakerOff.svg"
import SpeakerOn from "../../assets/images/speakerOn.svg"
import { useChatStore } from "../../state/stores/chatStore"
import { useCommunicationStore } from "../../state/stores/communicationStore"
import { iconForEmote } from "../../utils/iconForEmote"
import Expire from "../expire/expire"
import MediaStream from "../video/mediaStream"

interface ParticipantsProps {
	data: Record<string, unknown>
	mutable: boolean
}

const Participant = ({ data, mutable }: ParticipantsProps) => {
	const messages = useChatStore((state) => state.messages)

	const emotes = messages.filter((v) => v.emote !== undefined)
	const myEmotes = emotes
		.filter((v) => v.id === data.id)
		.map((v) => {
			const time = moment(v.timestamp as Date)
			v.deltaTime = moment.duration(moment().diff(time)).asSeconds()
			return v
		})
		.filter((v) => (v.deltaTime as number) < 5)

	const participants = useCommunicationStore((state) => state.participants)
	const activeSpeakerId = useCommunicationStore(
		(state) => state.activeSpeakerId
	)
	const participant = participants[data.email as string]

	const [mute, setMute] = useState(false)
	const [isSpeakerOff, setIsSpeakerOff] = useState(false)

	return (
		<div
			className={`flex flex-row items-center justify-between border-2 shadow-md bg-white ${
				activeSpeakerId === data.email
					? "border-s-blue p-0.5"
					: "border-transparent p-0"
			} rounded-xl`}
		>
			<p className="ml-3 my-1">{data.name as string}</p>

			<div className="flex flex-row justify-end w-24">
				{myEmotes.length > 0 && (
					<Expire delay={4000}>
						<img
							alt={myEmotes[myEmotes.length - 1].emote as string}
							src={iconForEmote(myEmotes[myEmotes.length - 1].emote)}
						/>
					</Expire>
				)}

				{participant && (
					<MediaStream
						enableAudio={!isSpeakerOff}
						isOwner={false}
						participant={participant}
						showVideo={false}
						onAudioMute={(disabled) => {
							setMute(disabled)
						}}
					/>
				)}

				{participant && mutable && (
					<img
						alt=""
						src={isSpeakerOff ? SpeakerOff : SpeakerOn}
						style={{ cursor: "pointer" }}
						onClick={() => setIsSpeakerOff((previous) => !previous)}
					/>
				)}
				{participant && (
					<img alt="" src={mute ? MicrophoneOff : MicrophoneOn} />
				)}

				{participant === undefined && (
					<img alt="Loading" src={LoadingBlue} width="80px" />
				)}
			</div>
		</div>
	)
}

export default Participant
