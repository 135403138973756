import { useCallback, useEffect, useRef, useState } from "react"

const useHandleChange = <T>(
	initial: T,
	onChange: ((value: T) => void) | undefined = undefined
) => {
	const [value, setValue] = useState<T>(initial)
	const lastValue = useRef<T>(initial)

	const handleChange = useCallback<T | any>((event: T | any) => {
		if (event && event.target) {
			setValue(event.target.value)
		} else {
			setValue(event)
		}
	}, [])

	useEffect(() => {
		setValue(initial)
	}, [initial])

	useEffect(() => {
		if (onChange && lastValue.current !== value) {
			onChange(value)

			lastValue.current = value
		}
	}, [value, onChange])

	return [value, handleChange]
}

export default useHandleChange
