import Button, { ButtonTheme } from "src/components/button/button"

interface SegmentedControlOption {
	title: string
	value: any
}

interface SegmentedControlProps {
	options: SegmentedControlOption[]
	disabled?: boolean
	value: any
	onChange: (value: any) => void
}

const SegmentedControl = ({
	options,
	disabled = false,
	value,
	onChange,
}: SegmentedControlProps) => {
	return (
		<div className="flex flex-row bg-white rounded-xl">
			{options &&
				options.map((option) => (
					<Button
						className={`disabled:${
							value === option.value ? "text-slate-400" : "text-white"
						} shadow-none ${
							disabled
								? value === option.value
									? "text-white"
									: "text-slate-400"
								: ""
						}`}
						disabled={disabled}
						key={option.title as string}
						theme={
							value === option.value ? ButtonTheme.Blue : ButtonTheme.White
						}
						onClick={() => onChange(option.value)}
					>
						{option.title as string}
					</Button>
				))}
		</div>
	)
}

export default SegmentedControl
