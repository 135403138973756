import { ReactNode } from "react"
import Button, { ButtonTheme } from "src/components/button/button"

interface DashboardHeaderProps {
	title: string
	titleAddition?: string | React.ReactElement
	titleOnClick?: () => void
	buttonOnClick?: () => void
	buttonTitle?: string
	buttonDisabled?: boolean
	cancelButtonOnClick?: () => void
	cancelButtonTitle?: string
	additional?: ReactNode
	loading?: boolean
}

const DashboardHeader = ({
	title,
	titleAddition,
	titleOnClick,
	buttonOnClick,
	buttonTitle,
	buttonDisabled = false,
	cancelButtonOnClick,
	cancelButtonTitle,
	additional,
	loading = false,
}: DashboardHeaderProps) => {
	let content
	if (buttonOnClick && cancelButtonOnClick) {
		content = (
			<div className="flex flex-row">
				<Button theme={ButtonTheme.White} onClick={cancelButtonOnClick}>
					{cancelButtonTitle}
				</Button>
				<Button
					className="ml-2.5"
					disabled={buttonDisabled}
					loading={loading}
					onClick={buttonOnClick}
				>
					{buttonTitle}
				</Button>
			</div>
		)
	} else if (buttonOnClick) {
		content = (
			<Button disabled={buttonDisabled} onClick={buttonOnClick}>
				{buttonTitle}
			</Button>
		)
	}

	return (
		<div
			className={`flex flex-row items-center justify-between border-b border-b-slate-200 bg-white bg-opacity-70 sticky top-0 z-40 backdrop-blur-lg backdrop-brightness-110 backdrop-saturate-200 backdrop-filter h-20`}
			style={{
				padding: buttonOnClick ? "22px 20px 20px 20px" : "33px 20px 31px 20px",
			}}
		>
			<div className="flex flex-row">
				<h4
					className={`${
						titleOnClick ? "text-s-blue font-medium cursor-pointer" : ""
					}`}
					onClick={titleOnClick}
				>
					{title}
				</h4>
				{titleAddition && (
					<h4 className="m-0 flex flex-row">&nbsp;{titleAddition}</h4>
				)}
			</div>
			{additional}
			{content}
		</div>
	)
}

export default DashboardHeader
