import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import useSWR from "swr"
import DashboardBody from "../../../components/dashboardBody/dashboardBody"
import DashboardHeader from "../../../components/dashboardHeader/dashboardHeader"
import ListView from "../../../components/list/list"
import { HasAuth } from "../../../components/privateRoute/privateRoute"
import { fetchApiAuthorized } from "../../../network/fetch"

const Users = ({ auth }: HasAuth) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { data: moderators } = useSWR(
		["/users/moderators", auth.token, auth.expiresIn],
		fetchApiAuthorized
	)
	const { data: moderator } = useSWR(
		["/users/current", auth.token, auth.expiresIn],
		fetchApiAuthorized
	)

	const onCreate = () => {
		navigate("/moderator/user/create")
	}

	return (
		<DashboardBody>
			<DashboardHeader
				buttonOnClick={moderator && moderator.role === "admin" && onCreate}
				buttonTitle={t("create_user") as string}
				title={t("user") as string}
			/>

			<ListView
				components={(value: Record<string, unknown>) =>
					!value.activated && <p className="text-s-red">{t("not_activated")}</p>
				}
				data={moderators}
				loading={moderators === undefined || moderator === undefined}
				showDelete={(v: any) => {
					if (moderator && moderator.role === "admin") {
						return v.id !== moderator.id
					} else {
						return false
					}
				}}
				title="name"
				onDelete={(v) =>
					fetchApiAuthorized(
						`users/${v.id}`,
						auth.token,
						auth.expiresIn,
						{},
						"DELETE"
					)
				}
				onEdit={(v) => navigate(`/moderator/user/${v._id}`)}
			/>
		</DashboardBody>
	)
}

export default Users
