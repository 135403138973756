import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Button, { ButtonTheme } from "src/components/button/button"
// @ts-ignore
import Video from "../../../assets/videos/chatDemo.mp4"
import { iconForEmote } from "../../../utils/iconForEmote"

const Emote = ({
	animation = false,
	emote = "",
	headline = "",
	subline = "",
	delay = "",
}) => {
	return (
		<div
			className="flex flex-col items-center translate-all w-[140px]"
			style={{
				opacity: animation ? 1 : 0,
				transform: animation ? "scale(1)" : "scale(1.3)",
				transitionDelay: delay,
			}}
		>
			<Button className="w-11 h-11 !p-0" theme={ButtonTheme.White}>
				<img alt="Emote" src={iconForEmote(emote)} width="100%" />
			</Button>
			<h3 className="mt-2.5 mb-1.5">{headline}</h3>
			<p className="text-center">{subline}</p>
		</div>
	)
}

const ChatTutorial = () => {
	const { t } = useTranslation()
	const [animation, animate] = useState(false)

	useEffect(() => {
		setTimeout(() => animate(true), 2400)
	}, [])

	return (
		<>
			<div className="flex flex-col items-center">
				<h2 className="mt-20 mb-5">Chat emotes</h2>
				<p className="text-center">{t("tutorial_chat_1")}</p>
			</div>

			<video loop={true} playsInline={true} src={Video} autoPlay muted />

			<div className="flex flex-row items-start justify-between w-full max-w-5xl">
				<Emote
					animation={animation}
					delay=""
					emote="yes"
					headline={t("yes") as string}
					subline={t("tutorial_chat_2") as string}
				/>
				<Emote
					animation={animation}
					delay="600ms"
					emote="no"
					headline={t("no") as string}
					subline={t("tutorial_chat_3") as string}
				/>
				<Emote
					animation={animation}
					delay="1200ms"
					emote="raise_hand"
					headline={t("raise_hand") as string}
					subline={t("tutorial_chat_4") as string}
				/>
				<Emote
					animation={animation}
					delay="1800ms"
					emote="slower"
					headline={t("slower") as string}
					subline={t("tutorial_chat_5") as string}
				/>
				<Emote
					animation={animation}
					delay="2400ms"
					emote="question"
					headline={t("question") as string}
					subline={t("tutorial_chat_6") as string}
				/>
			</div>
		</>
	)
}

export default ChatTutorial
