import { useEffect, useState } from "react"
import Button, { ButtonTheme } from "src/components/button/button"
import useSWR from "swr"
import CameraOn from "../../assets/images/camera.svg"
import CameraOff from "../../assets/images/cameraOff.svg"
import LoadingBlue from "../../assets/images/loadingBlue.gif"
import MicrophoneOn from "../../assets/images/microphone.svg"
import MicrophoneOff from "../../assets/images/microphoneOff.svg"
import Settings from "../../assets/images/settings.svg"
import { fetchApiAuthorized } from "../../network/fetch"
import { AuthData } from "../../state/stores/authStore"
import {
	showSettingsValue,
	useCameraSettingsStore,
} from "../../state/stores/cameraSettingsStore"
import { useCommunicationStore } from "../../state/stores/communicationStore"
import { useSceneStore } from "../../state/stores/sceneStore"

interface ControlsProps {
	isModerator: boolean
	auth: AuthData
}

const Controls = ({ isModerator, auth }: ControlsProps) => {
	const [isAudioMute, setIsAudioMute] = useState<boolean | undefined>(undefined)
	const [isVideoMute, setIsCameraMute] = useState<boolean | undefined>(
		undefined
	)

	const muteAudio = useCommunicationStore((state) => state.ioActions.muteAudio)
	const muteVideo = useCommunicationStore((state) => state.ioActions.muteVideo)

	const { data } = useSWR(
		["/users/current", auth.token, auth.expiresIn],
		fetchApiAuthorized
	)

	useEffect(() => {
		const role = data && data.role

		useCommunicationStore.subscribe((state) => {
			if (!data) return

			const localParticipant =
				state.participants[
					role === "admin" || role === "moderator" ? role : data.email
				]

			if (localParticipant) {
				setIsAudioMute(!localParticipant.audio)
				setIsCameraMute(!localParticipant.video)
			}
		})
	}, [data])

	const index = useSceneStore((state) => state.index)
	useEffect(() => {
		if (!isModerator) {
			muteAudio(true)
		}
	}, [index, isModerator, muteAudio])

	return (
		<div className="flex flex-row rounded-xl backdrop-blur-lg backdrop-brightness-110 backdrop-saturate-150 bg-white bg-opacity-70">
			{isAudioMute === undefined ? (
				<img alt="Loading" className="py-4" src={LoadingBlue} width="80px" />
			) : (
				<>
					{isModerator && (
						<Button
							className="rounded-none p-2 bg-transparent shadow-none"
							theme={ButtonTheme.White}
							onClick={() => muteVideo(!isVideoMute)}
						>
							<img
								alt={isVideoMute ? "Camera is off" : "Camera is on"}
								height="29px"
								src={isVideoMute ? CameraOff : CameraOn}
							/>
						</Button>
					)}
					<Button
						className="rounded-none p-2 bg-transparent shadow-none"
						theme={ButtonTheme.White}
						onClick={() => muteAudio(!isAudioMute)}
					>
						<img
							alt={isAudioMute ? "Microphone is off" : "Microphone is on"}
							height="29px"
							src={isAudioMute ? MicrophoneOff : MicrophoneOn}
						/>
					</Button>
					<Button
						className="rounded-none p-2 bg-transparent shadow-none"
						theme={ButtonTheme.White}
						onClick={() =>
							useCameraSettingsStore.setState({
								showSettings: isModerator
									? showSettingsValue.moderator
									: showSettingsValue.user,
							})
						}
					>
						<img alt="Settings" height="29px" src={Settings} />
					</Button>
				</>
			)}
		</div>
	)
}

export default Controls
